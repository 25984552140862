import React from "react";

import { DatePicker, IDatePickerProps } from "office-ui-fabric-react";
import { DisplayContext } from "../../DisplayContext";

const formatDateUK = (date?: Date | undefined) => {
    if (date && date.toString() !== "Invalid Date") {
        return date.toLocaleDateString();
    } else {
        return "";
    }
};

const UKDatePicker = (props: IDatePickerProps & { 
    dateString: string | undefined,
    errorMessage?: string,
}) => {
    const passedProps = {...props};
    if (passedProps.dateString && !passedProps.value) {
        passedProps.value = new Date(passedProps.dateString);
    }
    return (
        <DisplayContext.Consumer>
            {({ darkMode }) => (
                <div>
                    <DatePicker
                        styles={{callout: {
                            " .ms-DatePicker-goToday": {
                                right: 13
                            }
                        }}}
                        {...passedProps}
                        
                        formatDate={formatDateUK}
                    />
                    { props.errorMessage !== undefined && (
                        <span style={{fontSize: "12px", fontWeight: 400, color: darkMode ? "#e28388" : "#a80000"}}>{props.errorMessage}</span>
                    )}
                </div>

            )}
        </DisplayContext.Consumer>
    );
};

export default UKDatePicker;
