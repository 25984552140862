import { IUserProfile } from "../types";

export async function searchUserByEmail(emailSearch: string) {
    const result = await fetch(`/api/users/app-users/${emailSearch}`, {
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });
    if (result.ok) {
        return await result.json() as IUserProfile[];
    } else {
        throw new Error('Unable to get search results');
    }
}

export async function assignRoles(userId: number, roleIds: number[]) {
    const result = await fetch(`/api/users/${userId}/assign-roles`, {
        method: 'POST',
        body: JSON.stringify(roleIds),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        credentials: 'include',
    });
    if (result.ok) {
        return true;
    } else {
        return false;
    }
}

export async function assignBusinessRelationships(userId: number, businessIds: number[]) {
    const result = await fetch(`/api/users/${userId}/assign-business`, {
        method: 'POST',
        body: JSON.stringify(businessIds),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        credentials: 'include',
    });
    if (result.ok) {
        return true;
    } else {
        return false;
    }
}
