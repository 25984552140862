import Moment from "moment";
import React from "react";
import EditUpdateDialog from "../components/EditUpdateDialog";
import { DevelopmentFormContext } from "../DevelopmentFormContext";

import { 
    CommandBar, 
    DetailsList, 
    IColumn, 
    Icon, 
    Label, 
    PrimaryButton, 
    TextField, 
    Toggle, 
    TooltipHost,
    Selection,
    Spinner, 
    Stack,
    SelectionMode,
    Dialog,
    DialogFooter,
    DefaultButton,
} from "office-ui-fabric-react";
import { IDevelopmentUpdate } from "../../types";

import * as API from "../../api/updates";
import styles from "./Updates.module.scss";
import { IDevelopmentFormProps } from "../DevelopmentForm2";

export interface IUpdatesProps {
    initialFieldText?: string;
    onChangeUpdateField: (updateText: string | undefined) => void;
    // onNewUpdate: (updatedDevelopment: IDevelopmentItem) => void;
    developmentId: number;
}
export interface IUpdatesState {
    loading: boolean;
    isPrivate: boolean;
    saving: boolean;
    selectedUpdate?: IDevelopmentUpdate;
    editingUpdate?: IDevelopmentUpdate;
    showEditDialog: boolean;
    showDeleteWarning: boolean;
    updates: IDevelopmentUpdate[] | undefined;
    updateTextValue: string | undefined;
}

export default class Updates extends React.Component<IUpdatesProps, IUpdatesState> {
    // declare context: React.ContextType<typeof DevelopmentFormContext>;
    static contextType?: React.Context<Partial<IDevelopmentFormProps>> = DevelopmentFormContext;
    private _columns: IColumn[] = this._buildColumns();
    private _selection: Selection = new Selection({
        onSelectionChanged: () => {
            const selection = this._selection.getSelection();
            this.setState({
                ...this.state,
                selectedUpdate: (selection[0] as IDevelopmentUpdate) || undefined,
            });
        }
    });
    constructor(props: IUpdatesProps) {
        super(props);
        this._loadUpdates = this._loadUpdates.bind(this);
        this._buildColumns = this._buildColumns.bind(this);
        this._addNewUpdate = this._addNewUpdate.bind(this);
        this.state = {
            loading: true,
            isPrivate: true,
            saving: false,
            showDeleteWarning: false,
            showEditDialog: false,
            updates: undefined,
            updateTextValue: props.initialFieldText,
        };
    }
    private get LatestUpdate() {
        if (this.state.updates && this.state.updates.length) {
            return this.state.updates[0].entry;
        } else {
            return undefined;
        }
    }
    public componentDidMount() {
        document.addEventListener('updateAdded', this._handleExternalUpdate);
        this._loadUpdates(true);
    }
    public componentWillUnmount() {
        document.removeEventListener('updateAdded', this._handleExternalUpdate);
    }
    public render() {
        const { editingUpdate, loading, isPrivate, saving, selectedUpdate, showDeleteWarning, showEditDialog, updates, updateTextValue } = this.state;
        if (!this.props.developmentId) return null;
        if (!loading && updates !== undefined) {
            return (
                (<div>
                    { this.context.customer === false && (
                        <TextField label="Add Latest Update"
                            multiline={true}
                            value={updateTextValue}
                            autoFocus={true}
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
                                this.props.onChangeUpdateField(newValue);
                                this.setState({
                                    ...this.state,
                                    updateTextValue: newValue,
                                });
                            }} />
                    )}
                    { this.context.customer === false && (
                        <div style={{textAlign: "right", marginTop: "20px", marginBottom: "20px"}}>
                            { saving && (
                                <Spinner className={styles.inlineSpinner} />
                            )}
                            <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                                <Stack horizontal verticalAlign="center">
                                    <Icon iconName={isPrivate ? "Hide" : "View"} style={{marginRight:10, fontSize:24}} />
                                    <Toggle disabled={saving} styles={{root: {marginBottom: 0}}} onText="Public" offText="Private" checked={!isPrivate} onChange={(e, isChecked) => {
                                        this.setState({
                                            ...this.state,
                                            isPrivate: !isChecked
                                        });
                                    }}/>
                                </Stack>
                                <PrimaryButton 
                                    iconProps={{
                                        iconName: "Add",
                                    }}
                                    text="Add Update"
                                    disabled={saving}
                                    onClick={this._addNewUpdate}
                                    />
                            </Stack>
                        </div>
                    )}
                    <hr />
                    <Label>Previous Updates</Label>
                    <CommandBar items={[
                        {
                            key: "0",
                            disabled: this.context.customer || this.state.selectedUpdate === undefined,
                            text: "Edit",
                            iconProps: { iconName: "Edit" },
                            onClick: () => {
                                this.setState({
                                    ...this.state,
                                    showEditDialog: true,
                                    editingUpdate: {...this.state.selectedUpdate}
                                });
                            }
                        },
                        {
                            key: "1",
                            disabled: this.context.customer || this.state.selectedUpdate === undefined,
                            text: "Delete",
                            iconProps: { iconName: "Delete" },
                            onClick: () => {
                                this.setState({
                                    ...this.state,
                                    showDeleteWarning: true,
                                });
                            }
                        }
                    ]} />
                    <DetailsList 
                        columns={this._columns}
                        items={updates}
                        selectionMode={SelectionMode.single}
                        selection={this._selection}
                    />
                    { editingUpdate !== undefined && showEditDialog === true && (
                        <EditUpdateDialog
                            update={editingUpdate}
                            onDismiss={() => {
                                this.setState({
                                    ...this.state,
                                    editingUpdate: undefined,
                                    showEditDialog: false,
                                });
                            }}
                            onSave={async (editedUpdate) => {
                                const modifyResponse = await API.modifyUpdate(editingUpdate.id, editingUpdate.entry, editingUpdate.private, editingUpdate.date);
                                if (modifyResponse.success) {
                                    this.setState({
                                        ...this.state,
                                        editingUpdate: undefined,
                                        showEditDialog: false,
                                    }, this._loadUpdates);
                                }
                            }}
                            onUpdated={(editedUpdate) => {
                                this.setState({
                                    ...this.state,
                                    editingUpdate: editedUpdate
                                });
                            }}
                        />
                    )}
                    <Dialog dialogContentProps={{
                        title: "Delete Update", subText: "Are you sure?", onDismiss: () => {
                            this.setState({
                                ...this.state,
                                showDeleteWarning: false,
                            });
                        }
                    }} hidden={!(showDeleteWarning === true && selectedUpdate !== undefined)}>
                        <DialogFooter>
                            <PrimaryButton text="Yes" onClick={async () => {
                                const deletionResult = await API.deleteUpdate(this.state.selectedUpdate.id);
                                if (deletionResult.ok) {
                                    this.setState({
                                        ...this.state,
                                        showDeleteWarning: false,
                                    }, this._loadUpdates);
                                } else {
                                    const responseTxt = await deletionResult.text();
                                    if (responseTxt === "Related to saved activity") {
                                        alert('This update was created via the Activity page. You can edit here, or visit the Activity page to remove it and make other changes.');
                                        this.setState({
                                            ...this.state,
                                            showDeleteWarning: false,
                                        });
                                    }
                                }
                            }} />
                            <DefaultButton text="No" onClick={() => {
                                this.setState({
                                    ...this.state,
                                    showDeleteWarning: false,
                                });
                            }} />
                        </DialogFooter>
                    </Dialog>
                </div>)
            );
        } else {
            return <Spinner />;
        }
    }
    private _handleExternalUpdate() {
        this._loadUpdates(false);
    }
    private async _loadUpdates(initialLoad?: boolean) {
        const updatesResponse = await API.getUpdatesByDevelopmentId(this.props.developmentId);
        if (updatesResponse && updatesResponse.success) {
            this.setState({
                ...this.state,
                updateTextValue: initialLoad ? this.props.initialFieldText : this.state.updateTextValue,
                loading: false,
                saving: false,
                updates: updatesResponse.updates,
            });
        }   
    }
    private async _addNewUpdate() {
        if (this.state.updateTextValue && this.state.updateTextValue.length > 0) {
            this.setState({
                ...this.state,
                saving: true,
            }, async () => {
                if (this.state.updateTextValue) {
                    const response = await API.addLatestUpdateToDevelopment(this.props.developmentId, this.state.updateTextValue, this.state.isPrivate);
                    if (!response) alert("Unable to save latest update");
                    if (response) {
                        this.setState({
                            ...this.state,
                            updateTextValue: "",
                        });
                    }
                }
                this._loadUpdates();
            });
        }
    }
    private _buildColumns(): IColumn[] {
        return [
            {
                className: styles.entryCol,
                fieldName: "entry",
                key: "0",
                minWidth: 100,
                name: "Update",
            },
            {
                className: styles.dateCol,
                fieldName: "date",
                key: "1",
                minWidth: 180,
                name: "Date / Author",
                onRender: (item?: IDevelopmentUpdate, index?: number | undefined, column?: IColumn | undefined) => {
                    if (item) {
                        return (
                            <span>{Moment(item.date).format("DD/MM/YYYY HH:mm:SS")} by<br/>
                                {item.updatedByName}  
                            </span>
                        );
                    }
                },
            },
            {
                fieldName: "private",
                key: "2",
                minWidth: 50,
                maxWidth: 50,
                isIconOnly: true,
                iconName: "View",
                name: "Privacy",
                onRender: (item?: IDevelopmentUpdate, index?: number | undefined, column?: IColumn | undefined) => {
                    if (item && item.private === false) {
                        return (
                            <TooltipHost content="Public update. Account managers and/or clients may be able to see this update.">
                                <Icon iconName="View" />
                            </TooltipHost>
                        );
                    }
                }
            }
        ];
    }
}
