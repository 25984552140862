import { Dropdown, Icon, IDropdownOption, Pivot, PivotItem, TooltipHost } from "office-ui-fabric-react";
import * as React from "react";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { IDevelopmentQuery } from "../../../types";

import styles from "./FilterMenu.module.scss";

declare var ResizeObserver: any;

enum DisplayMode {
    Dropdown,
    Pivot
}

export interface ISortConfig {
    fieldName: string;
    ascending: boolean;
}

interface IFilterMenuItem {
    /** Filters the full table view of BaseView derived views so that only certain columns show. */
    columnFilter?: (columns: any) => any;
    /** Determines if the current menu item is the default when not presented with a filter via URL */
    isDefault?: boolean;
    /** Determines if the count of items is shown alongside the menu item */
    showCount?: boolean;
    /** The text / title for the menu item */
    text: string;
    /** The Office UI icon for the menu item */
    iconName: string;
    /** The query used to fetch items from the server */
    itemQuery?: Partial<IDevelopmentQuery>;
    /** The URL that should be used for the link in lieu of an itemQuery */
    href?: string;
    /** The initial sort that the table component should use when this filtered view is loaded */
    initialSort?: ISortConfig;
    /** Displays a Gantt view in place of the table */
    ganttView?: boolean;
}
interface IFilterMenuItemWithQuery extends IFilterMenuItem {
    itemQuery: Partial<IDevelopmentQuery>;
}
interface IFilterMenuItemWithHref extends IFilterMenuItem {
    href: string;
}
export type FilterMenuItem = IFilterMenuItemWithQuery | IFilterMenuItemWithHref;

export interface IFilterMenuProps {
    items: any;
    itemCounts: Record<string,number>;
    menuItems: FilterMenuItem[];
    onItemClick: (menuItem: FilterMenuItem) => void;
    showCounts?: boolean;
    selectedFilter?: string;
}

export function FilterMenu(props: IFilterMenuProps) {
    if (!props.menuItems || !props.menuItems.length) throw new Error("FilterMenu must specify menuItems");

    const history = useHistory();
    const container = useRef<HTMLDivElement>();
    const displayModeRef = useRef(DisplayMode.Pivot);
    const [displayMode, setDisplayMode] = useState(DisplayMode.Pivot);
    
    const checkSize = () => {
        const useDropdown = window.innerWidth < 910 ? DisplayMode.Dropdown : DisplayMode.Pivot;
        if (useDropdown !== displayModeRef.current) {
            displayModeRef.current = useDropdown;
            setDisplayMode(useDropdown);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // useEffect(checkSize, [displayMode]);

    useEffect(() => {
        if (container.current) {
            let resizeObserver = new ResizeObserver(checkSize);
            resizeObserver.observe(container.current);
            return () => { resizeObserver.disconnect(); };
        }
        checkSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // If view specifies a default option, use that to begin with, otherwise just use the first menu item as default
    const defaultOptions = props.menuItems.filter((m) => m.isDefault === true);
    const firstOption = props.menuItems[0].text;
    let initialKey: string;
    if (props.selectedFilter) {
        const matchingOptions = props.menuItems.filter((m) => m.text.toLowerCase().replace(/\s/g, '-') === props.selectedFilter);
        if (matchingOptions.length) {
            initialKey = matchingOptions[0].text;
        } else {
            initialKey = firstOption;
        }
    } else {
        initialKey = defaultOptions.length > 0 ? defaultOptions[0].text : firstOption;
    }
    const [ selectedKey, setSelectedKey ] = useState<string>(initialKey);

    const renderItemLink = (menuItem: FilterMenuItem) => {
        return () => {
            const filteredItemCount = props.itemCounts[menuItem.text];
            return (
                <TooltipHost content={menuItem.text}>
                    <span style={{display: "flex", alignItems: "center"}}>
                        <div>
                            <span className={styles.icon}>
                                <Icon iconName={menuItem.iconName} /> 
                            </span>
                            <span>
                                {menuItem.text}
                            </span>
                        </div>
                        { (props.showCounts === true || menuItem.showCount === true) && filteredItemCount !== undefined && (
                            <div className={styles.badge}>{filteredItemCount}</div>
                        )}
                    </span>
                </TooltipHost> 
            );
        };
    };
    
    return (
        <div ref={container}>
            { displayMode === DisplayMode.Dropdown && (
                <div className={styles.dropdown}>
                    <Dropdown
                        styles={{
                            title: {
                                height: 40
                            },
                            dropdownItems: {
                                ">div": {
                                    height: 40,
                                    display: "flex",
                                    alignItems: "center"    
                                },
                                ">div:hover": {
                                    backgroundColor: "#e3e3e3",
                                }
                            }
                        }}
                        onRenderTitle={(selectedOptions) => selectedOptions.length ? renderItemLink(selectedOptions[0].data)() : undefined}
                        onRenderOption={(selectableProps) => <div>{renderItemLink(selectableProps.data as FilterMenuItem)()}</div>}
                        // onRenderItem={(selectableProps) => <div>{renderItemLink(selectableProps.data as FilterMenuItem)()}</div>}
                        options={props.menuItems.map((menuItem, i) => ({
                            key: menuItem.text,
                            itemKey: menuItem.text,
                            text: menuItem.text,
                            data: menuItem
                        } as IDropdownOption))}
                        selectedKey={selectedKey}
                        onChange={(e, option) => {
                            if (option) {
                                // const menuItem = props.menuItems.filter((m) => m.text === option.key)[0];
                                // props.onItemClick(menuItem);
                                setSelectedKey(option.key as string);
                                history.push((option.key as string).toLowerCase().replace(/\s/g, '-'));
                            }
                        }}
                    />
                </div>
            )}
            { displayMode === DisplayMode.Pivot && (
                <Pivot
                    className={styles.pivot}
                    onLinkClick={(item, e) => {
                        if (item) {
                            // const menuItem = props.menuItems.filter((m) => m.text === item.props.itemKey)[0];
                            // props.onItemClick(menuItem);
                            // setSelectedKey(item.props.itemKey);
                            setSelectedKey(item.props.itemKey);
                            history.push(item.props.itemKey.toLowerCase().replace(/\s/g, '-'));
                        }
                    }} 
                    selectedKey={selectedKey}
                >
                    { props.menuItems.map((menuItem, i) => (
                        <PivotItem key={menuItem.text} itemKey={menuItem.text} className={"devStatus" + i} headerText={menuItem.text} 
                            onRenderItemLink={renderItemLink(menuItem)}
                            itemIcon={menuItem.iconName}
                        />
                    )) }
                </Pivot>
            )}
        </div>
    );
}