import * as React from "react";
import { css, DefaultButton, PrimaryButton, Stack, MessageBarType, MessageBar, Spinner } from "office-ui-fabric-react";
import { ICriteria, ISurveyQuestionScore, ISurveyResponse } from "./matrix.types";
import { questions } from "./matrixDefinition";

import logo from "../assets/dev_service.png";
import styles from "./matrixSurvey.module.scss";
import { ViewItem } from "../types";
import { getDevelopment } from "../api";
import { RouteComponentProps, useHistory } from "react-router";
import { loadSurveyResult, saveSurveyResult } from "../api/prioritisation";

export interface ISurveyProps extends RouteComponentProps {}

export const PrioritisationSurvey = (props: ISurveyProps) => {
    
    const history = useHistory();
    const [beginSurvey, setBeginSurvey] = React.useState(false);

    const [loading, setLoading] = React.useState(true);
    const [development, setDevelopment] = React.useState<ViewItem|undefined>();

    // Track X and Y coordinates of mobile device touch to approximate swipe left/right
    const touchX = React.useRef<number[]|undefined>([]);
    const touchY = React.useRef<number[]|undefined>([]);

    // Question to display
    const [questionNumber, setQuestionNumber] = React.useState<number>(0);

    // Store responses, and track status of them
    const [surveyData, setSurveyData] = React.useState<ISurveyResponse>({ responses: [], score: 0 });
    const curQuestion = questions[questionNumber];
    const questionAnswered = surveyData.responses.filter(r => r.id === curQuestion.id).length > 0;
    const allAnswered = surveyData.responses.length === questions.length;

    const loadDevelopment = React.useCallback(async () => {
        const developmentIdStr = (props.match.params as any).developmentId;
        const developmentId = parseInt(developmentIdStr, 10);
        if (isNaN(developmentId) || developmentId < 0) history.push('/');
        const dev = await getDevelopment(developmentId);
        const previousSurvey = await loadSurveyResult(developmentId);
        if (!(dev instanceof Error)) {
            setDevelopment(dev);
        }
        if (!(previousSurvey instanceof Error)) {
            setSurveyData(previousSurvey);
        }
        setLoading(false);
    }, [history, props.match.params]);

    React.useEffect(() => {
        loadDevelopment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // When user touches screen store X/Y coords in num array.
    // Where we store [a,b] - a is where the user started the touch movement
    const touchStart = React.useCallback((ev: TouchEvent) => {
        const touchList = ev.targetTouches;
        if (touchList.length === 1) {
            touchX.current = [touchList.item(0).screenX, undefined];
            touchY.current = [touchList.item(0).screenY, undefined];
        }
    }, []);

    // When user moves their finger on the screen, store X/Y coords in num array(s).
    // Where we store [a,b] - b is where the user's finger is currently
    const touchMove = React.useCallback((ev: TouchEvent) => {
        // ev.preventDefault();
        // ev.stopPropagation();
        if (touchX.current[0] === undefined || touchY.current[0] === undefined) return;
        const touchList = ev.touches;
        // Ignore multi-touch
        if (touchList.length === 1) {
            touchX.current[1] = touchList.item(0).screenX;
            touchY.current[1] = touchList.item(0).screenY;
        } else {
            return;
        }
        const xDiff = touchX.current[0] - touchX.current[1];
        // not using Y coord - const yDiff = touchY.current[0] - touchY.current[1];
        
        // Only act if the user moves more than 50 pixels. Threshold can be adjusted.
        if (Math.abs(xDiff) > 50) {
            if (xDiff > 0 && (questionNumber + 1) < questions.length && questionAnswered) {
                setQuestionNumber(questionNumber + 1);
            } else if (xDiff < 0 && questionNumber > 0) {
                setQuestionNumber(questionNumber - 1);
            }
            touchX.current = [];
            touchY.current = [];
        }

    }, [questionNumber, questionAnswered]);

    // Add event listeners for touch movements when the component loads
    React.useEffect(() => {
        document.addEventListener('touchmove', touchMove, { passive: false });
        document.addEventListener('touchstart', touchStart, { passive: false });
        
        return () => {
            document.removeEventListener('touchmove', touchMove);
            document.removeEventListener('touchstart', touchStart);
        };
    }, [touchStart, touchMove]);

    // Callback for when user answers a question
    const onResponseClick = React.useCallback((question: ICriteria, score: number, response: ISurveyQuestionScore) => {
        let responses = [...surveyData.responses];
        // if there was a previous response to this question remove it
        if (response) {
            responses = responses.filter((r) => r.id !== question.id);
        }
        // push the new response in to the survey data object
        if (!(response && response.id === question.id && response.score === score)) {
            responses.push({
                description: question.description,
                finalScore: score * question.weight,
                id: question.id,
                score,
                title: question.title,
                weight: question.weight,
                evidence: null
            });
        }
        
        setSurveyData({
            ...surveyData,
            responses,
            score: responses.reduce((prev, cur, idx, arr) => prev + cur.finalScore, 0)
        });
    }, [surveyData]);

    // Render

    if (loading) {
        return (
            <Spinner />
        );
    }

    let authorInfo: JSX.Element;
    if (surveyData.authorName && surveyData.created) {
        const created = new Date(surveyData.created);
        const dateStr = `Created by ${surveyData.authorName} ${created.toLocaleDateString()} ${created.toLocaleTimeString()}`;
        authorInfo = <div>{dateStr}</div>;
    }

    return (
        <div style={{margin: "0 auto", maxWidth: 900}}>
            <div className={styles.logoContainer}>
                <img className={styles.logo} src={logo} alt="Development Service Logo"/>
            </div>
            <div>
                <h2>{development.title}</h2>
                <h3>Development Survey</h3>
                { beginSurvey === false && (
                    <div>
                        <MessageBar messageBarType={MessageBarType.info}>
                            You may only complete or amend this survey if you requested this development, or if you are responsible for prioritisation.
                        </MessageBar>
                        <p>This survey helps the Trust, account managers and the development team to prioritise developments appropriately. Please be aware your responses will be reviewed.</p>
                        <PrimaryButton text="Begin Survey" onClick={() => {
                            setBeginSurvey(true);
                        }} />
                    </div>
                )}
                { beginSurvey === true && (
                    <>
                        <p>Please select from the options below and swipe or click Next to continue:</p>
                        <div style={{width: "100%", overflow: "hidden"}}>
                            <div className={styles.questionsContainer} style={{width: `calc(100% * ${questions.length}`, left: `${(questionNumber * 100 * -1)}%`}}>
                                { questions.map((question, qIdx) => (
                                    <Stack key={`q_${qIdx}`} style={{width: "100%", top: 0, left: `${(qIdx * 100)}%`}}>
                                        <h3>{question.title}</h3>
                                        <p>{question.description}</p>
                                        <Stack className={styles.buttonRow} wrap horizontal horizontalAlign="stretch" verticalAlign="stretch">
                                            { Object.keys(question.scoreDefinitions).map((key) => {
                                                const keyInt = parseInt(key, 10);
                                                let selected: string;
                                                const response: ISurveyQuestionScore = surveyData.responses.filter((r) => r.id === question.id)?.[0];
                                                if (response && response.score === keyInt) {
                                                    selected = styles.isSelected;
                                                }
                                                
                                                return (
                                                    <div 
                                                        key={`q_${question.id}_${keyInt}`}
                                                        className={css(styles.button, selected)} 
                                                        style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                                                        onClick={() => onResponseClick(question, keyInt, response)}
                                                    >
                                                        {question.scoreDefinitions[keyInt]}
                                                    </div>
                                                );
                                            }) }
                                        </Stack>
                                    </Stack>

                                ))}
                            </div>
                        </div>
                        <Stack horizontal horizontalAlign={authorInfo !== undefined ? "space-between" : "end"} verticalAlign="center">
                            { authorInfo }
                            <div>
                                <DefaultButton 
                                    disabled={questionNumber <= 0}
                                    text="Prev" 
                                    iconProps={{ iconName: "Previous" }} 
                                    onClick={() => {
                                        setQuestionNumber(questionNumber - 1);
                                    }} 
                                />
                                <PrimaryButton 
                                    disabled={!questionAnswered}
                                    text={ (questionNumber + 1) === questions.length ? "Finish" : "Next" }
                                    iconProps={{ iconName: (questionNumber + 1) === questions.length ? "Save" : "Next" }} 
                                    onClick={async () => {
                                        if (questionNumber + 1 < questions.length) {
                                            setQuestionNumber(questionNumber + 1);
                                        } else if (allAnswered) {
                                            // save
                                            const result = await saveSurveyResult(development.id, surveyData);
                                            if (result) {
                                                const newData = await loadSurveyResult(development.id);
                                                if (!(newData instanceof Error)) {
                                                    setSurveyData(newData);
                                                    setQuestionNumber(0);
                                                }
                                            }
                                        }
                                    }} 
                                />
                            </div>
                        </Stack>
                    </>
                )}
            </div>
        </div>
    );
};

export default PrioritisationSurvey;