import React from "react";
import { IDevelopmentItem, IStatusOption } from "../../types";
import { getStatusIconName } from "../../utils";

import { 
    DefaultButton, 
    Dialog, 
    IconButton,
} from "office-ui-fabric-react";

export interface IStatusPickerProps {
    className?: string;
    statusOptions: IStatusOption[];
    onStatusPicked: (development: IDevelopmentItem, newStatus: IStatusOption) => void;
}
export interface IStatusPickerState {
    development: IDevelopmentItem | undefined;
    isOpen: boolean;
    selectedStatus: number;
}

export default class StatusPicker extends React.Component<IStatusPickerProps, IStatusPickerState> {
    constructor(props: IStatusPickerProps) {
        super(props);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.statusUp = this.statusUp.bind(this);
        this.statusDown = this.statusDown.bind(this);
        this.state = {
            development: undefined,
            isOpen: false,
            selectedStatus: 0,
        };
    }
    public componentDidMount() {

    }
    public componentWillUnmount() {
        
    }
    public render() {
        const { development, isOpen } = this.state;
        if (!development || !isOpen) return null;
        
        const selectedStatus = this.props.statusOptions[this.state.selectedStatus];

        return (
            <Dialog 
                isDarkOverlay={true} 
                className={this.props.className} 
                isOpen={true} 
                title="Change Status..." 
                onDismiss={this.close}>
                
                <div style={{textAlign: "center"}}>
                    <p>For development: <strong>{development.title}</strong></p>
                    <p>Use the arrow keys to select a status, press space or click the button to select...</p>
                    <div style={{textAlign: "center"}}>
                        <IconButton iconProps={{iconName: "ChevronUp"}} onClick={this.statusDown} />
                        <DefaultButton autoFocus={true} iconProps={{
                            iconName: getStatusIconName(selectedStatus.text),
                        }}
                        text={selectedStatus.text}
                        onClick={() => {
                            this.props.onStatusPicked(development, selectedStatus);
                            this.close();
                        }} 
                        />
                        <IconButton iconProps={{iconName: "ChevronDown"}} onClick={this.statusUp} />
                    </div>
                </div>
            </Dialog>
        );
    }
    public statusDown() {
        let { selectedStatus } = this.state;
        const { statusOptions } = this.props;
        selectedStatus++;
        if (selectedStatus > (statusOptions.length - 1)) selectedStatus = 0;
        this.setState({
            ...this.state,
            selectedStatus,
        });
    }
    public statusUp() {
        let { selectedStatus } = this.state;
        const { statusOptions } = this.props;
        selectedStatus--;
        if (selectedStatus < 0) selectedStatus = statusOptions.length - 1;
        this.setState({
            ...this.state,
            selectedStatus,
        });
    }
    public open(development: IDevelopmentItem) {
        let statusIdx = 0;
        const status = development.status;
        if (status && this.props.statusOptions) {
            this.props.statusOptions.forEach((statusOption, idx) => {
                if (statusOption.id === status.id) statusIdx = idx;
            });
        }
        this.setState({
            ...this.state,
            development,
            isOpen: true,
            selectedStatus: statusIdx,
        });
    }
    private close() {
        this.setState({
            ...this.state,
            development: undefined,
            isOpen: false,
            selectedStatus: 0,
        });
    }
}
