/**
 * Returns the amount of working business days between two dates
 * @param dateA 
 * @param dateB 
 */
export function getBusinessDays(dateA: Date, dateB: Date) {
    if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
        dateA.setHours(0,0,0,0);
        dateB.setHours(0,0,0,0);
        const days = Math.round(Math.abs(dateA.getTime() - dateB.getTime())/(1000*60*60*24));
        let busDays = days - ((days/7) * 2);
        busDays = Math.round(busDays);
        if (dateB.getDay() === 6) busDays--;
        if (dateA.getDay() === 7) busDays--;
        return busDays;
    }
}

export function getStatusIconName(status: string): string | undefined {
    const icons: {[index: string]: string} = {
        "All": "FabricFormLibrary",
        "Closed (Development Complete)": "CheckboxComposite",
        "Closed (Not Required)": "ChromeClose",
        "In Development": "DeveloperTools",
        "Current Work": "DeveloperTools",
        "Live": "Rocket",
        "Live / Closed": "Rocket",
        "More Information Required": "Info",
        "My Current Work": "Contact",
        "My Future Work": "RenewalFuture",
        "My Prior Work": "History",
        "New": "Starburst",
        "On Hold": "CirclePause",
        "Pipeline": "BacklogBoard",
        "Non-Prioritised": "Unsubscribe",
        "Ready for Deployment": "Deploy",
        "Review": "Glasses",
        "Scoping Stage": "DietPlanNotebook",
        "Testing": "TestCase",
        "Triage": "Medical",
        "RAG (Red)": "Warning",
        "Flagged": "Flag",
        "Service Desk Calls": "Headset",
    };
    if (icons[status]) {
        return icons[status];
    } else {
        return undefined;
    }
}

export function getPlatformIconName(platform: string | undefined): string | undefined {
    let iconName: string | undefined;
    switch (platform) {
        case "Integration":
            iconName = "Flow";
            break;
        case "SharePoint":
            iconName = "SharepointAppIcon16";
            break;
        case "Web":
            iconName = "Website";
            break;
        case "RiO Forms":
            iconName = "ContactCard";
            break;
        case "Sostenuto":
            iconName = "Headset";
            break;
        case "Software Development":
            iconName = "CommandPrompt";
            break;
        case "Communications": 
        case "Communication":
            iconName = "Megaphone";
            break;
        case "Toca":
            iconName = "Robot";
            break;
        case "Interoperability":
            iconName = "PlugDisconnected";
            break;
    }
    return iconName;
}
