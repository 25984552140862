import React from "react";
import * as API from "../../api";

import { DirectionalHint, IBasePicker, ITag, Spinner, TagPicker, TooltipHost } from "office-ui-fabric-react";

export interface IBusinessGroupPickerProps {
    selectedOrganisations: ITag[] | undefined;
    onChange: (items?: ITag[] | undefined) => void;
    disabled: boolean;
}
export interface IBusinessGroupPickerState {
    loading: boolean;
    businessGroups: ITag[] | undefined;
}

export default class BusinessGroupPicker extends React.Component<IBusinessGroupPickerProps, IBusinessGroupPickerState> {
    private _picker = React.createRef<IBasePicker<ITag>>();
    constructor(props: IBusinessGroupPickerProps) {
        super(props);
        this._loadBusinessGroups = this._loadBusinessGroups.bind(this);
        this.state = {
            loading: true,
            businessGroups: undefined,
        };
    }
    public render() {
        const { loading, businessGroups } = this.state;
        if (loading) {
            return <Spinner />;
        } else {
            if (!businessGroups) {
                return <p>No organisations found</p>;
            } else {
                return (
                    <TooltipHost content="Type some text to search for an Organisation..."
                        calloutProps={{
                            directionalHint: DirectionalHint.leftCenter, 
                        }}
                    >
                        <TagPicker
                            selectedItems={this.props.selectedOrganisations}
                            onResolveSuggestions={this._onFilterChanged}
                            getTextFromItem={this._getTextFromItem}
                            pickerSuggestionsProps={{
                                noResultsFoundText: 'No Organisations Found',
                                suggestionsHeaderText: 'Suggested Organisations',
                            }}
                            disabled={this.props.disabled}
                            inputProps={{
                                'aria-label': 'Organisation Picker',
                            }}
                            onChange={this.props.onChange}
                        />
                    </TooltipHost>
                );
            }
        }
    }

    public componentDidMount() {
        this._loadBusinessGroups();
    }

    private async _loadBusinessGroups() {
        const response = await API.getOrganisations();
        let orgsAsTags;
        if (response.businessGroups) {
            orgsAsTags = response.businessGroups.map((a) => ({
                key: a.id.toString(),
                name: a.name,
            } as ITag));
        }
        this.setState({
            ...this.state,
            loading: false,
            businessGroups: orgsAsTags,
        });
    }

    private _getTextFromItem(item: ITag): string {
        return item.name;
      }
    
      private _onFilterChanged = (filter: string, selectedItems?: ITag[]): ITag[] => {
        const { businessGroups: organisations } = this.state;
        if (organisations) {
            return filter
            ? organisations
                .filter((tag) => tag.name.toLowerCase().indexOf(filter.toLowerCase()) === 0)
                .filter((tag) => !this._listContainsOrganisation(tag, selectedItems))
            : [];
        } else {
            return [];
        }
      }
    
      private _onFilterChangedNoFilter = (filterText: string, tagList: ITag[]): ITag[] => {
        const { businessGroups: organisations } = this.state;
        if (organisations) {
            return filterText ? organisations.filter((tag) => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0) : [];
        } else {
            return [];
        }
      }
    
      private _onItemSelected = (item: ITag): ITag | null => {
        if (this._picker.current && this._listContainsOrganisation(item, this._picker.current.items)) {
            return null;
        }
        return item;
      }

      private _listContainsOrganisation(tag: ITag, tagList?: ITag[]) {
        if (!tagList || !tagList.length || tagList.length === 0) {
          return false;
        }
        return tagList.filter((compareTag) => compareTag.key === tag.key).length > 0;
      }
}
