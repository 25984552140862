import { IPhotoResponse, IUserProfile } from "../types";

export async function getMyProfile() {
    const response = await fetch(`/api/users/me`, {
        headers: {
            Accept: 'application/json',
            "X-Requested-With": "XMLHttpRequest",
            "x-ReturnUrl": `${window.location.protocol}//${window.location.host}`
        },
        credentials: 'include',
        cache: 'no-cache'
    });
    if (response.ok) {
        return await response.json() as IUserProfile;
    } else {
        const wwwAuthHeader = response.headers.get('www-authenticate');
        if (wwwAuthHeader) {
            const fm = new Date(); fm.setMinutes(fm.getMinutes() + 5);
                document.cookie = `dp_postconsent_redirect=${escape(window.location.pathname)}; path=/; expires=${fm.toUTCString()}`;
            const consentUri = wwwAuthHeader.split(/,? /).filter(v => /consentUri/.test(v)).map((v) => v.replace(/consentUri="(.*)"/, '$1'));
            if (consentUri.length) {
                // if we're getting a consent url we're probably not signed in
                window.location.href = '/MicrosoftIdentity/Account/SignIn';
            }
        }
        return Error("Unable to load user profile");
    }
}

export async function getMyPhoto(): Promise<Response> {
    const response = await fetch('/api/users/myphoto', {
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include'
    });
    return response;
}

export async function getPhoto(userName: string): Promise<Response> {
    const response = await fetch ('/api/users/' + userName + '/photo', {
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });
    return response;
}

export async function getPhotos(emails: string[], abortController?: AbortController): Promise<Record<string, IPhotoResponse>|Error> {
    try {
        const response = await fetch(`/api/users/photos`, {
            headers: {
                Accept: 'application/json',
                "Content-Type": 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(emails),
            credentials: 'include',
            signal: abortController.signal
        });
        if (response.ok) {
            return await response.json();
        } else {
            return new Error("Unable to fetch photos");
        }
    } catch (ex) {
        console.error(ex);
    }
}