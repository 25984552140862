import * as React from "react";
import { HeaderMenu } from "./components/Header2";
import { Bar } from "react-chartjs-2";
import { WeekPicker } from "../common/WeekPicker";

import logo from "../assets/dev_service_compact.png";
import darkLogo from "../assets/dev_service_compact_dark.png";

import styles from "./Activity.module.scss";
import { PrimaryButton, Stack, DefaultPalette, Spinner, TooltipHost, Icon, Label, DefaultButton, MessageBar, MessageBarType } from "office-ui-fabric-react";
import { ALSicknessPanel } from "./components/activity/ALSicknessPanel";
import { DayActivityPanel } from "./components/activity/DayActivityPanel";
import { EventDevAssignPanel } from "./components/activity/EventDevAssignPanel";
import { ICalendarEvent, ISLATransactionDTO } from "../types";
import { Chart, ChartDataset, ChartEvent } from "chart.js";
import { DisplayContext } from "../DisplayContext";
import { getCalendarTeamsItems, getMyActivity } from "../api/activity";

const palette = [
    DefaultPalette.blue,
    DefaultPalette.green, DefaultPalette.magenta,
    DefaultPalette.orange, DefaultPalette.purple,
    DefaultPalette.red, DefaultPalette.teal,
    DefaultPalette.yellow
];

interface ICalendarStatus {
    loading?: boolean;
    error?: string;
    weekStart?: Date;
    weekEnd?: Date;
}

export interface IActivityProps {}
export interface IActivityState {
    activities: ISLATransactionDTO[];
    activityData?: ChartDataset<"bar", number[]>[];
    calendar?: ICalendarStatus;
    loading: boolean;
    showALSicknessPanel: boolean;
    showDayPanel?: number;
    showCalendarPanel: boolean;
    weekStart: Date;
    weekEnd: Date;
    weekCalendarEvents: ICalendarEvent[];
}

export enum Mode {
    Hours,
    Days
}

export class Activity extends React.Component<IActivityProps, IActivityState> {
    private _chart: Chart<"bar", number[], string> = null;
    constructor(props: IActivityProps) {
        super(props);

        this._addDays = this._addDays.bind(this);
        this._nextWeek = this._nextWeek.bind(this);
        this._prevWeek = this._prevWeek.bind(this);

        const weekStart = this._getWeekStart(new Date());
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekEnd.getDate() + 6);
        this.state = {
            activities: [],
            loading: true,
            showALSicknessPanel: false,
            showCalendarPanel: false,
            weekStart,
            weekEnd,
            weekCalendarEvents: undefined,
        };
    }

    public componentDidMount() {
        this._load();
    }

    public render() {
        const { activities, activityData, calendar, loading, showALSicknessPanel, showCalendarPanel, showDayPanel, weekCalendarEvents, weekStart } = this.state;
        return (
            <DisplayContext.Consumer>
                {({darkMode}) => (
                    <div>
                        <div className={styles.logoContainer}>
                            <img className={styles.logo} src={darkMode ? darkLogo : logo} alt="Development Service Portal Logo" />
                        </div>
                        <HeaderMenu />
                        <div className={styles.mainForm} style={{marginBottom: "unset"}}>
                            <h3>Activity Recording</h3>
                            <MessageBar messageBarType={MessageBarType.info}>
                                Double click a date on the graph to add development activity. Use the menu options provided to add other types of activity.
                            </MessageBar>
                        </div>
                        <Stack horizontal horizontalAlign="center">
                            <div className={styles.mainForm}>
                                <Stack wrap horizontal verticalAlign="start">
                                    <Stack grow styles={{root: { flexGrow: 9999, minWidth: "50vw"}}}>
                                        <div className={styles.graphContainer}>
                                            <div>
                                                { loading === true && (
                                                    <Spinner label="Loading activity" />
                                                )}
                                                { loading === false && (
                                                    <Bar height="300px" ref={ref => this._chart = ref} data={{
                                                        labels: ['S', 'M','T','W','T','F', 'S'],
                                                        datasets: activityData || [],
                                                    }} options={{
                                                        color: darkMode ? 'white' : undefined,
                                                        maintainAspectRatio: false,
                                                        onClick: (e: ChartEvent) => {
                                                            if (this._chart) {
                                                                const points = this._chart.getElementsAtEventForMode(e as any as Event, 'nearest', { intersect: true }, true);

                                                                if (points.length) {
                                                                    const idx = points[0].index;
                                                                    this.setState({
                                                                        ...this.state,
                                                                        showDayPanel: idx
                                                                    });
                                                                }
                                                            }
                                                        },
                                                        interaction: {
                                                            intersect: false,
                                                            mode: 'index',
                                                        },
                                                        plugins: {
                                                            legend: {
                                                                labels: {
                                                                    color: darkMode ? 'white' : undefined,
                                                                }
                                                            },
                                                            tooltip: {
                                                                filter: (item, idx, arr, data) => {
                                                                    var value = data.datasets[item.datasetIndex].data[item.dataIndex];
                                                                    if (value === 0) {
                                                                        return false;
                                                                    } else {
                                                                        return true;
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        datasets: {
                                                            bar: {
                                                                barThickness: 30,
                                                                minBarLength: 0,
                                                            }
                                                        },
                                                        scales: {
                                                            y: {
                                                                ticks: {
                                                                    color: darkMode ? 'white' : undefined,
                                                                },
                                                                title: {
                                                                    text: 'Hours', display: true, color: darkMode ? 'white' : undefined
                                                                },
                                                                stacked: true,
                                                            },
                                                            x: {
                                                                ticks: {
                                                                    color: darkMode ? 'white' : undefined
                                                                }
                                                            }
                                                        }
                                                    }} />
                                                )}
                                            </div>
                                        </div>
                                        <Stack className={styles.dateControls} wrap horizontal verticalAlign="end" horizontalAlign="space-between">
                                            <WeekPicker 
                                                className={styles.datePicker}
                                                value={weekStart} 
                                                datePickerProps={{label: "Date"}}
                                                onSelectWeek={(weekStart, weekEnd) => {
                                                    this.setState({...this.state, loading: true, weekStart, weekEnd}, this._load);
                                                }} 
                                            />
                                            <Stack className={styles.weekControls} horizontal tokens={{childrenGap: 10}}>
                                                <Label>Week: </Label>
                                                <Stack className={styles.weekControlBtns} horizontal horizontalAlign="start" tokens={{childrenGap: 10}}>
                                                    <DefaultButton text="Prev" disabled={loading} iconProps={{iconName: "ChevronLeft"}} onClick={this._prevWeek} />
                                                    <DefaultButton text="Next" disabled={loading} iconProps={{iconName: "ChevronRight"}} onClick={this._nextWeek} />
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Stack className={styles.menu}>
                                        <Stack>
                                                <Stack horizontal verticalAlign="center">
                                                    <Icon iconName="Calendar" styles={{root: {marginRight: 10}}}/>
                                                    <Label>Calendar / Teams:</Label>
                                                </Stack>
                                                
                                                <PrimaryButton 
                                                    disabled={loading || !weekCalendarEvents || weekCalendarEvents.length === 0} 
                                                    style={{width: "100%", marginBottom: 20}} 
                                                    onClick={() => {
                                                        this.setState({
                                                            ...this.state,
                                                            showCalendarPanel: true,
                                                        });
                                                    }}
                                                >
                                                    <Stack horizontal verticalAlign="center">
                                                        { calendar?.loading === true && (
                                                            <>
                                                                <div>Loading</div>
                                                                <Spinner />
                                                            </>
                                                        )}
                                                        { calendar?.loading === false && (
                                                            <>
                                                                <TooltipHost content={weekCalendarEvents?.length === 0 ? 'No events' : undefined}>
                                                                    <div>Import from Calendar</div>
                                                                </TooltipHost>
                                                                { calendar.error !== undefined && (
                                                                    <TooltipHost content={calendar.error}>
                                                                        <Icon iconName="Warning" />
                                                                    </TooltipHost>
                                                                )}
                                                            </>
                                                        )}
                                                    </Stack>
                                                </PrimaryButton>

                                                <Stack horizontal verticalAlign="center">
                                                    <Icon iconName="Medical" styles={{root: {marginRight: 10}}}/>
                                                    <Label>Annual Leave / Sickness:</Label>
                                                </Stack>
                                                <PrimaryButton 
                                                    style={{marginBottom: 20, minWidth: 180, maxWidth: 300}} 
                                                    onClick={() => {
                                                        this.setState({
                                                            ...this.state,
                                                            showALSicknessPanel: true,
                                                        });
                                                    }}
                                                    text="Add / Remove" 
                                                />
                                            </Stack>
                                        </Stack> 
                                </Stack>
                            </div>
                        </Stack>
                        { showDayPanel !== undefined && (
                            <DayActivityPanel
                                activities={activities}
                                weekStart={weekStart}
                                day={showDayPanel}
                                onDismiss={() => {
                                    this.setState({
                                        ...this.state,
                                        showDayPanel: undefined
                                    });
                                }}
                                onSave={() => {
                                    this.setState({
                                        ...this.state,
                                        showDayPanel: undefined,
                                    }, this._load);
                                }}
                                onDelete={(closePanel) => {
                                    if (closePanel) {
                                        this.setState({
                                            ...this.state,
                                            showDayPanel: undefined,
                                        }, this._load);
                                    } else {
                                        this._load();
                                    }
                                }}
                            />
                        )}
                        { showCalendarPanel === true && (
                            <EventDevAssignPanel 
                                activity={activities}
                                events={weekCalendarEvents} 
                                onDismiss={() => { 
                                    this.setState({
                                        ...this.state,
                                        showCalendarPanel: false,
                                    });
                                }} 
                                onSave={() => {
                                    this.setState({
                                        ...this.state,
                                        showCalendarPanel: false,
                                    }, this._load);
                                }}
                            />
                        )}
                        { showALSicknessPanel === true && (
                            <ALSicknessPanel 
                                activities={activities}
                                weekStart={weekStart}
                                onDismiss={() => {
                                    this.setState({
                                        ...this.state,
                                        showALSicknessPanel: false,
                                    });
                                }}
                                onSave={() => {
                                    this.setState({
                                        ...this.state,
                                        showALSicknessPanel: false,
                                    }, this._load);
                                }}
                            />
                        )}
                    </div>
                )}
            </DisplayContext.Consumer>
        )
    }

    private async _load() {
        this._loadCalendar();

        this._loadActivity();
    }

    private async _loadActivity() {
        const { weekStart, weekEnd } = this.state;

        this.setState({ loading: true }, async () => {
            // Get user activity
            let activities: ISLATransactionDTO[] = undefined;
            let datasets: ChartDataset<"bar", number[]>[] = undefined;
            const activityResults = await getMyActivity(weekStart, weekEnd);
            if (!(activityResults instanceof Error)) {
                activities = activityResults;
                const transactionsByDevelopment: Record<string, ISLATransactionDTO[]> = {};
                activityResults.forEach((activity) => {
                    if (activity.type === "Activity") {
                        if (transactionsByDevelopment[activity.developmentName] === undefined) {
                            transactionsByDevelopment[activity.developmentName] = [activity];
                        } else {
                            transactionsByDevelopment[activity.developmentName].push(activity);
                        }
                    } else if (activity.type === "BAU") {
                        const bauName = "BAU - " + activity.businessGroupName;
                        if (transactionsByDevelopment[bauName] === undefined) {
                            transactionsByDevelopment[bauName] = [activity];
                        } else {
                            transactionsByDevelopment[bauName].push(activity);
                        }
                    } else if (activity.type === "Absence") {
                        const absenceType = activity.absenceType === "AL" ? "Annual Leave" : "Sickness";
                        if (transactionsByDevelopment[absenceType] === undefined) {
                            transactionsByDevelopment[absenceType] = [activity];
                        } else {
                            transactionsByDevelopment[absenceType].push(activity);
                        }
                    }
                });
                datasets = [];
                let count = 0;
                for(const key in transactionsByDevelopment) {
                    const transactions = transactionsByDevelopment[key];
                    let hourData: number[] = [];
                    let dateIterator = new Date(weekStart.getTime());
                    while (dateIterator.getTime() <= weekEnd.getTime()) {
                        let dayStart = new Date(dateIterator.getTime());
                        dayStart = new Date(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate(), 0, 0, 0, 0);
                        let dayEnd = new Date(dateIterator.getTime());
                        dayEnd = new Date(dayEnd.getFullYear(), dayEnd.getMonth(), dayEnd.getDate(), 23, 59, 59, 999);
                        const matchingTransactions: ISLATransactionDTO[] = transactions.filter((t) => {
                            return (t.type !== "Absence" && new Date(t.periodStart) <= dayEnd && new Date(t.periodEnd) >= dayStart) ||
                            (t.type === "Absence" && new Date(t.dateOfAbsence) >= dayStart && new Date(t.dateOfAbsence) <= dayEnd);
                        });
                        let hours = 0;
                        matchingTransactions.forEach((transaction) => {
                            hours += transaction.hours;
                        });
                        hourData.push(hours);
    
                        dateIterator.setDate(dateIterator.getDate() + 1);
                    }
                    datasets.push({
                        data: hourData,
                        label: key,
                        stack: '1',
                        backgroundColor: palette[count % palette.length],
                        borderColor: palette[count % palette.length]
                    });
    
                    count++;
                }
    
                // calculate BAU
                let dayHours = [0,0,0,0,0,0,0];
                dayHours = datasets.reduce((prev, cur, dsIdx, arr) => {
                    let newTotals = [...prev];
                    cur.data.forEach((i, idx) => {
                        newTotals[idx] += i;
                    });
                    return newTotals;
                }, dayHours);
                let bau = [0,0,0,0,0,0,0];
                dayHours.forEach((dh, idx) => {
                    if (dh < 7.5) {
                        bau[idx] = 7.5 - dh;
                    }
                });
                datasets.push({
                   data: bau,
                   label: "Unassigned BAU",
                   stack: '1',
                   backgroundColor: "rgba(255, 205, 86, 0.2)",
                   borderColor: "rgba(255, 205, 86, 0.2)",
                });    
            }
    
            this.setState({
                ...this.state,
                activities,
                loading: false,
                activityData: datasets,
            });
        });
    }

    private _loadCalendar() {
        const { calendar, weekStart, weekEnd } = this.state;

        if (calendar === undefined || calendar.weekStart !== weekStart || calendar.weekEnd !== weekEnd) {
            this.setState({
                calendar: {
                    ...calendar,
                    loading: true,
                    error: undefined,
                }, 
                weekCalendarEvents: undefined 
            }, () => {
                // Get calendar items
                let calendarEvents: ICalendarEvent[] = undefined;
                getCalendarTeamsItems(weekStart, weekEnd).then(async (calendarItemsResponse: Response) => {
                    if (calendarItemsResponse.ok) {
                        calendarEvents = await calendarItemsResponse.json() as ICalendarEvent[];
                    } else {
                        const wwwAuthHeader = calendarItemsResponse.headers.get('www-authenticate');
                        if (wwwAuthHeader) {
                            const consentUri = wwwAuthHeader.split(/,? /).filter(v => /consentUri/.test(v)).map((v) => v.replace(/consentUri="(.*)"/, '$1'));
                            if (consentUri.length) {
                                consentUri[0] = consentUri[0].replace(/(redirect_uri=)[^&]+/, `redirect_uri=${window.location.protocol}//${window.location.host}`);
                                window.location.href = consentUri[0];
                            }
                        } else {
                            this.setState({ 
                                calendar: {
                                    ...calendar,
                                    loading: false,
                                    error: `Unable to load items: ${calendarItemsResponse.statusText}`
                                }, 
                            });
                        }
                    }
                    this.setState({
                        calendar: {
                            ...calendar,
                            loading: false,
                            weekStart, weekEnd
                        },
                        weekCalendarEvents: calendarEvents
                    });
                });
            });
        }
    }

    private _nextWeek() {
        this._addDays(7);
    }

    private _prevWeek() {
        this._addDays(-7);
    }

    private _addDays(days: number) {
        const weekStart = new Date(this.state.weekStart);
        weekStart.setDate(weekStart.getDate() + days);
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6);
        this.setState({
            ...this.state,
            loading: true,
            weekStart,
            weekEnd,
        }, this._load);
    }

    private _getWeekStart(date: Date): Date {
        while (date.getDay() > 0) {
            date.setDate(date.getDate() - 1);
        }
        return date;
    }
}

export default Activity;
