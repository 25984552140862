import * as React from "react";
import { 
    css, mergeStyles, 
    ColumnActionsMode, DetailsHeader, DetailsList, DetailsRow, IColumn, IDetailsRowProps,
    ContextualMenu, ContextualMenuItemType, 
    CommandBar, 
    DefaultButton, PrimaryButton, 
    DirectionalHint, 
    Icon, IIconProps, 
    IContextualMenuItem, IContextualMenuProps, 
    IObjectWithKey, 
    Selection, SelectionMode, 
    Stack, 
    TooltipHost,
    IDetailsHeaderProps,
    IDetailsFooterProps,
    IFacepilePersona, 
} from "office-ui-fabric-react";
import { AsyncFacepile } from "../AsyncFacepile";
import SearchFilter from "../InfoBar";
import { IDevelopmentItem, IOLITDevelopmentItem, IStatusOption } from "../../../types";
import { DevTableColumn, IProgressFieldMapping, ITableIconColumn, ITablePersonaColumn, ITableProgressColumn, ITableTextOrObjColumn } from "./DevTable.types";
import { ISortConfig } from "./FilterMenu";

import styles from "./DevTable.module.scss";
import StatusPicker from "../../dialogs/StatusPicker";
import StatusChangeDialog from "../../dialogs/statusChangeDialog";
import DeleteDialog from "../../dialogs/deleteDialog";

export type { DevTableColumn };

//#region props and state
export interface ITableProps {
    calculateRowClasses?: (rowData: any, index: number) => string[];
    className?: string;
    columns: DevTableColumn[];
    items: any;
    onNew?: () => void;
    onEdit?: (item: any, tabId?: string) => void;
    onStatusChange?: (item: IDevelopmentItem | IOLITDevelopmentItem, newStatus: IStatusOption) => void;
    onShowVersionHistory?: (item: IDevelopmentItem | IOLITDevelopmentItem) => void;
    showNewButton?: boolean;
    selectedItemOptions?: {
        editItem?: boolean;
        addUpdate?: boolean;
        changeStatus?: boolean;
        versionHistory?: boolean;
        delete?: boolean;
    };
    statusOptions: IStatusOption[];
    viewLabel: string;
    sortConfig?: ISortConfig;
}

export interface ITableState {
    activeFilters: Record<string, (items: any[]) => any[]>;
    columnContextMenu?: IContextualMenuProps;
    globalFilter?: string;
    sortFieldName?: string;
    sortAscending?: boolean;
    selectedItem?: any;
    stickyHeader: boolean;
}
//#endregion

export class DevTable extends React.Component<ITableProps, ITableState> {
    
    private _keyMap: Record<string,boolean> = {};

    private _root = React.createRef<HTMLDivElement>();

    protected _statusDialog: StatusChangeDialog | null = null;
    protected _statusPicker: StatusPicker | null = null;
    protected _deleteDialog: DeleteDialog | null = null;

    private _selection: Selection<IObjectWithKey> = new Selection({
        onSelectionChanged: () => {
            this.forceUpdate();
        }
    }); 
    
    constructor(props: ITableProps) {
        super(props);

        // fn bindings 
        this._addFilter = this._addFilter.bind(this);
        this._defaultRenderMethod = this._defaultRenderMethod.bind(this);
        this._dismissHeaderContextMenu = this._dismissHeaderContextMenu.bind(this);
        this._onKeyDown = this._onKeyDown.bind(this);
        this._onKeyUp = this._onKeyUp.bind(this);
        this._onRenderDetailsHeader = this._onRenderDetailsHeader.bind(this);
        this._getOnRenderDetailsFooter = this._getOnRenderDetailsFooter.bind(this);
        this._onRenderRow = this._onRenderRow.bind(this);
        this._removeFilter = this._removeFilter.bind(this);
        this._scrollChecker = this._scrollChecker.bind(this);
        this._setSort = this._setSort.bind(this);

        let sortFieldName: string, sortAscending: boolean;
        if (props.sortConfig) {
            sortFieldName = props.sortConfig.fieldName;
            sortAscending = props.sortConfig.ascending;
        }

        this.state = {
            activeFilters: {},
            stickyHeader: false,
            sortAscending,
            sortFieldName
        };
    }

    public componentDidMount() {
        document.addEventListener('scroll', this._scrollChecker);
        document.addEventListener('keyup', this._onKeyUp);
        document.addEventListener('keydown', this._onKeyDown);
    }

    public componentWillUnmount() {
        document.removeEventListener('scroll', this._scrollChecker);
        document.removeEventListener('keyup', this._onKeyUp);
        document.removeEventListener('keydown', this._onKeyDown);
    }

    public render() {
        const { className } = this.props;
        let items = [...this.props.items];
        const { 
            activeFilters, 
            columnContextMenu, 
            sortAscending, 
            sortFieldName,
            stickyHeader 
        } = this.state;

        // Don't render if we don't have items yet
        if (items === undefined) return null;

        const itemCount = items.length;

        // Apply filters
        items = this._applyGlobalFilter(items);
        for (const filter in activeFilters) {
            items = activeFilters[filter](items);
        }

        const filterCount = items.length;

        // Apply sort
        if (sortFieldName !== undefined && sortAscending !== undefined) {
            items = this._performSort(items, sortFieldName, sortAscending);
        }

        // get selection, if any
        const selection = this._selection.getSelection();
        let selectedItem: any; 
        if (selection.length) {
            selectedItem = selection[0];
        }

        let stickyCommandBar;
        if (stickyHeader) {
            stickyCommandBar = styles.stickyHeaderRow;
        }

        return (
            <div ref={this._root}>
                <Stack className={css(stickyCommandBar, styles.commandRoot)} horizontal verticalAlign="center" horizontalAlign="space-between">
                    <Stack horizontal verticalAlign="center" horizontalAlign="start">
                        <PrimaryButton 
                            text="New" 
                            iconProps={{iconName: "Add"}} 
                            onClick={() => {
                                if (this.props.onNew) {
                                    this.props.onNew();
                                }
                            }} 
                            style={{margin: 10}}
                        />
                        { activeFilters !== undefined && Object.keys(activeFilters).length > 0 && (
                            <DefaultButton text="Clear Filter(s)" iconProps={{iconName: "ClearFilter"}} onClick={() => {
                                this.setState({
                                    ...this.state,
                                    activeFilters: {}
                                });
                            }} /> 
                        )}
                        { selectedItem !== undefined && (
                            <CommandBar
                                items={[
                                    {
                                        key: "Edit",
                                        text: "Edit",
                                        iconProps: { iconName: 'Edit' },
                                        onClick: (e) => {
                                            this.props.onEdit(this._selection.getSelection()[0] as any);
                                        }
                                    },
                                    {
                                        key: "Update",
                                        text: "Add an Update",
                                        iconProps: { iconName: 'Comment' },
                                        onClick: (e) => {
                                            this.props.onEdit(this._selection.getSelection()[0] as any, "3");
                                        }
                                    },
                                ]}
                            />
                        )}
                    </Stack>
                    <div className={styles.filter}>
                        <SearchFilter 
                            filterCount={filterCount}
                            itemCount={itemCount}
                            onFilterChanged={(globalFilter) => {
                                this.setState({
                                    ...this.state,
                                    globalFilter
                                });
                            }}
                        />
                        <TooltipHost styles={{root: { display: "flex", alignSelf: "center", alignItems: "center" }}} tooltipProps={{
                            directionalHint: DirectionalHint.leftCenter,
                            onRenderContent: () => {
                                return (
                                    <div>
                                        <p style={{marginTop: "0px"}}><strong>Keyboard Shortcuts -</strong></p>
                                        <div><strong>Tab:</strong> change focus on the page</div>
                                        <div><strong>Arrow keys</strong> (with table focused): navigate up and down the table</div>
                                        <div><strong>Shift + S</strong> (with item selected): change status</div>
                                        <div><strong>Shift + U</strong> (with item selected): add an update</div>
                                        <div><strong>Escape:</strong> close any open dialogs / panels</div>
                                        <hr/>
                                        <div>
                                            <div><strong>When viewing a development:</strong></div>
                                            <div><strong>Ctrl + Left/Right</strong>: View prev/next development</div>
                                            <div><strong>Ctrl + Shift + Left/Right</strong>: Change tabs</div>
                                        </div>
                                    </div>
                                );
                            },
                        }}>
                            <Icon style={{marginLeft: 10, fontSize: 24}} iconName="KeyboardClassic" className="ms-motion-slideLeftIn"/>
                        </TooltipHost>
                    </div>
                </Stack>
                <DetailsList
                    styles={{
                        root: {
                            "overscroll-behaviour-x": "contain",
                            "user-select": "none"
                        }
                    }}
                    setKey={'banana'}
                    getKey={(item: IDevelopmentItem | IOLITDevelopmentItem, index) => item.id.toString()}
                    className={className}
                    columns={this._buildColumns(items)}
                    items={items}
                    onRenderDetailsHeader={this._onRenderDetailsHeader}
                    onRenderRow={this._onRenderRow}
                    onRenderDetailsFooter={this._getOnRenderDetailsFooter()}
                    selectionMode={SelectionMode.single}
                    selection={this._selection}
                />
                { stickyHeader === true && (
                    <div style={{height: 15}} />
                )}
                { columnContextMenu !== undefined && (
                    <ContextualMenu {...columnContextMenu} />
                )}
                <DeleteDialog ref={(d) => this._deleteDialog = d} 
                    className={styles.deletionDialog}
                    onDeletion={(id) => {
                        
                    }} />
                <StatusChangeDialog ref={(s) => this._statusDialog = s}
                    className={styles.statusDialog}
                    onDataEntered={(devId, newStatus) => {}}
                />
                {this.props.statusOptions && (
                    <StatusPicker ref={(s) => this._statusPicker = s}
                        className={styles.statusPickerDialog}
                        statusOptions={this.props.statusOptions}
                        onStatusPicked={(development: IDevelopmentItem, newStatus: IStatusOption) => {
                            // this._onStatusMenuClick(development, newStatus)();
                        }}
                    />
                )}
            </div>
        );
    }

    //#region - Table Building Related

        /** Given a list of items, and the column configuration passed
         * to the component, generates a list of DetailsList compatible
         * IColumn objects
         */
        private _buildColumns(items: any[]): IColumn[] {
            const { columns } = this.props;
            const { activeFilters, sortFieldName, sortAscending } = this.state;
            return columns.map((column) => {
                
                // If display name provided use it
                let displayName = column.displayName;
                if (!displayName) {
                    displayName = this._titleCase(column.fieldName);
                }
                
                // start with mandatory column properties
                const detailsListColumn: IColumn = {
                    columnActionsMode: ColumnActionsMode.hasDropdown,
                    className: column.fieldName,
                    key: column.fieldName,
                    fieldName: column.fieldName,
                    name: displayName,
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth
                };

                if (sortFieldName === column.fieldName) {
                    detailsListColumn.isSorted = true;
                    detailsListColumn.isSortedDescending = !sortAscending;
                }
                
                if (activeFilters[column.fieldName]) {
                    detailsListColumn.isFiltered = true;
                }

                // fetch custom render methods
                let onRender: (item: any, index: number, column: IColumn) => React.ReactNode;
                if (column.onRender) {
                    onRender = column.onRender;
                } 
                else {
                    onRender = this._defaultRenderMethod;
                    switch (column.type) {
                        case "Date":
                            onRender = this._renderDateCol(column.fieldName);
                            break;
                        case "Persona":
                            onRender = this._renderPersonaCol(
                                (column as ITablePersonaColumn).personaConversion
                            );
                            break;
                        case "Icon":
                            onRender = this._renderIconCol(
                                (column as ITableIconColumn).iconConversion, 
                                (column as ITableIconColumn).iconProps
                            );
                            break;
                        case "Progress":
                            onRender = this._renderProgressCol(
                                (column as ITableProgressColumn).progressConversion
                            );
                    }
                }
                detailsListColumn.onRender = onRender;

                // provide header context menu and filter options
                detailsListColumn.onColumnClick = this._generateHeaderOptions(column, items).bind(this);

                return detailsListColumn;
            });
        }

        private _onRenderDetailsHeader(detailsHeaderProps: IDetailsHeaderProps) {
            const className = (this.state.stickyHeader ? " " + styles.stickyHeaderRow : undefined);
            return (
                <div key={detailsHeaderProps.key} className={styles.detailsListHeader}>
                    <div className={className}>
                        <DetailsHeader 
                            {...detailsHeaderProps} 
                        />
                    </div>
                </div>
            );
        }

        private _getOnRenderDetailsFooter() {
            const columns = this.props.columns;
            if (columns.filter((c) => c.onRenderFooterCell !== undefined).length) {
                return (detailsFooterProps: IDetailsFooterProps) => {
                    const footerColumns = [...detailsFooterProps.columns];
                    for (let i = 0; i < footerColumns.length; i++) {
                        const column = {...footerColumns[i]};
                        if (columns[i].onRenderFooterCell !== undefined) {
                            column.onRender = columns[i].onRenderFooterCell;
                        } else {
                            column.onRender = () => <div></div>;
                        }
                        footerColumns[i] = column;
                    }
                    detailsFooterProps.columns = footerColumns;
                    return (
                        <DetailsRow item={{}} itemIndex={-1} {...detailsFooterProps} />
                    );
                };
            } else {
                return undefined;
            }
        }

        private _onRenderRow(props: IDetailsRowProps) {
            const { calculateRowClasses } = this.props;
            const rowClass = props.itemIndex % 2 === 0 ? styles.even : styles.odd;
            let additionalClasses: string[] = [];
            if (calculateRowClasses) {
                additionalClasses = calculateRowClasses(props.item, props.itemIndex);
            }
            const mergedClasses = mergeStyles(rowClass, ...additionalClasses);
            return (
                <div key={"item_" + props.itemIndex} onDoubleClick={this._onItemDoubleClick(props.item)}>
                    <DetailsRow className={mergedClasses} {...props} />
                </div>
            );
        }

        protected _onItemDoubleClick(item: any) {
            return (e: React.MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();
                this.props.onEdit(item);
            };
        }

    //#endregion

    //#region - Header / Title Context Menu

        private _generateHeaderOptions(column: DevTableColumn, items: any[]) {
            return (ev: React.MouseEvent<HTMLElement, MouseEvent>, detailsListColumn: IColumn) => {
                const { activeFilters } = this.state;

                const columnIsFiltered = activeFilters[column.fieldName] !== undefined;

                const menuItems: IContextualMenuItem[] = [
                    {
                        iconProps: { iconName: 'SortUp' },
                        key: "sortAsc",
                        name: "Sort Ascending",
                        onClick: (e, item) => {
                            e.preventDefault(); 
                            this._setSort(column, true); 
                        },
                    },
                    {
                        iconProps: { iconName: 'SortDown' },
                        key: "sortDsc",
                        name: "Sort Descending",
                        onClick: (e, item) => { 
                            e.preventDefault();
                            this._setSort(column, false); 
                        },
                    },
                ];

                if (columnIsFiltered) {
                    menuItems.push(
                        {
                            itemType: ContextualMenuItemType.Divider,
                            key: 'divider_1',
                        },
                        {
                            iconProps: {
                                iconName: 'ClearFilter',
                            },
                            key: 'removeFilter',
                            name: 'Clear Filter',
                            onClick: (e) => {
                                e.preventDefault();
                                this._removeFilter(column.fieldName);
                            },
                        },
                    );
                }

                if (column.filters && typeof column.filters !== "string") {
                    column.filters.forEach((filter, i) => {
                        if (typeof filter.filterLabel === "string") {
                            menuItems.push({
                                key: `filter_${column.fieldName}_${i}`,
                                text: filter.filterLabel,
                                onClick: (e) => {
                                    e.preventDefault();
                                    this._addFilter(column.fieldName, filter.filterFn);
                                }
                            });
                        } else {
                            menuItems.push({
                                key: `filter_${column.fieldName}_${i}`,
                                onRender: (item, dismissMenu) => {
                                    return (
                                        <button onClick={(e) => {
                                            this._addFilter(column.fieldName, filter.filterFn);
                                        }}>
                                            {filter.filterLabel}
                                        </button>
                                    );
                                }
                            });
                        }
                    });
                }
                if (column.filters && column.filters === "UniqueText") {
                    if (column.type === "Text" || column.mapToText !== undefined) {
                        let uniqueValues;
                        if (column.mapToText !== undefined) {
                            const values: any[] = [];
                            items.forEach((item) => {
                                const vals = column.mapToText(item);
                                if (Array.isArray(vals)) {
                                    vals.forEach((value) => {
                                        values.push(value);
                                    });
                                } else {
                                    values.push(vals);
                                }
                            });
                            uniqueValues = this._getUniqueFieldValues(values);
                        } else {
                            uniqueValues = this._getUniqueFieldValues(
                                items.map((item) => item[column.fieldName])
                            );
                        }
                        if (uniqueValues.length > 0) {
                            menuItems.push({
                                itemType: ContextualMenuItemType.Divider,
                                key: "divider_2",
                            });
                            uniqueValues.forEach((uv) => {
                                menuItems.push({
                                    key: uv,
                                    text: uv,
                                    onClick: (e) => {
                                        e.preventDefault();
                                        this._addFilter(column.fieldName, (itemList: any[]) => {
                                            return itemList.filter((item) => {
                                                if (Array.isArray(item[column.fieldName])) {
                                                    if (column.mapToText) {
                                                        const textMappedValue = column.mapToText(item);
                                                        if (textMappedValue && Array.isArray(textMappedValue)) {
                                                            return textMappedValue.indexOf(uv) >= 0;
                                                        }
                                                    }
                                                    if ((column as any).fieldMapping) {
                                                        const mappedValue = item[column.fieldName].map((val: any) => 
                                                            (column as ITableTextOrObjColumn).objectValueMapping(val)
                                                        );
                                                        if (mappedValue && Array.isArray(mappedValue)) {
                                                            return mappedValue.indexOf(uv) >= 0;
                                                        }
                                                    }
                                                    return item[column.fieldName].indexOf(uv) >= 0;
                                                } else {
                                                    if (column.mapToText) {
                                                        const mappedValue = column.mapToText(item);
                                                        if (Array.isArray(mappedValue)) {
                                                            return mappedValue.indexOf(uv) >= 0;
                                                        } else {
                                                            return mappedValue === uv;
                                                        }
                                                    } else {
                                                        return item[column.fieldName] === uv;
                                                    }
                                                }
                                            });
                                        });
                                    }
                                });
                            });
                        }
                    }
                }

                this.setState({
                    ...this.state,
                    columnContextMenu: {
                        directionalHint: DirectionalHint.bottomLeftEdge,
                        gapSpace: 10,
                        isBeakVisible: true,
                        items: menuItems,
                        onDismiss: this._dismissHeaderContextMenu,
                        target: ev.currentTarget as HTMLElement,
                    }
                });
            };
        }

        private _dismissHeaderContextMenu() {
            this.setState({
                ...this.state,
                columnContextMenu: undefined,
            });
        }
    
    //#endregion

    //#region - Sorting

        private _setSort(column: DevTableColumn, ascending: boolean) {
            this.setState({
                ...this.state,
                sortFieldName: column.fieldName,
                sortAscending: ascending,
                columnContextMenu: undefined,
            });
        }

        private _performSort(items: any[], fieldName: string, ascending: boolean) {
            const { columns } = this.props;
            const column = columns.filter((c) => c.fieldName === fieldName)[0];
            const hasTextMapping = column.mapToText !== undefined;
            return items.sort((a,b) => {

                const res1 = ascending ? -1 : 1;
                const res2 = ascending ? 1 : -1;

                // Handle a and/or b being undefined or null...
                if (!a[column.fieldName] && !b[column.fieldName]) return 0;
                if (a[column.fieldName] && !b[column.fieldName]) return res1;
                if (!a[column.fieldName] && b[column.fieldName]) return res2;
                
                // Text based sorting
                if (column.type === "Text" || hasTextMapping) {
                    let aText = a[column.fieldName];
                    let bText = b[column.fieldName];
                    if (hasTextMapping) {
                        aText = column.mapToText(a);
                        bText = column.mapToText(b);
                    }
                    return aText < bText ? res1 : (aText === bText ? 0 : res2);
                }

                if (column.type === "Date") {
                    const aDate = new Date(a[column.fieldName]).getTime();
                    const bDate = new Date(b[column.fieldName]).getTime();
                    return ascending ? aDate - bDate : bDate - aDate;
                }

                return 0;
            });
        }

    //#endregion 

    //#region - Filtering

        private _applyGlobalFilter(items: any): any[] {
            const { columns } = this.props;
            const { globalFilter } = this.state;
            if (globalFilter === undefined || globalFilter.trim() === "") return items;
            const escapedRegex = this._escapeForRegex(globalFilter);
            const regex = new RegExp(escapedRegex, "i");
            if (columns && columns.length > 0) {
                let filteredItems = items.filter((item: any) => {
                    for (let i = 0; i < columns.length; i++) {
                        const column = columns[i];
                        // Date string comparison
                        if (column.type === "Date") {
                            const date = new Date(item[column.fieldName]);
                            if (!isNaN(date.getTime())) {
                                const localeDateStr = date.toLocaleDateString();
                                if (regex.test(localeDateStr)) return true;
                            }
                        }
                        // Basic text value comparison
                        if (item[column.fieldName] && 
                            typeof item[column.fieldName] === "string" &&
                            regex.test(item[column.fieldName])
                        ) {
                            return true;
                        }
                        // mapToText value comaprison
                        if (column.mapToText) {
                            let match = false;
                            const mappedValue = column.mapToText(item);
                            if (Array.isArray(mappedValue)) {
                                mappedValue.forEach((value) => {
                                    if (regex.test(value)) match = true;
                                });
                            } else {
                                if (typeof mappedValue === "number") {
                                    if (regex.test(mappedValue.toString())) match = true;
                                } else {
                                    if (regex.test(mappedValue)) match = true;
                                }
                            }
                            if (match) return true;
                        }
                        // 
                        if ((column as ITableTextOrObjColumn).objectValueMapping) {
                            const mappedValue = (column as ITableTextOrObjColumn).objectValueMapping(item[column.fieldName]);
                            if (regex.test(mappedValue)) return true;
                        }
                    }
                    return false;
                });
                return filteredItems;
            }
            return items;
        }
    
        private _addFilter(fieldName: string, filterFn: (items: any[]) => any[]) {
            const newFilters = {...this.state.activeFilters};
            newFilters[fieldName] = filterFn;
            this.setState({
                ...this.state,
                activeFilters: newFilters,
                columnContextMenu: undefined,
            });
        }

        private _removeFilter(fieldName: string) {
            const filters: Record<string, (items:any[]) => any[]> = {}; 
            for (const field in this.state.activeFilters) {
                if (field !== fieldName) {
                    filters[fieldName] = this.state.activeFilters[field];
                }
            }
            this.setState({
                ...this.state,
                activeFilters: filters,
                columnContextMenu: undefined,
            });
        }

    //#endregion 

    //#region - Column Render Methods

        /** Default render method used to render text and basic object, and
         * object array columns.
         */
        private _defaultRenderMethod(item: any, index: number, column: IColumn) {
            const colSettings: DevTableColumn = this.props.columns.filter((c) => c.fieldName === column.fieldName)[0];
            let returnVal: React.ReactNode | string;
            if (Array.isArray(item[column.fieldName])) {
                if (colSettings.type === "Text" || colSettings.type === "Object") {
                    if ((colSettings as ITableTextOrObjColumn).objectValueMapping) {
                        const fieldMappingValues = item[column.fieldName].map((val: any) => 
                            (colSettings as ITableTextOrObjColumn).objectValueMapping(val)
                        );
                        returnVal = fieldMappingValues.map((val: any) => {
                            if (typeof val === "string") {
                                return <div key={val}>{val}</div>;
                            }
                            return null;
                        });
                    } else {
                        returnVal = item[column.fieldName]
                            .filter((val: any) => typeof val === "string")
                            .map((val: any) => <div key={val}>{val}</div>);
                    }
                }
                return <div>{returnVal}</div>;
            } else {
                if ((colSettings.type === "Text" || colSettings.type === "Object")) {
                    if ((colSettings as ITableTextOrObjColumn).objectValueMapping) {
                        const fieldMapping = (colSettings as ITableTextOrObjColumn).objectValueMapping(item[column.fieldName]);
                        if (typeof fieldMapping === "string") {
                            return fieldMapping;
                        } else {
                            return null;
                        }
                    } else {
                        if (typeof item[column.fieldName] === "string") {
                            return item[column.fieldName];
                        }
                    }
                }
            }
        }

        /** Render method used for Date columns */
        private _renderDateCol(fieldName: string) {
            return (item: any, index: number, column: IColumn) => {
                if (!item[fieldName]) return null;
                return new Date(item[fieldName]).toLocaleDateString();
            };
        }

        /** Render method used for People / Persona columns */
        private _renderPersonaCol(fieldMapping: (value: any) => IFacepilePersona) {
            return (item: any, index: number, column: IColumn) => {
                if (!item[column.fieldName]) return null;
                let facepilePersonas: IFacepilePersona[] = [];
                if (!Array.isArray(item[column.fieldName]) && typeof item[column.fieldName] === "object") {
                    const personaProps = fieldMapping(item[column.fieldName]);
                    facepilePersonas.push(personaProps);
                }
                if (Array.isArray(item[column.fieldName])) {
                    const personaPropsArr = item[column.fieldName].map((value: any) => fieldMapping(value));
                    facepilePersonas.push(...personaPropsArr);
                }
                return (
                    <AsyncFacepile 
                        personas={facepilePersonas}
                    />
                );
                // if (!Array.isArray(item[column.fieldName]) && typeof item[column.fieldName] === "object") {
                //     const personaProps = fieldMapping(item[column.fieldName]);
                //     return (
                //         <TooltipHost content={[
                //             <span>{personaProps.text}</span>,<br/>,
                //             <span>({personaProps.secondaryText})</span>
                //         ]}>
                //             <PersonaCoin {...personaProps} />
                //         </TooltipHost>
                //     );
                // }
                // if (Array.isArray(item[column.fieldName])) {
                //     return (
                //         <Stack horizontal wrap>
                //             {item[column.fieldName].map((value: any) => {
                //                 const personaProps = fieldMapping(value);
                                
                //                 return (
                //                     <TooltipHost key={personaProps.text} tooltipProps={{
                //                         onRenderContent: () => (
                //                             <div>
                //                                 <div>{personaProps.text}</div>
                //                                 { personaProps.secondaryText !== undefined && (
                //                                     <div>({personaProps.secondaryText})</div>
                //                                 )}
                //                             </div>
                //                         )
                //                     }}>
                //                         <PersonaCoin {...personaProps} />
                //                     </TooltipHost>
                //                 );
                //             })}
                //         </Stack>
                //     );
                // }
                // return null;
            };
        }

        /** Render method used for Icon columns */
        private _renderIconCol(fieldMapping: (value: any) => IIconProps, iconProps: IIconProps) {
            return (item: any, index: number, column: IColumn) => {
                if (!item[column.fieldName]) return null;
                return <Icon {...iconProps} {...fieldMapping(item[column.fieldName])} />;
            };
        }

        /** Render method used for progress bar columns */
        private _renderProgressCol(fieldMapping: (value: any, item: any) => IProgressFieldMapping) {
            return (item: any, index: number, column: IColumn) => {
                const progressProps = fieldMapping(item[column.fieldName], item);
                if (typeof progressProps.barValue === "string") {
                    return progressProps.barValue as string;
                } else {
                    return (
                        <TooltipHost content={progressProps.barValue + "%"}>
                            <div className={progressProps.barClass}>
                                <div className="bar" style={{width: progressProps.barValue + "%"}}></div>
                            </div>
                        </TooltipHost>
                    );
                }
            };
        }

    //#endregion

    //#region - Sticky Header

        protected _attachHorizontalScroll() {
            const root = this._root.current;
            const list = root.querySelector('.ms-DetailsList');
            if (list) {
                list.removeEventListener('scroll', this._listHorizontalScroll);
                list.addEventListener('scroll', this._listHorizontalScroll);
            }
        }

        protected _listHorizontalScroll(evt: Event) {
            const root = this._root.current;
            if (this._root.current) {
                const list = root.querySelector('.ms-DetailsList');
                const scrollLeft = (evt.target as HTMLElement).scrollLeft;
                const headerRow = (list.querySelector('.ms-DetailsHeader') as HTMLElement);
                if (this.state.stickyHeader && headerRow) {
                    headerRow.style.left = (0 - scrollLeft) + "px";
                }
            }
        }

        protected _scrollChecker(evt: Event) {
            const root = this._root.current;
            if (this._root.current) {
                const list = root.querySelector('.ms-DetailsList') as HTMLElement;
                const detailsHeader = list.querySelector('.ms-DetailsHeader');
                const detailsListHeader = list.querySelector('.detailsListHeader');
                if (detailsHeader && detailsListHeader) {
                    const rect = (detailsListHeader as HTMLElement).getBoundingClientRect();
                    const curStickyHeader = this.state.stickyHeader;
                    const stickyHeader = rect.top < 52;
                    this.setState({
                        ...this.state,
                        stickyHeader,
                    }, () => {
                        const headerRow = (list.querySelector('.ms-DetailsHeader') as HTMLElement);
                        if (list && headerRow) {
                            if (stickyHeader) {
                                headerRow.style.left = (0 - list.scrollLeft) + "px";
                            } else {
                                if (curStickyHeader) {
                                    if (headerRow) {
                                        headerRow.style.left = "0px";
                                    }
                                }
                            }
                        }
                    });
                }
            }
        }

    //#endregion

    protected _onStatusMenuClick(item: IDevelopmentItem, newStatus: IStatusOption) {
        return () => {
            const requiredData: string[] = [];
            const warn: boolean = false;
            switch (newStatus.text) {
                case "More Information Required":
                    requiredData.push("ReviewDate", "LatestUpdate");
                    if (!item.assignedTo || (item.assignedTo && item.assignedTo.length === 0)) {
                        requiredData.push("AssignedTo");
                    }
                    break;
                case "Closed (Not Required)":
                case "Closed (Development Complete)":
                    requiredData.push("ClosedComments");
                    break;
                case "On Hold":
                    requiredData.push("ReviewDate", "LatestUpdate");
                    break;
                case "Scoping Stage":
                    requiredData.push("ReviewDate", "LatestUpdate");
                    break;
                case "In Development":
                    requiredData.push("LatestUpdate");
                    if (!item.assignedTo || (item.assignedTo && item.assignedTo.length === 0)) {
                        requiredData.push("AssignedTo");
                    }
                    break;
                case "Testing":
                    requiredData.push("ReviewDate", "LatestUpdate");
                    break;
                case "Ready for Deployment":
                    requiredData.push("LatestUpdate");
                    break;
                case "Live":
                    requiredData.push("LatestUpdate");
                    break;
            }
            if (item && item.id) {
                if (this._statusDialog && (warn || requiredData.length > 0)) {
                    this._statusDialog.open(item, newStatus, requiredData, warn);
                } else {
                    this.props.onStatusChange(item, newStatus);
                }
            }
        };
    }
    
    /* #########
       # Utils #
       #########
    */

    private _titleCase(str: string) {
        return str.split(/\s/).map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase()).join(' ');
    }

    private _escapeForRegex(str: string | undefined) {
        if (!str) return str;
        return str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    private _getUniqueFieldValues(values: any[]) {
        const uniqueValues: any[] = [];
        values.forEach((value) => {
            if (value) {
                if (uniqueValues.indexOf(value) === -1) {
                    uniqueValues.push(value);
                }
            }
        });
        return uniqueValues.sort();
    }

    private _onKeyDown(e: KeyboardEvent) {
        this._keyMap[e.key] = true;
        const selection: IObjectWithKey = this._selection.getSelection()[0];

        if (/development\/\d*/.test(window.location.href)) return;
        
        if (this._keyMap["Shift"] && this._keyMap["U"]) {
            if (selection) {
                this.props.onEdit(selection, "3");
            }
        }
            
        if (this._keyMap["Shift"] && this._keyMap["S"]) {
            if (selection && this._statusPicker) {
                this._statusPicker.open(selection as any);
            }
        }
    }

    private _onKeyUp(e: KeyboardEvent) {
        this._keyMap[e.key] = false;
        if (this._statusPicker && this._statusPicker.state.isOpen) {
            if (e.key === "Up" || e.key === "ArrowUp") {
                this._statusPicker.statusUp();
            }
            if (e.key === "Down" || e.key === "ArrowDown") {
                this._statusPicker.statusDown();
            }
        }
    }
}