import React from "react";
import BusinessGroupPicker from "./customPickers/BusinessGroupPicker";
import SPPeoplePicker from "./customPickers/PeoplePicker";
import PlatformPicker from "./customPickers/PlatformPicker";
import RAGPicker from "./customPickers/RAGPicker";
import RequestTypePicker from "./customPickers/RequestType";
import StatusDropdown from "./customPickers/StatusDropdown";
import UKDatePicker from "./customPickers/UKDatePicker";
import Updates from "./sections/Updates";

import * as API from "../api";

import { 
    BaseButton, 
    DefaultButton,
    Dropdown, 
    IChoiceGroupOption, 
    Icon, 
    IDropdownOption, 
    IPanelProps, 
    IPersonaProps, 
    ITag, 
    Label, 
    Panel, 
    PanelType, 
    Pivot, 
    PivotItem, 
    PrimaryButton, 
    Slider, 
    TextField, 
    Toggle, 
    TooltipHost,
    registerIcons,
    Stack, 
} from "office-ui-fabric-react";
import { IDevelopmentItem, IBusinessGroup, IUserLookup } from "../types";

import InterfacePicker from "./customPickers/InterfacePicker";
import DevelopmentHistory from "./sections/History";
import styles from "./DevelopmentForm.module.scss";
import { Attachments } from "./sections/Attachments";

// COVID-19 icon
registerIcons({
    icons: {
        covid19 : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g>
                <path d={`M422.219,383.348l0.179-0.07l-26.722-19.68c-21.203-15.66-29.488-36.156-32.16-54.129
                    c-0.07-0.492-0.191-1.012-0.25-1.496c-0.094-0.699-0.121-1.359-0.195-2.047c-0.187-1.82-0.367-3.637-0.449-5.343l1.359,0.453
                    l0.336,0.152l0.004-0.042l0.156,0.054l0.481-6.16c1.601-19.918,6.082-50.641,11.281-56.641
                    c9.039-10.558,28.078-37.442,33.438-53.121c15.043-44.321,6.722-88.958-24-128.958C353.757,14.801,304.316,0,271.676,0
                    c-2.718,0-5.359,0.078-7.598,0.32c-15.121,1.438-22.082,4.321-27.203,6.398c-3.039,1.282-5.118,2.161-8.156,2.402
                    c-1.442,0.157-2.883,0.157-4.321,0.157c-2.16,0-4.242-0.078-6.242-0.238c-2.238-0.16-4.399-0.238-6.719-0.238
                    c-3.199,0-6,0.238-9.039,0.718c-9.042,1.52-18.082,7.68-25.441,12.641c-1.68,1.199-3.282,2.238-4.801,3.199
                    c-1.118,0.801-2.078,0.879-2.801,0.879c-1.118,0-2.078-0.238-3.118-0.398c-1.68-0.402-3.199-0.641-4.882-0.641
                    c-3.278,0-6.238,1.121-8.958,3.282c-4.242,3.519-5.281,7.437-6,10c-0.562,2.32-0.562,2.32-2.722,2.64
                    c-6.078,0.879-26.078,5.118-28.558,22.238c-0.078,3.68-0.481,8.078-0.801,12.879c-1.199,14.32-2.481,32,1.441,47.121
                    c-3.359,7.762-5.762,15.918-7.199,24.238c-3.363,19.121-0.16,33.84,1.36,40.961c1.679,7.442,0.238,13.442-4.242,18.641
                    c-0.718,0.879-1.438,1.758-2.16,2.558c-0.238,0.082-0.477,0.321-0.558,0.562c-0.082,0.078-0.082,0.161-0.082,0.238
                    c-0.156,0.16-0.156,0.398-0.238,0.562c-5.039,11.199-8.32,22.157-10.398,32.398v0.078c-1.281,6.481-2.082,12.641-2.402,18.32
                    c-0.558,8.883-0.078,16.641,1.039,22.723c4.082,22.16,11.121,34.718,22.801,55.519c2.801,5.039,5.922,10.558,9.282,16.797
                    c0.718,1.282,1.762,2.481,2.961,3.203c0.48,0.797,1.039,1.68,1.519,2.558c0,0,1.125-0.374,2.789-0.953l0.367-0.106
                    c1.074,0.062,2.082-0.074,3.082-0.461c3.68-1.282,8.16-2.961,11.68-4.558c0.16,0.078,0.402,0.238,0.64,0.316
                    c5.602,2.723,13.282,3.762,25.84,3.762c9.84,0,21.039-0.64,30-1.199c0,0,6.082-0.398,7.84-0.48c0.562,1.442,1.121,3.442,1.922,6
                    c10.641,36,14.16,48.16,4,81.121c-2.722,8.879-7.039,21.68-12.878,38c-2.321,6.321-1.363,13.359,2.558,18.961
                    c3.918,5.598,10.238,8.879,17.039,8.879c5.281,0,10.32-2.082,14.242-5.762c10.958-10.321,66.637-62.641,90.317-77.282
                    c36.562-22.316,61.762-32.637,64.481-33.758l20.066-7.703l10.878-4.086L422.219,383.348z M199.387,182.774
                    c0.082,0.027,0.218,0.086,0.289,0.106c-7.996-10-28.254-35.321-36.813-45.934c-1.801-2.286-3.023-3.86-3.523-4.543
                    c25.254,13.926,49.801,25.371,55.938,28.718c8.16,4.477,8.078,17.836,8.961,21.758c0.379,2.218,2.301,22.804,3.797,40.625
                    c0.058,0.809,0.122,1.617,0.18,2.414l-44.469-2.519l-0.707-0.043c0.238-2.957,0.558-5.996,0.957-8.874
                    c0.082-0.321-0.078-0.723-0.32-1.043c-0.238-0.238-0.637-0.317-1.039-0.317c-1.762,0.317-4,0.559-6.48,0.719
                    c-13.68,0.801-34.399-0.801-49.918-2.321c-0.481-0.082-0.961-0.082-1.442-0.16c3.762-7.519,5.122-16.402,2.961-26.558
                    c-1.679-7.762-3.839-19.282-1.199-34c0.555-3.164,1.27-6.118,2.004-8.914c0.238-0.801,0.418-1.546,0.699-2.382
                    c11.649,12.641,42.738,30.187,59.734,38.606c5.035,2.539,8.895,4.316,10.555,4.871L199.387,182.774z M116.875,300.238l17.121-6.078
                    l20.161-7.121c0.64-0.16,0.961-0.879,0.801-1.52l-0.719-2.48c-0.16-0.641-0.722-0.961-1.363-0.879l-18.797,4.481l-18.402,4.48
                    l-10.718,2.559c-2.641-5.84-3.442-15.922-3.52-22.402c0-0.477,0-0.637,0-0.637v-0.562l28.481-2.161l22.238-1.679
                    c0.64,0,1.199-0.558,1.199-1.199V262c0-0.641-0.559-1.199-1.199-1.282l-20.719-0.238l-26.961-0.375l-1.758-0.035
                    c0.238-2.879,0.718-6.317,1.277-9.832c0.562-3.598,1.199-7.282,1.84-10.481c0.883-4.801,1.762-8.558,2-9.679
                    c0.961-3.84,4.242-6.481,8.16-6.641c0.402-0.16,0.879-0.078,1.282-0.078c12.718,1.199,31.442,2.641,46,2.879
                    c4,0.082,7.121,3.359,6.961,7.359c-0.32,10.402,0.078,20.481,1.278,28.883c2.082,14.797,6.402,31.038,6.562,31.758l2.238,8.48
                    c0.16,0.559,0.722,0.879,1.359,0.879l8.562-1.359c1.824-0.226,3.574-0.457,5.324-0.684l0.274-0.027l0.321,6.23
                    c-7.199,4.801-29.199,20.481-45.52,30.481c-7.121,4.402-13.121,7.602-16.398,8.402c-0.961,0.238-1.602,0.238-2.082,0.078
                    c-5.598-2.398-20.078-29.762-24-43.359L116.875,300.238z M166.312,342.375c16.953-11.434,41.445-27.578,41.766-27.817
                    c0.398-0.32,0.558-0.718,0.558-1.121l-0.398-6.719l75.359-21.839l-1.121-4.481l-65.106,13.633l-9.778,2.023l-0.316-5.894
                    c0-0.562-0.481-1.039-1.039-1.121c-0.16,0-2.961-0.238-8.16,0.082c-2.722,0.157-5.52,0.398-8.562,0.797
                    c-0.958-3.758-4.321-17.117-6-29.199c-1.118-7.762-1.52-17.038-1.199-26.64l47.281-0.161c1.122,1.122,2.961,1.203,4.078-0.078
                    h0.082l50.879-0.16l0.16-4.559l-38.492-2.168l-9.813-0.594c6.879-20.542,17.192-39.644,26.703-47.961
                    c7.008-6.09,17.258-9.633,27.371-10.34c0.082-0.004,0.168-0.015,0.25-0.019c10.125-0.66,20.082,1.539,26.461,6.918
                    c20.238,17.203,15.918,50.562,14.398,59.282c-1.039,6.16-8.078,22-8.078,22s7.84,22.718,9.278,24.48
                    c1.844,2.402,6.481,5.039,11.442,7.36c-0.848,18.297,1.777,45.074,19.07,68.742c0.035,0.051,0.074,0.098,0.109,0.145
                    c1.34,1.82,2.746,3.629,4.262,5.406c0.649,0.762,1.379,1.5,2.055,2.254c0.945,1.035,1.844,2.086,2.855,3.102
                    c3.598,3.657,7.622,7.188,12.122,10.547c-0.906,0.351-27.57,10.793-67.434,35.164c-23.68,14.562-73.918,61.281-93.36,79.519
                    c-0.16,0.188-0.339,0.274-0.519,0.383c-0.168,0.102-0.355,0.187-0.554,0.238c-0.196,0.046-0.391,0.102-0.606,0.102
                    c-1.68,0-3.122-1.52-2.481-3.36c3.762-10.402,9.52-26.801,13.203-38.801c11.598-38.082,7.039-54.242-3.922-91.602
                    c-3.84-12.961-7.199-18.238-11.762-19.277c-0.317-0.082-1.118-0.082-2.078-0.082c-6.801,0-27.442,2-43.442,2
                    c-1.57,0-3.008-0.023-4.461-0.062C167.024,342.453,166.668,342.438,166.312,342.375z M316.48,93.598l10.238,0.481l1.972,0.05
                    l0.922,0.102c0,0-0.031-0.058-0.039-0.078l0.183,0.008c0,0-2.48-4.961-7.839-11.203c-0.879-1.117-2-2.238-3.122-3.519
                    c-0.16-0.16-0.398-0.317-0.558-0.481c-0.199-0.129-0.394-0.347-0.59-0.586c-0.012-0.039-0.031-0.07-0.039-0.114
                    c1.492,0.238,3.058,0.551,4.547,0.942c0.801,0.238,1.601,0.481,2.32,0.801c16.16,5.68,26.481,17.039,31.602,24.32
                    c1.625,2.106,2.598,3.649,3.258,4.794c0.289,0.57,0.629,1.16,0.813,1.679l-2.473-0.152v-0.082c0,0,0,0-0.078,0l-7.519-0.641
                    l-3.23-0.246l-1.832-0.234c0,0,0.05,0.086,0.054,0.098l-0.195-0.016c0,0,1.844,3.122,4.562,7.438
                    c1.68,2.641,3.918,5.684,6.16,8.801c0.29,0.348,0.551,0.73,0.809,1.098c0.074,0.153,0.187,0.262,0.246,0.43
                    c-1.742,0.234-3.559,0.395-5.375,0.473h-0.719c-16.64,0-30.64-9.277-37.519-14.718c-1.442-1.199-2.641-2.321-3.442-3.122
                    c3.758,0.883,7.84,1.282,12,1.122c1.039,0,2.078-0.16,3.122-0.238c0.238,0,0.558-0.082,0.718,0
                    c0.238-0.082,0.558-0.082,0.801-0.082c0,0,0-0.078-0.082-0.078c0.082,0,0.082,0,0.082,0c-1.922-2.16-3.602-4.402-4.882-6.481
                    c-0.161-0.242-0.238-0.48-0.481-0.722c-1.199-2-2.156-3.84-2.797-5.438c-1.187-2.614-1.586-4.348-1.598-4.386V93.598z`}></path>
            </g>
            </svg>
        ),
    },
});

export enum DevelopmentFormMode {
    NEW = "NEW",
    EDIT = "EDIT",
    DISPLAY = "DISPLAY",
}

export interface IDevelopmentFormProps {
    mode: DevelopmentFormMode;
    development?: Partial<IDevelopmentItem>;
    presetTab?: string;
    onCompletionOrClose: (updatedDevelopment?: IDevelopmentItem, newItemAdded?: boolean) => void;
    onUpdateAdded: (id: number, updatedDevelopment: IDevelopmentItem) => void;
}
export interface IDevelopmentFormState extends Partial<IDevelopmentItem> {
    [index: string]: any;
    saving: boolean;
    currentTab: string;
    pendingLatestUpdate: string | undefined;
    ActualEffortStr: string;
    EstimatedEffortStr: string;
    PrioritisationStr: string;
    errors: { [index: string]: string };
}

const divisionOptions: IDropdownOption[] = [
    { key: 'Local', text: 'Local' },
    { key: 'Community', text: 'Community' },
    { key: 'Corporate', text: 'Corporate' },
    { key: 'Secure and Specialist LD', text: 'Secure and Specialist LD' }
  ];

export default class DevelopmentForm extends React.Component<IDevelopmentFormProps, IDevelopmentFormState> {
    private _mode = "NEW";
    constructor(props: IDevelopmentFormProps) {
        super(props);

        // bindings
        this._onTextFieldChange = this._onTextFieldChange.bind(this);
        this._onBusinessGroupChanged = this._onBusinessGroupChanged.bind(this);
        this._onPlatformChanged = this._onPlatformChanged.bind(this);
        this._onPeoplePickerChange = this._onPeoplePickerChange.bind(this);
        this._onDropDownChange = this._onDropDownChange.bind(this);
        this._onNewUpdate = this._onNewUpdate.bind(this);
        this._saveItem = this._saveItem.bind(this);
        this._tabChanged = this._tabChanged.bind(this);
        
        let state: IDevelopmentFormState;
        const { development } = this.props;
        if (development) {
            // if a valid development was passed in
            // we'll use it's values
            const EstimatedEffortStr = development.estimatedEffort ? development.estimatedEffort.toString() : "";
            const ActualEffortStr = development.actualEffort ? development.actualEffort.toString() : "";
            const PrioritisationStr = development.prioritisation ? development.prioritisation.toString() : "";
            state = {...development,
                ActualEffortStr, 
                EstimatedEffortStr,
                PrioritisationStr,
                currentTab: this.props.presetTab ? this.props.presetTab : "0",
                errors: {},
                pendingLatestUpdate: "",
                saving: false,
            };
        } else {
            // otherwise we'll start with a blank
            state = {
                errors: {},
                status: { id: 1, text: "New" }
            } as IDevelopmentFormState;
        } 

        this.state = state;
    }

    public componentDidMount() {
        this._validateForm();
    }

    public componentDidUpdate(prevProps: IDevelopmentFormProps, prevState: IDevelopmentFormState) {
        for (const key in prevState) {
            if (key !== "errors" && prevState[key] !== this.state[key]) {
                this._validateForm();
            }
        }
    }

    public render() {
        const { 
            businessGroups: Organisation,
        } = this.state;

        const headerText = !this.props.development ? "New Development" : 
            this.props.development.title;

        let hidePeoplePicker = true;
        if (Organisation && 
            (Organisation.filter((a) => a.id === 1).length || // Mersey Care
            Organisation.filter((a) => a.id === 2).length || // IM
            Organisation.filter((a) => a.id === 17).length)) { // IM Development Service
                hidePeoplePicker = false;
            }
        
        // Show Division drop down if Orgainstion is Mersey Care    
        let hideDivison = true;
        if (Organisation && Organisation.filter((a) => a.id === 1).length) { 
                hideDivison = false; 
            }

        let canSave = 
            !this.isNullOrEmpty(this.state.title) &&
            !this.isNullOrEmpty(this.state.department) &&
            !this.isNullOrEmpty(this.state.dateSubmitted) &&
            !this.isNullOrEmpty(this.state.description) &&
            !this.isNullOrEmpty(this.state.requestType);

        let cannotSaveMsg: JSX.Element | undefined; 
        if (canSave) {
            let tmp = "The following fields need updating before you can save: <br/>";
            let errors = false;
            for (const key in this.state.errors) {
                if (this.state.errors[key] !== undefined && this.state.errors[key] !== "") {
                    tmp += key + ", <br/>";
                    errors = true;
                }
            }
            if (errors) {
                canSave = false;
                cannotSaveMsg = <span dangerouslySetInnerHTML={{__html: tmp}} />;
            }
        }

        return (
            <Panel isOpen={true} 
            headerText={headerText} 
            onDismiss={(ev?: React.SyntheticEvent<HTMLElement, Event> | undefined) => { 
                this.props.onCompletionOrClose();
            }}
            onRenderFooter={(props: IPanelProps | undefined) => {
                if (this.state.currentTab !== "3" && this.state.currentTab !== "4" && this.state.currentTab !== "5") {
                    return (
                        <Stack horizontal horizontalAlign="end" verticalAlign="center" style={{margin: 20}}>
                            <TooltipHost content={cannotSaveMsg}>
                                <PrimaryButton style={{marginRight: "20px"}}
                                    text="Save"
                                    disabled={!canSave}
                                    iconProps={{ iconName: "Save" }}
                                    onClick={this._saveItem}
                                />
                            </TooltipHost>
                            <DefaultButton text="Cancel" onClick={() => { this.props.onCompletionOrClose(); }} />
                        </Stack>
                    );
                } else {
                    return null;
                }
            }}
            onRenderHeader={(props: IPanelProps | undefined) => {
                if (props) {
                    return (
                        <Stack horizontal horizontalAlign="space-between" verticalAlign="center" grow style={{margin: "10px 25px"}}>
                            <h3>{ props.headerText }</h3>
                            <Stack horizontal horizontalAlign="end" verticalAlign="center">
                                <TooltipHost content="Flag to OLIT">
                                    <Stack horizontal verticalAlign="center">
                                        <Icon iconName="Flag" style={{width: 25, fontSize: 23}} /> 
                                        <Toggle 
                                            checked={this.state.flagged} onChange={(event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) => {
                                            this.setState({
                                                ...this.state,
                                                flagged: checked,
                                            });
                                        }} />
                                    </Stack>
                                </TooltipHost>
                                <TooltipHost content="Flag if urgent or related to Covid-19 response">
                                    <Stack horizontal verticalAlign="center">
                                        <Icon iconName="covid19" style={{width: 25}} />
                                        <Toggle 
                                            checked={this.state.majorIncidentRelated} onChange={(event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) => {
                                            this.setState({
                                                ...this.state,
                                                majorIncidentRelated: checked,
                                            });
                                        }} />
                                    </Stack>
                                </TooltipHost>
                            </Stack>
                        </Stack>
                    );
                } else {
                    return null;
                }
            }}
            type={PanelType.medium}
            className={styles.developmentForm + 
                (!this.props.development ? " " + styles.newForm : "")}>

                <Pivot className={styles.tabs} selectedKey={this.state.currentTab} onLinkClick={this._tabChanged}>
                    <PivotItem key="0" itemKey="0" headerText="Request Details">
                        <TextField 
                            autoFocus={true}
                            label="Development Name" 
                            value={this.state.title} 
                            onChange={this._onTextFieldChange("title")}
                            errorMessage={this.isNullOrEmpty(this.state.title) ?
                                "Please enter a development name" : undefined
                            }
                        />
                        <Label>Organisation</Label>
                        <BusinessGroupPicker 
                            onChange={this._onBusinessGroupChanged} 
                            disabled={false} 
                            selectedOrganisations={this.state.businessGroups ? this.state.businessGroups.map((a: IBusinessGroup) => ({
                                key: a.id.toString(),
                                name: a.name,
                            } as ITag)) : undefined} 
                        />
                        <PlatformPicker 
                            selectedPlatform={this.state.platform} 
                            onChange={this._onPlatformChanged} 
                        />
                        { !hideDivison && (
                            <Dropdown
                                label="Division"
                                selectedKey={this.state.division ? this.state.division : undefined}
                                options={divisionOptions}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IDropdownOption | undefined) => {
                                    if (option) {
                                        this.setState({
                                            ...this.state,
                                            division: option.key as string,
                                        });
                                    }
                                }}
                            />
                        )}
                        <TextField 
                            label="Department" 
                            value={this.state.department}
                            onChange={this._onTextFieldChange("department")}
                            errorMessage={this.isNullOrEmpty(this.state.department) ?
                                "Please enter a department name" : undefined
                            }
                        />
                        { hidePeoplePicker && (
                            <TextField 
                                label="Requested By"
                                value={this.state.requestedBy}
                                onChange={this._onTextFieldChange("requestedBy")} 
                            />
                        )}
                        <TextField
                            label="Contact Telephone Number"
                            value={this.state.contactNumber}
                            onChange={this._onTextFieldChange("contactNumber")} />
                        { hidePeoplePicker && (
                            <TextField
                                label="Contact Email"
                                value={this.state.contactEmail}
                                onChange={this._onTextFieldChange("contactEmail")} />
                        )}
                        { !hidePeoplePicker && (
                            <SPPeoplePicker 
                                label="Requestor(s)"
                                selectedPeople={this.state.requestors ? this.state.requestors.map((u: IUserLookup) => ({
                                    itemID: u.id,
                                    secondaryText: u.mail,
                                    text: u.name,
                                } as IPersonaProps)) : []} 
                                onChange={this._onPeoplePickerChange("requestors")} 
                            />
                        )}
                        <UKDatePicker 
                            label="Date Submitted" 
                            initialPickerDate={new Date()} 
                            dateString={this.state.dateSubmitted ? this.state.dateSubmitted : undefined} 
                            onSelectDate={(date: Date | null | undefined) => {
                                this.setState({
                                    ...this.state,
                                    dateSubmitted: date.toJSON(),
                                });
                            }}   
                            errorMessage={this.isNullOrEmpty(this.state.dateSubmitted) ?
                                "Please select a date" : undefined
                            }
                        />
                        <RequestTypePicker 
                            selectedRequestType={this.state.requestType} 
                            onChange={(type) => {
                                this.setState({
                                    ...this.state,
                                    link: type === "New" ? undefined : this.state.link,
                                    project: type === "New" ? undefined : this.state.project,
                                    requestType: type,
                                });
                            }}
                            errorMessage={this.isNullOrEmpty(this.state.requestType) ?
                                "Please select a request type" : undefined
                            }
                        />
                        { this.state.requestType === "Upgrade" && 
                            (this.state.platform === "Web" || this.state.platform === "SharePoint")
                            && (
                            <TextField label="Link to existing site"
                                value={this.state.link}
                                onChange={this._onTextFieldChange("link")}
                                errorMessage={this.state.link && /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(this.state.link) ? "" : "Please enter a valid Url"}
                            />
                        )}
                        { this.state.requestType === "Upgrade" && this.state.platform === "Integration" && (
                            <InterfacePicker
                                value={this.state.project}
                                onChanged={(newValue) => {
                                    this.setState({
                                        ...this.state,
                                        project: newValue,
                                    });
                                }}
                            />
                        )}
                        { this.state.requestType === "Upgrade" && (
                            <p>Please include details of the upgrade to be carried out in the Description box below.</p>
                        )}
                        <Label>Description</Label>
                        <div className="expandingTextarea">
                            {/* <TextareaAutosize
                                value={this.state.description}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    const target = event.target as HTMLTextAreaElement;
                                    if (target) {
                                        this.setState({
                                            ...this.state,
                                            description: target.value,
                                        });
                                    }
                                }}
                            /> */}
                        </div>
                        { !this.state.description && (
                            <span className="ms-error">Please include a description</span>
                        )}
                        {/* <TextField 
                            multiline={true} 
                            label="Description"
                            value={this.state.Description} 
                            onChanged={this._onTextFieldChange("Description")}
                            errorMessage={this.isNullOrEmpty(this.state.Description) ?
                                "Please include a description" : undefined
                            }
                        /> */}
                    </PivotItem>
                    <PivotItem key="1" itemKey="1" headerText="Development">
                        <StatusDropdown 
                            autoFocus={true}
                            value={this.state.status}
                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) => {
                                this.setState({
                                    ...this.state,
                                    status: option ? {
                                        id: (option.key as number),
                                        order: index || 0,
                                        text: option.text,
                                    } : undefined,
                                });
                            }}
                        />
                        <UKDatePicker 
                            label="Review Date" 
                            initialPickerDate={new Date()} 
                            dateString={this.state.reviewDate ? this.state.reviewDate : undefined} 
                            onSelectDate={(date: Date | null | undefined) => {
                                this.setState({
                                    ...this.state,
                                    reviewDate: date.toJSON(),
                                });
                            }}    
                            errorMessage={this._getError("Review Date")}
                        />
                        <TextField 
                            label="Estimated Effort"
                            value={this.state.EstimatedEffortStr}
                            onChange={this._onTextFieldChange("EstimatedEffortStr")}
                            onKeyDown={this._preventAlphaKeypresses}
                            onBlur={this._onNumericFieldBlur("estimatedEffort")}
                            />
                        <TextField 
                            label="Prioritisation"
                            value={this.state.PrioritisationStr}
                            onChange={this._onTextFieldChange("PrioritisationStr")}
                            onKeyDown={this._preventAlphaKeypresses}
                            onBlur={this._onNumericFieldBlur("prioritisation")}
                            />
                        <SPPeoplePicker 
                            label="Assigned To"
                            selectedPeople={this.state.assignedTo ? this.state.assignedTo.map((u: IUserLookup) => ({
                                itemID: u.id,
                                secondaryText: u.mail,
                                text: u.name,
                            } as IPersonaProps)) : []} 
                            onChange={this._onPeoplePickerChange("assignedTo")} 
                        />
                        <TextField 
                            label="Project"
                            value={this.state.project}
                            onChange={this._onTextFieldChange("project")}
                        />
                        <UKDatePicker 
                            label="Due Date" 
                            initialPickerDate={new Date()} 
                            dateString={this.state.dueDate ? this.state.dueDate : undefined} 
                            onSelectDate={(date: Date | null | undefined) => {
                                this.setState({
                                    ...this.state,
                                    dueDate: date.toJSON(),
                                });
                            }}    
                        />
                        <UKDatePicker 
                            label="Estimated Start Date" 
                            initialPickerDate={new Date()} 
                            dateString={this.state.estimatedStart ? this.state.estimatedStart : undefined} 
                            onSelectDate={(date: Date | null | undefined) => {
                                this.setState({
                                    ...this.state,
                                    estimatedStart: date.toJSON(),
                                });
                            }}    
                        />
                        <UKDatePicker 
                            label="Estimated End Date" 
                            initialPickerDate={new Date()} 
                            dateString={this.state.estimatedEnd ? this.state.estimatedEnd : undefined} 
                            onSelectDate={(date: Date | null | undefined) => {
                                this.setState({
                                    ...this.state,
                                    estimatedEnd: date.toJSON(),
                                });
                            }}    
                        />
                        <RAGPicker 
                            label="RAG Status"
                            value={this.state.ragStatus}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
                                this.setState({
                                    ...this.state,
                                    ragStatus: option ? option.text : undefined,
                                });
                            }}
                        />
                        <UKDatePicker 
                            label="Start Date" 
                            initialPickerDate={new Date()} 
                            dateString={this.state.startDate ? this.state.startDate : undefined} 
                            onSelectDate={(date: Date | null | undefined) => {
                                this.setState({
                                    ...this.state,
                                    startDate: date.toJSON(),
                                });
                            }}    
                        />
                        <UKDatePicker 
                            label="End Date" 
                            initialPickerDate={new Date()} 
                            dateString={this.state.endDate ? this.state.endDate : undefined} 
                            onSelectDate={(date: Date | null | undefined) => {
                                this.setState({
                                    ...this.state,
                                    endDate: date.toJSON(),
                                });
                            }}    
                        />
                        <TextField 
                            label="Actual Effort"
                            value={this.state.ActualEffortStr}
                            onChange={this._onTextFieldChange("ActualEffortStr")}
                            onKeyDown={this._preventAlphaKeypresses}
                            onBlur={this._onNumericFieldBlur("actualEffort")}
                            />
                        <Slider 
                            className={styles.completionSlider}
                            label="Complete"
                            min={0}
                            max={100}
                            step={1}
                            showValue={true}
                            value={this.state.complete}
                            onChange={(value: number) => {
                                this.setState({
                                    ...this.state,
                                    complete: value,
                                });
                            }}
                        />
                        <UKDatePicker 
                            label="Go Live Date" 
                            initialPickerDate={new Date()} 
                            dateString={this.state.goLiveDate ? this.state.goLiveDate : undefined} 
                            onSelectDate={(date: Date | null | undefined) => {
                                this.setState({
                                    ...this.state,
                                    goLiveDate: date.toJSON(),
                                });
                            }}    
                        />
                        <TextField 
                            label="Closed Comments"
                            multiline={true}
                            value={this.state.closedComm}
                            onChange={this._onTextFieldChange("closedComm")}
                            errorMessage={this._getError("Closed Comments")}
                        />
                    </PivotItem>
                    <PivotItem key="2" itemKey="2" headerText="OLIT">
                        <TextField 
                            autoFocus={true}
                            multiline={true} 
                            label="OLIT Description"
                            value={this.state.olitDescription} 
                            onChange={this._onTextFieldChange("olitDescription")}
                        />
                        <TextField 
                            multiline={true} 
                            label="OLIT Update"
                            value={this.state.olitUpdate} 
                            onChange={this._onTextFieldChange("olitUpdate")}
                        />
                    </PivotItem>
                    <PivotItem key="3" itemKey="3" headerText="Updates">
                        { this.props.development && this.props.development.id && (
                            <Updates 
                                initialFieldText={this.state.pendingLatestUpdate}
                                onChangeUpdateField={(newValue) => {
                                    this.setState({
                                        ...this.state,
                                        pendingLatestUpdate: newValue,
                                    });
                                }}
                                // onNewUpdate={this._onNewUpdate}
                                developmentId={this.props.development.id} 
                            />
                        )}
                    </PivotItem>
                    <PivotItem key="4" itemKey="4" itemIcon="History">
                        { this.props.development && this.props.development.id && (
                            <DevelopmentHistory developmentId={this.props.development.id} />
                        )}
                    </PivotItem>
                    <PivotItem key="5" itemKey="5" itemIcon="Attach">
                        { this.props.development && (
                            <Attachments developmentId={this.props.development.id} />
                        )}
                    </PivotItem>
                </Pivot>
            </Panel>
        );
    }
    private _getError(field: string) {
        return this.state.errors[field] !== undefined ? this.state.errors[field] : "";
    }
    private _validateForm() {
        const errors: { [index: string]: string } = {};
        if (this.state.status) {
            if (/closed/i.test(this.state.status.text)) {
                if (!this.state.closedComm || (this.state.closedComm && this.state.closedComm.trim() === "")) {
                    errors["Closed Comments"] = "Please enter closed comments";
                }
            }
            if (this.state.status.text === "More Information Required" ||
                this.state.status.text === "On Hold" ||
                this.state.status.text === "Testing" ||
                this.state.status.text === "Scoping Stage" ||
                this.state.status.text === "Live") {
                    if (!this.state.reviewDate) {
                        errors["Review Date"] = "Please enter a review date";
                    }
                }
        }
        this.setState({
            ...this.state,
            errors,
        });
    }

    private _tabChanged(item?: PivotItem | undefined, ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) {
        if (item && item.props.itemKey) {
            this.setState({
                ...this.state,
                currentTab: item.props.itemKey,
            });
        }
    }

    private _onNewUpdate(updatedDevelopment: IDevelopmentItem) {
        this.setState({
            ...this.state,
            latestUpdate: updatedDevelopment.latestUpdate,
            pendingLatestUpdate: "",
        }, () => {
            if (this.props.development && this.props.development.id) {
                this.props.onUpdateAdded(this.props.development.id, updatedDevelopment);
            }
        });
    }

    private _saveItem(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton, MouseEvent>) {
        event.preventDefault();
        this.setState({
            ...this.state,
            saving: true,
        }, async () => {
            const response = await API.saveDevelopment(this.state);
            if (response && !(response instanceof Error) && response.success === true) {
                // saved yay
                this.props.onCompletionOrClose(response.development, !this.props.development);
            } else {
                // boooo
                if (response && (response instanceof Error)) {
                    alert('Error occurred while trying to save: ' + response.message);
                } else {
                    alert('Failed to save');
                }
            }
        });
    }

    private _preventAlphaKeypresses(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
        // TO DO: Check this works ok in IE
        if (/[0-9.]/.test(event.key) || event.key === "Backspace" || event.key === "Delete" || event.key === "Tab" ||
            /(?:Arrow)Left/.test(event.key) || /(?:Arrow)Right/.test(event.key)) {
            return true;
        } else {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
    }

    private _isNumericValue(value: string) {
        return value === "" || /(?!a-Z)([0-9.]*)/.test(value);
    }
    
    private _onTextFieldChange(stateProperty: string) {
        return (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
            const newState: IDevelopmentFormState = {...this.state};
            newState[stateProperty] = newValue;
            this.setState(newState);
        };
    }

    private _onNumericFieldChange(stateProperty: string) {
        return (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
            const newState: IDevelopmentFormState = {...this.state};
            if (newValue !== undefined) {
                if (newValue === "") {
                    newState[stateProperty] = undefined;
                } else if (this._isNumericValue(newValue)) {
                    newState[stateProperty] = parseFloat(newValue);
                }
            } else {
                newState[stateProperty] = undefined;
            }
            console.log(newState);
            this.setState(newState);
        };
    }

    private _onNumericFieldBlur(stateProperty: string) {
        return (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const val = (event.target as HTMLInputElement).value;
            const newState: IDevelopmentFormState = {...this.state};
            if (val !== undefined) {
                if (val === "") {
                    newState[stateProperty] = undefined;
                } else if (this._isNumericValue(val)) {
                    newState[stateProperty] = parseFloat(val);
                }
            } else {
                newState[stateProperty] = undefined;
            }
            this.setState(newState);
        };
    }

    private _onBusinessGroupChanged(items?: ITag[] | undefined) {
        if (items) {
            const businessGroups: IBusinessGroup[] = items.map((a: ITag) => ({
                id: parseInt(a.key as string, 10),
                name: a.name,
            } as IBusinessGroup));
            this.setState({
                ...this.state,
                businessGroups: businessGroups,
            });
        }
    }

    private _onPlatformChanged(ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) {
        this.setState({
            ...this.state,
            platform: option ? option.key : undefined,
        });
    }

    private _onPeoplePickerChange(property: string) {
        return (items?: IPersonaProps[] | undefined) => {
            if (items) {
                const people: IUserLookup[] = items.map((r: IPersonaProps) => {
                    return ({
                        mail: r.secondaryText,
                        id: r.itemID,
                        name: r.text,
                    } as IUserLookup);
                });
                const newState = {...this.state};
                newState[property] = people;
                this.setState(newState);
            }
        };
    }   

    private _onDropDownChange(property: string) {
        return (option: IDropdownOption, index?: number | undefined) => {
            const newState = {...this.state};
            newState[property] = option.key;
            this.setState(newState);
        };
    }

    private isNullOrEmpty(str: string | null | undefined) {
        return !str || (str && str.trim() === "");
    }
}
