import * as React from "react";
import { Calendar, DatePicker, DateRangeType, IDatePickerProps, IDatePickerStyleProps, IDatePickerStyles, IStyleFunctionOrObject } from "office-ui-fabric-react";

export interface IWeekPickerProps {
    /** Week commencing (Sunday) */
    value: Date;
    onSelectWeek: (weekStart: Date, weekEnd: Date) => void;
    datePickerProps?: IDatePickerProps;
    className?: string;
    styles?: IStyleFunctionOrObject<IDatePickerStyleProps, IDatePickerStyles>;
}

export interface IWeekPickerState {}

export const WeekPicker = (props: IWeekPickerProps) => {
    const getWeekStart = (date: Date) => {
        if (date == null) return null;
        while (date.getDay() > 0) {
            date.setDate(date.getDate() - 1);
        }
        return date;
    };

    return (
        <DatePicker
            className={props.className}
            styles={{
                ...props.styles,
                callout: {
                    ".ms-DatePicker-goToday": {
                        right: 13
                    }
                }
            }}
            calendarAs={(calendarProps) => (
                <Calendar 
                    {...calendarProps}
                    isMonthPickerVisible={true}
                    dateRangeType={DateRangeType.Week}
                    showGoToToday={true}
                    showSixWeeksByDefault={true}
                />
            )}
            label="Date" value={props.value} onSelectDate={(newDate) => {
                const weekStart = getWeekStart(newDate);
                const weekEnd = new Date(weekStart);
                weekEnd.setDate(weekStart.getDate() + 6);
                props.onSelectWeek(weekStart, weekEnd);
            }} formatDate={(date) => {
                const weekStart = date;
                const weekEnd = new Date(date);
                weekEnd.setDate(weekStart.getDate() + 6);
                const ddMMM = (d: Date, i?: boolean) => {
                    return ("0" + d.getDate()).slice(-2) + " " + d.toLocaleString('en-us',{month: 'short', year: i ? '2-digit' : undefined});
                };
                return `${ddMMM(weekStart)} - ${ddMMM(weekEnd, true)}`;
            }} 
            {...props.datePickerProps}
        />
    );
}