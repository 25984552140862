import { IBusinessGroupResponse } from "../types";

export async function getBusinessGroups() {
    const result = await fetch(`/api/businessGroup`, {
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });
    if (result.ok) {
        return await result.json() as IBusinessGroupResponse;
    } else {
        throw new Error('Unable to retrieve business groups');
    }
}