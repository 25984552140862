import * as React from "react";
import { 
    css, 
    Checkbox, 
    DirectionalHint, 
    Facepile, 
    Icon, 
    IconButton, 
    IFacepilePersona, 
    Persona, 
    PersonaCoin, 
    PersonaSize, 
    Stack, 
    TooltipHost, 
    Panel,
    PanelType,
    Label,
    DefaultButton
} from "office-ui-fabric-react";
import { IBusinessGroup, IUserLookup, ViewItem } from "../types";
import { getPlatformIconName } from "../utils";
import styles from "./SortComponent.module.scss";
import { DisplayContext } from "../DisplayContext";
import FormSection from "../developmentForm/sections/FormSection";

const colors = ["rgb(120,180,198)", "rgb(43,250,254)", "rgb(49,164,108)", "rgb(178,226,145)", "rgb(99,254,166)", "rgb(59,181,2)", "rgb(232,234,53)", "rgb(253,89,37)", "rgb(246,141,146)", "rgb(214,121,31)", "rgb(136,254,14)", "rgb(137,151,91)", "rgb(255,204,137)", "rgb(255,77,130)", "rgb(4,148,251)"];

export enum SortIntent {
    Prioritise = "Prioritise",
    ResolveConflicts = "ResolveConflicts",
    PlatformMerge = "PlatformMerge"
}

export interface ISortComponentProps {
    developments?: ViewItem[];
    selectedDevelopmentIds?: number[];
    showNonPrioritised?: boolean;
    selectionEnabled?: boolean;
    isDevTeamView?: boolean;
    intent: SortIntent;
    sortProperty?: "businessPriority" | "platformPriority";
    onUpdate: (developments: ViewItem[]) => void;
    onSelection?: (selectedDevelopmentIds: number[]) => void;
}

export interface ISortComponentState {
    orgColors: Record<string,string>;
    showDevelopmentInfo?: number;
}

export class SortComponent extends React.Component<ISortComponentProps, ISortComponentState> {
    static contextType = DisplayContext;

    private _draggedDevelopment?: HTMLDivElement;
    private _hoveredDevelopment?: HTMLDivElement;
    private _firstDrag?: boolean = false;
    private _draggableArea?: HTMLDivElement;

    constructor(props: ISortComponentProps) {
        super(props);
        this._changePrioritisation = this._changePrioritisation.bind(this);
        this._handleDevelopmentMouseDown = this._handleDevelopmentMouseDown.bind(this);
        this._handleDevelopmentMouseOver = this._handleDevelopmentMouseOver.bind(this);
        this._handleDocumentMouseMove = this._handleDocumentMouseMove.bind(this);
        this._handleDocumentMouseUp = this._handleDocumentMouseUp.bind(this);
        this._handleDocumentTouchEnd = this._handleDocumentTouchEnd.bind(this);
        this._handleDocumentTouchMove = this._handleDocumentTouchMove.bind(this);
        this._handleDropPlaceholderMouseOut = this._handleDropPlaceholderMouseOut.bind(this);
        this._handleDropPlaceholderMouseOver = this._handleDropPlaceholderMouseOver.bind(this);
        this._handleTouchDown = this._handleTouchDown.bind(this);
        this._onKeyUp = this._onKeyUp.bind(this);
        this._sortTileRender = this._sortTileRender.bind(this);

        this.state = {
            orgColors: this._calculateOrgColors()
        };
    }

    public componentDidMount() {
        if (this._draggableArea) {
            this._draggableArea.addEventListener("mouseup", this._handleDocumentMouseUp);
            this._draggableArea.addEventListener("mousemove", this._handleDocumentMouseMove);
            this._draggableArea.addEventListener("touchmove", this._handleDocumentTouchMove, { passive: false });
            this._draggableArea.addEventListener("touchend", this._handleDocumentTouchEnd);
            document.addEventListener("keyup", this._onKeyUp);
        }
    }

    public componentWillUnmount() {
        if (this._draggableArea) {
            this._draggableArea.removeEventListener("mouseup", this._handleDocumentMouseUp);
            this._draggableArea.removeEventListener("mousemove", this._handleDocumentMouseMove);
            this._draggableArea.removeEventListener("touchmove", this._handleDocumentTouchMove);
            this._draggableArea.removeEventListener("touchend", this._handleDocumentTouchEnd);
            document.removeEventListener("keyup", this._onKeyUp);
        }
    }

    public componentDidUpdate(prevProps: ISortComponentProps, prevState: ISortComponentState) {
        if (prevProps.developments !== this.props.developments) {
            this.setState({
                orgColors: this._calculateOrgColors()
            });
        }
    }

    public render() {
        const { 
            developments: filteredDevelopments, 
            sortProperty,
            isDevTeamView,
            intent
        } = this.props;
        const {
            showDevelopmentInfo
        } = this.state;

        let moreInfoDevelopment: ViewItem, moreInfoDevIdx: number;
        if (showDevelopmentInfo) {
            moreInfoDevIdx = filteredDevelopments.map((d) => d.id).indexOf(showDevelopmentInfo);
            moreInfoDevelopment = filteredDevelopments[moreInfoDevIdx];
        }

        const sortedTiles = filteredDevelopments.filter((d) => Boolean(d[sortProperty]) === true);
        const unsortedTiles = filteredDevelopments.filter((d) => Boolean(d[sortProperty]) === false);

        let sortedLabel = 'Prioritised', unsortedLabel = 'Not yet prioritised';
        switch (intent) {
            case SortIntent.PlatformMerge:
                sortedLabel = 'Priority Order (for submission to Development Service)';
                unsortedLabel = 'Remaining developments will be assigned priority in-order';
                break;
            case SortIntent.ResolveConflicts:
                sortedLabel = 'Order'; unsortedLabel = 'Conflicts to resolve';
                break;
        }

        return (
            <DisplayContext.Consumer>
                {({darkMode}) => (
                    <div ref={(ref) => this._draggableArea = ref}>
                        { sortedTiles.length > 0 && <Label>{sortedLabel}</Label>}
                        <Stack id="sortedTiles" onMouseOver={this._handleDropPlaceholderMouseOver} onMouseOut={this._handleDropPlaceholderMouseOut} className={styles.sortComponent} wrap horizontal horizontalAlign="center">
                            { sortedTiles.map(this._sortTileRender(true))}
                        </Stack>
                        { unsortedTiles.length > 0 && <Label>{unsortedLabel}</Label>}
                        <Stack id="unsortedTiles" className={styles.sortComponent} wrap horizontal horizontalAlign="center">
                            { unsortedTiles.map(this._sortTileRender(false))}
                        </Stack>
                        { moreInfoDevelopment !== undefined && (
                            <Panel 
                                type={PanelType.medium} 
                                headerText={moreInfoDevelopment.title} 
                                isOpen={true} 
                                onDismiss={() => this.setState({showDevelopmentInfo: undefined})}
                                isFooterAtBottom={true}
                                onRenderFooter={(panelProps) => (
                                    <Stack styles={{root: {margin: 20}}} horizontal horizontalAlign="space-between">
                                        <IconButton iconProps={{iconName: "Previous"}} onClick={() => {
                                            let prevIdx = moreInfoDevIdx - 1;
                                            if (prevIdx < 0) prevIdx = filteredDevelopments.length - 1;
                                            const nextDevelopment = filteredDevelopments[prevIdx];
                                            this.setState({
                                                showDevelopmentInfo: nextDevelopment.id
                                            });
                                        }} />
                                        <DefaultButton
                                            text="Move to Top"
                                            onClick={() => this._toTop(moreInfoDevelopment)}
                                        />
                                        { Boolean(moreInfoDevelopment[sortProperty]) === false && (
                                            <DefaultButton
                                                text="Prioritise"
                                                onClick={() => this._toBottom(moreInfoDevelopment)}
                                            />
                                        ) }
                                        <DefaultButton
                                            text="Move to Bottom"
                                            onClick={() => this._toBottom(moreInfoDevelopment)}
                                        />
                                        <IconButton iconProps={{iconName: "Next"}} onClick={() => {
                                            const nextIdx = (moreInfoDevIdx + 1) % filteredDevelopments.length;
                                            const nextDevelopment = filteredDevelopments[nextIdx];
                                            this.setState({
                                                showDevelopmentInfo: nextDevelopment.id
                                            });
                                        }} />
                                    </Stack>
                                )}
                            >
                                <Stack horizontal horizontalAlign="space-between">
                                    <IconButton iconProps={{iconName: "Previous"}} onClick={() => {
                                        let prevIdx = moreInfoDevIdx - 1;
                                        if (prevIdx < 0) prevIdx = filteredDevelopments.length - 1;
                                        const nextDevelopment = filteredDevelopments[prevIdx];
                                        this.setState({
                                            showDevelopmentInfo: nextDevelopment.id
                                        });
                                    }} />
                                    <IconButton iconProps={{iconName: "Next"}} onClick={() => {
                                        const nextIdx = (moreInfoDevIdx + 1) % filteredDevelopments.length;
                                        const nextDevelopment = filteredDevelopments[nextIdx];
                                        this.setState({
                                            showDevelopmentInfo: nextDevelopment.id
                                        });
                                    }} />
                                </Stack>
                                <FormSection label="Description"><div style={{whiteSpace: "pre-wrap"}}>{moreInfoDevelopment.description}</div></FormSection>
                                <FormSection label="Request">
                                    <Stack horizontal horizontalAlign="stretch" verticalAlign="start">
                                        <div style={{marginRight: 20}}>
                                            <Label>Date Requested:</Label>
                                            {moreInfoDevelopment.dateSubmitted ? new Date(moreInfoDevelopment.dateSubmitted).toLocaleDateString() : ""}
                                        </div>
                                        <div>
                                            <Label>Requestor(s):</Label>
                                            {moreInfoDevelopment.requestors.map((requestor: IUserLookup) => (
                                                <Persona text={requestor.name} secondaryText={requestor.mail} />
                                            ))}
                                        </div>
                                    </Stack>
                                </FormSection>
                                <FormSection label="Estimation">
                                    <Stack horizontal verticalAlign="start">
                                    <div style={{marginRight: 20}}>
                                            <Label>Due Date:</Label>
                                            <Icon iconName="Calendar" /> {moreInfoDevelopment.dueDate ? new Date(moreInfoDevelopment.dueDate).toLocaleDateString() : "Not set"}
                                        </div>
                                        <div>
                                            <Label>Estimated Effort:</Label>
                                            { moreInfoDevelopment.estimatedEffort !== undefined && <TooltipHost content="Estimated Effort">
                                                <Icon iconName="Clock" /> { moreInfoDevelopment.estimatedEffort }d ({Math.round(moreInfoDevelopment.estimatedEffort * 7.5)}h)
                                            </TooltipHost>}
                                        </div>
                                    </Stack>
                                </FormSection>
                                { isDevTeamView === true && (
                                    <FormSection label="Business Priority">
                                        <table className={styles.priorityTable}>
                                            <tbody>
                                                { Boolean(moreInfoDevelopment.businessGroups) === true && moreInfoDevelopment.businessGroups.map((bp: any) => (
                                                    <tr>
                                                        <th>{bp.name}</th>
                                                        <td>
                                                            { Boolean(moreInfoDevelopment.businessPriorities) === true && moreInfoDevelopment.businessPriorities[bp.id] }
                                                            { Boolean(moreInfoDevelopment.businessPriorities) === false && moreInfoDevelopment.businessPriority }
                                                        </td>
                                                    </tr>
                                                )) }
                                            </tbody>
                                        </table>
                                    </FormSection>
                                )}
                            </Panel>
                        )}
                    </div>
                )}
            </DisplayContext.Consumer>
        );
    }

    private _sortTileRender(prioritised: boolean) {
        return (development: ViewItem, idx: number) => {
            const { 
                onSelection,
                selectionEnabled, 
                selectedDevelopmentIds, 
                isDevTeamView: showOrganisation,
                sortProperty ,
                intent
            } = this.props;
            const {
                orgColors,
            } = this.state;

            let statusText = development.status.text;
            let statusTooltip: string;
            if (intent === SortIntent.PlatformMerge || (statusText === "Non-Prioritised" && !showOrganisation && Boolean(development[sortProperty]) === true)) {
                statusText = "Business Prioritised";
                statusTooltip = "";
            }
            const status = <TooltipHost content={statusTooltip}><div style={{marginBottom: 6}}>Status:</div><div className={styles.status} data-status={statusText}>{statusText}</div></TooltipHost>;
            
            return (
                <div 
                    key={development.id}
                    className={css(styles.development, showOrganisation ? styles.forTeam : undefined)}
                    onContextMenu={(e) => e.preventDefault()}
                    onMouseDown={this._handleDevelopmentMouseDown}
                    onMouseOver={prioritised ? this._handleDevelopmentMouseOver : undefined}
                    onTouchStart={this._handleTouchDown}
                >
                    <div className={styles.orderNo}>
                        { selectionEnabled === true && !Boolean(development[sortProperty]) && (
                            <Checkbox 
                                checked={selectedDevelopmentIds.indexOf(development.id) >= 0}
                                onChange={(ev, checked) => {
                                    let newSelection = [...selectedDevelopmentIds];
                                    newSelection.filter((s) => s !== development.id);
                                    if (checked) newSelection.push(development.id);
                                    onSelection(newSelection);
                                }}
                            />
                        )}
                        {(development[sortProperty] || "")}
                    </div>
                    <div className={styles.details} data-id={development.id}>
                        <div className={styles.devMenu}>
                            <IconButton 
                                iconProps={{iconName: "GlobalNavButton"}} 
                                menuProps={{
                                    items: [
                                        {
                                            key: "toTop",
                                            text: "To top",
                                            iconProps: { iconName: "Upload" },
                                            onClick: () => {
                                                this._toTop(development);
                                            }
                                        },
                                        {
                                            key: "toBottom",
                                            text: "To bottom",
                                            iconProps: { iconName: "Download" },
                                            onClick: () => {
                                                this._toBottom(development);
                                            }
                                        },
                                        {
                                            key: "moreInfo",
                                            text: "More Info",
                                            iconProps: { iconName: "Info" },
                                            onClick: () => {
                                                this.setState({
                                                    showDevelopmentInfo: development.id
                                                });
                                            }
                                        },
                                        {
                                            key: prioritised ? "deprioritise" : "prioritise",
                                            text: prioritised ? "De-Prioritise" : "Prioritise",
                                            iconProps: { iconName: prioritised ? "Cancel" : "CheckMark" },
                                            onClick: () => {
                                                this._changePrioritisation(prioritised, development);
                                            }
                                        }
                                    ]
                                }}
                            />
                        </div>
                        <Stack styles={{root: { height: 35, marginBottom: 30 }}} horizontal verticalAlign="center">
                            { sortProperty === "businessPriority" && (
                                <TooltipHost content={development.platform} styles={{root: { display: "inline-block" }}} directionalHint={DirectionalHint.topCenter}>
                                    <Icon
                                        styles={{root: { backgroundColor: this._getPlatformIconColor(development.platform), color: "white", fontSize: "1.4em", borderRadius: 5, padding: 5, marginRight: 5}}}
                                        iconName={getPlatformIconName(development.platform)} 
                                    />
                                </TooltipHost>
                            )}
                            <div style={{color: Boolean(development.newBusinessPrioritisation) === true ? "red" : undefined}}>
                                {development.title} { Boolean(development.newBusinessPrioritisation) === true && (
                                    <Icon iconName="AlertSolid" />
                                ) }
                            </div>
                        </Stack>
                        <div className={styles.footer}>
                            <Stack horizontal horizontalAlign="space-between" verticalAlign="start">
                                { status }
                                <div>{ development.estimatedEffort !== undefined && <TooltipHost content="Estimated Effort">
                                        <Icon iconName="Clock" /> { development.estimatedEffort }d ({Math.round(development.estimatedEffort * 7.5)}h)
                                    </TooltipHost>}
                                </div>
                            </Stack>
                            <Stack styles={{root: {marginTop: 5}}} horizontal horizontalAlign="space-between" verticalAlign="center">
                                <div>Matrix Score: <span className={styles.matrixScore}>{development.matrixScore || 0}</span></div>
                                <div>
                                    <TooltipHost content="Due Date" style={{marginTop: 6}}>
                                        <Icon iconName="Calendar" /> <span style={{marginTop: 6}}>
                                            { Boolean(development.dueDate) ? new Date(development.dueDate).toLocaleDateString() : 'Not set' }
                                        </span>
                                    </TooltipHost>
                                </div>
                            </Stack>
                            { showOrganisation && (
                                <Stack styles={{root: {marginTop: 5}}} horizontal horizontalAlign="start" verticalAlign="end">
                                    <div>
                                        <div style={{marginTop: 6}}>Organisation(s):</div> 
                                        <Stack horizontal wrap styles={{root: { marginTop: 6 }}}>
                                            { development.businessGroups.map((bg: IBusinessGroup, bgIdx: number) => {
                                                let color = orgColors[bg.name];
                                                if (color && this.context.darkMode) color = color.replace('rgb', 'rgba').replace(')', ',0.2)');
                                                let tooltip: string;
                                                if (development.businessPriorities && development.businessPriorities[bg.id]) {
                                                    tooltip = `${bg.name} Priority: ${development.businessPriorities[bg.id]}`;
                                                } else if (development.businessPriority && development.businessGroups.length === 1) {
                                                    tooltip = `${development.businessGroups[0].name} Priority: ${development.businessPriority}`;
                                                }
                                                return (
                                                    <TooltipHost content={tooltip}>
                                                        <div key={bgIdx} className={styles.status} style={{ backgroundColor: color }}>{bg.name}</div>
                                                    </TooltipHost>
                                                );
                                            })}
                                        </Stack>
                                    </div>
                                </Stack>
                            )}
                            <Stack styles={{root: {marginTop: 10}}} horizontal horizontalAlign="end" verticalAlign="end">
                                <Facepile 
                                    onRenderPersona={(personaProps, defaultRender) => (
                                        <TooltipHost content={personaProps.name} 
                                            tooltipProps={{
                                                onRenderContent: (tooltipHostProps) => {
                                                    return (
                                                        <Persona text={personaProps.personaName} secondaryText={personaProps.data.email} imageUrl={personaProps.imageUrl} />
                                                    );
                                                }
                                            }}
                                        >
                                            <PersonaCoin size={PersonaSize.size32} text={personaProps.personaName} imageUrl={personaProps.imageUrl} showOverflowTooltip={false} />
                                        </TooltipHost>
                                    )}
                                    onRenderPersonaCoin={(personaProps, defaultRender) => {
                                        return (
                                            <TooltipHost content={personaProps.name} 
                                                tooltipProps={{
                                                    onRenderContent: (tooltipHostProps) => {
                                                        return (
                                                            <Persona text={personaProps.personaName} secondaryText={personaProps.data.email} imageUrl={personaProps.imageUrl} />
                                                        );
                                                    }
                                                }}
                                            >
                                                { defaultRender(personaProps) }
                                            </TooltipHost>
                                        );
                                    }}
                                    showTooltip={false}
                                    personas={development.requestors.map((requestor: IUserLookup) => {
                                        const photo = requestor.photo;
                                        let imageUrl: string;
                                        if (photo) {
                                            imageUrl = `data:${photo.type};base64,${photo.data}`;
                                        }
                                        return {
                                            personaName: requestor.name,
                                            imageUrl,
                                            data: {
                                                email: requestor.mail,
                                                imageUrl
                                            }
                                        } as IFacepilePersona;
                                    })}
                                />
                            </Stack>
                        </div>
                    </div>
                </div>
            );
        };
    }

    private _onKeyUp(e: KeyboardEvent) {
        if (this.state.showDevelopmentInfo !== undefined) {
            if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
                let devIdx = this.props.developments.map((d) => d.id).indexOf(this.state.showDevelopmentInfo);
                if (e.key === "ArrowLeft") {
                    devIdx = devIdx - 1;
                    if (devIdx < 0) devIdx = this.props.developments.length - 1;
                }
                if (e.key === "ArrowRight") {
                    devIdx = (devIdx + 1) % this.props.developments.length;
                }
                const development = this.props.developments[devIdx];
                this.setState({
                    showDevelopmentInfo: development.id
                });
            }
        }
    }

    private _calculateOrgColors() {
        const organisations = this.props.developments.reduce((prev, cur, idx, arr) => {
            const orgArr = [...prev];
            const orgs = cur.businessGroups as IBusinessGroup[];
            orgs.forEach((org) => {
                if (prev.indexOf(org.name) < 0) orgArr.push(org.name);
            });
            return orgArr;
        }, [] as string[]);
        const organisationColors: Record<string,string> = {};
        organisations.forEach((org, idx) => {
            const colorIdx = idx % colors.length;
            organisationColors[org] = colors[colorIdx];
        });
        return organisationColors;
    }

    // #region - Data

    private _updateProps(filteredDevelopments: ViewItem[], insertionIndex: number) {
        const { sortProperty } = this.props;
        const subset = filteredDevelopments
            .filter((development, idx) => {
                return Boolean(development[sortProperty]) || idx <= insertionIndex;
            });
        subset.forEach((development, idx) => {
                const prop = sortProperty as keyof typeof development;
                const originalFilteredIdx = filteredDevelopments.map((d) => d.id).indexOf(development.id);
                filteredDevelopments[originalFilteredIdx][prop] = idx + 1;
            });
        return filteredDevelopments;
    }

    private _changePrioritisation(isPrioritised: boolean, development: ViewItem) {
        let { developments, onUpdate, sortProperty } = this.props;
        const developmentIdx = developments.map((d) => d.id).indexOf(development.id);
        if (isPrioritised) {
            developments[developmentIdx][sortProperty] = null;
            onUpdate(developments);
        } else {
            this._toBottom(development);
        }
    }

    private _toTop(development: ViewItem) {
        let { developments, onUpdate } = this.props;
        developments = developments.filter((d) => d.id !== development.id);
        developments.splice(0, 0, development);
        developments = this._updateProps(developments, 0);
        onUpdate(developments);
    }

    private _toBottom(development: ViewItem) {
        const { developments: filteredDevelopments, onUpdate, sortProperty } = this.props;
        
        const orderedDevelopments = filteredDevelopments.filter((d) => 
            d.id !== development.id &&
            Boolean(d[sortProperty])
        );
        const remainingDevelopments = filteredDevelopments.filter((d) => 
            d.id !== development.id &&
            orderedDevelopments.map((od) => od.id).indexOf(d.id) < 0
        );
        orderedDevelopments.push(development);
        orderedDevelopments.forEach((d, idx) => {
            d[sortProperty] = idx + 1;
            // const devIdx = allDevelopments.map((ad) => ad.id).indexOf(development.id);
            // if (devIdx >= 0) allDevelopments[devIdx][updateProp] = idx + 1;
        });
        orderedDevelopments.push(...remainingDevelopments);
        onUpdate(orderedDevelopments);
    }

    // #endregion

    // #region - UI
    
    private _getPlatformIconColor(platform: string) {
        let color = "#b1324e";
        switch(platform) {
            case "SharePoint":
                color = "#004B50";
                break;
            case "Web":
                color = "#0078d7";
                break;
            case "RiO Forms":
                color = "#5C2D91";
                break;
            case "Sostenuto":
                color = "#004B1C";
                break;
            case "Software Development":
                color = "#5C005C";
                break;
        }
        return color;
    }

    /**
     * Shows a clone of the dragged element at coordinates x,y
     * @param x 
     * @param y 
     */
    private _showDragClone(x: number, y: number) {
        if (!this._draggableArea) return;
        let offsetX = 0, offsetY = 0;
        if (this.props.intent === SortIntent.ResolveConflicts) {
            const panelContentRect = this._draggableArea.parentElement.getBoundingClientRect();
            if (panelContentRect) {
                offsetX = panelContentRect.x;
                offsetY = panelContentRect.y - panelContentRect.top;
            }
        }
        let dragClone: HTMLDivElement | null = this._draggableArea.querySelector('.dragClone');
        if (!dragClone) {
            dragClone = document.createElement("div");
            dragClone.className = css('dragClone', styles.dragClone);
            const viewport = document.createElement("div");
            const container = document.createElement("div");
            const clone = this._draggedDevelopment?.cloneNode(true);
            const menu = clone?.firstChild?.parentElement?.querySelector(`.${styles.devMenu}`);
            if (menu) menu.remove();
            if (clone) {
                container.appendChild(clone);
                viewport.appendChild(container);
                dragClone.appendChild(viewport);
                this._draggableArea.appendChild(dragClone);
            }
        }
        dragClone.style.left = (x - offsetX) + "px";
        dragClone.style.top = (y - offsetY) + "px";
    }

    /**
     * Removes the dragged clone from the UI
     */
    private _removeDragClone() {
        if (!this._draggableArea) return;
        const dragClone = this._draggableArea.querySelector('.dragClone');
        if (dragClone) {
            dragClone.remove();
        }
    }

    /**
     * Removes hover CSS styling from any tiles visible on the page
     */
    private _removeHoverStyle() {
        document.querySelectorAll(`.${styles.hovered}`).forEach((el) => el.classList.remove(styles.hovered));
    }

    // #endregion

    // #region - Mouse Handlers

    private _handleDevelopmentMouseDown(ev: React.MouseEvent) {
        if (this._draggedDevelopment) return;
        let el = ev.target as HTMLDivElement, i = 0;
        // ignore if user clicked the sub-menu 
        if (el.classList.contains("ms-Button") || el.classList.contains("ms-Button-flexContainer") || el.classList.contains("ms-Button-icon") || el.classList.contains("ms-Button-menuIcon")) {
            return;
        }
        // traverse dragged element to find tile details div
        while (i < 3 && el && !el.classList.contains(styles.details)) {
            el = el.parentElement as HTMLDivElement;
            i++;
        }
        // Add 'dragging' class
        if (el && el.classList.contains(styles.details)) {
            this._draggedDevelopment = el;
            this._draggedDevelopment.classList.add(styles.dragging);
        }
        // If sortedTiles area is empty, add styling to make visible
        if (this._draggableArea) {
            const sortedTiles = this._draggableArea.querySelector('#sortedTiles') as HTMLDivElement;
            if (sortedTiles) {
                if (sortedTiles.getElementsByClassName(styles.development).length === 0) {
                    sortedTiles.classList.add(styles.outlineDragArea);
                };
            }
        }
    }

    private _handleDropPlaceholderMouseOver(ev: React.MouseEvent) {
        if (!this._draggedDevelopment) return;
        const sortedTiles = document.getElementById("sortedTiles") as HTMLDivElement;
        if (sortedTiles) {
            if (sortedTiles.getElementsByClassName(styles.development).length === 0 && !this._firstDrag) {
                this._firstDrag = true;
                console.log('placeholder_in');
            }
        }
    }

    private _handleDropPlaceholderMouseOut(ev: React.MouseEvent) {
        if (!this._draggedDevelopment) return;
        const sortedTiles = document.getElementById("sortedTiles") as HTMLDivElement;
        if (sortedTiles) {
            if (sortedTiles.getElementsByClassName(styles.development).length === 0 && this._firstDrag) {
                this._firstDrag = false;
                console.log('placeholder_out');
            }
        }
    }

    private _handleDevelopmentMouseOver(ev: React.MouseEvent) {
        if (!this._draggedDevelopment) return;
        if (this._draggedDevelopment !== ev.target) {
            this._removeHoverStyle();
            let el = ev.target as HTMLDivElement, i = 0;
            while (i < 3 && el && !el.classList.contains(styles.details)) {
                el = el.parentElement as HTMLDivElement;
                i++;
            }
            if (el && el.classList.contains(styles.details)) {
                this._hoveredDevelopment = el;
                this._hoveredDevelopment.classList.add(styles.hovered);
            }
        }
    }

    private _handleDocumentMouseMove(ev: MouseEvent) {
        if (this._draggedDevelopment) {
            ev.preventDefault();
            this._showDragClone(ev.pageX, ev.pageY);
        }
    }

    private _handleDocumentMouseUp(ev: MouseEvent) {
        let { developments, onUpdate} = this.props;
        
        if (this._draggableArea) {
            const sortedTiles = this._draggableArea.querySelector('#sortedTiles') as HTMLDivElement;
            if (sortedTiles) {
                sortedTiles.classList.remove(styles.outlineDragArea);
            }
        }
        if (this._draggedDevelopment) this._draggedDevelopment.classList.remove(styles.dragging);
        if (this._draggedDevelopment && this._hoveredDevelopment &&
            this._draggedDevelopment.getAttribute("data-id") !== this._hoveredDevelopment.getAttribute("data-id")) {
            const draggedDevelopmentId = parseInt(this._draggedDevelopment.getAttribute("data-id") as string, 10);
            const hoveredDevelopmentId = parseInt(this._hoveredDevelopment.getAttribute("data-id") as string, 10);
            const draggableIndex = developments.map((d) => d.id).indexOf(draggedDevelopmentId);
            const draggedDevelopment = developments.filter((d) => d.id === draggedDevelopmentId)[0];
            developments = developments.filter((d) => d.id !== draggedDevelopmentId);
            let insertionIndex = developments.map((d) => d.id).indexOf(hoveredDevelopmentId);
            if (insertionIndex >= draggableIndex) insertionIndex++;
            developments.splice(insertionIndex, 0, draggedDevelopment);
            developments = this._updateProps(developments, insertionIndex);
        } else {
            if (this._draggedDevelopment && this._firstDrag) {
                const draggedDevelopmentId = parseInt(this._draggedDevelopment.getAttribute("data-id") as string, 10);
                const draggedDevelopment = developments.filter((d) => d.id === draggedDevelopmentId)[0];
                developments = developments.filter((d) => d.id !== draggedDevelopmentId);
                const insertionIndex = 0;
                developments.splice(insertionIndex, 0, draggedDevelopment);
                developments = this._updateProps(developments, insertionIndex);
                this._firstDrag = false;
            }
        }
        this._removeDragClone();
        this._removeHoverStyle();
        this._draggedDevelopment = undefined;
        this._hoveredDevelopment = undefined;
        onUpdate(developments);
        // this.setState({visibleDevelopments: filteredDevelopments}, () => {
        //     this.props.onUpdate([...developments])
        // });
    }

    // #endregion

    // #region - Touch Handlers
   
    private _handleTouchDown(ev: React.TouchEvent) {
        if (this._draggedDevelopment) return;
        let el = ev.target as HTMLDivElement, i = 0;
        while (i < 3 && el && !el.classList.contains(styles.details)) {
            el = el.parentElement as HTMLDivElement;
            i++;
        }
        if (el && el.classList.contains(styles.details)) {
            this._draggedDevelopment = el;
            this._draggedDevelopment.classList.add(styles.dragging);
        }
    }

    private _handleDocumentTouchMove(ev: TouchEvent) {
        if (this._draggedDevelopment) {
            ev.preventDefault();
            const touches = ev.touches;
            if (touches.length) {
                const touch = touches[0];
                const elements = document.elementsFromPoint(touch.clientX, touch.clientY).filter((el) => el.getAttribute("data-id") !== this._draggedDevelopment?.getAttribute("data-id") && el.classList.contains(styles.details));
                this._removeHoverStyle();
                if (elements.length) {
                    const element = elements[0];
                    if (this._draggedDevelopment !== element) {
                        this._hoveredDevelopment = element as HTMLDivElement;
                        this._hoveredDevelopment.classList.add(styles.hovered);
                    }
                }
                this._showDragClone(touch.pageX, touch.pageY);
            }
        }
    }

    private _handleDocumentTouchEnd(ev: TouchEvent) {
        let { developments, onUpdate } = this.props;
        if (this._draggedDevelopment) this._draggedDevelopment.classList.remove(styles.dragging);
        if (this._draggedDevelopment && this._hoveredDevelopment) {
            const draggedDevelopmentId = parseInt(this._draggedDevelopment.getAttribute("data-id") as string, 10);
            const hoveredDevelopmentId = parseInt(this._hoveredDevelopment.getAttribute("data-id") as string, 10);
            const draggableIndex = developments.map((d) => d.id).indexOf(draggedDevelopmentId);
            const draggedDevelopment = developments.filter((d) => d.id === draggedDevelopmentId)[0];
            developments = developments.filter((d) => d.id !== draggedDevelopmentId);
            let insertionIndex = developments.map((d) => d.id).indexOf(hoveredDevelopmentId);
            if (insertionIndex >= draggableIndex) insertionIndex++;
            developments.splice(insertionIndex, 0, draggedDevelopment);
            developments = this._updateProps(developments, insertionIndex);
            // visibleDevelopments = updates.filteredDevelopments;
            // developments = updates.developments;
        }
        this._removeDragClone();
        this._removeHoverStyle();
        this._draggedDevelopment = undefined;
        this._hoveredDevelopment = undefined;
        onUpdate(developments);
        // this.setState({visibleDevelopments: visibleDevelopments}, () => {
        //     this.props.onUpdate([...developments])
        // });
    }

    // #endregion
}

export default SortComponent;