import * as React from "react";
import { IconButton, Icon, Stack, TooltipHost, DirectionalHint } from "office-ui-fabric-react";
import { getPlatformIconName } from "../utils";

import styles from "./PlatformMenu.module.scss";

export interface IPlatformMenuProps {
    currentView: string | undefined;
    platforms: string[] | undefined;
    platformViewsOnly?: boolean;
    onChange: (newView: string) => void;
}

export const PlatformMenu = (props: IPlatformMenuProps) => {
    const { currentView, onChange, platforms: allPlatforms } = props;

    if (!allPlatforms || !allPlatforms.length) return null;
    let platforms = allPlatforms.filter((d) => d !== null);

    return (
        <>
            <h2>{currentView === "prioritised" ? "Prioritised Developments" : currentView}</h2>
            <div className={styles.platformMenu}>
                <Stack className={styles.menuBtns} horizontal horizontalAlign="space-around">
                    { !props.platformViewsOnly && (
                        <>
                            <div key="upcomingWork" className={currentView === "Current and Upcoming Work" ? styles.active : undefined}>
                                <TooltipHost content="Current and Upcoming Work" styles={{root: { display: "inline-block" }}} directionalHint={DirectionalHint.topCenter}>
                                    <IconButton iconProps={{ iconName: "Subscribe"}}
                                    onClick={() => {
                                        onChange("Current and Upcoming Work");
                                    }} />
                                </TooltipHost>
                            </div>
                            <div key={`arrow-separator-upcoming`} className={styles.chevron}>
                                <Icon iconName="ChromeBackMirrored" />
                            </div>
                        </>
                    )}
                    { [...Array(platforms.length)].map((item, idx) => {
                        const platform = platforms[idx];
                        let activeClass: string | undefined;
                        if (currentView === platform) activeClass = styles.active;
                        return (
                            <>
                                <div key={platform} className={activeClass}>
                                    <TooltipHost content={platform} styles={{root: { display: "inline-block" }}} directionalHint={DirectionalHint.topCenter}>
                                        <IconButton 
                                            iconProps={{ iconName: getPlatformIconName(platform)}} 
                                            onClick={() => {
                                                onChange(platform);
                                            }}
                                        />
                                    </TooltipHost>
                                </div>
                                { (idx + 1 < platforms.length || !props.platformViewsOnly) && (
                                    <div key={`arrow-separator-${idx}`} className={styles.chevron}>
                                        <Icon iconName="ChromeBackMirrored" />
                                    </div>
                                )}
                            </>
                        );
                    })}
                    { !props.platformViewsOnly && (
                        <div key="prioritised" className={styles.allBtn}>
                            <IconButton className={styles.allBtn} iconProps={{ iconName: "Sort" }} onClick={() => {
                                onChange("prioritised");
                            }} />
                        </div>
                    )}
                </Stack>
            </div>
        </>
    );
};

export default PlatformMenu;