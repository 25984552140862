import * as React from "react";
import { Dialog, IconButton, Spinner, Stack } from "office-ui-fabric-react";
import { useRive, useStateMachineInput, Layout, Fit } from "@rive-app/react-canvas";

export interface ICustomerHelpProps {
    onDismiss: () => void;
}

export const CustomerHelp = (props: ICustomerHelpProps) => {
    const [ loading, setLoading ] = React.useState(true);
    const [ currentAnimation, setCurrentAnimation ] = React.useState<string>();

    const { rive, RiveComponent } = useRive({
        src: "/prioritisation.riv",
        autoplay: true,
        stateMachines: "State Machine 1",
        layout: new Layout({ fit: Fit.Cover, maxX: 500, maxY: 440, minX: 200, minY: 176 }),
        onLoad: (e) => {
            setLoading(false);
        },
        onStateChange: (e) => {
            const animationArr = e.data as string[];
            if (animationArr && animationArr.length) {
                setCurrentAnimation(animationArr[0]);
            }
        }
    });
    
    const trigger1 = useStateMachineInput(rive, "State Machine 1", "Trigger 1");
    const trigger2 = useStateMachineInput(rive, "State Machine 1", "Trigger 2");
    
    let helpText: JSX.Element;
    switch(currentAnimation) {
        case "Animation 1":
            helpText = (
                <>
                    <p>This page can be used to aid prioritisation of developments.</p>
                    <p>
                        Work your way through each development platform, and review the 
                        final prioritisation list in the prioritisation tab (green icon).
                    </p>
                </>
            );
            break;
        case "Animation 2":
            helpText = (
                <>
                    <p>As you progress, drag developments in to an appropriate order.</p>
                </>
            );
            break;
        case "Animation 3":
            helpText = (
                <>
                    <p>You can save from any page using the save icon.</p>
                    <p>
                        From the final prioritisation list, use the 'Send' button 
                        to submit the list to the development service.
                    </p>
                </>
            );
            break;
    }

    return (
        <Dialog hidden={false} onDismiss={props.onDismiss} 
            dialogContentProps={{
                title: "How to use", 
                showCloseButton: true
            }}
        >
            { loading === true && (
                <Spinner label="Please wait" />
            )}
            <div style={{display: "flex", justifyContent: "center"}}>
                <RiveComponent width="250" height="220" style={{ width: 250, height: 220 }} />
            </div>
            { helpText }
            <Stack styles={{root: {margin: 20}}} horizontal horizontalAlign="space-between">
                <IconButton disabled={currentAnimation === "Animation 1"} iconProps={{iconName: "Previous"}} onClick={() => {
                    if (trigger2) {
                        trigger2.fire();
                    }
                }}/>
                { currentAnimation !== "Animation 3" && (
                    <IconButton iconProps={{iconName: "Next"}} onClick={() => {
                        if (trigger1) {
                            trigger1.fire();
                        }
                    }}/>
                )}
                { currentAnimation === "Animation 3" && (
                    <IconButton iconProps={{iconName: "ChromeClose"}} onClick={() => {
                        props.onDismiss();
                    }} />
                )}
            </Stack>
        </Dialog>
    );
};