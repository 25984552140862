import * as React from "react";
import { Facepile, IFacepilePersona, IFacepileProps, Persona, PersonaCoin, TooltipHost } from "office-ui-fabric-react";
import { getPhotos } from "../../api";
import { IPhotoResponse } from "../../types";

export interface IAsyncFacepileProps {
    personas: IFacepilePersona[];
}

const renderPersonaCoin = (fpProps?: IFacepilePersona, photoDataArr?: Record<string, IPhotoResponse>) => {
    if (!fpProps) return null;

    let photoData: IPhotoResponse | undefined;
    if (fpProps && photoDataArr) {
        photoData = photoDataArr[fpProps.data?.email?.toLowerCase()];
    }
    
    let imageUrl: string | undefined;
    if (photoData) {
        imageUrl = `data:${photoData.type};base64,${photoData.data}`;
    }

    return (
        <TooltipHost tooltipProps={{
            onRenderContent: (ttProps) => {
                if (!fpProps) return null;
                return (
                    <Persona text={fpProps.name} secondaryText={fpProps.data.email} imageUrl={imageUrl} />
                );
            }
        }}>
            <PersonaCoin showOverflowTooltip={false} text={fpProps.name} secondaryText={fpProps.data.email} imageUrl={imageUrl} />
        </TooltipHost>
    );
};

export const AsyncFacepile = (props: IAsyncFacepileProps) => {
    const [ loaded, setLoaded ] = React.useState(false);
    const [ photoData, setPhotoData ] = React.useState<Record<string,IPhotoResponse>>({});
    const facepileContainer = React.useRef<HTMLDivElement|null>(null);

    // Loads photo data from provided persona data
    const loadData = React.useCallback(async (abortController: AbortController) => {
        if (props.personas && props.personas.length) {
            const emails = props.personas.map((p) => {
                if (p.data?.email) {
                    return p.data.email.toLowerCase();
                }
                return null;
            }).filter((e) => Boolean(e));
            if (emails.length > 0) {
                const data = await getPhotos(emails, abortController);
                if (!(data instanceof Error)) {
                    setPhotoData(data);
                }
            }
        }
        setLoaded(true);
    }, [props.personas]);

    // On mount, wait for component to become visible / in-view
    React.useEffect(() => {
        const abortController = new AbortController();
        const o = new IntersectionObserver(([entry]) => {
            if (entry.intersectionRatio > 0 && !loaded) {
                loadData(abortController);
                if (facepileContainer.current) {
                    o.unobserve(facepileContainer.current);
                }
            }
        });
        o.observe(facepileContainer.current);

        return () => {
            abortController.abort();
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div ref={facepileContainer}>
            <Facepile
                showTooltip={false}
                personas={props.personas as any as IFacepileProps[]}
                onRenderPersona={(fpProps) => {
                    return renderPersonaCoin(fpProps, photoData);
                }}
                onRenderPersonaCoin={(fpProps) => {
                    return renderPersonaCoin(fpProps, photoData);
                }}
            />
        </div>
    );
};