import React from "react";

import { ChoiceGroup, IChoiceGroupOption } from "office-ui-fabric-react";

import styles from "./PlatformPicker.module.scss";

export interface IPlatformPickerProps {
    disabled?: boolean;
    selectedPlatform: string | undefined;
    onChange: (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => void;
}
export interface IPlatformPickerState {}

export default class PlatformPicker extends React.Component<IPlatformPickerProps, IPlatformPickerState> {
    private _platformOptions: IChoiceGroupOption[] = [
        {
            iconProps: {
                iconName: "Flow",
            },
            key: 'Integration',
            text: 'Integration',
        },
        {
            iconProps: {
                iconName: "SharePointLogoInverse",
            },
            key: 'SharePoint',
            text: 'SharePoint',
        },
        {
            iconProps: {
                iconName: "CommandPrompt",
            },
            key: 'Software Development',
            text: 'Software Development',
        },
        {
            iconProps: {
                iconName: "HeadsetSolid",
            },
            key: 'Sostenuto',
            text: 'Sostenuto',
        },
        {
            iconProps: {
                iconName: "Website",
            },
            key: 'Web',
            text: 'Web',
        },
        {
            iconProps: {
                iconName: "Robot",
            },
            key: "Toca",
            text: "Toca"
        },
        {
            iconProps: {
                iconName: "PlugDisconnected"
            },
            key: "Interoperability",
            text: "Interoperability"
        }
    ];
    constructor(props: IPlatformPickerProps) {
        super(props);
        this.state = {
            selectedPlatform: undefined,
        };
    }
    
    public render() {
        const { selectedPlatform } = this.props;
        return (
                <ChoiceGroup
                    disabled={this.props.disabled}
                    className={styles.platformPicker}
                    label="Choose a platform"
                    selectedKey={selectedPlatform}
                    options={this._platformOptions}
                    onChange={this.props.onChange}
                />
        );
    }
}
