import * as React from "react";
import { ContextualMenuItemType, DefaultButton, Icon, Label, Pivot, PivotItem, Stack } from "office-ui-fabric-react";
import { DisplayContext } from "../DisplayContext";
import { UserContext } from "../UserContext";
import { useHistory } from "react-router";
import { HeaderMenu } from "./components/Header2";

import styles from "./MainMenu.module.scss";

import logo from "../assets/dev_service.png";
import darkLogo from "../assets/dev_service_dark.png";
import giteaLogo from "../assets/gitea_logo.png";
import sunriseLogo from "../assets/sunrise_logo.png";
import { ReactComponent as IMG_DEV} from "../assets/pair_programming.svg";
import { ReactComponent as IMG_TEAM} from "../assets/scrum_board.svg";
import { ReactComponent as IMG_NONPRIORITISED} from "../assets/organizing_projects.svg";
import { ReactComponent as IMG_CLOSED} from "../assets/done.svg";
import { ReactComponent as IMG_ACTIVITY} from "../assets/time_management.svg";
import { ReactComponent as IMG_PRIORITISATION} from "../assets/business_decisions.svg";
import { ReactComponent as IMG_USERMGT} from "../assets/meet_the_team.svg";
import { ReactComponent as IMG_SLA} from "../assets/agreement.svg";

export interface IMenuButtonProps {
    imgComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    label: string;
    href?: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement|HTMLButtonElement>) => void;
}

export interface IMainMenuProps {

}

const menuStyle: React.CSSProperties = {
    display: "flex", flexWrap: "wrap", justifyContent: "center"
};

const svgStyle: React.CSSProperties = {
    width: "80%", maxWidth: 400, maxHeight: 400, height: "unset", marginTop: 10, marginBottom: 10
};

const MenuButton = (props: IMenuButtonProps) => {
    const Img = props.imgComponent;
    return (
        <DefaultButton href={props.href} className={styles.menuButton} onClick={props.onClick}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1}}>
                <Img style={svgStyle} /><br />
            </div>
            <div>
                {props.label}
            </div>
        </DefaultButton>
    );
};

export const MainMenu = (props: IMainMenuProps) => {

    const history = useHistory(); 

    const isMobileDevice = /Mobi|Tablet|iPad|iPhone/i.test(navigator.userAgent);

    const displayContext = React.useContext(DisplayContext);
    const userContext = React.useContext(UserContext);
    const { roles, teams } = userContext.profile;

    const userTeamIds = teams.map((t) => t.id);
    const userRoleNames = roles.map((r) => r.roleName);

    const isDeveloper = userTeamIds.indexOf("a467634f-fc30-4adb-8fbb-aa9ad93113e3") >= 0;
    const isCustomer = userRoleNames.indexOf("Customer") >= 0;
    const isColleague = userRoleNames.indexOf("Colleague") >= 0;
    const isAccountMgr = userRoleNames.indexOf("Account Manager") >= 0;
    const isPrioritiser = userRoleNames.indexOf("Prioritisation") >= 0;
    const isAdmin = userRoleNames.indexOf("Admin") >= 0;

    return (
        <div>
            <Stack horizontalAlign="center">
                <div className={styles.logoContainer}>
                    <img className={styles.logo} src={displayContext.darkMode ? darkLogo : logo} alt="Development Service Logo"/>
                </div>
                <div style={{width: "100%"}}><HeaderMenu /></div>
                <Pivot style={{width: "100%"}} className={styles.pivotMenu}>
                    { isDeveloper === false && isCustomer === false && isAccountMgr === false && isColleague === false && (
                        <PivotItem headerText="New Account">
                            <div className={styles.newAccountInfo}>
                                <p><Icon className={styles.welcomeWaveIcon} iconName="HandsFree" /></p>
                                <p>Thank you for signing up to the Developer Portal.</p>
                                <p>You can use this site to obtain access to updates and information about development work at NHS Informatics Merseyside. To ensure you are given appropriate access, please email <a href="mailto:development.service@iemrseyside.nhs.uk">development.service@imerseyside.nhs.uk</a>.</p>
                                <p>If you have logged-in in error, and wish to be removed from the site, you can also request this via the same address.</p>
                            </div>
                        </PivotItem>
                    )}
                    { isDeveloper === true && (
                        <PivotItem headerText="Developer">
                            <div style={menuStyle}>
                                <MenuButton imgComponent={IMG_DEV} label="Developments" onClick={() => { history.push('/view/team/default'); }} />
                                <MenuButton imgComponent={IMG_ACTIVITY} label="Activity" onClick={() => { history.push('/activity'); }} />
                                <MenuButton imgComponent={IMG_TEAM} label="Team Meeting" onClick={() => { history.push('/view/team-meeting/new'); }} />
                                { isPrioritiser === true && (
                                    <MenuButton imgComponent={IMG_PRIORITISATION} label="Prioritisation" onClick={() => history.push('/team/prioritisation')} />
                                )}
                                <MenuButton imgComponent={IMG_NONPRIORITISED} label="Non-Prioritised" onClick={() => { history.push('/view/team/non-prioritised'); }} />
                                <MenuButton imgComponent={IMG_CLOSED} label="Closed Developments" onClick={() => { history.push('/view/team/development-complete'); }}/>
                            </div>
                            <Stack styles={{root: {maxWidth: 300, margin: "20px auto"}}}>
                                <Label>Useful Links</Label>
                                { isMobileDevice === false && (
                                    <>
                                        <DefaultButton 
                                            iconProps={{imageProps: { src: sunriseLogo, width: 24 }}} 
                                            href="http://servicedesk/" target="_blank" 
                                            style={{marginBottom: 10}}
                                            text="Sostenuto / ITSM" />
                                        <DefaultButton
                                            iconProps={{imageProps: { src: giteaLogo, width: 24, style: { position: "relative", top: -5}}}}
                                            href="http://im-webdev:3000" target="_blank" 
                                            style={{marginBottom: 10}}
                                            text="Gitea" />
                                    </>
                                )}
                                <DefaultButton 
                                    split={true}
                                    iconProps={{ iconName: "TeamsLogo" }}
                                    styles={{
                                        icon: { fontSize: 20, color: "#6264a7" },
                                        splitButtonContainer: {
                                            display: "flex", 
                                            " > span": { flexGrow: 1},
                                            " > span > button:first-of-type": { flexGrow: 1 }
                                        }
                                    }}
                                    splitButtonAriaLabel="See one option"
                                    aria-roledescription="split button"
                                    menuProps={{
                                        // directionalHint: DirectionalHint.rightBottomEdge,
                                        alignTargetEdge: true,
                                        items: [
                                            {
                                                key: "openTeamsWeb",
                                                text: "Open Web App",
                                                href: "https://teams.microsoft.com"
                                            },
                                            {
                                                key: "divider",
                                                itemType: ContextualMenuItemType.Divider
                                            },
                                            {
                                                key: "genChan",
                                                text: "General Channel",
                                                href: "msteams:/l/channel/19:1691f526e8b446a0acc83599a4862ddf@thread.tacv2/General?groupId=a467634f-fc30-4adb-8fbb-aa9ad93113e3&tenantId=b07d40a5-b7e9-4f95-9e5e-5c1fe6e8964d&deeplinkId=74a33624-2698-4cd2-8f18-86ca59c3d25d",
                                                subMenuProps: {
                                                    items: [{
                                                        key: "genChanWeb",
                                                        text: "Open in Web App",
                                                        href: "https://teams.microsoft.com/_?tenantId=b07d40a5-b7e9-4f95-9e5e-5c1fe6e8964d#/l/channel/19:1691f526e8b446a0acc83599a4862ddf@thread.tacv2/General?groupId=a467634f-fc30-4adb-8fbb-aa9ad93113e3&tenantId=b07d40a5-b7e9-4f95-9e5e-5c1fe6e8964d&deeplinkId=74a33624-2698-4cd2-8f18-86ca59c3d25d"
                                                    }]
                                                }
                                            }
                                        ]
                                    }}
                                    onClick={() => {
                                        window.location.href = "msteams://";
                                    }}
                                    text="Microsoft Teams App" />
                                <DefaultButton 
                                    href="https://tasks.office.com"
                                    style={{marginTop: 10}} 
                                    styles={{icon: { fontSize: 20, color: "#31752f" }}} 
                                    iconProps={{iconName: "PlannerLogo"}} 
                                    text="Tasks / Planner" />
                                
                            </Stack>
                        </PivotItem>
                    )}
                    { (isColleague === true || isCustomer === true || isAccountMgr === true ) && (
                        <PivotItem headerText="Customer Portal" style={menuStyle} >
                            <MenuButton imgComponent={IMG_DEV} label="Developments" onClick={() => history.push("/view/customer/in-development")} />
                            { isPrioritiser === true && (
                                <MenuButton imgComponent={IMG_PRIORITISATION} label="Prioritisation" onClick={() => history.push('/customer/prioritisation')} />
                            )}
                        </PivotItem>
                    )}
                    { isAdmin === true && (
                        <PivotItem headerText="Admin" style={menuStyle}>
                            <MenuButton imgComponent={IMG_USERMGT} label="User Management" onClick={() => history.push("/admin/user-management")} />
                            <MenuButton imgComponent={IMG_SLA} label="Support Agreements" onClick={() => alert('In development')} />
                        </PivotItem>
                    )}
                </Pivot>
            </Stack>
        </div>
    );
};

export default MainMenu;