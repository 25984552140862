import * as React from "react";
import { Gantt, Task, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { IBusinessGroup, IUserLookup, ViewItem } from "../../../types";
import { CommandBarButton, css, IFacepilePersona, Label, MessageBar, MessageBarType, Stack, TooltipHost } from "office-ui-fabric-react";
import { AsyncFacepile } from "../AsyncFacepile";

import styles from "./GanttWrapper.module.scss";

export interface IGanttWrapperProps {
    developments: ViewItem[];
    onClick: (development: ViewItem) => void;
}

export const GanttWrapper = (props: IGanttWrapperProps) => {
    const [groupingField, setGroupingField] = React.useState<string>("platform");
    const [groupings, setGroupings] = React.useState<string[]>([]);
    const [hiddenPlatforms, setHiddenPlatforms] = React.useState<string[]>([]);
    const [viewDate, setViewDate] = React.useState(new Date());
    const [tasks, setTasks] = React.useState<Task[]>([]);
    const [error, setError] = React.useState<JSX.Element>(null);

    React.useEffect(() => {
        let newGroupings: string[] = [];
        if (groupingField === "platform") {
            newGroupings = props.developments.reduce((prev, cur, curIdx, arr) => {
                let next: string[] = [...prev];
                if (next.indexOf(cur.platform) < 0) {
                    next.push(cur.platform);
                }
                return next;
            }, [] as string[]); 
        } else if (groupingField === "organisation") {
            newGroupings = props.developments.reduce((prev, cur, curIdx, arr) => {
                let next = [...prev];
                if (cur.businessGroups[0]) {
                    const businessGroupName = (cur.businessGroups[0] as IBusinessGroup).name;
                    if (next.indexOf(businessGroupName) < 0) {
                        next.push((cur.businessGroups[0].name));
                    }
                } 
                return next;
            }, [] as string[]);
        }
        newGroupings.sort();
        setGroupings(newGroupings);
        setHiddenPlatforms(newGroupings.map((dp, idx) => (groupingField === "platform" ? "p" : "o") + idx));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupingField]);

    React.useEffect(() => {
        const groupingTasks = groupings.map((grouping, idx) => ({
            id: (groupingField === "platform" ? "p" : "o") + idx,
            name: grouping,
            type: "project",
            hideChildren: hiddenPlatforms.indexOf((groupingField === "platform" ? "p" : "o") + idx) >= 0
        } as Task));
    
        let orderedTasks: Task[] = [], invalidTasks: Task[], invalidTaskCount: number = 0;
        if (groupings.length) {
            const newTasks = props.developments.map((development: ViewItem, idx) => {
                let platformTaskIdx = -1;
                if (groupingField === "platform") {
                    platformTaskIdx = groupingTasks.findIndex((pt) => pt.name === development.platform);
                } else if (groupingField === "organisation") {
                    platformTaskIdx = groupingTasks.findIndex((pt) => (development.businessGroups as IBusinessGroup[]).filter((bg) => bg.name === pt.name).length > 0);
                }
                if (platformTaskIdx === -1) return null;
                let sDate: Date | undefined, eDate: Date | undefined;
                if (development.estimatedStart) {
                    sDate = new Date(development.estimatedStart);
                }
                if (development.startDate) {
                    sDate = new Date(development.startDate);
                }
                if (development.estimatedEnd) {
                    eDate = new Date(development.estimatedEnd);
                }
                if (development.endDate) {
                    eDate = new Date(development.endDate);
                }
                if (!groupingTasks[platformTaskIdx].start || sDate < groupingTasks[platformTaskIdx].start) {
                    groupingTasks[platformTaskIdx].start = sDate;
                }
                if (!groupingTasks[platformTaskIdx].end || eDate > groupingTasks[platformTaskIdx].end) {
                    groupingTasks[platformTaskIdx].end = eDate;
                }
                const platformId = groupingTasks[platformTaskIdx].id;
                return ({
                    project: platformId,
                    start: sDate,
                    progress: development.complete,
                    end: eDate,
                    id: development.id.toString(),
                    name: `${development.title} (${development.status.text})`,
                    type: "task",
                } as Task);
            }).filter((task) => task !== null);
            const validTasks = newTasks.filter((ganttItem: Task) => Boolean(ganttItem.start) && Boolean(ganttItem.end));
            invalidTasks = newTasks.filter((ganttItem: Task) => !Boolean(ganttItem.start) || !Boolean(ganttItem.end));
            invalidTaskCount = invalidTasks.length;
        
            let displayOrder = 1;
            groupingTasks.forEach((platformTask, idx) => {
                orderedTasks.push({...platformTask, displayOrder});
                if (!platformTask.hideChildren) {
                    const subTasks = validTasks.filter((t) => t.project === platformTask.id);
                    subTasks.forEach((task, sIdx) => {
                        displayOrder++;
                        orderedTasks.push({...task, displayOrder});
                    });
                }
                displayOrder++;
            });
        }

        orderedTasks = orderedTasks.filter((ot) => !(!Boolean(ot.start) || !Boolean(ot.end)));

        setTasks(orderedTasks);

        if (invalidTaskCount > 0) {
            setError(<MessageBar messageBarType={MessageBarType.info}>
                <TooltipHost content={(<>
                    {invalidTasks.map((invalidTask, idx) => <React.Fragment key={invalidTask.id}>
                        <span>{invalidTask.name}</span>
                        {(idx + 1) < invalidTasks.length ? <br/> : null}
                    </React.Fragment>)}
                </>)}>
                    {invalidTaskCount} developments are missing dates necessary for this chart.
                </TooltipHost>
            </MessageBar>);
        } else {
            setError(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings, hiddenPlatforms]);

    const getClassName = React.useCallback((groupStr: string) => {
        return css(styles.sortButton, groupStr === groupingField ? styles.active : undefined);
    }, [groupingField]);

    return (
        <div className={styles.ganttWrapper}>
            { error }
            <Stack horizontal verticalAlign="center">
                <Label style={{marginRight: 10}}>Sort by:</Label>
                <CommandBarButton
                    className={getClassName("platform")}
                    text="Platform"
                    onClick={() => { setTasks([]); setGroupingField("platform"); }}
                    />
                <CommandBarButton
                    className={getClassName("organisation")}
                    text="Organisation"
                    onClick={() => { setTasks([]); setGroupingField("organisation"); }}
                />
            </Stack>
            <div style={{marginBottom: 20}}>
                { tasks.length > 0 && (
                    <Gantt 
                        key={viewDate.toLocaleDateString()}
                        ganttHeight={300}
                        viewDate={viewDate}
                        viewMode={ViewMode.Month}
                        tasks={tasks}
                        onClick={(task) => {
                            if (task.type === "project") {
                                if (hiddenPlatforms.indexOf(task.id) >= 0) {
                                    setHiddenPlatforms(hiddenPlatforms.filter((p) => p !== task.id));
                                    setViewDate(task.start);
                                } else {
                                    setHiddenPlatforms([...hiddenPlatforms, task.id]);
                                }
                            }
                        }}
                        onDoubleClick={(task) => {
                            if (isNaN(parseInt(task.id, 10))) return null;
                            const devId = parseInt(task.id, 10);
                            const development = props.developments.find((d) => d.id === devId);
                            props.onClick(development);
                        }}
                        TooltipContent={(taskProps) => {
                            const task = taskProps.task;
                            if (isNaN(parseInt(task.id, 10))) return null;
                            const devId = parseInt(task.id, 10);
                            const development = props.developments.find((d) => d.id === devId);
                            if (development) {
                                let estimatedStart: Date, start: Date, estimatedEnd: Date, end: Date;
                                if (development.estimatedStart) estimatedStart = new Date(development.estimatedStart);
                                if (development.start) start = new Date(development.start);
                                if (development.estimatedEnd) estimatedEnd = new Date(development.estimatedEnd);
                                if (development.end) end = new Date(development.end);
                                return (
                                    <Stack className={styles.tooltip}>
                                        { (estimatedStart || start) && (
                                            <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                                                <Label>{start !== undefined ? "Start" : "Estimated Start"}</Label>
                                                <div>{start !== undefined ? start.toLocaleDateString() : estimatedStart.toLocaleDateString()}</div>
                                            </Stack>
                                        )}
                                        { (estimatedEnd || end) && (
                                            <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                                                <Label>{end !== undefined ? "End" : "Estimated End"}</Label>
                                                <div>{end !== undefined ? end.toLocaleDateString() : estimatedEnd.toLocaleDateString()}</div>
                                            </Stack>
                                        )}
                                        { development.assignedTo && development.assignedTo.length && (
                                            <>
                                                <Label>Assigned To:</Label> 
                                                <AsyncFacepile
                                                    personas={development.assignedTo.map((at: IUserLookup) => ({
                                                        name: at.name,
                                                        data: {
                                                            email: at.mail
                                                        }
                                                    } as IFacepilePersona))} />
                                            </>
                                        )}
                                    </Stack>
                                );
                            }
                            return null;
            
                        }}
                        onExpanderClick={(task) => {
                            if (hiddenPlatforms.indexOf(task.id) >= 0) {
                                setHiddenPlatforms(hiddenPlatforms.filter((p) => p !== task.id));
                                setViewDate(task.start);
                            } else {
                                setHiddenPlatforms([...hiddenPlatforms, task.id]);
                            }
                        }}
                    />
                )}
            </div>
        </div>
    );
};