import * as React from "react";
import { ActionButton, CommandBar, DetailsList, ICommandBarItemProps, Icon, IContextualMenuItem, IIconProps, Panel, PanelType, Persona, SelectionMode, Toggle, TooltipHost } from "office-ui-fabric-react";
import { IPrioritisation } from "../types/Prioritisation";
import { CustomerHelp } from "./CustomerHelp";

import styles from "./PrioritisationMenu.module.scss";

export interface IPrioritisationMenuOptions {
    /** The prioritisation currently loaded on the page */
    prioritisation?: IPrioritisation;
    /** Prioritisations previously published */
    prioritisationHistory?: IPrioritisation[];
    /** Count of visible developments currently prioritised */
    prioritisedItemCount?: number;
    /** Save status to display */
    saveStatus?: string;
    /** Number of items selected on page */
    selectedItemCount?: number;
    /** Enables development selection checkboxes */
    selectionEnabled?: boolean;
    /** Determines whether non-prioritised items are visible or hidden */
    showNonPrioritised?: boolean;
    /** Name of the current view */
    currentView: string;
    /** Determines whether the Publish button should appear on screen */
    showPublish?: boolean;
    /** Determines whether prior submissions / publish history should be shown */
    showSubmissionsButton?: boolean;
    /** Number of items visible on page */
    visibleItemCount?: number;
    /** Use to customise the text on the publish button */
    publishLabel?: string;
    /** Use to customise the tooltip text on the publish button */
    publishTooltip?: string;
    /** Used to indicate that new submissions have been made since the last dev team prioritisation */
    newSubmissions?: boolean;
}

export interface IPrioritisationMenuProps {
    onAddAll: () => void;
    onClearPrioritisedItems: () => void;
    onToggleNonPrioritised: () => void;
    onToggleSelection: () => void;
    onSaveClick: (publish?: boolean) => void;
    onSelectionAdded: () => void;
    options: IPrioritisationMenuOptions;
}

const getSaveStatusIcon = (status: string) => {
    let iconProps: IIconProps | undefined;
    switch (status) {
        case "Save":
            iconProps = { iconName: "Save" };
            break;
        case "Saving":
            iconProps = { iconName: "ProgressRingDots", className: styles.spin };
            break;
        case "Saved":
        case "Draft Saved":
            iconProps = { iconName: "Completed" };
            break;
    }
    return iconProps;
};

export const PrioritisationMenu = (props: IPrioritisationMenuProps) => {
    const { 
        onAddAll, 
        onClearPrioritisedItems,
        onSaveClick, 
        onToggleSelection,
        onSelectionAdded,
        onToggleNonPrioritised,
        options
    } = props;
    const {
        currentView,
        prioritisation, 
        prioritisationHistory: prioritisationArr,
        prioritisedItemCount, 
        saveStatus, 
        selectionEnabled,
        selectedItemCount,
        showNonPrioritised, 
        showPublish, 
        visibleItemCount,
        publishLabel,
        publishTooltip,
        newSubmissions
    } = options;

    const [showSubmissions, setShowSubmissions] = React.useState(false);
    const [showHelp, setShowHelp] = React.useState(false);

    const menu: ICommandBarItemProps[] = [];
    const farMenu: ICommandBarItemProps[] = [];
    
    if (prioritisationArr && prioritisationArr.length) {
        farMenu.push({
            key: "submissionInfo",
            text: "Show Submissions",
            iconProps: { iconName: newSubmissions ? "AlertSolid" : "Calendar" },
            onClick: () => setShowSubmissions(true)
        });
    }
    if (prioritisation && prioritisation.user) {
        farMenu.push({
            key: "info",
            onRender: (menuItemProps, defaultRenders) => {
                return (
                    <div className={styles.saveDataInfo}>
                        <TooltipHost tooltipProps={{
                            onRenderContent: (tooltipProps) => {
                                return (
                                    <>
                                        Edited by: 
                                        <Persona text={prioritisation.user.displayName} secondaryText={prioritisation.user.email} imageUrl={prioritisation.user.photo} />
                                    </>
                                );
                            }
                        }}>
                            {prioritisation.published ? "Published " : "Draft "} {new Date(prioritisation.dateModified).toLocaleDateString()}
                        </TooltipHost>
                    </div>
                );
            },
        });
    }
    if (saveStatus) {
        farMenu.push(
            {
                key: "saveStatus",
                disabled: saveStatus !== "Publishing" && saveStatus !== "Save",
                text: saveStatus,
                iconProps: getSaveStatusIcon(saveStatus),
                onClick: () => {
                    onSaveClick();
                }
            },
        );
    }
    if (saveStatus !== "Publishing" && saveStatus !== "Published" && showPublish) {
        farMenu.push({
            key: "savePublish",
            disabled: saveStatus === "Publishing" || saveStatus === "Saving",
            text: Boolean(publishLabel) ? publishLabel : "Send",
            iconProps: { iconName: "Send" },
            onRender: (menuItemProps, defaultRender) => {
                const item = (menuItemProps as unknown as ICommandBarItemProps);
                return (
                    <TooltipHost content={Boolean(publishTooltip) ? publishTooltip : "Send to Development Service"}>
                        <ActionButton onClick={() => onSaveClick(true)} style={{height: "100%"}} iconProps={item.iconProps}
                            text={item.text} />
                    </TooltipHost>
                );
            }
        });
    }
    farMenu.push(
        {
            key: "help",
            text: "Help",
            iconProps: { iconName: "Unknown" },
            onClick: () => {
                setShowHelp(true);
            }
        },
        {
            key: "unprioritised",
            onRender: (item, dismissMenu) => (
                <Toggle 
                    disabled={showNonPrioritised && visibleItemCount === 0}
                    styles={{
                        root: { alignSelf: "center", justifySelf: "center", marginBottom: 0, marginLeft: 10 }
                    }}
                    onText="Show All" 
                    offText="Prioritised Only" 
                    checked={showNonPrioritised}
                    onChange={onToggleNonPrioritised}
                />
            )
        }
    );
    if (currentView !== "Current and Upcoming Work" && prioritisedItemCount < visibleItemCount) {
        menu.push({
            key: "add",
            text: "Include",
            iconProps: { iconName: "Add" },
            subMenuProps: {
                items: [
                    {
                        key: "addAll",
                        text: "All",
                        iconProps: { iconName: "SelectAll" },
                        onClick: onAddAll
                    },
                    {
                        key: "select",
                        text: "Selection",
                        iconProps: { iconName: "MultiSelect" },
                        onClick: onToggleSelection
                    }
                ]
            }
        });
    }
    if (selectionEnabled) {
        menu.push({
            key: "addSelection",
            text: "Add Selected",
            iconProps: { iconName: "AddToShoppingList" },
            disabled: selectedItemCount === 0,
            onClick: onSelectionAdded
        });
    }
    if (prioritisedItemCount > 0) {
        menu.push({
            key: "reset",
            text: "Clear",
            iconProps: { iconName: "EraseTool" },
            onClick: onClearPrioritisedItems
        });
    }

    const farMenuOverflow: ICommandBarItemProps[] = [];
    if (window.innerWidth < 500) {
        farMenuOverflow.push({
            key: "farOverflow",
            iconOnly: true,
            iconProps: { iconName: "More" },
            subMenuProps: {
                items: farMenu as IContextualMenuItem[]
            }
        });
    }

    return (
        <>
            <CommandBar 
                items={menu} 
                farItems={window.innerWidth < 500 ? farMenuOverflow : farMenu}
            />
            { showHelp === true && (
                <CustomerHelp onDismiss={() => setShowHelp(false)}/>
            )}
            { showSubmissions === true && (
                <Panel isOpen={true} type={PanelType.medium} headerText="Submissions" onDismiss={() => setShowSubmissions(false)}>
                    <DetailsList
                        items={prioritisationArr}
                        columns={[
                            {
                                key: "org",
                                name: "Organisation",
                                fieldName: "businessArea.name",
                                minWidth: 100,
                                onRender: (item: IPrioritisation) => {
                                    return item?.businessArea?.name;
                                }
                            },
                            {
                                key: "date",
                                name: "Date Updated",
                                fieldName: "businessArea.dateModified",
                                minWidth: 80,
                                maxWidth: 80,
                                onRender: (item: IPrioritisation) => {
                                    const modified = item.dateModified;
                                    if (modified) {
                                        const modifiedDate = new Date(modified);
                                        const isNew = modifiedDate > new Date(prioritisation.dateModified);
                                        return (
                                            <>
                                                { isNew ? <Icon iconName="AlertSolid" /> : undefined}
                                                { (isNew ? ' ' : '') + modifiedDate.toLocaleDateString()}
                                            </>
                                        );
                                    }
                                    return null;
                                }
                            },
                            {
                                key: "author",
                                name: "Modified By",
                                fieldName: "user.displayName",
                                minWidth: 250,
                                onRender: (item: IPrioritisation) => {
                                    if (item.user) {
                                        return (
                                            <Persona text={item.user.displayName} secondaryText={item.user.email} imageUrl={item.user.photo} />
                                        );
                                    } else {
                                        return null;
                                    }
                                }
                            },
                            {
                                key: "count",
                                name: "Count",
                                minWidth: 100,
                                onRender: (item: IPrioritisation) => {
                                    if (item.developments && item.developments.length) {
                                        return item.developments.length.toString();
                                    } else {
                                        return "0";
                                    }
                                }
                            }
                        ]}
                        selectionMode={SelectionMode.none}
                    />
                </Panel>
            )}
        </>
    );
};

export default PrioritisationMenu;