import * as React from "react";
import {
    TooltipHost,
    PersonaCoin,
    PersonaSize
} from "office-ui-fabric-react";
import { 
    IDevelopmentItem,
    ViewItem
} from "../types";

export interface IAssignedToStackProps {
    className?: string;
    coinSize?: PersonaSize;
    item: Partial<ViewItem>;
    overflowCount?: number;
    style?: React.CSSProperties;
}

export const AssignedToStack = (props: IAssignedToStackProps) => {

    const item = props.item as IDevelopmentItem;
    const coinSize = props.coinSize || PersonaSize.size24;
    const overflowCount = props.overflowCount || 2;
    let stack: JSX.Element[] = [];
    let overflow = false;
    if (!item.assignedTo || item.assignedTo.length === 0) {
        return null;
    }
    for (let i = 0; i < item.assignedTo.length; i++) {
        if (i >= overflowCount) {
            overflow = true;
            continue;
        }
        const user = item.assignedTo[i];
        let imageUrl;
        if (user.photo) {
            imageUrl = `data:${user.photo.type};base64,${user.photo.data}`;
        }
        stack.push(
            <TooltipHost key={user.mail} content={<div>
                <div>{user.name}</div>
                { Boolean(user.mail) === true && (
                    <div>({user.mail})</div>
                )}
            </div>}>
                <PersonaCoin size={coinSize} text={user.name} secondaryText={user.mail} imageUrl={imageUrl} />
            </TooltipHost>
        );
    }
    if (overflow) {
        const overflowText = `+${(item.assignedTo.length - overflowCount)}`;
        const overflowAssignees = item.assignedTo.slice(-overflowCount);
        stack.push(
            <TooltipHost content={<div>
                <div>{overflowText}</div>
                { overflowAssignees.map((user) => (
                    <div>{user.name}</div>
                ))}
            </div>}>
                <PersonaCoin size={coinSize} imageInitials={overflowText} />
            </TooltipHost>
        );
    }

    return (
        <div className={props.className} style={props.style}>
            { stack }
        </div>
    );
};

export default AssignedToStack;