import { IRoleMembership } from "../types";

export async function getRoles() {
    const result = await fetch(`/api/roles`, {
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });
    if (result.ok) {
        return await result.json() as IRoleMembership[];
    } else {
        throw new Error('Unable to retrieve roles');
    }
}