import { ICriteria } from "./matrix.types";

export const questions: ICriteria[] = [
    {
        id: 1,
        title: "Recommended by Statute / Legislation",
        description: "This development supports a scheme that is recommended by bodies such as Fire & Rescue Service (improvement notices), CQC, NHSI, NICHE, Data Protection Authority, PLACE. The word 'risks' below pertains to these schemes and recommendations:",
        scoreDefinitions: {
            0: "No risks or consequences of not completing the development",
            1: "Minimal risks and consequences of not completing the development",
            2: "Significant risks and consequences of not completing the development",
            3: "Extreme risks and consequences of not completing the development"
        },
        weight: 30
    },
    {
        id: 2,
        title: "Protects Safety",
        description: "To what extent does this development directly protect patient / people safety?",
        scoreDefinitions: {
            0: "Does not protect patient / people safety",
            1: "Has an indirect benefit for patient / people safety",
            2: "Has a direct benefit for patient / people safety",
            3: "Essential to protect patient / people safety"
        },
        weight: 20
    },
    {
        id: 3,
        title: "Maintains and/or improves Experience",
        scoreDefinitions: {
            0: "Does not impact on maintaining and/or improving patients, visitors and staff experience",
            1: "Minimal impact on maintaining and/or improving patients, visitors and staff experience",
            2: "Significant impact on maintaining and/or improving patients, visitors and staff experience",
            3: "Essential to maintaining and/or improving patients, visitors and staff experience"
        },
        weight: 20
    },
    {
        id: 4,
        title: "Improves quality",
        description: "To what extent does this development improve service quality or address quality account priorities?",
        scoreDefinitions: {
            0: "Does not impact on quality of services",
            1: "Has an indirect impact on service quality",
            2: "Has a direct impact on service quality",
            3: "Essential to address quality priorities"
        },
        weight: 15
    },
    {
        id: 5, 
        title: "Supports effective use of resources - supports CIP delivery",
        description: "To what extent is delivery of CIP targets dependent on this development?",
        scoreDefinitions: {
            0: "Does not support CIP delivery",
            1: "Without this development, CIP delivery will be at low risk",
            2: "Without this development, CIP delivery will be at high risk",
            3: "Delivery of CIP savings is fully dependent upon this development"
        },
        weight: 15
    },
    {
        id: 6,
        title: "Business Continuity",
        description: "To what extent does this development affect or contribute to business continuity and what are the potential risks and consequences of not completing the development?",
        scoreDefinitions: {
            0: "Does not impact on business continuity",
            1: "Minimal impact on business continuity",
            2: "Significant impact on business continuity",
            3: "Essential to business continuity"
        },
        weight: 15
    },
    {
        id: 7,
        title: "Sustainability Agenda",
        description: "To what extent does this development support the trust Sustainability Plan and aid towards achieving Net Zero Carbon?",
        scoreDefinitions: {
            0: "No impact on sustainability",
            1: "Minimal impact on sustainability",
            2: "Marginal impact on sustainability",
            3: "Significant impact on sustainability"
        },
        weight: 15
    }
];