import React from "react";

import { 
    DirectionalHint, 
    Dropdown, 
    focusFirstChild,
    IDropdownOption, 
    Spinner, 
} from "office-ui-fabric-react";

import * as API from "../../api";
import { IStatusOption, IStatusOptionsResponse } from "../../types";

export interface IStatusDropdownProps {
    disabled?: boolean;
    autoFocus?: boolean;
    value: IStatusOption | undefined;
    onChange: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) => void;
}
export interface IStatusDropdownState {
    loading: boolean;
    statusOptions: IDropdownOption[] | undefined;
}

export default class StatusDropdown extends React.Component<IStatusDropdownProps, IStatusDropdownState> {
    private containerDiv: HTMLDivElement | null = null;
    private autoFocussed = false;
    constructor(props: IStatusDropdownProps) {
        super(props);
        this.state = {
            loading: true,
            statusOptions: undefined,
        };
    }
    public componentDidMount() {
        this._loadOptions();
    }
    public componentDidUpdate() {
        if (this.props.autoFocus && !this.autoFocussed && this.containerDiv) {
            focusFirstChild(this.containerDiv);
            this.autoFocussed = true;
        }
    }
    public render() {
        if (!this.state.loading && this.state.statusOptions) {
            return (
                <div ref={(ref) => this.containerDiv = ref}>
                    <Dropdown
                        disabled={this.props.disabled}
                        label="Status"
                        calloutProps={{ directionalHint: DirectionalHint.bottomLeftEdge, directionalHintFixed: true }}
                        selectedKey={this.props.value ? this.props.value.id : undefined}
                        options={this.state.statusOptions}
                        onChange={this.props.onChange}
                    />
                </div>
            );
        } else {
            return (
                <Spinner />
            );
        }
    }
    private async _loadOptions() {
        await API.getStatusOptions().then((r: IStatusOptionsResponse | undefined) => {
            if (r) {
                const dropdownOptions: IDropdownOption[] = r.options.map((a: IStatusOption) => ({
                    key: a.id,
                    text: a.text,
                } as IDropdownOption));
                this.setState({
                    ...this.state,
                    loading: false,
                    statusOptions: dropdownOptions,
                });
            }
        });
    }
}
