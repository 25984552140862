/* types */
import { IDevelopmentItem, IRequestorInfo, IUserLookup } from "../types";
import { FilterMenuItem } from "./components/developmentViews/FilterMenu";

/* Fabric UI */
import {
    IFacepilePersona,
    PersonaSize,
} from "office-ui-fabric-react";

/* base view */
import BaseView, { IBaseViewState, ViewDisplayMode } from "./BaseView";
import { DevTableColumn } from "./components/developmentViews/DevTable.types";

/* utils */
import { 
    getStatusIconName, 
    getPlatformIconName,
} from "../utils";
import { RouteComponentProps, withRouter } from "react-router";

/* State and Props */
export interface IClosedViewProps extends RouteComponentProps {
}
export interface IClosedViewState extends IBaseViewState {}

export class ClosedView extends BaseView<IClosedViewProps, IClosedViewState> {

    protected viewName = "ClosedView View";
    
    constructor(props: IClosedViewProps) {
        super(props);
        this.isSostenutoView = false;
        this._isDevTeamView = true;

        // init state
        this.state = {
            activeColumns: this._tableConfig,
            editFormTabId: undefined,
            editingItem: undefined,
            items: undefined,
            itemCounts: {},
            loading: true,
            loadingMessage: "Loading",
            statusOptions: undefined,
            displayMode: ViewDisplayMode.Table,
            statusFilter: false,
            toastNotifications: []
        };
    }

    //#region query / filter menu
    protected _menuConfig: FilterMenuItem[] = [
        {
            text: "Closed (All)",
            itemQuery: { includeClosed: true },
            iconName: getStatusIconName("All"),
        },
        { 
            text: "Not Required", 
            itemQuery: { status: "Closed (Not Required)", includeClosed: true },
            iconName: getStatusIconName("Closed (Not Required)") 
        }, 
        { 
            text: "Development Complete", 
            itemQuery: { status: "Closed (Development Complete)", includeClosed: true }, 
            iconName: getStatusIconName("Closed (Development Complete)"),
            isDefault: true,
        },
    ];
    //#endregion

    //#region table config
    protected _tableConfig: DevTableColumn[] = [
        {
            type: "Icon",
            fieldName: "platform",
            minWidth: 40,
            maxWidth: 40,
            iconConversion: (value: any) => ({
                iconName: getPlatformIconName(value),
                styles: { root: { fontSize: "2.5em", color: "#b1324e" }}
            }),
            mapToText: (item: IDevelopmentItem) => item.platform,
            filters: "UniqueText"
        },
        {
            type: "Text",
            fieldName: "project",
            minWidth: 80,
            maxWidth: 150,
            filters: "UniqueText"
        },
        {
            type: "Text",
            fieldName: "title",
            displayName: "Development",
            minWidth: 100,
        },
        {
            type: "Persona",
            fieldName: "combinedRequestors",
            displayName: "Requestors",
            minWidth: 100,
            maxWidth: 200,
            personaConversion: (value: IRequestorInfo) => ({
                size: PersonaSize.size32,
                name: value.contactName,
                data: {
                    email: value.contactEmail
                }
                // secondaryText: value.contactEmail,
                // imageUrl: value.contactPhoto ? `data:${value.contactPhoto.type};base64,${value.contactPhoto.data}` : undefined
            } as IFacepilePersona),
            mapToText: (item: IDevelopmentItem) => {
                return item.combinedRequestors.map((value, i, arr) =>
                    `${value.contactName} ${value.contactEmail}` + (i + 1 < arr.length ? ',' : '') 
                ).join('\n');
            }
        },
        {
            type: "Text",
            fieldName: "businessGroups",
            minWidth: 100,
            objectValueMapping: (value: any) => value.name,
            filters: "UniqueText",
            mapToText: (item: IDevelopmentItem) => {
                return item.businessGroups.map((value, i, arr) =>
                    value.name 
                );
            }
        },
        {
            type: "Date",
            fieldName: "startDate",
            displayName: "Start Date",
            minWidth: 80,
            maxWidth: 80
        },
        {
            type: "Date",
            fieldName: "endDate",
            displayName: "End Date",
            minWidth: 80,
            maxWidth: 80
        },
        {
            type: "Persona",
            fieldName: "assignedTo",
            minWidth: 100,
            maxWidth: 100,
            filters: "UniqueText",
            personaConversion: (value: IUserLookup) => ({
                size: PersonaSize.size32,
                name: value.name,
                data: {
                    email: value.mail
                }
                // secondaryText: value.mail,
                // imageUrl: value.photo ? `data:${value.photo.type};base64,${value.photo.data}` : undefined
            } as IFacepilePersona),
            mapToText: (item) => item.assignedTo.map((a: any) => a.mail)
        },
    ];
    //#endregion
}

export default withRouter(ClosedView);