import React from "react";

import { DefaultButton, Dialog, DialogContent, DialogFooter, DialogType, Icon, IPersonaProps, MessageBar, MessageBarType, PrimaryButton, TextField } from "office-ui-fabric-react";
import { IDevelopmentItem, IStatusOption, IUserLookup } from "../../types";
import { getStatusIconName } from "../../utils";
import SPPeoplePicker from "../../developmentForm/customPickers/PeoplePicker";
import UKDatePicker from "../../developmentForm/customPickers/UKDatePicker";

export interface IStatusChangeDialogProps {
    className?: string;
    onDataEntered: (developmentId: number, newStatus: IStatusOption, update: IStatusChangeDevelopmentUpdate) => void;
}
export interface IStatusChangeDevelopmentUpdate {
    reviewDate?: string;
    startDate?: string;
    assignedTo?: IUserLookup[];
    latestUpdate?: string;
    closedComments?: string;
}
export interface IStatusChangeDialogState extends IStatusChangeDevelopmentUpdate {
    confirmBox: string | undefined;
    development?: IDevelopmentItem | undefined;
    dataRequired: string[] | undefined;
    moveTo: IStatusOption | undefined;
    isOpen: boolean;
    warn: boolean;
}

export default class StatusChangeDialog extends React.Component<IStatusChangeDialogProps, IStatusChangeDialogState> {
    constructor(props: IStatusChangeDialogProps) {
        super(props);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.state = {
            confirmBox: undefined,
            dataRequired: undefined,
            development: undefined,
            isOpen: false,
            moveTo: undefined,
            warn: false,
        };
    }
    public open(development: IDevelopmentItem, status: IStatusOption, dataRequired?: string[], warn?: boolean) {
        this.setState({
            ...this.state,
            assignedTo: undefined,
            closedComments: undefined,
            latestUpdate: undefined,
            reviewDate: undefined,
            startDate: undefined,
            dataRequired,
            development,
            isOpen: true,
            moveTo: status,
            warn: warn ? warn : false,
        });
    }
    public render() {
        const { dataRequired, isOpen, moveTo, warn } = this.state;
        if (!isOpen || !moveTo || !dataRequired) return null;

        const showReviewDate = dataRequired.indexOf("ReviewDate") >= 0;
        const showStartDate = dataRequired.indexOf("StartDate") >= 0;
        const showAssignedTo = dataRequired.indexOf("AssignedTo") >= 0;
        const showLatestUpdate = dataRequired.indexOf("LatestUpdate") >= 0;
        const showClosureComments = dataRequired.indexOf("ClosedComments") >= 0;

        const enabled = 
            (!showReviewDate || (showReviewDate && this.state.reviewDate)) &&
            (!showStartDate || (showStartDate && this.state.startDate)) &&
            (!showAssignedTo || (showAssignedTo && this.state.assignedTo && this.state.assignedTo.length > 0)) &&
            (!showLatestUpdate || (showLatestUpdate && this.state.latestUpdate && this.state.latestUpdate.trim().length > 0)) &&
            (!showClosureComments || (showClosureComments && this.state.closedComments && this.state.closedComments.trim().length > 0)) &&
            (!warn || (warn && this.state.development && this.state.confirmBox && this.state.confirmBox === this.state.development.title));

        return (
            <Dialog isOpen={true} onDismiss={this.close} type={DialogType.largeHeader} title="Status Change" className={this.props.className}>
                <DialogContent>
                    <MessageBar messageBarType={this.state.warn ? MessageBarType.severeWarning : MessageBarType.warning}>
                        <p>Changing status to:</p>
                        <p className="newStatus"><Icon iconName={getStatusIconName(moveTo.text)} /> {moveTo.text}</p>
                        { !this.state.warn && (<p>The following information is required:</p>)}
                        { this.state.warn && (<p>By changing the status to <strong>{moveTo.text}</strong>, this development will no longer appear in the Team View.</p>)}
                    </MessageBar>
                    { showReviewDate && (
                        <UKDatePicker 
                            label="Review Date"
                            onSelectDate={(date: Date | null | undefined) => {
                                if (date) {
                                    this.setState({
                                        ...this.state,
                                        reviewDate: date.toJSON(),
                                    });
                                }
                            }}
                            dateString={this.state.reviewDate}  
                            errorMessage={!this.state.reviewDate ? 
                                "Please enter a review date" : ""
                            }
                        />
                    )}
                    { showStartDate && (
                        <UKDatePicker 
                            label="Start Date"
                            onSelectDate={(date: Date | null | undefined) => {
                                if (date) {
                                    this.setState({
                                        ...this.state,
                                        startDate: date.toJSON(),
                                    });
                                }
                            }}
                            dateString={this.state.startDate}  
                            errorMessage={!this.state.reviewDate ? 
                                "Please enter a start date" : ""
                            }
                        />
                    )}
                    { showAssignedTo && (
                        <SPPeoplePicker
                            label="Assign to Developer(s)"
                            onChange={(items?: IPersonaProps[] | undefined) => {
                                if (items) {
                                    const assignedTo = items.map((a) => ({
                                        mail: a.secondaryText,
                                        id: a.itemID,
                                        name: a.text,
                                    } as IUserLookup));
                                    this.setState({
                                        ...this.state,
                                        assignedTo,
                                    });
                                }
                            }}
                            selectedPeople={this.state.assignedTo ? this.state.assignedTo.map((a) => ({
                                itemID: a.id,
                                secondaryText: a.mail,
                                text: a.name,
                            } as IPersonaProps)) : []}
                            errorMessage={this.state.assignedTo === undefined || 
                                (this.state.assignedTo !== undefined && this.state.assignedTo.length === 0) ?
                                "Please assign to a developer" : ""
                            }
                        />
                    )}
                    { showLatestUpdate && (
                        <TextField label="Mandatory Update" value={this.state.latestUpdate} 
                            autoFocus={true}
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
                                this.setState({
                                    ...this.state,
                                    latestUpdate: newValue,
                                });
                            }}
                            errorMessage={!this.state.latestUpdate ? 
                                "Please enter an update" : ""}
                        />
                    )}
                    { showClosureComments && (
                        <TextField label="Closed Comments" value={this.state.closedComments}
                            autoFocus={true}
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
                                this.setState({
                                    ...this.state,
                                    closedComments: newValue,
                                });
                            }}
                            errorMessage={!this.state.closedComments ?
                                "Please enter a closure comment" : ""}
                        />
                    )}
                    { this.state.development && this.state.warn && (
                        <div>
                            <p>Please enter the development name <strong>{this.state.development.title}</strong> to confirm:</p>
                            <TextField value={this.state.confirmBox} 
                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
                                    this.setState({
                                        ...this.state,
                                        confirmBox: newValue,
                                    });
                                }} 
                                errorMessage={this.state.confirmBox !== this.state.development.title ? 
                                    "Enter the development name as above to confirm you wish to change the status" : ""
                                }
                            />
                        </div>
                    )}
                </DialogContent>
                <DialogFooter>
                    <PrimaryButton text="Move" 
                        iconProps={{
                            iconName: "FabricMovetoFolder",
                        }}
                        disabled={!enabled}
                        onClick={() => { 
                            if (this.state.development && this.state.development.id) {
                                this.props.onDataEntered(this.state.development.id,
                                    moveTo,
                                    {
                                        assignedTo: this.state.assignedTo,
                                        closedComments: this.state.closedComments,
                                        latestUpdate: this.state.latestUpdate,
                                        reviewDate: this.state.reviewDate,
                                    },
                                ); 
                                this.close();
                            }
                        }}
                    />
                    <DefaultButton text="Cancel" onClick={this.close}/>
                </DialogFooter>
            </Dialog>
        );
    }
    private close() {
        this.setState({
            ...this.state,
            assignedTo: undefined,
            closedComments: undefined,
            latestUpdate: undefined,
            reviewDate: undefined,
            startDate: undefined,
            dataRequired: undefined,
            development: undefined,
            isOpen: false,
            moveTo: undefined,
            warn: false,
        });
    }
}
