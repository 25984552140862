import * as React from "react";
import { Panel, PanelType, Stack, PrimaryButton, css, ChoiceGroup } from "office-ui-fabric-react";

import styles from "./ALSicknessPanel.module.scss";
import { ISLATransactionDTO } from "../../../types";

export interface IALSicknessPanelProps {
    activities: ISLATransactionDTO[];
    weekStart: Date;
    onDismiss: () => void;
    onSave: () => void;
}

export interface IALSicknessPanelState {
    absenceType?: string;
    selectedSquares: number[][];
    dragStart: number[];
    dragEnd: number[];
}

export class ALSicknessPanel extends React.Component<IALSicknessPanelProps, IALSicknessPanelState> {
    private _timeDayPickerRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    private _touchStartTarget: HTMLElement;
    private _lastTouchTarget: HTMLElement;

    constructor(props: IALSicknessPanelProps) {
        super(props);

        this._cellClick = this._cellClick.bind(this);
        this._dragOver = this._dragOver.bind(this);
        this._dragStart = this._dragStart.bind(this);
        this._getActivitySubset = this._getActivitySubset.bind(this);
        this._save = this._save.bind(this);
        this._selectSquaresFromActivities = this._selectSquaresFromActivities.bind(this);
        this._selectSquaresFromDragOp = this._selectSquaresFromDragOp.bind(this);
        this._touchEnd = this._touchEnd.bind(this);
        this._touchMove = this._touchMove.bind(this);
        this._touchStart = this._touchStart.bind(this);

        this.state = {
            dragEnd: [],
            dragStart: [],
            selectedSquares: [],
        };
    }

    public componentWillUnmount() {
        const timePickerRef = this._timeDayPickerRef.current;
        if (timePickerRef) {
            timePickerRef.removeEventListener('touchstart', this._touchStart);
            timePickerRef.removeEventListener('touchmove', this._touchMove);
            timePickerRef.removeEventListener('touchend', this._touchEnd);
        }
    }

    public componentDidUpdate(prevProps: Readonly<IALSicknessPanelProps>, prevState: Readonly<IALSicknessPanelState>, snapshot?: any): void {
        if (this.state.dragStart && this.state.dragEnd && (prevState.dragStart !== this.state.dragStart || prevState.dragEnd !== this.state.dragEnd)) {
            this._selectSquaresFromDragOp();
        }
        if (prevProps.activities !== this.props.activities || prevState.absenceType !== this.state.absenceType) {
            this._selectSquaresFromActivities();
        }
        if (prevState.absenceType === undefined && this.state.absenceType !== undefined) {
            const timePickerRef = this._timeDayPickerRef.current;
            if (timePickerRef) {
                timePickerRef.addEventListener('touchstart', this._touchStart, { passive: false });
                timePickerRef.addEventListener('touchmove', this._touchMove, { passive: false });
                timePickerRef.addEventListener('touchend', this._touchEnd, { passive: false });
            }
        }
    }
    private _selectSquaresFromDragOp() {
        const { dragStart, dragEnd } = this.state;
        const selectedSquares = [];
        if (dragStart.length && dragEnd.length) {
            const rowAsc = dragStart[0] <= dragEnd[0];
            const rowStart = rowAsc ? dragStart[0] : dragEnd[0];
            const rowEnd = rowAsc ? dragEnd[0] : dragStart[0];
            const colAsc = dragStart[1] <= dragEnd[1];
            const colStart = colAsc ? dragStart[1] : dragEnd[1];
            const colEnd = colAsc ? dragEnd[1] : dragStart[1];
            for (let r = rowStart; r <= rowEnd; r++) {
                for (let c = colStart; c <= colEnd; c++) {
                    selectedSquares.push([r,c]);
                }
            }
        }
        this.setState({
            ...this.state,
            selectedSquares,
        });
    }
    private _selectSquaresFromActivities() {
        const activitySubset = this._getActivitySubset();
        const selectedSquares = activitySubset.map((activity) => {
            const dateOfAbsence = new Date(activity.dateOfAbsence);
            const day = dateOfAbsence.getDay();
            const row = activity.absencePm ? 3 : 2;
            return [row, day + 1];
        });
        this.setState({
            ...this.state,
            selectedSquares,
        });
    }

    public render() {
        const { onDismiss } = this.props;
        const { absenceType, selectedSquares } = this.state;
        const dayStr = ["M", "T", "W", "T", "F"];
        const days = [1,2,3,4,5];

        return (
            <Panel 
                isOpen={true} 
                isFooterAtBottom={true}
                type={PanelType.medium} 
                headerText={"Annual Leave and Sickness"}
                onDismiss={onDismiss}
                onRenderFooter={(panelProps) => (
                    <Stack horizontal horizontalAlign={"end"} verticalAlign="center" styles={{root: {margin: 20}}}>
                        <PrimaryButton 
                            disabled={absenceType === undefined}
                            onClick={() => {
                                this._save();
                            }}
                            text="Save"
                        />
                    </Stack>
                )}
            >
                <ChoiceGroup
                    label="Absence Type"
                    options={[
                        {
                            key: "AL",
                            text: "Annual Leave",
                            iconProps: { iconName: "OutOfOffice" },
                            styles: {
                                field: { width: 120 },
                                labelWrapper: { maxWidth: 120 }
                            }
                        },
                        {
                            key: "Sick",
                            text: "Sickness",
                            iconProps: { iconName: "Medical" }
                        }
                    ]}
                    selectedKey={absenceType}
                    onChange={(e, option) => {
                        this.setState({
                            ...this.state,
                            absenceType: option.key,
                        });
                    }}
                />
                { absenceType !== undefined && (
                    <React.Fragment>
                        <p>Click, tap, or touch and drag to select or deselect time-blocks:</p>
                        <div ref={this._timeDayPickerRef} className={styles.dayTimeSelect}>
                            <div className={styles.am}>AM</div>
                            <div className={styles.pm}>PM</div>
                            { dayStr.map((ds, idx) => (
                                <div 
                                    key={"header-"+idx}
                                    style={{
                                        gridRow: 1,
                                        gridColumn: idx + 2
                                    }} 
                                    className={styles.header}
                                >
                                    {ds}
                                </div>
                            ))}
                            { days.map((day, idx) => {
                                const isSelected = selectedSquares.filter((s) => s[0] === 2 && s[1] === idx + 2).length > 0;
                                return (
                                    <div 
                                        key={"am-"+idx}
                                        draggable={true}
                                        data-row={2}
                                        data-col={idx}
                                        style={{
                                            gridRow: 2, 
                                            gridColumn: idx + 2
                                        }} 
                                        className={css(styles.option, isSelected ? styles.selected : undefined)}
                                        onDragStart={this._dragStart(2, idx)}
                                        onDragOver={this._dragOver(2, idx)}
                                        onClick={this._cellClick(isSelected, 2, idx)}
                                    />
                                );
                            })}
                            { days.map((day, idx) => {
                                const isSelected = selectedSquares.filter((s) => s[0] === 3 && s[1] === idx + 2).length > 0;
                                return (
                                    <div 
                                        key={"pm"+idx}
                                        draggable={true}
                                        data-row={3}
                                        data-col={idx}
                                        style={{
                                            gridRow: 3, 
                                            gridColumn: idx + 2
                                        }} 
                                        className={css(styles.option, isSelected ? styles.selected : undefined)}
                                        onDragStart={this._dragStart(3, idx)}
                                        onDragOver={this._dragOver(3, idx)}
                                        onClick={this._cellClick(isSelected, 3, idx)}
                                    />
                                );
                            })}
                        </div>
                    </React.Fragment>
                )}
            </Panel>
        );
    }

    private _getActivitySubset() {
        if (!this.state || !this.state.absenceType) return [];
        return this.props.activities.filter((a) => a.absenceType === this.state.absenceType);
    }

    private _cellClick(selected: boolean, row: number, index: number) {
        return (e: React.MouseEvent) => {
            let selectedSquares = [...this.state.selectedSquares];
            if (selected) {
                selectedSquares = selectedSquares.filter((s) => { return !(s[0] === row && s[1] === index+2); });
            } else {
                selectedSquares = [...selectedSquares, [row, index+2]];
            }
            this.setState({
                ...this.state,
                selectedSquares,
            });
        };
    }

    private _dragStart(row: number, index: number) {
        return (e: React.DragEvent<HTMLDivElement>) => {
            e.dataTransfer.setData("text/plain", `[${row},${index+2}]`);
            e.dataTransfer.setDragImage(new Image(), 0 ,0);
            this.setState({
                ...this.state,
                dragStart: [row, index + 2],
            });
        };
    }

    private _dragOver(row: number, index: number) {
        return (e: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
            this.setState({
                ...this.state,
                dragEnd: [row, index + 2],
            });
            return false;
        };
    }

    private _touchStart(e: TouchEvent) {
        e.preventDefault();
        if (e.target) {
            const el = e.target as HTMLDivElement;
            this._touchStartTarget = el;
        }
    }
    private _touchMove(e: TouchEvent) {
        if (e.touches.length) {
            const touch = e.touches[0];
            this._lastTouchTarget = document.elementFromPoint(touch.pageX, touch.pageY) as HTMLElement;
        }
        let dragStart = this.state.dragStart,
            dragEnd = this.state.dragEnd,
            changed = false;
        if (this._touchStartTarget !== this._lastTouchTarget) {
            const elStart = this._touchStartTarget as HTMLElement;
            if (elStart && elStart.classList.contains(styles.option)) {
                const touchRow = parseInt(elStart.getAttribute("data-row"), 10);
                const touchCol = parseInt(elStart.getAttribute("data-col"), 10) + 2;
                dragStart = [touchRow, touchCol];
                changed = true;
            }
            const elEnd = this._lastTouchTarget as HTMLElement;
            if (elEnd.classList.contains(styles.option)) {
                const touchRow = parseInt(elEnd.getAttribute("data-row"), 10);
                const touchCol = parseInt(elEnd.getAttribute("data-col"), 10) + 2;
                dragEnd = [touchRow, touchCol];
                changed = true;
            }
        }
        if (changed) {
            this.setState({
                ...this.state,
                dragStart,
                dragEnd
            });
        }
    }
    private _touchEnd(e: TouchEvent) {
        if (e.changedTouches.length) {
            const touch = e.changedTouches[0];
            this._lastTouchTarget = this._lastTouchTarget = document.elementFromPoint(touch.pageX, touch.pageY) as HTMLElement;
        }
        if (this._touchStartTarget === this._lastTouchTarget) {
            const el = (this._touchStartTarget as HTMLElement);
            const selected = el.classList.contains(styles.selected);
            const touchRow = parseInt(el.getAttribute("data-row"), 10);
            const touchCol = parseInt(el.getAttribute("data-col"), 10) + 2;
            let selectedSquares = this.state.selectedSquares;
            if (selected) {
                selectedSquares = selectedSquares.filter((s) => { return !(s[0] === touchRow && s[1] === touchCol); });
            } else {
                selectedSquares = [...selectedSquares, [touchRow, touchCol]];
            } 
            this.setState({
                ...this.state,
                selectedSquares,
            });
        }
    }

    private async _save() {
        const { onSave, weekStart } = this.props;
        const { absenceType, selectedSquares } = this.state;
        const transactions: ISLATransactionDTO[] = selectedSquares.map((s) => {
            const dayOffset = s[1] - 1;
            const pm = s[0] === 3;
            let dateOfAbsence = new Date(weekStart);
            if (dayOffset > 0) dateOfAbsence.setDate(dateOfAbsence.getDate() + dayOffset);
            dateOfAbsence.setHours(pm ? 13 : 9); 
            dateOfAbsence.setMinutes(pm ? 15 : 0);
            dateOfAbsence.setSeconds(0); dateOfAbsence.setMilliseconds(0);
            return {
                credit: false,
                hours: 3.75,
                absencePm: pm,
                absenceType,
                dateOfAbsence: dateOfAbsence.toISOString(),
            } as ISLATransactionDTO;
        });
        const response = await fetch(`/api/activity/absence/${absenceType}/wb/${weekStart.toISOString()}`, {
            method: 'POST',
            body: JSON.stringify(transactions),
            headers: { 
                'accept': 'application/json',
                'content-type': 'application/json'
            },
            credentials: 'include'
        });
        if (response.ok) {
            onSave();
        }
    }

}

export default ALSicknessPanel;