import React from "react";

import { ChoiceGroup, IChoiceGroupOption } from "office-ui-fabric-react";

import styles from "./PlatformPicker.module.scss";
import { DisplayContext } from "../../DisplayContext";

export interface IRequestTypeProps {
    disabled?: boolean;
    errorMessage?: string;
    selectedRequestType: string | undefined;
    onChange: (requestType: string | undefined) => void;
}
export interface IRequestTypeState {}

export default class RequestTypePicker extends React.Component<IRequestTypeProps, IRequestTypeState> {
    private _requestTypeOptions: IChoiceGroupOption[] = [
        {
            iconProps: {
                iconName: "Giftbox",
            },
            key: 'New',
            text: 'New',
        },
        {
            iconProps: {
                iconName: "DeveloperTools",
            },
            key: 'Upgrade',
            text: 'Upgrade',
        },
    ];
    constructor(props: IRequestTypeProps) {
        super(props);
        this.state = {
            internalRequestType: this.props.selectedRequestType,
        };
    }
    
    public render() {
        return (
            <DisplayContext.Consumer>
                {({ darkMode }) => (
                    <div>
                        <ChoiceGroup
                            disabled={this.props.disabled}
                            className={styles.platformPicker}
                            label="Request Type"
                            selectedKey={this.props.selectedRequestType}
                            options={this._requestTypeOptions}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
                                if (option) {
                                    this.props.onChange(option.key);
                                }
                            }}
                        />
                        { this.props.errorMessage && (
                            <span style={{fontSize: "12px", fontWeight: 400, color: darkMode ? "#e28388" : "#a80000"}}>{this.props.errorMessage}</span>
                        )}
                    </div>
                )}
            </DisplayContext.Consumer>
        );
    }
}
