import * as React from "react";
import { 
    Dropdown, 
    Icon, 
    IContextualMenuItem, 
    IDropdownOption, 
    MessageBar, 
    MessageBarType, 
    Spinner, 
} from "office-ui-fabric-react";

import ConflictResolver from "./ConflictResolver";
import HeaderMenu from "../pages/components/Header2";
import PlatformMenu from "./PlatformMenu";
import PrioritisationMenu from "./PrioritisationMenu";
import SortComponent, { SortIntent } from "./SortComponent";
import CustomerWorkUpdate from "./UpcomingWork";

import { ViewItem } from "../types";
import { IPrioritisation, IPrioritisationPageProps, IPrioritisationPageState, PrioritisationUpdateProp } from "../types/Prioritisation";
import { UserContext } from "../UserContext";
import { loadDevelopmentsByBusinessArea, savePriorityInfo } from "../api/prioritisation";

import styles from "./prioritisation.module.scss";
import logo from "../assets/dev_service_compact.png";
import darkLogo from "../assets/dev_service_compact_dark.png";
import { DisplayContext } from "../DisplayContext";

export class CustomerPrioritisation extends React.Component<IPrioritisationPageProps,IPrioritisationPageState> {
    public static contextType = UserContext;
    public context!: React.ContextType<typeof UserContext>; 

    constructor(props: IPrioritisationPageProps) {
        super(props);
        this._filterDevelopments = this._filterDevelopments.bind(this);
        this._loadDevelopments = this._loadDevelopments.bind(this);
        this._onClearPrioritisedItems = this._onClearPrioritisedItems.bind(this);
        this._onResolveConflicts = this._onResolveConflicts.bind(this);
        this._onSaveClick = this._onSaveClick.bind(this);
        this._onSelection = this._onSelection.bind(this);
        this._onSelectionAdded = this._onSelectionAdded.bind(this);
        this._onToggleNonPrioritised = this._onToggleNonPrioritised.bind(this);
        this._onToggleSelection = this._onToggleSelection.bind(this);
        this._sortDevelopments = this._sortDevelopments.bind(this);
        this.state = {
            loading: true,
            showNonPrioritised: true,
        };
    }

    public componentDidMount() {
        if (this.context.profile?.relationships?.length) {
            this.setState({businessAreaId: this.context.profile.relationships[0].id}, () => {
                this._loadDevelopments();
            });
        }
    }

    public render() {
        const { 
            activeUpdateProp, 
            businessAreaId, 
            currentView, 
            enableSelection, 
            error, 
            loading, 
            platforms,
            prioritisationData, 
            saveStatus, 
            selectedDevelopmentIds,
            showNonPrioritised,
            visibleDevelopments,
        } = this.state;
        let developments: ViewItem[] | undefined;
        if (prioritisationData) developments = prioritisationData.developments;

        const conflicts = this._getConflicts();

        const ready = !loading && 
            activeUpdateProp && 
            visibleDevelopments;

        return (
            <DisplayContext.Consumer>
                {({ darkMode }) => (

                    <div id="devServiceView">
                        <div className={styles.logoContainer}>
                            <img className={styles.logo} src={darkMode ? darkLogo : logo} alt="Development Service Portal Logo" />
                        </div>
                        <HeaderMenu />
                        <div style={{margin: 10}}>
                            { loading === true && (
                                <Spinner label="Loading Developments" />
                            )}
                            { ready && (
                                <>
                                    { this.context.profile.relationships.length > 1 && (
                                        <Dropdown 
                                            label="Business Area / Partner:"
                                            options={this.context.profile.relationships.map((r) => ({
                                                text: r.name,
                                                key: r.id
                                            } as IDropdownOption))} 
                                            selectedKey={businessAreaId}
                                            onChange={(e, option) => {
                                                this.setState({businessAreaId: option?.key as number}, this._loadDevelopments);
                                            }}
                                        />
                                    )}
                                    { error !== undefined && (
                                        <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar> 
                                    )}
                                    <PlatformMenu 
                                        platforms={platforms} 
                                        currentView={currentView} 
                                        onChange={(newView) => {
                                            const { activeFilter: newFilter, activeUpdateProp: newSortProperty } = this._getUpdatedFilter(newView);
                                            this.setState({
                                                currentView: newView,
                                                activeFilter: newFilter,
                                                activeUpdateProp: newSortProperty as PrioritisationUpdateProp
                                            }, this._filterDevelopments);
                                        }}
                                    />
                                    <PrioritisationMenu
                                        onAddAll={this._addAll}
                                        onClearPrioritisedItems={() => this._onClearPrioritisedItems()}
                                        onSaveClick={this._onSaveClick}
                                        onSelectionAdded={this._onSelectionAdded}
                                        onToggleNonPrioritised={this._onToggleNonPrioritised}
                                        onToggleSelection={this._onToggleSelection}
                                        options={{
                                            currentView: currentView,
                                            prioritisation: prioritisationData,
                                            prioritisedItemCount: visibleDevelopments?.filter((d) => Boolean(d[activeUpdateProp])).length,
                                            selectedItemCount: selectedDevelopmentIds?.length,
                                            visibleItemCount: visibleDevelopments?.length,
                                            saveStatus: saveStatus,
                                            selectionEnabled: enableSelection,
                                            showNonPrioritised: showNonPrioritised,
                                            showPublish: currentView === "prioritised" && conflicts.length === 0,
                                        }}
                                    />
                                    { currentView === "Current and Upcoming Work" && (
                                        <CustomerWorkUpdate developments={visibleDevelopments} />
                                    )}
                                    { currentView === "prioritised" && (
                                        <MessageBar messageBarType={MessageBarType.info}>
                                            Resolve any prioritisation conflicts, sort in to a final order and when happy use the <Icon iconName="Send" /> Send button to submit to the Development Service
                                        </MessageBar>
                                    )}
                                    { currentView !== "Current and Upcoming Work" && (
                                        <>
                                            <SortComponent 
                                                intent={currentView === "prioritised" ? SortIntent.PlatformMerge : SortIntent.Prioritise}
                                                // mode={currentView === "Current and Upcoming Work" ? "upcoming" : "developments"}
                                                developments={visibleDevelopments}
                                                // filter={activeFilter}
                                                // onSave={this._onSave}
                                                onSelection={this._onSelection}
                                                sortProperty={activeUpdateProp}
                                                selectedDevelopmentIds={selectedDevelopmentIds}
                                                onUpdate={(updates) => {
                                                    if (activeUpdateProp && this.state.prioritisationData?.developments) {
                                                        let updatedDevelopments = [...this.state.prioritisationData.developments];
                                                        updates.forEach((development, idx) => {
                                                            if (Boolean(development[activeUpdateProp])) {
                                                                const devIdx = developments?.map((d) => d.id).indexOf(development.id);
                                                                if (developments && devIdx && devIdx >= 0) {
                                                                    developments[devIdx][activeUpdateProp] = idx + 1;
                                                                    if (activeUpdateProp === "platformPriority") {
                                                                        developments[devIdx]["businessPriority"] = null;
                                                                    }
                                                                }
                                                            }
                                                        });
                                                        this._updatePrioritisaton({developments: updatedDevelopments}, this._filterDevelopments);
                                                    }
                                                }}           
                                                selectionEnabled={enableSelection}
                                                showNonPrioritised={showNonPrioritised}
                                            />
                                            { conflicts.filter((innerConflictArr) => innerConflictArr.length > 0).length > 0 && (
                                                <ConflictResolver 
                                                    developmentGroups={conflicts} 
                                                    onResolved={this._onResolveConflicts} 
                                                    onDoLater={() => {
                                                        this._onClearPrioritisedItems(() => {
                                                            this.setState({ currentView: "Current and Upcoming Work" }); 
                                                        });
                                                    }} 
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </DisplayContext.Consumer>
        );
    }

    // #region - UI

    private _onToggleNonPrioritised() {
        this.setState({
            showNonPrioritised: !this.state.showNonPrioritised
        });
    }

    // #endregion

    // #region - Conflict handling

    private _getConflicts() {
        const { activeUpdateProp, visibleDevelopments } = this.state;
        let conflicts: ViewItem[][] = [];
        if (visibleDevelopments && activeUpdateProp === "businessPriority") {
            let groups: Record<number,ViewItem[]> = {};
            groups = visibleDevelopments.reduce((grps, development, curIdx, arr) => {
                const currentPriority = development["platformPriority"];
                const hasBusinessPriority = Boolean(development.businessPriority);
                if (grps[currentPriority] && !hasBusinessPriority) {
                    grps[currentPriority].push(development);
                } else {
                    if (!hasBusinessPriority) {
                        grps[currentPriority] = [development];
                    }
                }
                return grps;
            }, groups);

            const groupKeys = Object.keys(groups).sort();
            groupKeys.forEach((key) => {
                const keyNum = parseInt(key, 10);
                if (!isNaN(keyNum) && groups[keyNum].length > 1) {
                    conflicts.push(groups[keyNum]);
                }
            });
        }
        return conflicts;
    }

    private _onResolveConflicts(orderedItems: ViewItem[]) {
        const { activeUpdateProp, prioritisationData, visibleDevelopments: filteredDevelopments } = this.state;
        const developments = prioritisationData?.developments;
        if (!developments || !filteredDevelopments || !activeUpdateProp) return;

        orderedItems.forEach((orderedItem) => {
            const itemIdx = filteredDevelopments.map((d) => d.id).indexOf(orderedItem.id);
            if (itemIdx >= 0) filteredDevelopments[itemIdx][activeUpdateProp] = orderedItem[activeUpdateProp];
            const devIdx = developments.map((d) => d.id).indexOf(orderedItem.id);
            if (devIdx >= 0) developments[devIdx][activeUpdateProp] = orderedItem[activeUpdateProp];
        });
        this.setState({ 
            prioritisationData: { ...prioritisationData, developments },
            saveStatus: "Save",
            visibleDevelopments: this._filterDevelopments(true, developments) 
        });
    }

    // #endregion 

    // #region - Filtering and sorting

    /**
     * Returns a filter function that can be used to update the UI
     * @param currentView 
     * @returns 
     */
    private _getUpdatedFilter(currentView: any) {
        let activeFilter: (d: ViewItem) => boolean;
        switch (currentView) {
            case "Current and Upcoming Work":
                activeFilter = d => d.status.id <=2 || (d.status.id > 5);
                break;
            case "prioritised":
                activeFilter = d => Boolean(d.platformPriority);
                break;
            default:
                activeFilter = d => d.platform === currentView && d.status.id > 2 && d.status.id < 6;
                break;
        }
        const activeUpdateProp: PrioritisationUpdateProp = currentView === "prioritised" ?
            "businessPriority" : "platformPriority";
        return { activeFilter, activeUpdateProp };
    };

    /**
     * Sort function - sorts developments by activeUpdateProp, secondary property, status, and dateSubmitted
     * @param a Left comparator
     * @param b Right comparator
     * @returns 
     */
    private _sortDevelopments(a: ViewItem, b: ViewItem) {
        const { activeUpdateProp } = this.state;

        /*#
          # Sort by prioritisation properties - 
          # ie. platformPriority and businessPriority
          #*/
        
        let primarySortProperty = activeUpdateProp as keyof typeof a;
        let secondarySortProperty = (activeUpdateProp === "businessPriority" ? "platformPriority" : "businessPriority") as keyof typeof a;

        // Move unsorted developments to the bottom
        if (!Boolean(a[primarySortProperty]) && Boolean(b[primarySortProperty])) return 1;
        if (!Boolean(b[primarySortProperty]) && Boolean(a[primarySortProperty])) return -1;

        if (a[primarySortProperty] === b[primarySortProperty]) {
            if (activeUpdateProp === "businessPriority") {
                // if we get here, the primary sort properties are a match, so sort by secondary
                if (!Boolean(a[secondarySortProperty])) return 1;
                if (a[secondarySortProperty] < b[secondarySortProperty]) {
                    return -1;
                } else if (a[secondarySortProperty] > b[secondarySortProperty]) {
                    return 1;
                }
            } else {
                // Status (Review -> Pipeline -> Non-Prioritised)
                const statusOrder = ["New", "More Information Required", "Review", "Pipeline", "Scoping", "In Development", "Testing", "Ready for Deployment", "On Hold", "Non-Prioritised"];
                if (statusOrder.indexOf(a.status.text) < statusOrder.indexOf(b.status.text)) {
                    return -1;
                } else if (statusOrder.indexOf(a.status.text) > statusOrder.indexOf(b.status.text)) {
                    return 1;
                }

                // Date Submitted
                if (a.dateSubmitted < b.dateSubmitted) {
                    return -1;
                } else if (a.dateSubmitted > b.dateSubmitted) {
                    return 1;
                }
            }
        }

        // Otherwise sort developments by primary
        if (a[primarySortProperty] < b[primarySortProperty]) {
            return -1;
        } else if (a[primarySortProperty] > b[primarySortProperty]) {
            return 1;
        }
        return 0;
    }

    /**
     * Filters developments using the filter function specified in this.props.filter
     * @param rtnValue Determines whether the function should return a list of filtered developments. State is updated when this is false.
     * @param passedDevelopmentList If passed, these developments are used rather than this.props.developments
     * @returns 
     */
     private _filterDevelopments(rtnValue?: boolean, passedDevelopmentList?: ViewItem[]) {
        // const { developments, filter, updateProp } = this.props;
        const { prioritisationData, activeFilter, activeUpdateProp, showNonPrioritised } = this.state;

        let developments: ViewItem[];
        if (prioritisationData && prioritisationData.developments) {
            developments = prioritisationData.developments;
        } else {
            return [];
        }

        if (!activeFilter || !activeUpdateProp) return developments;

        // Developments can come from props, or be passed in to the function
        // They come from props when a change is made via click and drag, 
        // but are passed in when another interaction needs to modify
        // the developments in state: ie. the command bar might clear prioritisations, or the conflict resolver
        // component may pass back a new order of developments
        let visibleDevelopments = passedDevelopmentList !== undefined ? 
            passedDevelopmentList.filter(activeFilter) : 
            developments.filter(activeFilter);

        // Applies the filter for the Show Unprioritised toggle button
        if (!rtnValue && activeUpdateProp === "platformPriority" && this.state && !showNonPrioritised) visibleDevelopments = visibleDevelopments.filter((d) => Boolean(d[activeUpdateProp]));

        // Sorts the developments once filtered
        visibleDevelopments.sort(this._sortDevelopments);

        // Account for any gaps in prioritisation ordering and renumber them for the UI.
        // This might be necessary if items were prioritised ie. 1,2,3,4 - and developments ranked 1 & 2 were 
        // moved to In Development. The UI would show 3,4 instead of 1,2. 
        visibleDevelopments.forEach((development, idx) => {
            if (development[activeUpdateProp]) {
                development[activeUpdateProp] = idx + 1;
            }
        });

        // Return the list of developments, or update them in state
        if (rtnValue) {
            return visibleDevelopments;
        } else {
            this.setState({
                visibleDevelopments, 
                showNonPrioritised: activeUpdateProp === "businessPriority" ? true : showNonPrioritised
            });
        }
    }

    // private _refreshFilteredItems() {
    //     this.setState({
    //         visibleDevelopments: this._filterDevelopments(true)
    //     });
    // }

    // #endregion

    // #region - Selection handling

    private _addAll(ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) {
        const { activeUpdateProp, prioritisationData, visibleDevelopments } = this.state;
        const developments = prioritisationData?.developments;
        if (!developments || !visibleDevelopments || !activeUpdateProp) return;

        if (window.confirm('All unprioritised developments will be added to this prioritisation. Continue?')) {
            visibleDevelopments.forEach((development, idx) => {
                visibleDevelopments[idx][activeUpdateProp] = idx + 1;
                const devIdx = developments.map((d) => d.id).indexOf(development.id);
                if (devIdx >= 0) developments[devIdx][activeUpdateProp] = idx + 1;
            });
            this.setState({ 
                prioritisationData: { ...prioritisationData, developments },
                visibleDevelopments: visibleDevelopments 
            });
        }
    }

    private _onSelection(selectedDevelopmentIds: number[]) {
        this.setState({
            selectedDevelopmentIds
        });
    }

    private _onSelectionAdded(ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) {
        const { activeUpdateProp, prioritisationData, selectedDevelopmentIds: selection, visibleDevelopments } = this.state;
        const developments = prioritisationData?.developments;
        if (!developments || !visibleDevelopments || !activeUpdateProp || !selection) return;

        const orderedDevelopments = visibleDevelopments.filter((d) => Boolean(d[activeUpdateProp]));
        const selectedDevelopments = visibleDevelopments.filter((d) => selection.indexOf(d.id) >= 0);
        const remainingDevelopments = visibleDevelopments.filter((d) => 
            orderedDevelopments.map((od) => od.id).indexOf(d.id) < 0 &&
            selectedDevelopments.map((sd) => sd.id).indexOf(d.id) < 0
        );
        orderedDevelopments.push(...selectedDevelopments);
        orderedDevelopments.forEach((fd, idx) => {
            fd[activeUpdateProp] = idx + 1;
            const devIdx = developments.map((d) => d.id).indexOf(fd.id);
            if (devIdx >= 0) developments[devIdx][activeUpdateProp] = idx + 1;
        });
        orderedDevelopments.push(...remainingDevelopments);
        this.setState({ 
            visibleDevelopments: orderedDevelopments, 
            selectedDevelopmentIds: [], 
            enableSelection: false,
            prioritisationData: {...prioritisationData, developments}
        });
    }

    private _onToggleSelection(ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) { 
        this.setState({
            selectedDevelopmentIds: [], 
            enableSelection: true
        });
    }

    // #endregion

    // #region - Loading, Saving and publishing

    private _loadDevelopments() {
        this.setState({loading: true}, async () => {
            if (this.state.businessAreaId) {

                var prioritisationData = await loadDevelopmentsByBusinessArea(this.state.businessAreaId);
                if (!(prioritisationData instanceof Error)) {
                    for (let i = 0; i < prioritisationData.developments.length; i++) {
                        prioritisationData.developments[i].businessPriority = null;
                    }
                    const developments = prioritisationData.developments;
                    let platforms: string[] = [];
                    if (developments && developments.length) {
                        platforms = developments.reduce((prev: string[], cur, curIdx, arr) => {
                            if (prev.indexOf(cur.platform) < 0) {
                                return [...prev, cur.platform];
                            } else {
                                return prev;
                            }
                        }, []);
                    }
                    platforms.sort();
                    const currentView = "Current and Upcoming Work";
                    const { activeFilter, activeUpdateProp } = this._getUpdatedFilter(currentView);
                    this.setState({
                        activeFilter, 
                        activeUpdateProp, 
                        currentView, 
                        loading: false,
                        prioritisationData, 
                        platforms, 
                    }, () => {
                        this.setState({
                            visibleDevelopments: this._filterDevelopments(true, developments),
                        });
                    });
                }
            }
        });
    }

    private _onSaveClick(publish?: boolean) {
        const { prioritisationData, visibleDevelopments } = this.state;
        if (prioritisationData) {
            this.setState({saveStatus: "Saving"}, async () => {
                const previousWasPublished = prioritisationData.published;
                const businessAreaId = prioritisationData.businessAreaId;
                
                // Only pass ID if we are updating a draft or publishing a draft
                let id: number | undefined;
                if (!previousWasPublished && prioritisationData.id >= 0) {
                    id = prioritisationData.id;
                }
    
                let response: IPrioritisation | Error;
                if (publish) {
                    visibleDevelopments.forEach((development, idx) => {
                        if (!Boolean(development.businessPriority)) {
                            development.businessPriority = idx + 1;
                        }
                    });
                    
                    response = await savePriorityInfo(businessAreaId, prioritisationData, false, id);
                } else {
                    response = await savePriorityInfo(businessAreaId, prioritisationData, true, id);
                }
                if (response instanceof Error) {
                    this.setState({
                        saveStatus: "Error",
                        error: response.message
                    });
                } else {
                    this.setState({
                        saveStatus: "Saved",
                        prioritisationData: {...prioritisationData, 
                            businessAreaId: response.businessAreaId,
                            dateCreated: response.dateModified,
                            dateModified: response.dateModified,
                            id: response.id,
                            devService: response.devService,
                            published: response.published,
                            userId: response.userId
                        }
                    });
                }
            });
        }
    }

    // #endregion

    // #region - Amending prioritisation data

    private _updatePrioritisaton(update: Partial<IPrioritisation>, callback?: () => void) {
        const { prioritisationData } = this.state;
        const updatedPrioritisationData = {...prioritisationData, ...update};
        this.setState({
            prioritisationData: updatedPrioritisationData as IPrioritisation,
            saveStatus: "Save"
        }, callback);
    }

    private _onClearPrioritisedItems(callback?: () => void) {
        const { activeUpdateProp, prioritisationData, visibleDevelopments } = this.state;
        const developments = prioritisationData?.developments;
        if (!developments || !visibleDevelopments || !activeUpdateProp) return;
        
        visibleDevelopments.forEach((development, idx) => {
            visibleDevelopments[idx][activeUpdateProp] = null;
            const devIdx = developments.map((d) => d.id).indexOf(development.id);
            if (devIdx >= 0) {
                developments[devIdx][activeUpdateProp] = null;
                if (activeUpdateProp === "platformPriority") {
                    developments[devIdx]["businessPriority"] = null;
                }
            }
        });

        this.setState({
            prioritisationData: { ...prioritisationData, developments },
            enableSelection: false, 
            selectedDevelopmentIds: [], 
            showNonPrioritised: true,
            visibleDevelopments,
        }, callback);
    }

    // #endregion
}

export default CustomerPrioritisation;