import * as React from "react";
import { ActionButton, css, DefaultButton, Icon, PrimaryButton, Spinner, Stack, TextField, Toggle } from "office-ui-fabric-react";

import styles from "./DevelopmentPicker.module.scss";
import { debounce } from "lodash";
import { getDevelopments } from "../../../api";

export interface IDevelopmentOption {
    id: number;
    name: string;
    status: string;
}

export interface IDevelopmentPickerProps {
    selectedDevelopment?: IDevelopmentOption;
    onSelect: (selectedDevelopment?: IDevelopmentOption, update?: string, updatePrivacy?: boolean) => void;
}

export const DevelopmentPicker = (props: IDevelopmentPickerProps) => {
    const [ developments, setDevelopments ] = React.useState<IDevelopmentOption[]>();
    const [ searchedDevelopments, setSearchedDevelopments ] = React.useState<IDevelopmentOption[]>();
    const [ selectedDevelopment, setSelectedDevelopment ] = React.useState<IDevelopmentOption>();
    const [ showClosed, setShowClosed ] = React.useState(false);
    const [ showSearch, setShowSearch ] = React.useState(false);
    const [ searchStr, setSearchStr ] = React.useState<string>();
    const [ includeUpdate, setIncludeUpdate ] = React.useState(false);
    const [ updateText, setUpdateText ] = React.useState<string>();
    const [ updatePrivacy, setUpdatePrivacy ] = React.useState(true);
    const [ loading, setLoading ] = React.useState(true);

    const _loadDevelopments = React.useCallback(async () => {
        const results = await fetch(`/api/developments/my`, {
            headers: {
                'Accept': 'application/json',
            },
            credentials: 'include'
        });
        if (!(results instanceof Error)) {
            let devs = await results.json() as IDevelopmentOption[];
            devs = [...devs.filter(d => d.status === "In Development"), ...devs.filter(d => d.status !== "In Development")];
            setDevelopments(devs);
        }
        setLoading(false);
    }, []);

    const _searchDevelopments = debounce(React.useCallback(async () => {
        if (!searchStr || searchStr.length < 3) {
            setSearchedDevelopments(undefined);   
            return;
        }
        const results = await getDevelopments({
            devTeamView: true,
            name: searchStr
        });
        if (results.success && results.developments.length) {
            setSearchedDevelopments(results.developments.map((d) => ({
                id: d.id,
                name: d.title,
                status: d.status.text
            } as IDevelopmentOption)));
        } else {
            setSearchedDevelopments(undefined);
        }
    }, [searchStr]), 1000);

    React.useEffect(() => {
        _searchDevelopments()
    }, [searchStr]);

    React.useEffect(() => {
        _loadDevelopments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return (
            <Spinner />
        );
    }

    const devOptions = searchedDevelopments ? searchedDevelopments : developments;

    return (
        <React.Fragment>
            <Stack wrap horizontal horizontalAlign="space-between" verticalAlign="center">
                <Stack wrap horizontal verticalAlign="center">
                    <Toggle 
                        disabled={props.selectedDevelopment !== undefined} 
                        label="Include developments" 
                        onText="Show Closed" offText="Hide Closed" 
                        checked={showClosed} 
                        onChange={(e, checked) => setShowClosed(checked)} 
                        styles={{root: {marginBottom: 20, marginTop: 20}}}
                    />
                    <Toggle 
                        disabled={props.selectedDevelopment === undefined} 
                        label="Add text update" 
                        onText="Yes" offText="No" 
                        checked={includeUpdate} 
                        onChange={(e, checked) => setIncludeUpdate(checked)} 
                        styles={{root: {marginLeft: 20, marginBottom: 20, marginTop: 20}}}
                    />
                </Stack>
                <DefaultButton styles={{root: { marginTop: 20 }}} text={`Search${showSearch ? " ✔" : ""}`} onClick={() => setShowSearch(!showSearch)} />
            </Stack>
            { showSearch === true && (
                <TextField styles={{root: { marginBottom: 20 }}} label="Search" placeholder="Enter a development name" onChange={(e, newValue) => {
                    if (!newValue || newValue.trim() === '') {
                        setSearchStr(undefined);
                        return;
                    }
                    setSearchStr(newValue);
                }} />
            )}
            <div className={css(styles.developmentsList, showClosed ? styles.showClosed : undefined)} >
                { devOptions.map((d) => {
                    const isSelected = props.selectedDevelopment && d.id === props.selectedDevelopment.id;
                    const isClosed = /closed/i.test(d.status);
                    return (
                        <div 
                            key={d.id}
                            className={css(styles.development, isClosed ? styles.closed : undefined, isSelected ? styles.s : (props.selectedDevelopment ? styles.ns : undefined))}
                            onClick={() => {
                                setSelectedDevelopment(isSelected ? undefined : d);
                                props.onSelect(
                                    isSelected ? undefined : d, 
                                    includeUpdate ? updateText : undefined, 
                                    includeUpdate ? updatePrivacy : undefined
                                );
                            }} 
                        >
                            {d.name}
                        </div>
                    );
                })}
            </div>
            { includeUpdate === true && (
                <React.Fragment>
                    <TextField multiline 
                        label="Update"
                        value={updateText}
                        onChange={(e, newVal) => {
                            setUpdateText(newVal);
                            props.onSelect(selectedDevelopment, newVal, updatePrivacy);
                        }}
                    />
                    <Stack horizontal verticalAlign="end">
                        <Toggle label="Privacy"
                            checked={updatePrivacy}
                            onChange={(e, checked) => {
                                setUpdatePrivacy(checked);
                                props.onSelect(selectedDevelopment, updateText, checked);
                            }}
                            offText="Public" onText="Private"
                            styles={{root: {marginRight: 10}}}
                        />
                        <div>
                            <Icon style={{fontSize: 20, marginBottom: 8}} iconName={updatePrivacy ? "Lock" : "View"} />
                        </div>
                    </Stack>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default DevelopmentPicker;