import React from "react";

/* types */
import { IDevelopmentItem, IRequestorInfo, IUserLookup } from "../types";
import { FilterMenuItem } from "./components/developmentViews/FilterMenu";

/* Fabric UI */
import {
    IColumn,
    IFacepilePersona,
    PersonaSize,
    TooltipHost,
} from "office-ui-fabric-react";

/* base view */
import BaseView, { IBaseViewState, ViewDisplayMode } from "./BaseView";
import { DevTableColumn } from "./components/developmentViews/DevTable.types";

/* utils */
import { 
    getStatusIconName, 
    getPlatformIconName,
} from "../utils";
import { RouteComponentProps, withRouter } from "react-router";

/* State and Props */
export interface INonPrioritisedViewProps extends RouteComponentProps {
}
export interface INonPrioritisedViewState extends IBaseViewState {}

export class NonPrioritisedView extends BaseView<INonPrioritisedViewProps, INonPrioritisedViewState> {

    protected viewName = "Team View";
    
    constructor(props: INonPrioritisedViewProps) {
        super(props);
        this.isSostenutoView = false;
        this._isDevTeamView = true;

        // init state
        this.state = {
            activeColumns: this._tableConfig,
            editFormTabId: undefined,
            editingItem: undefined,
            items: undefined,
            itemCounts: {},
            loading: true,
            loadingMessage: "Loading",
            statusOptions: undefined,
            displayMode: ViewDisplayMode.Table,
            statusFilter: false,
            toastNotifications: []
        };
    }

    //#region query / filter menu
    protected _menuConfig: FilterMenuItem[] = [
        {
            text: "Non-Prioritised",
            itemQuery: { status: "Non-Prioritised", includeNonPrioritised: true },
            iconName: getStatusIconName("Non-Prioritised"),
            isDefault: true,
        },
    ];
    //#endregion

    //#region table config
    protected _tableConfig: DevTableColumn[] = [
        {
            type: "Icon",
            fieldName: "platform",
            minWidth: 40,
            maxWidth: 40,
            iconConversion: (value: any) => ({
                iconName: getPlatformIconName(value),
                styles: { root: { fontSize: "2.5em", color: "#b1324e" }}
            }),
            mapToText: (item: IDevelopmentItem) => item.platform,
            filters: "UniqueText"
        },
        {
            type: "Date",
            fieldName: "dateSubmitted",
            displayName: "Submitted",
            minWidth: 80,
            maxWidth: 80,
        },
        {
            type: "Text",
            fieldName: "project",
            minWidth: 80,
            maxWidth: 150,
            filters: "UniqueText"
        },
        {
            type: "Text",
            fieldName: "title",
            displayName: "Development",
            minWidth: 100,
        },
        {
            type: "Text",
            fieldName: "description",
            displayName: "Description",
            minWidth: 400,
        },
        {
            type: "Persona",
            fieldName: "combinedRequestors",
            displayName: "Requestors",
            minWidth: 100,
            maxWidth: 200,
            personaConversion: (value: IRequestorInfo) => ({
                size: PersonaSize.size32,
                name: value.contactName,
                data: {
                    email: value.contactEmail
                }
                // secondaryText: value.contactEmail,
                // imageUrl: value.contactPhoto ? `data:${value.contactPhoto.type};base64,${value.contactPhoto.data}` : undefined
            } as IFacepilePersona),
            mapToText: (item: IDevelopmentItem) => {
                return item.combinedRequestors.map((value, i, arr) =>
                    `${value.contactName} ${value.contactEmail}` + (i + 1 < arr.length ? ',' : '') 
                ).join('\n');
            }
        },
        {
            type: "Text",
            fieldName: "businessGroups",
            displayName: "Business Grouping",
            minWidth: 100,
            objectValueMapping: (value: any) => value.name,
            filters: "UniqueText",
            mapToText: (item: IDevelopmentItem) => {
                return item.businessGroups.map((value, i, arr) =>
                    value.name 
                );
            }
        },
        {
            type: "Text",
            fieldName: "platformPriority",
            displayName: "Priority",
            minWidth: 65,
            onRender: (item: IDevelopmentItem) => item.platformPriority?.toString(),
            mapToText: (item: IDevelopmentItem) => item.platformPriority
        },
        {
            type: "Date",
            fieldName: "dueDate",
            displayName: "Due Date",
            minWidth: 80,
            maxWidth: 80
        },
        {
            type: "Text",
            fieldName: "estimatedEffort",
            displayName: "Estimated Effort",
            minWidth: 80,
            maxWidth: 80,
            objectValueMapping: (value: any) => {
                if (!value) return '';
                return `${value} days`;
            },
            onRenderFooterCell: (item, index, column) => {
                return this.state.items.reduce((prev, cur, arr) => {
                    const curVal = parseFloat(cur.estimatedEffort);
                    if (!Number.isNaN(curVal)) {
                        return prev + curVal;
                    } else {
                        return prev;
                    }
                }, 0).toFixed(2) + ' days';
            }
        },
        {
            type: "Text",
            fieldName: "latestUpdate",
            displayName: "Latest Update",
            minWidth: 200,
            onRender: (item?: any, index?: number | undefined, column?: IColumn | undefined) => {
                return (
                    <TooltipHost content="Double click to add an update">
                        <div style={{whiteSpace: "pre-wrap"}} className="latestUpdate" onDoubleClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            this._handleEdit(item, "3");
                            // this.setState({
                            //     ...this.state,
                            //     editFormTabId: "3",
                            //     editingItem: item,
                            // });
                        }}>
                            {item.latestUpdate}
                        </div>
                    </TooltipHost>
                );
            },
        },
        {
            type: "Persona",
            fieldName: "assignedTo",
            minWidth: 100,
            maxWidth: 100,
            filters: "UniqueText",
            personaConversion: (value: IUserLookup) => ({
                size: PersonaSize.size32,
                name: value.name,
                data: {
                    email: value.mail
                }
                // secondaryText: value.mail,
                // imageUrl: value.photo ? `data:${value.photo.type};base64,${value.photo.data}` : undefined
            } as IFacepilePersona),
            mapToText: (item) => item.assignedTo.map((a: any) => a.mail)
        },
    ];
    //#endregion
}

export default withRouter(NonPrioritisedView);