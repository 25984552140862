import * as React from "react";
import { css, Spinner } from "office-ui-fabric-react";
import { IBusinessGroup, IBusinessGroupResponse } from "../../../types";

import styles from "./BAUOrgPicker.module.scss";

export interface IBAUOrgPickerProps {
    selectedPartner?: IBusinessGroup;
    onSelect: (selectedPartner?: IBusinessGroup) => void;
}

export const BAUOrgPicker = (props: IBAUOrgPickerProps) => {
    const [ businessGroups, setBusinessGruops ] = React.useState<IBusinessGroup[]>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ selectedPartner, setSelectedPartner ] = React.useState<IBusinessGroup>();
    const [ loading, setLoading ] = React.useState(true);

    const _loadBusinessGroups = React.useCallback(async () => {
        const response = await fetch(`/api/businessgroup`, {
            headers: { 'accept': 'application/json' },
            credentials: 'include',
        });
        if (response.ok) {
            const businessGroupResponse = await response.json() as IBusinessGroupResponse;
            setBusinessGruops(businessGroupResponse.businessGroups);
        }
        setLoading(false);
    }, []);

    React.useEffect(() => {
        _loadBusinessGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return (
            <Spinner />
        );
    }

    return (
        <React.Fragment>
            <div className={css(styles.busOrgList)} >
                { businessGroups.map((bg) => {
                    const isSelected = props.selectedPartner && bg.id === props.selectedPartner.id;
                    return (
                        <div 
                            key={bg.id}
                            className={css(styles.busOrg, isSelected ? styles.s : (props.selectedPartner ? styles.ns : undefined))}
                            onClick={() => {
                                setSelectedPartner(isSelected ? undefined : bg);
                                props.onSelect(
                                    isSelected ? undefined : bg
                                );
                            }} 
                        >
                            {bg.name}
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default BAUOrgPicker;