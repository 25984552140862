import Moment from "moment";
import React from "react";

import { 
    CommandBar,
    DetailsList,
    IColumn,
    Icon,
    IContextualMenuItem,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    ProgressIndicator,
    Selection,
    SelectionMode,
    Spinner,
    TooltipHost,
} from "office-ui-fabric-react";

import styles from "./Attachments.module.scss";
import { deleteAttachment, getAttachmentsByDevelopmentId, uploadAttachmentToDevelopment } from "../../api";
import { IAttachmentInfo, IAttachmentUpload } from "../../types";

export interface IAttachmentsProps {
    developmentId: number;
}

export interface IAttachmentsState {
    error: string | undefined;
    loading: boolean;
    attachments: IAttachmentInfo[] | undefined;
    isUploading: boolean;
    selectedAttachment: IAttachmentInfo | undefined;
    uploadedPercentage: number | undefined;
}

export class Attachments extends React.Component<IAttachmentsProps, IAttachmentsState> {
    private selection: Selection = new Selection({
        onSelectionChanged: () => {
            this._onSelectionChanged();
        },
    });

    constructor(props: IAttachmentsProps) {
        super(props);
        this._loadAttachments = this._loadAttachments.bind(this);
        this._onSelectionChanged = this._onSelectionChanged.bind(this);
        this._uploadFiles = this._uploadFiles.bind(this);
        this.state = {
            attachments: undefined,
            error: undefined,
            isUploading: false,
            loading: true,
            selectedAttachment: undefined,
            uploadedPercentage: 0,
        };
    }

    public componentDidMount() {
        this._loadAttachments();
    }

    public render() {
        const { attachments, error, isUploading, loading, selectedAttachment, uploadedPercentage } = this.state;

        if (loading) {
            return <Spinner/>;
        }
    
        if (error) {
            return <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>;
        }
    
        if (attachments) {
    
            return (
                <div className={styles.attachments}>
                    <CommandBar
                        items={[
                            {
                                disabled: selectedAttachment === undefined,
                                iconProps: {
                                    iconName: "OpenFolderHorizontal",
                                },
                                key: "open",
                                onClick: (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, item?: IContextualMenuItem | undefined) => {
                                    if (selectedAttachment) {
                                        const url = selectedAttachment.ServerRedirectedEmbedUrl || `https://merseycarenhsuk.sharepoint.com${selectedAttachment.FileRef}`;
                                        window.open(url, "_blank");
                                    }
                                },
                                text: "Open",
                            },
                            {
                                disabled: selectedAttachment === undefined,
                                iconProps: {
                                    iconName: "Delete",
                                },
                                key: "delete",
                                onClick: async (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, item?: IContextualMenuItem | undefined) => {
                                    if (selectedAttachment && window.confirm("Are you sure?")) {
                                        const filename = selectedAttachment.FileLeafRef;
                                        const itemDeleted = await deleteAttachment(filename);
                                        if (itemDeleted) {
                                            this._loadAttachments();
                                        }
                                    }
                                },
                                text: "Delete",
                            },
                        ]}
                    />
                    <DetailsList
                        columns={[
                            {
                                key: "icon",
                                maxWidth: 30,
                                minWidth: 30,
                                name: "",
                                onRender: (item?: IAttachmentInfo, index?: number | undefined, column?: IColumn | undefined) => {
                                    if (item) {
                                        const iconName = this._getFileIcon(item.Title);
                                        return <Icon iconName={iconName} style={{ fontSize: "2em" }}/>;
                                    }
                                },
                            },
                            {
                                fieldName: "Title",
                                key: "name",
                                minWidth: 200,
                                name: "Name",
                                onRender: (item?: IAttachmentInfo, index?: number | undefined, column?: IColumn | undefined) => {
                                    if (item) {
                                        let url: string;
                                        if (item.ServerRedirectedEmbedUrl) {
                                            url = item.ServerRedirectedEmbedUrl;
                                        } else {
                                            url = `https://merseycarenhsuk.sharepoint.com${item.FileRef}`;
                                        }
                                        return (
                                            <TooltipHost content={`Filename: ${item.FileLeafRef}`}>
                                                <a href={url} target="_blank" rel="noopener noreferrer">{item.Title}</a>
                                            </TooltipHost>
                                        );
                                    }
                                },
                            },
                            {
                                key: "modifiedBy",
                                maxWidth: 150,
                                minWidth: 80,
                                name: "Modified By",
                                onRender: (item?: IAttachmentInfo, index?: number | undefined, column?: IColumn | undefined) => {
                                    if (item) {
                                        return item.Editor.Title;
                                    }
                                },
                            },
                            {
                                key: "modified",
                                maxWidth: 120,
                                minWidth: 100,
                                name: "Modified",
                                onRender: (item?: any, index?: number | undefined, column?: IColumn | undefined) => {
                                    if (item) {
                                        const mDate = Moment(item.Modified);
                                        if (mDate.isValid()) {
                                            if (mDate.isDST()) mDate.add(1, "hour");
                                            return mDate.format("DD/MM/YYYY HH:mm");
                                        }
                                    }
                                },
                            },
                        ]}
                        items={attachments}
                        selection={this.selection}
                        selectionMode={SelectionMode.single}
                    />
    
                    { isUploading && (
                        <ProgressIndicator percentComplete={uploadedPercentage} />
                    )}
    
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px", textAlign: "right" }}>
                        <PrimaryButton text="Upload" iconProps={{ iconName: "Upload" }}
                            onClick={() => {
                                (document.getElementById("fileUpload") as HTMLButtonElement).click();
                            }}
                        />
                        <input type="file" id="fileUpload" multiple style={{visibility: "hidden"}} onChange={this._uploadFiles} />
                    </div>
                </div>
            );
        }
        return null;
    }

    private async _uploadFiles() {
        const selectedFiles = (document.getElementById("fileUpload") as HTMLInputElement).files;
        if (selectedFiles !== null) {
            this.setState({
                ...this.state,
                isUploading: true,
                uploadedPercentage: 0,
            });
            const today = Moment().format("YYYY-MM-DD-HH-mm");
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < selectedFiles.length; i++) {
                const file = selectedFiles[i];
                let newFilename = file.name;
                const filenameParts = file.name.match(/(.*)\.(.{3,})/);
                if (filenameParts !== null) {
                    newFilename = this.props.developmentId + "_" + filenameParts[1] + "_" + today + "." + filenameParts[2];
                }
                const fileContent = await this._toBase64(selectedFiles[i]);
                
                // Upload Document
                const documentUploaded = await uploadAttachmentToDevelopment(this.props.developmentId, {
                    content: fileContent, 
                    filename: newFilename,
                    originalFilename: file.name
                } as IAttachmentUpload);
                if (documentUploaded) {
                    const isUploading = !((i + 1) === selectedFiles.length);
                    if (isUploading) {
                        this.setState({
                            ...this.state,
                            isUploading,
                            uploadedPercentage: i / selectedFiles.length,
                        });
                    } else {
                        this._loadAttachments();
                    }
                } else {
                    throw Error("An error occurred while trying to upload documents");
                }
            }
        }
    }

    private _onSelectionChanged() {
        const selection = this.selection.getSelection();
        if (selection && selection.length > 0) {
            this.setState({
                ...this.state,
                selectedAttachment: selection[0] as IAttachmentInfo,
            });
        } else {
            this.setState({
                ...this.state,
                selectedAttachment: undefined,
            });
        }
    }

    private async _loadAttachments() {
        const attachments = await getAttachmentsByDevelopmentId(this.props.developmentId);
        if (attachments && attachments.value) {
            this.setState({
                ...this.state,
                loading: false,
                attachments: attachments.value
            });
        } else {
            this.setState({
                ...this.state,
                loading: false
            });
        }
    }
    private _getFileIcon(filename: string) {
        const match = filename.match(/\.(docx?|xlsx?|pptx?|pubx?|vsdx?|mpp|pdf|png|jpe?g|tiff|bmp|gif|eml|msg)/);
        if (!match) {
            return "TextDocument";
        } else if (match.length > 0) {
            switch (match[1]) {
                case "doc":
                case "docx":
                    return "WordDocument";
                case "ppt":
                case "pptx":
                    return "PowerPointDocument";
                case "xls":
                case "xlsx":
                    return "ExcelDocument";
                case "pub":
                case "pubx":
                    return "PublisherLogo";
                case "vsd":
                case "vsdx":
                    return "VisioDocument";
                case "mpp":
                    return "ProjectDocument";
                case "pdf":
                    return "PDF";
                case "png":
                case "jpg":
                case "jpeg":
                case "tiff":
                case "bmp":
                    return "FileImage";
                case "gif":
                    return "GIF";
                case "eml":
                case "msg":
                    return "OutlookLogo";
            }
        }
    }

    private _toBase64(file: File) {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const result = reader.result as string;
                resolve(result.split(',', 2)[1]);
            };
            reader.onerror = (error: any) => reject(error);
        });
    }
}
