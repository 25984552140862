import React from "react";
import { getInterfaceOptions } from "../../api";

import { 
    ComboBox, 
    IComboBox,
    IComboBoxOption, 
    Spinner, 
} from "office-ui-fabric-react";

export interface IInterfacePickerProps {
    disabled?: boolean;
    onChanged: (newValue: string | undefined) => void;
    value: string | undefined;
}
export interface IInterfacePickerState {
    interfaceOptions: string[];
    loading: boolean;
}

export default class InterfacePicker extends React.Component<IInterfacePickerProps, IInterfacePickerState> {
    constructor(props: IInterfacePickerProps) {
        super(props);
        this._onComboBoxChange = this._onComboBoxChange.bind(this);
        this._onPendingComboBoxChange = this._onPendingComboBoxChange.bind(this);
        this.state = {
            interfaceOptions: [],
            loading: true,
        };
    }
    public componentDidMount() {
        this._loadOptions();
    }
    public render() {
        const { interfaceOptions, loading } = this.state;
        if (loading) {
            return <Spinner />;
        }

        const comboBoxOptions: IComboBoxOption[] = interfaceOptions.map((a) => ({
            key: a, text: a,
        }));

        return (
            <ComboBox
                disabled={this.props.disabled}
                label="Project or Interface"
                defaultSelectedKey={undefined}
                defaultValue={undefined}
                selectedKey={this.props.value}
                allowFreeform={true}
                autoComplete="on"
                options={comboBoxOptions}
                onPendingValueChanged={this._onPendingComboBoxChange}
                onChange={this._onComboBoxChange} 
            />
        );
    }
    private async _loadOptions() {
        const interfaceOptionsResponse = await getInterfaceOptions();
        if (interfaceOptionsResponse) {
            this.setState({
                ...this.state,
                interfaceOptions: interfaceOptionsResponse.options,
                loading: false,
            });
        } else {
            this.setState({
                ...this.state,
                interfaceOptions: [],
                loading: false,
            });
        }
    }
    private _onComboBoxChange(event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) {
        if (!option && value) {
            this.props.onChanged(value);
        }
        if (!value && option) {
            this.props.onChanged(option.text);
        }
    }
    private _onPendingComboBoxChange(option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) {
        if (!option && value) {
            this.props.onChanged(value);
        }
        if (!value && option) {
            this.props.onChanged(option.text);
        }
    }
}
