import { 
    IAttachmentResponse, 
    IAttachmentUpload, 
    IBusinessGroupResponse, 
    IDevelopmentHistoryResponse, 
    IDevelopmentItem, 
    IDevelopmentQuery, 
    IDevelopmentResponse, 
    IDevelopmentsResponse, 
    IInterfaceOptionsResponse, 
    IStatusOptionsResponse, 
    ViewItem 
} from "../types";

export async function getDevelopmentCount(query?: IDevelopmentQuery): Promise<number|Error> {
    const response = await fetch("/api/developments/query/count?" + Date.now(), {
        method: 'POST',
        body: JSON.stringify(query),
        headers: { 
            "Content-Type": "application/json",
            Accept : "application/json",
        },
        credentials: 'include',
    });
    if (response.ok) {
        const countStr = await response.text();
        return parseInt(countStr,10);
    } else {
        return new Error("Unable to get count");
    }
}

export async function getDevelopment(id: number): Promise<ViewItem|Error> {
    const response = await fetch(`/api/developments/${id}`, {
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include'
    });
    if (response.ok) {
        return await response.json();
    } else {
        return new Error(`Failed to load development. Status Code: ${response.status}. ${response.statusText}.`);
    }
}

export async function getDevelopments(query?: IDevelopmentQuery, signal?: AbortSignal): Promise<IDevelopmentsResponse | undefined> {
    try {
        const response = await fetch("/api/developments/query?" + Date.now(), {
            method: 'POST',
            body: JSON.stringify(query),
            signal,
            headers: { 
                "Content-Type": "application/json",
                Accept : "application/json",
            },
            credentials: 'include',
        });
        const json = await response.json();
        if (response.ok) {
            return {
                ...json,
                status: response.status,
                statusText: response.statusText,
            } as IDevelopmentsResponse;
        } else {
            console.error('Developments failed to load', response); 
        }
    } catch (e) {
        console.error(e);
        return {
            success: false,
            errors: [(e as Error).message],
        };
    }
}

export async function saveDevelopment(development: Partial<IDevelopmentItem>): Promise<IDevelopmentResponse | undefined> {
    if (development.id === undefined) {
        // new
        return fetch("/api/developments", {
            method: 'POST',
            body: JSON.stringify(development),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            credentials: 'include',
        }).then((response) => response.json()).catch((err) => err);
    } else {
        // edit
        return fetch("/api/developments/" + development.id + "/update", {
            method: 'POST',
            body: JSON.stringify(development),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            credentials: 'include',
        }).then((response) => response.json()).catch((err) => err);
    }
}

export async function deleteDevelopment(devId: number): Promise<Response> {
    return fetch('/api/developments/' + devId, {
        method: 'DELETE',
        credentials: 'include',
    });
}

export async function getOrganisations(): Promise<IBusinessGroupResponse | undefined> {
    return fetch("/api/businessGroup?" + Date.now(), {
        headers: {
            Accept: "application/json",
        },
        credentials: 'include',
    }).then((response) => {
        return response.json();
    });
}

export async function getStatusOptions(): Promise<IStatusOptionsResponse | undefined> {
    return fetch("/api/statuses?" + Date.now(), {
        headers: {
            Accept: "application/json",
        },
        credentials: 'include',
    }).then((response) => {
        return response.json();
    });
}

export async function getInterfaceOptions(): Promise<IInterfaceOptionsResponse | undefined> {
    return fetch("/api/developments/interfaces?" + Date.now(), {
        headers: {
            Accept: "application/json",
        },
        credentials: 'include',
    }).then((response) => {
        return response.json();
    });
}

export async function uploadAttachmentToDevelopment(devId: number, upload: IAttachmentUpload): Promise<boolean> {
    const response = await fetch(`/api/developments/${devId}/attachments`, {
        method: 'POST',
        body: JSON.stringify(upload),
        headers: {
            Accept: 'application/json',
            "content-type": 'application/json'
        },
        credentials: 'include'
    });
    return response.ok;
}

export async function getAttachmentsByDevelopmentId(devId: number): Promise<IAttachmentResponse | undefined> {
    return fetch(`/api/developments/${devId}/attachments?${Date.now()}`, {
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "x-ReturnUrl": `${window.location.protocol}//${window.location.host}`
        },
        credentials: 'include',
        cache: 'no-cache'
    }).then(async (response) => {
        if (response.ok) {
            return await response.json();
        } else {
            const wwwAuthHeader = response.headers.get('www-authenticate');
            if (wwwAuthHeader) {
                const fm = new Date(); fm.setMinutes(fm.getMinutes() + 5);
                document.cookie = `dp_postconsent_redirect=${escape(window.location.pathname)}; path=/; expires=${fm.toUTCString()}`;
                const consentUri = wwwAuthHeader.split(/,? /).filter(v => /consentUri/.test(v)).map((v) => v.replace(/consentUri="(.*)"/, '$1'));
                if (consentUri.length) {
                    consentUri[0] = consentUri[0].replace(/(redirect_uri=)[^&]+/, `${window.location.protocol}//${window.location.host}`);
                    window.location.href = consentUri[0];
                }
            }
        }
    });
}

export async function deleteAttachment(filename: string): Promise<boolean> {
    return fetch(`/api/developments/deleteAttachment/${filename}`, {
        method: 'POST',
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "x-ReturnUrl": `${window.location.protocol}//${window.location.host}`
        },
        credentials: 'include',
        cache: 'no-cache'
    }).then(async (response) => {
        return response.ok;
    });
}

export async function getHistoryByDevelopmentId(devId: number): Promise<IDevelopmentHistoryResponse | undefined> {
    return fetch("/api/developments/" + devId + "/history?" + Date.now(), {
        headers: {
            Accept: "application/json",
        },
        credentials: 'include',
    }).then((response) => {
        return response.json();
    });
}