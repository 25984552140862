import * as React from "react";
import {
    mergeStyles,
    Icon,
    IStyle,
    TooltipHost
} from "office-ui-fabric-react";
import { DisplayContext } from "../../DisplayContext";

export interface ICompactDateIconProps {
    iconName: string;
    tooltip: string;
    dateStr: string;
    redIfPast?: boolean;
}

/** Provides an Office UI icon, which when hovered shows the date specified by dateStr */
export const CompactDateIcon = (props: ICompactDateIconProps) => {
    const displayContext = React.useContext(DisplayContext);

    if (!props.dateStr || props.dateStr.trim() === "") return null;
    const date = new Date(props.dateStr);
    if (date.toString() === "Invalid Date") return null;

    const shortDateStr = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2);

    let iconStyle: IStyle;
    if (props.redIfPast && date < new Date()) {
        iconStyle = { color: displayContext.darkMode ? "#e28388" : "red" };
    }

    return (
        <TooltipHost content={<span><strong>{props.tooltip}</strong><br/>{date.toLocaleDateString()}</span>}>
            <div className={mergeStyles(iconStyle, {marginRight: 20})}>
                <Icon styles={{root: iconStyle}} iconName={props.iconName} /> {shortDateStr}
            </div>
        </TooltipHost>
    );
};

export default CompactDateIcon;