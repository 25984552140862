import React, { Suspense } from "react";
import Config from "../config";
import * as API from "../api";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { UserContext } from "../UserContext";

/* assets: external images and styles */
import logo from "../assets/dev_service_compact.png";
import darkLogo from "../assets/dev_service_compact_dark.png";
import styles from "./BaseView.module.scss";

/* types and util functions */
import { ISostenutoItem } from "../api/sostenuto.types";
import { IStatusChangeDevelopmentUpdate } from "./dialogs/statusChangeDialog";
import { 
    IDevelopmentItem, 
    IRequestorInfo, 
    IStatusOption, 
    IStatusOptionsResponse, 
    IUserLookup ,
    IDevelopmentResponse,
    IOLITDevelopmentItem,
    ViewItem,
    IDevelopmentQuery,
} from "../types";
import { 
    getBusinessDays 
} from "../utils";

/* view components */
import HeaderMenu from "./components/Header2";
import { DevTable, DevTableColumn } from "./components/developmentViews/DevTable";
import { DevListCompact } from "./components/developmentViews/DevListCompact";
import { FilterMenu, FilterMenuItem, ISortConfig } from "./components/developmentViews/FilterMenu";
import { ToastNotifications, INotification } from "./components/ToastNotifications";
import { IColumn, Icon, Panel, Spinner, Stack, TooltipHost } from "office-ui-fabric-react";
import { GanttWrapper } from "./components/developmentViews/GanttWrapper";

/* form */
import { DevelopmentFormMode } from "../developmentForm/DevelopmentForm";
import { RouteComponentProps } from "react-router";
import { getDevelopment } from "../api";
import { DisplayContext } from "../DisplayContext";
import { IUserContext } from "../types/Users";
const DevelopmentForm = React.lazy(() => import("../developmentForm/DevelopmentForm2"));

declare var ResizeObserver: any;

export enum ViewDisplayMode {
    Compact,
    Table,
    Gantt
}

export interface IBaseViewParams {
    filter: string;
    view: string;
    tabIdx: string;
    development: string | number;
}

export const ViewContext = React.createContext<IBaseViewParams>(undefined);

export interface IBaseViewState {
    activeColumns: DevTableColumn[];
    editingItem: Partial<IDevelopmentItem> | undefined;
    editFormTabId?: string;
    statusOptions: IStatusOption[] | undefined;
    itemCounts: Record<string,number>;
    items?: (IDevelopmentItem | IOLITDevelopmentItem | ISostenutoItem)[];
    loading?: boolean;
    loadingMessage?: string;
    displayMode: ViewDisplayMode;
    statusFilter: boolean;
    toastNotifications: INotification[];
    initialSortConfig?: ISortConfig;
    showGantt?: boolean;
}


export default class BaseView<P extends RouteComponentProps, S extends IBaseViewState> extends React.Component<P, S> {
    // declare context: React.ContextType<typeof UserContext>;
    public static contextType: React.Context<IUserContext> = UserContext;

    private resizeObserver: any = null;
    private prevWidth = window.innerWidth;

    private keys: string[] = [];

    protected viewName = "";

    private _abortController: AbortController = new AbortController();

    public isSostenutoView: boolean = false;

    protected _tableConfig: DevTableColumn[] = [];

    protected _menuConfig: FilterMenuItem[] = [];

    protected _isDevTeamView: boolean;

    private _hubConnection: HubConnection = null;

    constructor(props: P) {
        super(props);

        this._calculateCompletionRAG = this._calculateCompletionRAG.bind(this);
        this._onCompletionOrClose = this._onCompletionOrClose.bind(this);
        this._handleEdit = this._handleEdit.bind(this);
        this._loadData = this._loadData.bind(this);
        this._onItemDeleted = this._onItemDeleted.bind(this);
        this._onKeyDown = this._onKeyDown.bind(this);
        this._onKeyUp = this._onKeyUp.bind(this);
        this._navigateDevelopmentForm = this._navigateDevelopmentForm.bind(this);
        this._processStatusUpdateResponse = this._processStatusUpdateResponse.bind(this);
        this._renderReviewCol = this._renderReviewCol.bind(this);
    }

    public componentDidMount() {
        if (this.viewName.trim() !== "") {
            document.title = "IM Development Service | Delivery Plan | " + this.viewName;
        }

        // SignalR
        this._initialiseSignalR();

        // Keyboard shortcuts
        document.addEventListener('keydown', this._onKeyDown);
        document.addEventListener('keyup', this._onKeyUp);

        // Prepare to load default filter menu item, or filter selected via routing URL
        let defaultMenuItem = this._menuConfig.filter((m) => m.isDefault)[0];
        if ((this.props.match.params as any).filter) {
            defaultMenuItem = this._menuConfig.filter((m) => m.text.toLowerCase().replace(/\s/g, '-') === (this.props.match.params as any).filter)[0];
        }

        // Load items
        if (defaultMenuItem && Object.keys(defaultMenuItem).indexOf('itemQuery')) {
            const query: IDevelopmentQuery = { devTeamView: this._isDevTeamView || false, ...defaultMenuItem.itemQuery};
            if (query) {
                this._loadData(query, "Loading " + defaultMenuItem.text, defaultMenuItem);
                this._getItemCounts();
            }
        }

        // Handle resize events
        this.resizeObserver = new ResizeObserver(() => {
            const displayMode = window.innerWidth < 650 ? ViewDisplayMode.Compact : ViewDisplayMode.Table;
            if (this.state.displayMode !== displayMode) {
                this.setState({
                    ...this.state,
                    displayMode
                });
            }
        });
        this.resizeObserver.observe(document.body);
    }

    public async componentWillUnmount() {
        document.removeEventListener('keydown', this._onKeyDown);
        document.removeEventListener('keyup', this._onKeyUp);
        await this._hubConnection.stop();
        this.resizeObserver.disconnect();
    }

    public componentDidUpdate(prevProps: P, prevState: S) {
        // If the view's menu filter has changed, reload developments
        if ((prevProps.match.params as any).filter !== (this.props.match.params as any).filter) {
            const matchingMenuItem = this._menuConfig.filter((m) => m.text.toLowerCase().replace(/\s/g, '-') === (this.props.match.params as any).filter)[0];

            if (matchingMenuItem && Object.keys(matchingMenuItem).indexOf('itemQuery')) {
                const query: IDevelopmentQuery = { devTeamView: this._isDevTeamView || false, ...matchingMenuItem.itemQuery};
                if (query) {
                    this._loadData(query, "Loading " + matchingMenuItem.text, matchingMenuItem);
                    this._getItemCounts();
                }
            }
        }
    }

    public render() {
        
        const {  activeColumns, displayMode, items, loading, loadingMessage, showGantt, statusFilter, statusOptions, initialSortConfig } = this.state; 

        return (
            <DisplayContext.Consumer>
                {({ darkMode }) => (
                    <ViewContext.Provider value={(this.props.match.params as IBaseViewParams)}>
                        <div id="devServiceView">
                            <ToastNotifications notifications={this.state.toastNotifications} onExpiry={(idx) => {
                                const updatedNotifications = this.state.toastNotifications.filter((n) => n.time !== idx);
                                this.setState({
                                    ...this.state,
                                    toastNotifications: updatedNotifications
                                });
                            }} />
                            <div className={styles.logoContainer}>
                                <img className={styles.logo} src={darkMode ? darkLogo : logo} alt="Development Service Portal Logo" />
                            </div>
                            <HeaderMenu />
                            {/* <HeaderComponent pageTitle={viewName} type="Grey" user={this.state.currentUser} /> */}
                            <FilterMenu 
                                items={items}
                                itemCounts={this.state.itemCounts}
                                menuItems={this._menuConfig}
                                showCounts={true}
                                selectedFilter={(this.props.match.params as any).filter}
                                onItemClick={(menuItem) => {
                                    // abort any previous requests
                                    this._abortController.abort();
                                    this._abortController = new AbortController();
                                    // if menu item has itemQuery, reload data
                                    if (Object.keys(menuItem).indexOf("itemQuery") >= 0) {
                                        const itemQuery = (menuItem as any).itemQuery;
                                        if (itemQuery) this._loadData(itemQuery, "Loading " + menuItem.text, menuItem);
                                    // if it has href, navigate to it
                                    } else if (Object.keys(menuItem).indexOf("href") >= 0) {
                                        const href = (menuItem as any).href;
                                        window.location.pathname = href;
                                    }
                                }}
                            />
                            { loading === true && (
                                <Spinner label={loadingMessage} />
                            )}
                            { showGantt === true && (
                                <GanttWrapper developments={items} onClick={this._handleEdit} />
                            )}
                            { Boolean(showGantt) === false && (
                                <>
                                    { loading === false && displayMode === ViewDisplayMode.Compact && (
                                        <DevListCompact 
                                            items={items} 
                                            statusFilter={statusFilter} 
                                            onEdit={this._handleEdit}
                                            onNew={() => {
                                                this.props.history.push(
                                                    `/view/${(this.props.match.params as IBaseViewParams).view}/${(this.props.match.params as IBaseViewParams).filter}/development/new/tab/1`
                                                );
                                            }}
                                        />
                                    )}
                                    { loading === false && displayMode === ViewDisplayMode.Table && (
                                        <DevTable 
                                            columns={activeColumns}
                                            items={items}
                                            className={styles.developments}
                                            viewLabel="Team View"
                                            statusOptions={statusOptions}
                                            onNew={() => {
                                                this.props.history.push(
                                                    `/view/${(this.props.match.params as IBaseViewParams).view}/${(this.props.match.params as IBaseViewParams).filter}/development/new/tab/1`
                                                );
                                            }}
                                            onEdit={this._handleEdit}
                                            sortConfig={initialSortConfig}
                                        />
                                    )}
                                </>
                            )}
                            { this._renderForm() }
                        </div>
                    </ViewContext.Provider>
                )}
            </DisplayContext.Consumer>
        );
    }

    /** Sets up the connection to the SignalR hub on the server, allowing live updates */
    private _initialiseSignalR() {
        this._hubConnection = new HubConnectionBuilder()
            .withUrl(`${Config.BaseUrl}/DevHub`)
            .withAutomaticReconnect([0, 1000, 5000, null])
            .configureLogging(LogLevel.Information)
            .build();

        // When the server reports a development has been added or edited
        this._hubConnection.on("NotifyDevEdit", (user: string, userMail: string, development: string, developmentId: number, newItem: boolean) => {
            const updatedToastNotifications = this.state.toastNotifications;
            updatedToastNotifications.push({ text: `${user} ${newItem ? "created" : "updated"} the development '${development}'` } as INotification);
            this.setState({
                ...this.state,
                toastNotifications: updatedToastNotifications,
            }, () => {
                if (this.state.items.filter((d) => d.id === developmentId)) {
                    // If this development is already open in a form and the update was made by someone else
                    const regex = new RegExp(`development/${developmentId}/?.*`);
                    if (regex.test(window.location.href) && userMail !== this.context.profile.email.toLowerCase()) {
                        if (window.confirm('This development has just been modified. Press OK to reload.')) {
                            window.location.reload();
                        }
                    } else {
                        this._refreshUpdatedItem(developmentId);
                    }
                } else {
                    this._refreshUpdatedItem(developmentId);
                }
            });
        });

        this._hubConnection.on("NotifyDevDeletion", (user: string, userMail: string, development: string, developmentId: number) => {
            const updatedToastNotifications = this.state.toastNotifications;
            updatedToastNotifications.push({ text: `${user} deleted the development '${development}'` } as INotification);
            this.setState({
                ...this.state,
                toastNotifications: updatedToastNotifications,
            }, () => {
                if (this.state.items.filter((d) => d.id === developmentId)) {
                   // If this development is already open in a form and the update was made by someone else
                   const regex = new RegExp(`development/${developmentId}/?.*`);
                   if (regex.test(window.location.href) && userMail !== this.context.email.toLowerCase()) {
                       if (window.confirm('This development has just been deleted by another user (but can be recovered). Press OK to reload.')) {
                           window.location.reload();
                       }
                   } else {
                       this._refreshDeletedItem(developmentId);
                   }
                }
            });
        });

        // When the server reports a development has been given a dev update (text update)
        this._hubConnection.on("NotifyDevUpdate", (user: string, development: string, developmentId: number) => {
            const updatedToastNotifications = this.state.toastNotifications;
            updatedToastNotifications.push({ text: `${user} added an update to the development '${development}'` } as INotification);
            this.setState({
                ...this.state,
                toastNotifications: updatedToastNotifications,
            }, () => {
                if (this.state.items.filter((d) => d.id === developmentId)) {
                    const regex = new RegExp(`development/${developmentId}/tab/3`);
                    // If the development is already open in a form, on the update tab - trigger a refresh of the view
                    if (regex.test(window.location.href)) {
                        const event = new Event("updateAdded");
                        window.dispatchEvent(event);
                        this._refreshUpdatedItem(developmentId);
                    } else {
                        this._refreshUpdatedItem(developmentId);
                    }
                }
            });
        });

        // When the server reports a development has had an update deleted
        this._hubConnection.on("NotifyDevUpdateDeletion", (user: string, development: string, developmentId: number) => {
            const updatedToastNotifications = this.state.toastNotifications;
            updatedToastNotifications.push({ text: `${user} removed an update from the development '${development}'` } as INotification);
            this.setState({
                ...this.state,
                toastNotifications: updatedToastNotifications,
            }, () => {
                if (this.state.items.filter((d) => d.id === developmentId)) {
                    const regex = new RegExp(`development/${developmentId}/tab/3`);
                    // If the development is already open in a form, on the update tab - trigger a refresh of the view
                    if (regex.test(window.location.href)) {
                        const event = new Event("updateAdded");
                        window.dispatchEvent(event);
                        this._refreshUpdatedItem(developmentId);
                    } else {
                        this._refreshUpdatedItem(developmentId);
                    }
                }
            });
        });

        // start the connection
        this._hubConnection.start();
    }

    private async _refreshDeletedItem(developmentId: number) {
        let items = [...this.state.items];
        items = items.filter((i) => i.id !== developmentId);
        this.setState({
            ...this.state,
            items
        });
    }

    /** When the page is notified a development has updated, this method adds it to the current view or updates it if appropriate */
    private async _refreshUpdatedItem (developmentId: number) {
        // Get the current view filter
        let activeFilter: string;
        if (this.props.match.params && (this.props.match.params as any)["filter"] &&
            (this.props.match.params as any)["filter"].trim() !== "") {
            activeFilter = (this.props.match.params as any)["filter"].trim();
        }

        // Get the updated item and status
        const response = await getDevelopment(developmentId);
        if (!(response instanceof Error)) {
            let development: ViewItem = response;
            const developmentStatus: string = development.status ? development.status.text : undefined;
    
            // Determine if the updated item should be in the current view
            let shouldBeInView = true;
            if (this._menuConfig) {
                // Load the config for the current view filter
                const matchingViews = this._menuConfig.filter((item) => item.text.toLowerCase().replace(/\s/g, '-') === activeFilter);
                if (matchingViews.length) {
                    const currentView = matchingViews[0];
                    // If the status of this development matches the active filter
                    if (currentView.itemQuery) {
                        if (currentView.itemQuery.status) {
                            const statuses = currentView.itemQuery.status.split(" || ");
                            if (developmentStatus && statuses.indexOf(developmentStatus) < 0) {
                                shouldBeInView = false;
                            } 
                        } else if (currentView.itemQuery.assignedToMe === true) {
                            if (development.assignedTo.map((at: IUserLookup) => at.mail.toLowerCase()).indexOf(this.context.email.toLowerCase()) < 0) {
                                shouldBeInView = false;
                            } 
                        }
                    }
                }
            }
            if (shouldBeInView) {
                development = this._combineRequestors([development])[0];
                const items = [...this.state.items];
                const itemIdx = items.map((i) => i.id).indexOf(developmentId);
                if (itemIdx >= 0) {
                    items[itemIdx] = development;
                } else {
                    items.push(development);
                }
                this.setState({
                    ...this.state,
                    items
                });
            }
        }
    }

    protected _handleEdit(item: any, tabId?: string) {
        let url = `/view/${(this.props.match.params as IBaseViewParams).view}/${(this.props.match.params as IBaseViewParams).filter}/development/${item.id}`;
        if (tabId) url += `/tab/${tabId}`;
        this.props.history.push(url);
    }

    private _renderForm() {
        const viewParams = (this.props.match.params as IBaseViewParams);
        if (this.state.loading || this.state.items === undefined || this.state.items.length === 0)
            return null;
        if (viewParams.development !== undefined) {
            const developmentId = Number(viewParams.development);
            let mode: DevelopmentFormMode;
            if (viewParams.development === "new") {
                mode = DevelopmentFormMode.NEW;
            } else if (!Number.isNaN(developmentId)) {
                mode = DevelopmentFormMode.EDIT;
            }
            if (mode === undefined) return null;
            return (
                <Suspense fallback={<Panel isOpen={true}>
                    <Spinner />
                </Panel>}>
                    <DevelopmentForm 
                        customer={!this._isDevTeamView}
                        developmentId={mode === DevelopmentFormMode.NEW ? undefined : developmentId} 
                        tabKey={(this.props.match.params as IBaseViewParams).tabIdx}
                        onNavigate={(ascending) => {
                            this._navigateDevelopmentForm(ascending);
                        }}
                    />
                </Suspense>
            );
        }
    }

    protected _renderReviewCol(item: any, index: number, column: IColumn): React.ReactNode {
        const fieldName = column.fieldName;
        const today = new Date();
        const oneWrkWeek = new Date();
        const darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        oneWrkWeek.setDate(oneWrkWeek.getDate() + 5);
        if (item[fieldName]) {
            const isOpen = !/Live|Closed/i.test((item as IDevelopmentItem).status?.text);
            let date = new Date(item[fieldName]);
            let dateStr = date.toLocaleDateString();
            let pastDate = date <= today;
            let imminentDate = date > today && date < oneWrkWeek;
            if (isOpen && pastDate === true) {
                return (
                    <TooltipHost content="Review is overdue">
                        <Stack style={{color: darkMode ? "#e28388" : "red"}} horizontal verticalAlign="center">
                            { dateStr } <Icon style={{marginLeft: 5, fontSize: "1.2em"}} iconName="WarningSolid" />
                        </Stack>
                    </TooltipHost>
                );
            } else if (isOpen && imminentDate === true) {
                return (
                    <TooltipHost content="Review due soon">
                        <Stack style={{color: "#ff8f00"}} horizontal verticalAlign="center">
                            { dateStr } <Icon style={{marginLeft: 5, fontSize: "1.2em"}} iconName="WarningSolid" />
                        </Stack>
                    </TooltipHost>
                );
            } else {
                return dateStr;
            }
        }
        return null;
    }

    private _onCompletionOrClose(updatedDevelopment?: IDevelopmentItem, newItemAdded?: boolean) {
        if (updatedDevelopment && newItemAdded) {
            // new development added
            window.location.reload();
        } else if (updatedDevelopment && updatedDevelopment.id) {
            let items = [...this.state.items];
            // const filters = [...this.state.filters];
            let matchingItemIdx = items.map((item) => item.id).indexOf(updatedDevelopment.id);
            if (matchingItemIdx >= 0) {
                items[matchingItemIdx] = this._calculateCompletionRAG([{...updatedDevelopment}])[0];
            }
            this.setState({
                ...this.state,
                editFormTabId: undefined,
                editingItem: undefined,
                items,
            });
        } else {
            this.setState({
                ...this.state,
                editFormTabId: undefined,
                editingItem: undefined,
            });
        }
    }

    protected async _getItemCounts() {
        const itemCounts: Record<string,number> = {};
        if (this._menuConfig) {
            for (let i = 0; i < this._menuConfig.length; i++) {
                const menuItemName = this._menuConfig[i].text;
                // const menuItemQuery = (this._menuConfig[i] as any).itemQuery;
                const menuItemQuery: IDevelopmentQuery = { devTeamView: this._isDevTeamView || false, ...(this._menuConfig[i] as any).itemQuery};
                if (menuItemQuery) {
                    const count = await API.getDevelopmentCount(menuItemQuery);
                    if (!(count instanceof Error)) {
                        itemCounts[menuItemName] = count;
                    }
                }
            }
        }
        this.setState({
            ...this.state,
            itemCounts
        });
    }

    /**
     * Loads data on page load and when an item is added or modified
     */
    protected _loadData(query?: IDevelopmentQuery, loadingMessage?: string, menuItem?: FilterMenuItem) {
        this.setState({
            ...this.state,
            loading: true,
            loadingMessage: loadingMessage ? loadingMessage : "Loading"
        }, async () => {
            let showGantt: boolean;
            if (menuItem.ganttView) {
                showGantt = menuItem.ganttView;
            }
            const statusFilter = query.status !== undefined;
            const data = await API.getDevelopments(query, this._abortController.signal);
            if (!(data instanceof Error)) {
                if (data.developments) {
                    const statusOptions = (await API.getStatusOptions() as IStatusOptionsResponse).options;
                    let items; 
                    if (data) {
                        items = this._calculateCompletionRAG(data.developments);
                        items = this._combineRequestors(data.developments);
                    }
                    if (data && statusOptions) {
                        this.setState({
                            ...this.state,
                            activeColumns: menuItem && menuItem.columnFilter ? menuItem.columnFilter(this._tableConfig) : this._tableConfig,
                            items,
                            statusOptions,
                            loading: false,
                            loadingMessage: undefined,
                            statusFilter,
                            initialSortConfig: menuItem.initialSort,
                            showGantt
                        });
                    }
                } else {
                    if (data.errors.filter((e) => e === "MicrosoftIdentityWebChallengeUserException").length > 0) {
                        window.location.pathname = '/MicrosoftIdentity/Account/SignIn';
                    }
                    console.error('An error occured while fetching data', data);
                    // this.props.history.push('/');
                }
            } else {
                console.error(data);
            }
        });
    }

    private _onKeyDown(e: KeyboardEvent) {
        if (this.keys.indexOf(e.key) < 0) {
            this.keys.push(e.key);
        }
    }

    private _navigateDevelopmentForm(ascending: boolean) {
        const { items } = this.state;
        const viewParams = this.props.match.params as IBaseViewParams;
        const developmentIdx = items.map((i) => i.id.toString()).indexOf(viewParams.development);
        let nextIdx = developmentIdx + (ascending ? 1 : -1);
        if (nextIdx >= items.length) {
            nextIdx = nextIdx % items.length;
        }
        if (nextIdx < 0) {
            nextIdx = items.length + nextIdx;
        }
        const nextItem = items[nextIdx];
        let url = `/view/${viewParams.view}/${viewParams.filter}/development/${nextItem.id}`;
        if (viewParams.tabIdx) {
            url += `/tab/${viewParams.tabIdx}`;
        }
        this.props.history.push(url);
    }

    private _onKeyUp(e: KeyboardEvent) {
        const { items } = this.state;
        const viewParams = this.props.match.params as IBaseViewParams;
        this.keys = this.keys.filter((k) => k !== e.key);
        if (e.key === "Escape" && viewParams.development !== undefined) {
            this.props.history.push(
              `/view/${viewParams.view}/filter/${viewParams.filter}`  
            );
        }
        const shiftKey = this.keys.indexOf("Shift") >= 0;
        const ctrlKey = this.keys.indexOf("Control") >= 0;
        if (/input|textarea/i.test((e.target as HTMLElement).nodeName) || (e.target as HTMLElement).isContentEditable) {
            return true;
        }
        if (viewParams.development && ctrlKey && !shiftKey) {
            let direction = true, developmentNav = false;
            if (e.key === "Right" || e.key === "ArrowRight") {
                e.preventDefault();
                e.stopPropagation();
                developmentNav = true;
            }
            if (e.key === "Left" || e.key === "ArrowLeft") {
                e.preventDefault();
                e.stopPropagation();
                direction = false;
                developmentNav = true;
            }
            if (developmentNav && items.length > 0) {
                this._navigateDevelopmentForm(direction);
            }
        } 
        if (viewParams.development && ctrlKey && shiftKey) {
            let direction = true, tabNav = false;
            if (e.key === "Right" || e.key === "ArrowRight") {
                e.preventDefault();
                e.stopPropagation();
                tabNav = true;
            }
            if (e.key === "Left" || e.key === "ArrowLeft") {
                e.preventDefault();
                e.stopPropagation();
                direction = false;
                tabNav = true;
            }
            if (tabNav) {
                let curTab = viewParams.tabIdx;
                if (curTab === undefined) curTab = "0";
                const tabId = Number(curTab);
                if (!Number.isNaN(tabId)) {
                    let nextTab = tabId + (direction ? 1 : -1);
                    if (nextTab >= 6) {
                        nextTab = nextTab % 6;
                    }
                    if (nextTab < 0) {   
                        nextTab = 6 + nextTab;
                    }
                    this.props.history.push(
                        `/view/${viewParams.view}/${viewParams.filter}/development/${viewParams.development}/tab/${nextTab}`
                    );
                }
            }
        }
    }

    protected _onItemDeleted(id: number) {
        let items = [...(this.state as IBaseViewState).items];
        items = items.filter((a) => a.id !== id);
        this.setState({
            ...this.state,
            items,
        });
    }

    protected _onLatestUpdateAdded(id: number, updatedDevelopment: IDevelopmentItem) {
        const items = [...(this.state as IBaseViewState).items];
        let matchingItemIdx = items.map((item) => item.id).indexOf(id);
        if (matchingItemIdx >= 0) {
            items[matchingItemIdx] = updatedDevelopment;
        }
        if (matchingItemIdx >= 0) {
            this.setState({
                ...this.state,
                items,
            });
        }
    }

    protected _processStatusUpdateResponse(response: IDevelopmentResponse, developmentId: number, newStatus: IStatusOption, update?: IStatusChangeDevelopmentUpdate) {
        if (!(response instanceof Error) && response.success) {
            const items = [...(this.state as IBaseViewState).items];
            let matchingItemIdx = items.map((item) => item.id).indexOf(developmentId);
            if (matchingItemIdx >= 0) {
                items[matchingItemIdx] = response.development;
            }
            this.setState({
                ...this.state,
                items,
            });
        } else {
            if ((response as any).message) {
                alert('Failed to update status: ' + (response as any).message);
            }
        }
    }

    protected _combineRequestors(items: ViewItem[] | IDevelopmentItem[] | IOLITDevelopmentItem[]): ViewItem[] | IDevelopmentItem[] | IOLITDevelopmentItem[] {
        for (let i = 0; i < items.length; i++) {
            const combinedRequestors: IRequestorInfo[] = [];
            const item = items[i];
            if (item.requestedBy != null && item.requestedBy.trim() !== "") {
                if (combinedRequestors.filter((a) => a.contactName === item.requestedBy).length === 0) {
                    combinedRequestors.push({
                        contactEmail: item.contactEmail,
                        contactName: item.requestedBy,
                        contactNumber: item.contactNumber,
                        contactType: "Manual",
                    });
                }
            } 
            if (item.requestors && item.requestors.length > 0) {
                item.requestors.forEach((requestor: any) => {
                    if (combinedRequestors.filter((a) => a.contactID === requestor.id).length === 0) {
                        combinedRequestors.push({
                            contactID: requestor.id,
                            contactLogin: requestor.mail,
                            contactName: requestor.name,
                            contactType: "MSGraph",
                            contactPhoto: requestor.photo
                        });
                    }
                });
            }
            item.combinedRequestors = combinedRequestors;
            items[i] = item;
        }
        return items;
    }

    /**
     * Calculates a RAG for each item based on the proximity to the items End Date and Estimated Days Remaining
     * @param items Development Service Developments
     */
    protected _calculateCompletionRAG(items: IDevelopmentItem[] | IOLITDevelopmentItem[]): IDevelopmentItem[] | IOLITDevelopmentItem[] {
        
        // Return values will be used to colour each item... 
        // We do this by recording the SASS/CSS class to be used against each item in the CompletedRAGClass property of each item
        
        // Date calculations are with respect to distance from today...
        const today = new Date();
        
        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            let barClass: string | undefined;
            const percentageComplete = item.complete;
            if (percentageComplete) {
                // We default to a gray bar for any not in the critical phases of delivery
                barClass = styles.barGrey;
            } else {
                // Those without a value we don't have to worry about
                continue;
            }

            const totalDays = item.estimatedEffort || 0;
            const endDate = item.estimatedEnd ? new Date(item.estimatedEnd) : undefined;
            const status = item.status;

            // If the development is in any of these phases, we want to assign a RAG colour
            if (status && (status.text === "In Development" || status.text === "Testing" || status.text === "Ready for Deployment" || status.text === "On Hold")) {
                if (totalDays > 0 && endDate) {
                    const estimatedDaysCompleted = totalDays / 100 * percentageComplete;
                    const estimatedDaysRemaining = totalDays - estimatedDaysCompleted;
                    const daysToEndDate = getBusinessDays(endDate, today);

                    // The default state for items in these phases is green, unless...
                    barClass = styles.barGreen;

                    // If the Estimated End Date has passed, we colour the item dark red
                    if (daysToEndDate < 0 && percentageComplete < 100) {
                        barClass = styles.barDarkRed;
                    }
                    if (daysToEndDate >= 0) {
                        // If there are fewer days to the end date than the estimated work (in days) remaining, we colour the item red
                        if (daysToEndDate <= estimatedDaysRemaining) {
                            barClass = styles.barRed;
                        } else {
                            // If we are within two days of the item turning red, we colour the item amber
                            if (daysToEndDate > estimatedDaysRemaining && (daysToEndDate - 2) <= estimatedDaysRemaining) {
                                    barClass = styles.barAmber;
                            } 
                        }
                    }
                }
            } 
            item.completedRagClass = barClass;
            items[i] = item;
        }
        return items;
    }

    protected _calculateOLITCompletionRAG(items: IDevelopmentItem[]): IDevelopmentItem[] {

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            let barClass: string | undefined;
            barClass = styles.barGreen;
            item.completedRagClass = barClass;
            items[i] = item;
        }
        return items;
    }
}