const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

export async function saveSubscription(sub: PushSubscription) {
    const response = await fetch(`/api/pushMessaging`, {
        method: 'POST',
        body: JSON.stringify({
            endpoint: sub.endpoint,
            p256dh: arrayBufferToBase64(sub.getKey("p256dh")),
            auth: arrayBufferToBase64(sub.getKey("auth"))
        }),
        headers: {
            "content-type": 'application/json',
            Accept: 'application/json'
        },
        credentials: 'include'
    });
    if (!response.ok) {
        console.error('Unable to save push messaging subscription', response.status, response.statusText);
    }
}