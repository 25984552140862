import * as React from "react";
import { Selection, ChoiceGroup, CommandBar, DefaultButton, DetailsList, IColumn, Label, Panel, PanelType, PrimaryButton, Slider, Stack, Toggle, SelectionMode, MessageBar, MessageBarType, Checkbox } from "office-ui-fabric-react";
import { DevelopmentPicker, IDevelopmentOption } from "./DevelopmentPicker";
import { BAUOrgPicker } from "./BAUOrgPicker";
import { IBusinessGroup, ISLATransactionDTO } from "../../../types";

type TimeSelection = "half-day" | "full-day" | "custom";
type TypeSelection = "development" | "bau";
type DayPeriod = "am" | "pm";

export interface IDayActivityPanelProps {
    activities?: ISLATransactionDTO[];
    weekStart: Date;
    day: number;
    onDismiss: () => void;
    onSave: () => void;
    onDelete: (closePanel: boolean) => void;
}

export interface IDayActivityPanelState {
    ackOvertime: boolean;
    customTime: number;
    dayPeriod: DayPeriod;
    errorJsx?: JSX.Element;
    page: number;
    selectedActivities?: ISLATransactionDTO[];
    selectedDevelopment?: IDevelopmentOption;
    selectedPartner?: IBusinessGroup;
    timeSelection: TimeSelection;
    typeSelection: TypeSelection;
    updateText?: string;
    updatePrivacy?: boolean;
}

export class DayActivityPanel extends React.Component<IDayActivityPanelProps, IDayActivityPanelState> {
    
    private _activitySubset: ISLATransactionDTO[];

    /** Stores the date to display in the Panel header */
    private _day: Date;
    
    /** The date format to use in the Panel header */
    private _dateFormat: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    };
    
    /** The selection object used for activity management (deletion etc) */
    private _selection = new Selection({
        onSelectionChanged: () => {
            const selectedItems = this._selection.getSelection();
            const selectedActivities = selectedItems.length ? selectedItems as ISLATransactionDTO[] : undefined;
            this.setState({
                ...this.state,
                selectedActivities,
            });
        }
    });

    constructor(props: IDayActivityPanelProps) {
        super(props);

        this._deleteActivity = this._deleteActivity.bind(this);
        this._getCommandBarItems = this._getCommandBarItems.bind(this);
        this._save = this._save.bind(this);
        this._setPage = this._setPage.bind(this);
        this._renderPage0 = this._renderPage0.bind(this);
        this._renderPage1 = this._renderPage1.bind(this);

        this._day = this._calculateDate(props.weekStart, props.day);
        this._activitySubset = this._getActivitySubset(props.activities, this._day);
        this.state = {
            ackOvertime: false,
            customTime: 3.75,
            dayPeriod: "am",
            page: this._activitySubset.length > 0 ? 0 : 1,
            timeSelection: "full-day",
            typeSelection: "development",
        };
    }

    public componentDidUpdate(prevProps: IDayActivityPanelProps, prevState: IDayActivityPanelState) {
        if (prevProps.day !== this.props.day || prevProps.weekStart !== this.props.weekStart) {
            this._day = this._calculateDate(this.props.weekStart, this.props.day);
            this.forceUpdate();
        }
        if (prevProps.activities !== this.props.activities || prevProps.day !== this.props.day || prevProps.weekStart !== this.props.weekStart) {
            this._activitySubset = this._getActivitySubset(this.props.activities, this._day);
            this.forceUpdate();
        }
    }

    public render() {
        const { onDismiss } = this.props;
        const { ackOvertime, customTime, dayPeriod, errorJsx, page, selectedActivities, selectedDevelopment, selectedPartner, timeSelection, typeSelection } = this.state;

        const activitySubsetTotalHrs = this._activitySubset.reduce((prev, cur, curIdx, arr) => {
            return prev + cur.hours;
        }, 0);

        // Hour string for second page
        let hrStr = "7h 30", hrs = 7.5;
        switch(timeSelection) {
            case "half-day":
                hrStr = "3h 45";
                hrs = 3.75;
                break;
            case "custom":
                hrStr = this._decToHrs(customTime);
                hrs = customTime;
                break;
        }
        const overFullDay = hrs + activitySubsetTotalHrs > 7.5;

        return (
            <Panel 
                isOpen={true} 
                isFooterAtBottom={true}
                type={PanelType.medium} 
                headerText={`${page === 0 ? "Activities" : "Add Activity"} - ${Intl.DateTimeFormat('en-GB', this._dateFormat).format(this._day)}`}
                onDismiss={onDismiss}
                onRenderFooter={(panelProps) => (
                    <Stack horizontal horizontalAlign={page === 0 ? "end" : (this._activitySubset.length > 0 ? "space-between" : "end")} verticalAlign="center" styles={{root: {margin: 20}}}>
                        { page === 1 && this._activitySubset.length > 0 && (
                            <DefaultButton
                                onClick={() => this._setPage(0)}
                                text="Back"
                            />
                        )}
                        { page === 1 && (
                            <PrimaryButton
                                disabled={
                                    (overFullDay && !ackOvertime) || 
                                    (typeSelection === "development" && selectedDevelopment === undefined) ||
                                    (typeSelection === "bau" && selectedPartner === undefined)
                                }
                                onClick={this._save}
                                text="Save"
                            />
                        )}
                    </Stack>
                )}
            >
                { errorJsx !== undefined && (
                    <MessageBar messageBarType={MessageBarType.error}>{errorJsx}</MessageBar>
                )}
                { this._renderPage0(page, selectedActivities) }
                { this._renderPage1(
                    ackOvertime,
                    customTime,
                    dayPeriod,
                    hrStr,
                    overFullDay,
                    page,
                    selectedDevelopment,
                    selectedPartner,
                    timeSelection,
                    typeSelection
                ) }
            </Panel>
        );
    }

    private _renderPage0(page: number, selectedActivities: ISLATransactionDTO[]) {
        if (page !== 0) return null;
        return (
            <React.Fragment>
                { this._activitySubset.length > 0 && (
                    <React.Fragment>
                        <CommandBar 
                            items={this._getCommandBarItems(this._activitySubset, selectedActivities)}
                        />
                        <DetailsList
                            items={this._activitySubset}
                            selectionMode={SelectionMode.multiple}
                            selection={this._selection}
                            columns={this._detailsListColumns}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    private _renderPage1(ackOvertime: boolean, customTime: number, dayPeriod: DayPeriod, hrStr: string, overFullDay: boolean, page: number, selectedDevelopment: IDevelopmentOption | undefined, selectedPartner: IBusinessGroup | undefined, timeSelection: TimeSelection, typeSelection: TypeSelection) {
        if (page !== 1) return null;
        return (
            <React.Fragment>
                <ChoiceGroup
                    label="Activity Length"
                    styles={{label: { fontSize: 18 }}}
                    options={this._timeSelectOptions}
                    selectedKey={timeSelection}
                    onChange={(e, option) => {
                        this.setState({
                            ...this.state,
                            timeSelection: option.key as TimeSelection,
                        });
                    }}
                /> 
                { timeSelection === "half-day" && (
                    <Toggle
                        styles={{root: {marginTop: 20}}}
                        label="Morning or Afternoon"
                        onText="Afternoon"
                        offText="Morning"
                        checked={dayPeriod === "pm"}
                        onChange={(e, checked) => {
                            this.setState({
                                ...this.state,
                                dayPeriod: checked ? "pm" : "am",
                            });
                        }}
                    />
                )}
                { timeSelection === "custom" && (
                    <Slider 
                        label="Custom" 
                        valueFormat={(val) => this._decToHrs(val)} 
                        styles={{root: { flexGrow: 1, marginTop: 20 }}} 
                        min={0} max={7.5} step={0.25} 
                        value={customTime} 
                        onChange={(val) => { 
                            this.setState({
                                ...this.state,
                                customTime: val
                            });
                        }} 
                    />
                )}
                { overFullDay === true && (
                    <React.Fragment>
                        <MessageBar styles={{root: {marginTop: 20}}} messageBarType={MessageBarType.warning}>
                            Adding this much activity will take you over the normal working day. If this is agreed overtime, be sure to submit a claim.
                        </MessageBar>
                        <Checkbox 
                            styles={{
                                root: {marginTop: 10}, 
                                text: { 
                                    color: !ackOvertime ? "darkred" : undefined
                                }
                            }} 
                            label="Click here to acknowledge" 
                            checked={ackOvertime} 
                            onChange={(e, checked) => {
                                this.setState({
                                    ...this.state,
                                    ackOvertime: checked,
                                });
                            }} />
                    </React.Fragment>
                )}
                <Label style={{marginTop: 18, fontSize: 20}}>Assign {hrStr} to:</Label>
                <ChoiceGroup
                    options={this._assignmentTypeOptions}
                    selectedKey={typeSelection}
                    onChange={(e, option) => {
                        this.setState({
                            ...this.state,
                            typeSelection: option.key as TypeSelection,
                        });
                    }}
                />
                { typeSelection === "development" && (
                    <DevelopmentPicker 
                        selectedDevelopment={selectedDevelopment}
                        onSelect={(development, updateText, updatePrivacy) => {
                            this.setState({
                                ...this.state,
                                selectedDevelopment: development,
                                updateText,
                                updatePrivacy
                            });
                        }} 
                    />
                )}
                { typeSelection === "bau" && (
                    <BAUOrgPicker
                        selectedPartner={selectedPartner}
                        onSelect={(selectedPartner) => {
                            this.setState({
                                ...this.state,
                                selectedPartner,
                            });
                        }}
                    />
                )}
            </React.Fragment>
        );
    }

    private _getCommandBarItems(activitySubset: ISLATransactionDTO[], selectedActivities: ISLATransactionDTO[]) {
        return [
            {
                key: "new",
                text: "Add New",
                iconProps: { iconName: "Add" },
                onClick: () => {
                    this._setPage(1);
                }
            },
            {
                key: "delete",
                text: "Delete",
                iconProps: { iconName: "Delete" },
                disabled: selectedActivities === undefined || selectedActivities.length === 0,
                onClick: () => {
                    this._deleteActivity(activitySubset);
                }
            },
        ];
    } 

    private _detailsListColumns = [
        {
            key: "name",
            fieldName: "developmentName",
            name: "Name",
            minWidth: 100,
            onRender: (item: ISLATransactionDTO, idx: number, column: IColumn) => {
                let title = item.developmentName;
                if (item.type === "Absence") {
                    title = item.absenceType === "AL" ? "Annual Leave" : "Sickness";
                }
                if (item.type === "BAU") {
                    title = `BAU - ${item.businessGroupName}`;
                }
                return (
                    <React.Fragment>
                        {title}<br/>
                        {item.comment !== undefined && (
                            <em>{item.comment}</em>
                        )}
                    </React.Fragment>
                );
            }
        },
        {
            key: "duration",
            fieldName: "hours",
            name: "Duration",
            minWidth: 80,
            maxWidth: 80,
            onRender: (item: ISLATransactionDTO, idx: number, column: IColumn) => {
                return this._decToHrs(item.hours);
            }
        }
    ];

    private _timeSelectOptions = [
        {
            key: "half-day",
            iconProps: { iconName: "CircleHalfFull" },
            text: "Half Day"
        },
        {
            key: "full-day",
            iconProps: { iconName: "CircleShapeSolid" },
            text: "Full Day"
        },
        {
            key: "custom",
            iconProps: { iconName: "ThreeQuarterCircle" },
            text: "Custom"
        },
    ];

    private _assignmentTypeOptions = [
        {
            key: "development",
            text: "Development",
            iconProps: { iconName: "GitGraph" },
            styles: { field: { width: 120 }, labelWrapper: { maxWidth: 120 }}
        },
        {
            key: "bau",
            text: "BAU",
            iconProps: { iconName: "Work" }
        }
    ];

    private _setPage(page: number) {
        this.setState({
            ...this.state,
            page,
        });
    }

    /** Set date and date string format date (for panel header)  */
    private _calculateDate(weekStart: Date, dayOffset: number): Date {
        let day = new Date(weekStart);
        if (dayOffset > 0) day.setDate(day.getDate() + dayOffset);
        return day;
    }

    /** Get activity subset for selected day */
    private _getActivitySubset(activities: ISLATransactionDTO[], date: Date) {
        return activities.filter((activity) => {
            let startTime: Date;
            if (!activity.periodStart) {
                if (!activity.dateOfAbsence) return false;
                startTime = new Date(activity.dateOfAbsence);
            } else {
                startTime = new Date(activity.periodStart);
            }
            return startTime.getDate() === date.getDate() &&
                startTime.getMonth() === date.getMonth() &&
                startTime.getFullYear() === date.getFullYear();
        });
    }

    /** Method to convert hours in decimal to hour / minute representation */
    private _decToHrs(decVal: number) {
        let dec = decVal % 1;
        let int = decVal - dec;
        if (dec > 0) {
            dec = dec * 60;
        }
        let fractionalMins = dec % 1 > 0 && dec % 1 < 1;
        return `${int}h ${fractionalMins ? dec.toFixed(1) : dec}`;
    }

    private async _deleteActivity(activitySubset: ISLATransactionDTO[]) {
        const { onDelete } = this.props;
        const { selectedActivities } = this.state;
        if (selectedActivities === undefined || selectedActivities.length === 0) return;
        const responses = await Promise.all(selectedActivities.map((activity) => {
            let url = `/api/activity/${activity.id}`;
            if (activity.type === "BAU") {
                url = `/api/activity/bau/${activity.id}`;
            }
            if (activity.type === "Absence") {
                url = `/api/activity/absence/${activity.id}`;
            }
            return fetch(url, {
                method: 'DELETE',
                headers: { 'accept': 'application/json' },
                credentials: 'include'
            });
        }));
        if (responses.filter((r) => r.ok === true).length === responses.length) {
            onDelete(selectedActivities.length === activitySubset.length);
        } else {
            onDelete(false);
            this.setState({
                ...this.state,
                errorJsx: (
                    <div>There was an issue deleting one or more items</div>
                )
            });
        }
    }

    private async _save() {
        const { customTime, dayPeriod, selectedDevelopment, selectedPartner, timeSelection, typeSelection } = this.state;
        const isDevActivity = typeSelection === "development";
        if (isDevActivity && (selectedDevelopment === undefined ||
            (timeSelection === "custom" && customTime === 0)
        )) return;
        if (!isDevActivity && (selectedPartner === undefined || 
            (timeSelection === "custom" && customTime === 0)
        )) return;
        let hrs = 7.5, start = new Date(this._day), end = new Date(this._day);
        switch(timeSelection) {
            case "full-day": 
                start.setHours(9); start.setMinutes(0);
                end.setHours(17); end.setMinutes(0);
                break;
            case "half-day":
                hrs = 3.75;
                start.setHours(dayPeriod === "am" ? 9 : 13);
                start.setMinutes(dayPeriod === "am" ? 0 : 15);
                end.setHours(dayPeriod === "am" ? 12 : 17);
                end.setMinutes(dayPeriod === "am" ? 45 : 0);
                break;
            case "custom":
                hrs = customTime;
                start.setHours(9); start.setMinutes(0);
                end.setHours(17); end.setMinutes(0);
                break;
        }
        const activity: ISLATransactionDTO = {
            type: isDevActivity ? "Activity" : "BAU",
            credit: false,
            hours: hrs,
            periodStart: start.toISOString(),
            periodEnd: end.toISOString()
        };
        if (isDevActivity) {
            activity.developmentId = selectedDevelopment.id;
            activity.developmentName = selectedDevelopment.name;
            if (this.state.updateText) {
                activity.saveUpdate = this.state.updateText;
                activity.saveUpdatePrivacy = this.state.updatePrivacy;
            }
        } else {
            activity.businessGroupId = selectedPartner.id;
        }
        const url = isDevActivity ?
            `/api/activity/development/${selectedDevelopment.id}` :
            `/api/activity/bau`;
        const result = await fetch(url, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json'
            },
            body: JSON.stringify(activity),
            credentials: 'include'
        });
        if (!result.ok) {
            this.setState({
                ...this.state,
                errorJsx: (
                    <div>Failed to save</div>
                ),
            });
        } else {
            this.props.onSave();
        }
    }
}

export default DayActivityPanel;