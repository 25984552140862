import * as React from "react";
import {
    TooltipHost
} from "office-ui-fabric-react";

export interface IProgressBarProps {
    barValue: string | number;
    ragClass: string;
}

export const ProgressBar = (props: IProgressBarProps) => {
    if (typeof props.barValue === "string") {
        return <span>{props.barValue}</span>;
    } else {
        return (
            <TooltipHost content={props.barValue + "%"}>
                <div className={props.ragClass}>
                    <div className="bar" style={{width: props.barValue + "%"}}></div>
                </div>
            </TooltipHost>
        );
    }
};

export default ProgressBar;