import * as React from "react";
import { css, Icon, Label, Stack } from "office-ui-fabric-react";
import { ICalendarEvent } from "../../../types";

import styles from "./CalendarEventPicker.module.scss";

export interface ICalendarEventPickerProps {
    disableIds?: string[];
    selectedEvents?: ICalendarEvent[];
    calendarEvents?: ICalendarEvent[];
    onSelectEvents: (events: ICalendarEvent[]) => void;
}

export const CalendarEventPicker = (props: ICalendarEventPickerProps) => {

    const selectedEventIds = props.selectedEvents.map((e) => e.id);
    const selectedEventCount = props.selectedEvents.length;

    if (!props.calendarEvents || props.calendarEvents.length === 0) {
        return <div>No events to show</div>;
    }

    // Place events in to object - subdividing events by week-day
    const eventsByDay: Record<string, ICalendarEvent[]> = {};
    props.calendarEvents.forEach((calendarEvent) => {
        const date = new Date(calendarEvent.start.dateTime);
        const dateStr = `${date.getFullYear()}-${(("0" + (date.getMonth() + 1))).slice(-2)}-${(("0" + date.getDate()).slice(-2))}`;
        if (eventsByDay[dateStr] === undefined) {
            eventsByDay[dateStr] = [];
        }
        eventsByDay[dateStr].push(calendarEvent);
    });

    // Create JSX output for events by week-day
    const days: JSX.Element[] = [];
    Object.keys(eventsByDay).forEach((key, idx) => {
        const dayDate = new Date(key);
        const day = dayDate.toLocaleString('en-gb', { weekday: 'long' });
        const events = eventsByDay[key].sort((a,b) => {
            const aStart = new Date(a.start.dateTime);
            const bStart = new Date(b.start.dateTime);
            return aStart > bStart ? 1 : (aStart === bStart ? 0 : -1);
        });
        const format: Intl.DateTimeFormatOptions = {
            hour: '2-digit', minute: '2-digit'
        }
        days.push(
            <div className={css(styles.day, idx % 2 !== 0 ? styles.odd : undefined)} key={key}>
                <Label>{day}</Label>
                { events.map((event) => {
                    const start = new Date(event.start.dateTime);
                    const end = new Date(event.end.dateTime);
                    const isDisabled = props.disableIds.indexOf(event.id) >= 0 || event.dna;
                    const isSelected = selectedEventIds.indexOf(event.id) >= 0;
                    let duration: string;
                    if (event.durationFromTeams) {
                        const mins = event.durationFromTeams / 60;
                        const hours = Math.floor(mins / 60);
                        const minsRemain = Math.floor(mins % 60);
                        duration = hours + 'h ' + ("0" + minsRemain).slice(-2);
                    }
                    return (
                        <Stack 
                            key={event.id} horizontal verticalAlign="center" 
                            className={css(
                                styles.calendarItem, 
                                isSelected ? styles.isSelected : undefined,
                                isDisabled ? styles.disabled : undefined
                            )} 
                            onClick={() => {
                                if (!isDisabled) {
                                    if (!isSelected) {
                                        props.onSelectEvents([...props.selectedEvents, event]);
                                    } else {
                                        props.onSelectEvents(props.selectedEvents.filter((e) => e.id !== event.id));
                                    }
                                }
                            }}
                        >
                            <div style={{flexGrow: 1}}>{event.subject}</div>
                            <div style={{width: 100}}>
                                { event.onlineMeeting === true && (
                                    <>
                                        { event.dna === true && (
                                            <div className={styles.dnaBadge}>Did Not Attend</div>
                                        )}
                                        { event.dna !== true && Boolean(duration) && (
                                            <Stack horizontal verticalAlign="center">
                                                <Icon style={{fontSize: 24, marginRight: 10}} iconName="TeamsLogo" /> 
                                                <div>{duration}</div>
                                            </Stack>
                                        )}
                                        { event.dna !== true && !Boolean(duration) && 
                                            `${start.toLocaleString('en-gb', format)}-${end.toLocaleString('en-gb', format)}`
                                        }
                                    </>
                                )}
                                { event.onlineMeeting !== true && (
                                    <>
                                        {start.toLocaleString('en-gb', format)}-{end.toLocaleString('en-gb', format)}
                                    </>
                                )}
                            </div>
                        </Stack>
                    );
                })}
            </div>
        );
    });

    return (
        <div className={styles.calendarItemPicker} style={{marginTop: 20}}>
            <p>Click or tap an item from your calendar to assign to a development.<br/>{selectedEventCount} items selected:</p>
            { days }
        </div>
    );
}