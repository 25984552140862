import React from "react";

/* types */
import { IDevelopmentItem, IRequestorInfo, IUserLookup } from "../types";
import { FilterMenuItem } from "./components/developmentViews/FilterMenu";

/* Fabric UI */
import {
    IColumn,
    IFacepilePersona,
    PersonaSize,
    TooltipHost,
} from "office-ui-fabric-react";

/* base view */
import BaseView, { IBaseViewState, ViewDisplayMode } from "./BaseView";
import { DevTableColumn } from "./components/developmentViews/DevTable.types";

/* utils */
import { 
    getStatusIconName, 
    getPlatformIconName,
} from "../utils";
import { RouteComponentProps, withRouter } from "react-router";

/* State and Props */
export interface ITeamMtgViewProps extends RouteComponentProps {
}
export interface ITeamMtgViewState extends IBaseViewState {}

export class TeamMtgView extends BaseView<ITeamMtgViewProps, ITeamMtgViewState> {

    protected viewName = "Team Meeting View";
    
    constructor(props: ITeamMtgViewProps) {
        super(props);
        this.isSostenutoView = false;
        this._isDevTeamView = true;

        // init state
        this.state = {
            activeColumns: this._tableConfig,
            editFormTabId: undefined,
            editingItem: undefined,
            items: undefined,
            itemCounts: {},
            loading: true,
            loadingMessage: "Loading",
            statusOptions: undefined,
            displayMode: ViewDisplayMode.Table,
            statusFilter: false,
            toastNotifications: []
        };
    }

    //#region query / filter menu
    protected _menuConfig: FilterMenuItem[] = [
        {
            text: "New",
            itemQuery: { status: "New" },
            columnFilter: (cols) => cols.filter((c: any) => 
                c.fieldName !== "dateSubmitted" && 
                c.fieldName !== "project" && 
                c.fieldName !== "reviewDate" &&
                c.fieldName !== "latestUpdate" &&
                c.fieldName !== "assignedTo" &&
                c.fieldName !== "status"
            ),
            iconName: getStatusIconName("Starburst"),
        },
        { 
            text: "More Information Required", 
            itemQuery: { status: "More Information Required" },
            columnFilter: (cols) => cols.filter((c: any) => 
                c.fieldName !== "dateSubmitted" &&
                c.fieldName !== "combinedRequestors" &&
                c.fieldName !== "department" &&
                c.fieldName !== "estimatedEffort"
            ), 
            iconName: getStatusIconName("On Hold") 
        },
        { 
            text: "On Hold", 
            itemQuery: { status: "On Hold" },
            columnFilter: (cols) => cols.filter((c: any) => 
                c.fieldName !== "combinedRequestors" &&
                c.fieldName !== "department"
            ), 
            iconName: getStatusIconName("On Hold") 
        },
        { 
            text: "Scoping Stage",
            itemQuery: { status: "Scoping Stage" },
            columnFilter: (cols) => cols.filter((c: any) => 
                c.fieldName !== "dateSubmitted" &&
                c.fieldName !== "combinedRequestors" &&
                c.fieldName !== "department" &&
                c.fieldName !== "estimatedEffort"
            ),
            iconName: getStatusIconName("Scoping Stage")
        }, 
        { 
            text: "RAG (Red)",
            itemQuery: { rag: "red" },
            columnFilter: (cols) => cols.filter((c: any) => 
                c.fieldName !== "dateSubmitted" &&
                c.fieldName !== "project" &&
                c.fieldName !== "combinedRequestors" &&
                c.fieldName !== "department" &&
                c.fieldName !== "reviewDate" &&
                c.fieldName !== "estimatedEffort" &&
                c.fieldName !== "latestUpdate" &&
                c.fieldName !== "assignedTo"
            ), 
            iconName: getStatusIconName("Warning")
        }, 
        { 
            text: "Flagged",
            itemQuery: { flagged: true },
            columnFilter: (cols) => cols.filter((c: any) => 
                c.fieldName !== "dateSubmitted" &&
                c.fieldName !== "project" &&
                c.fieldName !== "combinedRequestors" &&
                c.fieldName !== "department" &&
                c.fieldName !== "reviewDate" &&
                c.fieldName !== "estimatedEffort" &&
                c.fieldName !== "latestUpdate" &&
                c.fieldName !== "assignedTo"
            ), 
            iconName: getStatusIconName("Flag")
        }, 
        { 
            text: "COVID-19",
            itemQuery: { majorIncidentRelated: true },
            columnFilter: (cols) => cols.filter((c: any) => 
                c.fieldName !== "dateSubmitted" &&
                c.fieldName !== "project" &&
                c.fieldName !== "combinedRequestors" &&
                c.fieldName !== "department" &&
                c.fieldName !== "reviewDate" &&
                c.fieldName !== "estimatedEffort" &&
                c.fieldName !== "latestUpdate" &&
                c.fieldName !== "assignedTo"
            ), 
            iconName: getStatusIconName("Ready for Deployment")
        }, 
    ];
    //#endregion

    //#region table config
    protected _tableConfig: DevTableColumn[] = [
        {
            type: "Icon",
            fieldName: "platform",
            minWidth: 40,
            maxWidth: 40,
            iconConversion: (value: any) => ({
                iconName: getPlatformIconName(value),
                styles: { root: { fontSize: "2.5em", color: "#b1324e" }}
            }),
            mapToText: (item: IDevelopmentItem) => item.platform,
            filters: "UniqueText"
        },
        {
            type: "Date",
            fieldName: "dateSubmitted",
            displayName: "Submitted",
            minWidth: 80,
            maxWidth: 80,
        },
        {
            type: "Text",
            fieldName: "project",
            minWidth: 80,
            maxWidth: 150,
            filters: "UniqueText"
        },
        {
            type: "Text",
            fieldName: "title",
            displayName: "Development",
            minWidth: 100,
        },
        {
            type: "Persona",
            fieldName: "combinedRequestors",
            displayName: "Requestors",
            minWidth: 100,
            maxWidth: 200,
            personaConversion: (value: IRequestorInfo) => ({
                size: PersonaSize.size32,
                name: value.contactName,
                data: {
                    email: value.contactEmail
                }
                // secondaryText: value.contactEmail,
                // imageUrl: value.contactPhoto ? `data:${value.contactPhoto.type};base64,${value.contactPhoto.data}` : undefined
            } as IFacepilePersona),
            mapToText: (item: IDevelopmentItem) => {
                return item.combinedRequestors.map((value, i, arr) =>
                    `${value.contactName} ${value.contactEmail}` + (i + 1 < arr.length ? ',' : '') 
                ).join('\n');
            }
        },
        {
            type: "Text",
            fieldName: "department", 
            displayName: "Department",
            minWidth: 100,
        },
        {
            type: "Text",
            fieldName: "estimatedEffort",
            displayName: "Estimated Effort",
            minWidth: 80,
            maxWidth: 80,
            objectValueMapping: (value: any) => {
                if (!value) return '';
                return `${value} days`;
            }
        },
        {
            type: "Date",
            fieldName: "reviewDate",
            displayName: "Review Date",
            minWidth: 80,
            maxWidth: 80,
            onRender: this._renderReviewCol
        },
        {
            type: "Text",
            fieldName: "latestUpdate",
            displayName: "Latest Update",
            minWidth: 200,
            onRender: (item?: any, index?: number | undefined, column?: IColumn | undefined) => {
                return (
                    <TooltipHost content="Double click to add an update">
                        <div style={{whiteSpace: "pre-wrap"}} className="latestUpdate" onDoubleClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            this._handleEdit(item, "3");
                        }}>
                            {item.latestUpdate}
                        </div>
                    </TooltipHost>
                );
            },
        },
        {
            type: "Persona",
            fieldName: "assignedTo",
            minWidth: 100,
            maxWidth: 100,
            filters: "UniqueText",
            personaConversion: (value: IUserLookup) => ({
                size: PersonaSize.size32,
                name: value.name,
                data: {
                    email: value.mail
                }
                // secondaryText: value.mail,
                // imageUrl: value.photo ? `data:${value.photo.type};base64,${value.photo.data}` : undefined
            } as IFacepilePersona),
            mapToText: (item) => item.assignedTo.map((a: any) => a.mail)
        },
        {
            type: "Text",
            fieldName: "platformPriority",
            displayName: "Priority",
            minWidth: 65,
            onRender: (item: IDevelopmentItem) => item.platformPriority?.toString(),
            mapToText: (item: IDevelopmentItem) => item.platformPriority
        },
        {
            type: "Text",
            fieldName: "status",
            objectValueMapping: (value: any) => value.text,
            mapToText: (item) => item.status.text,
            filters: "UniqueText",
            minWidth: 100,
            maxWidth: 100
        }
    ];
    //#endregion
}

export default withRouter(TeamMtgView);