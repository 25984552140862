import React from "react";

import { IPersonaProps, Label, NormalPeoplePicker, Persona, PersonaSize, PrimaryButton, Stack } from "office-ui-fabric-react";

import { searchUsers } from "../../api/peoplepicker";
import RequestorDialog from "./RequestorDialog";

export interface ISPPeoplePickerProps {
    disabled?: boolean;
    autoFocus?: boolean;
    label: string;
    selectedPeople: IPersonaProps[];
    showManualAddOption?: boolean;
    errorMessage?: string;
    onChange: (items?: IPersonaProps[] | undefined) => void;
}
export interface ISPPeoplePickerState {
    showManualAddDialog: boolean;
}

export default class SPPeoplePicker extends React.Component<ISPPeoplePickerProps, ISPPeoplePickerState> {
    constructor(props: ISPPeoplePickerProps) {
        super(props);
        this._onResolveSuggestions = this._onResolveSuggestions.bind(this);
        this.state = {
            showManualAddDialog: false
        };
    }

    public render() {
        const { selectedPeople } = this.props;
        return (
            <div>
                <Label>{this.props.label}</Label>
                <Stack horizontal horizontalAlign="start" verticalAlign="start">
                    <NormalPeoplePicker 
                        disabled={this.props.disabled}
                        inputProps={{autoFocus: this.props.autoFocus}}
                        className="ms-PeoplePicker"
                        pickerSuggestionsProps={{
                            suggestionsHeaderText: "Suggested People",
                        }}
                        resolveDelay={500}
                        selectedItems={selectedPeople}
                        defaultSelectedItems={selectedPeople}
                        onRenderSuggestionsItem={this._onRenderSuggestionsItem}
                        onResolveSuggestions={this._onResolveSuggestions}
                        onChange={this.props.onChange}
                    />
                    { (this.props.showManualAddOption === undefined || this.props.showManualAddOption === true) && (
                        <PrimaryButton disabled={this.props.disabled} iconProps={{iconName: "Add"}} label="Add Manually" onClick={() => {
                            this.setState({
                                ...this.state,
                                showManualAddDialog: true,
                            });
                        }} />
                    )}
                </Stack>
                { this.state.showManualAddDialog === true && (
                    <RequestorDialog 
                        isOpen={true}
                        onClose={(value?: IPersonaProps) => {
                            if (value) {
                                const newSelectedPeople = [...selectedPeople, value];
                                this.props.onChange(newSelectedPeople);
                            }
                            this.setState({
                                ...this.state,
                                showManualAddDialog: false,
                            });
                        }}
                    />
                )}
                { this.props.errorMessage !== undefined && (
                    <span>{this.props.errorMessage}</span>
                )}
            </div>
        );
    }

    private _onRenderSuggestionsItem(props: IPersonaProps, itemProps: any): JSX.Element {
        return (
            <Persona {...props} size={PersonaSize.size72} imageUrl={props.imageUrl} />
        );
    }

    private async _onResolveSuggestions(filter: string, selectedItems?: IPersonaProps[] | undefined): Promise<IPersonaProps[]> {
        let suggestions: IPersonaProps[] = await searchUsers(filter);
        if (selectedItems) {
            selectedItems.forEach((selectedItem) => {
                suggestions = suggestions.filter((a) => a.itemID !== selectedItem.itemID);
            });
        }
        return suggestions;
    }
}
