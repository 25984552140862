import Moment from "moment";
import React from "react";

import { 
    DetailsList, 
    DetailsRow,
    IColumn, 
    ProgressIndicator, 
    SelectionMode, 
    Spinner,
    TooltipHost, 
} from "office-ui-fabric-react";
import { IDevelopmentHistoryItem } from "../../types";

import * as API from "../../api";
import styles from "./Updates.module.scss";

export interface IHistoryProps {
    developmentId: number;
}
export interface IHistoryState {
    loading: boolean;
    history: IDevelopmentHistoryItem[] | undefined;
}

const ignoredFields = [
    "id", "developmentId", "modifiedDate",
    "businessGroups", "requestedType",
    "customerName", "latestUpdate",
    "devId", "updatedBy", "taigaId", "taigaSlug",
    "prioritisation",
    "moreInfoComm", "thirdPartyInputReq",
    "taigaTasksCreated", "taigaStoryRef", 
    "olitUpdate", "olitDescription",
    "moreInfoReviewDate", "onHoldReviewDate",
    "scopingReviewDate", "testingReviewDate",
    "fullScopingRequired", 
];

const fieldLabels: Record<string, string> = {
    "platform": "Platform",
    "title": "Development Name",
    "businessGroups": "Business, Group or Area",
    "department": "Department",
    "requestors": "Requestors",
    "dateSubmitted": "Date Submitted",
    "description": "Description",
    "link": "Relevant URL",
    "estimatedStart": "Estimated Start Date",
    "estimatedEnd": "Estimated End Date",
    "startDate": "Start Date",
    "endDate": "End Date",
    "estimatedEffort": "Estimated Effort",
    "interfaceCode": "Interface Code",
    "upgradeCode": "Upgrade Code",
    "assignedTo": "Assigned To",
    "status": "Status",
    "complete": "Progress (%)",
    "srCode": "SR Code",
    "closedComm": "Closure Comments",
    "ragStatus": "RAG",
    "project": "Associated Project(s)",
    "dueDate": "Due Date",
    "goLiveDate": "Go Live Date",
    "contactEmail": "Contact Email",
    "contactNumber": "Contact Number",
    "reviewDate": "Review Date",
    "division": "Division",
    "majorIncidentRelated": "Major Incident Related"
};

const dateFields = [
    "dateSubmitted", "estimatedStart", "estimatedEnd",
    "startDate", "endDate", "dueDate",
    "goLiveDate", "reviewDate"
];

const getFieldValue = (item: any, key: string) => {
    if (dateFields.indexOf(key) >= 0) {
        if (!Boolean(item[key])) return null;
        const date = new Date(item[key]);
        return date.toLocaleDateString();
    }
    if (key === "complete" && item[key] !== null) {
        return (
            <TooltipHost content={item[key] + "%"}>
                <ProgressIndicator barHeight={10} percentComplete={item[key] / 100} />
            </TooltipHost>
        );
    }
    return item[key];
};

export default class DevelopmentHistory extends React.Component<IHistoryProps, IHistoryState> {
    private _columns: IColumn[] = this._buildColumns();
    constructor(props: IHistoryProps) {
        super(props);
        this._loadUpdates = this._loadUpdates.bind(this);
        this._buildColumns = this._buildColumns.bind(this);
        this.state = {
            history: undefined,
            loading: true,
        };
    }
    public componentDidMount() {
        this._loadUpdates();
    }
    public render() {
        const { loading, history } = this.state;
        const darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        if (!this.props.developmentId) return null;
        if (!loading && history) {
            return (
                (<div>
                    <DetailsList 
                        columns={this._columns}
                        items={history}
                        selectionMode={SelectionMode.none}
                        onRenderRow={(props, defaultRender) => {
                            const isEven = props.itemIndex % 2 === 0;
                            return <DetailsRow {...props} styles={{root: { backgroundColor: isEven ? (darkMode ? "black" : "#f4f4f4") : undefined}}} />;
                        }}
                    />
                </div>)
            );
        } else {
            return <Spinner />;
        }
    }
    private async _loadUpdates() {
        const history = await API.getHistoryByDevelopmentId(this.props.developmentId);
        if (history && history.history) {
            this.setState({
                ...this.state,
                history: history.history,
                loading: false,
            });
        }   
    }
    private _buildColumns(): IColumn[] {
        return [
            {
                className: styles.entryCol,
                fieldName: "status",
                key: "0",
                minWidth: 100,
                name: "Status",
                onRender: (item: IDevelopmentHistoryItem, index: number, column: IColumn) => {
                    const lastItemIdx = this.state.history.length - 1;
                    const nextItemIdx = index + 1;
                    if (nextItemIdx > lastItemIdx) {
                        return Object.keys(item).filter((key) => ignoredFields.indexOf(key) === -1).map((key) => {
                            if ((item as any)[key] === null || (item as any)[key] === "") return null;
                            return (
                                <div key={key} style={{display: "flex"}}>
                                    <div style={{width: "50%"}} data-key={key}>{(fieldLabels[key] || key)}</div>
                                    <div style={{width: "50%"}}>{getFieldValue(item, key)}</div>
                                </div>
                            );
                        });
                    } else {
                        const diffValues: Record<string, any> = {};
                        Object.keys(item).forEach((key) => {
                            const prevValue = (this.state.history[nextItemIdx] as any)[key];
                            if (ignoredFields.indexOf(key) === -1 && prevValue !== (item as any)[key]) {
                                if ((item as any)[key] !== null && (item as any)[key] !== "") {
                                    diffValues[key] = (item as any)[key];
                                }
                            }
                        });
                        if (Object.keys(diffValues).length > 0) {
                            return Object.keys(diffValues).map((key) => {
                                return (
                                    <div key={key} style={{display: "flex"}}>
                                        <div style={{width: "50%"}} data-key={key}>{fieldLabels[key]}</div>
                                        <div style={{width: "50%"}}>{getFieldValue(item, key)}</div>
                                    </div>
                                );
                            });
                        } else {
                            return (
                                <div style={{textAlign: "center"}}>
                                    Resaved - No Changes
                                </div>
                            );
                        }
                    }
                }
            },
            {
                className: styles.dateCol,
                fieldName: "date",
                key: "2",
                minWidth: 180,
                name: "Date / Author",
                onRender: (item?: IDevelopmentHistoryItem, index?: number | undefined, column?: IColumn | undefined) => {
                    if (item && item.modifiedDate) {
                        return (
                            <span>{Moment(item.modifiedDate).format("DD/MM/YYYY HH:mm:SS")} by<br/>
                                {item.updatedBy}  
                            </span>
                        );
                    }
                },
            },
        ];
    }
}
