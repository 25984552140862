import * as React from "react";
import { ActionButton, CommandBar, ContextualMenu, ContextualMenuItemType, ICommandBarItemProps, Icon, IContextualMenuItem, Persona, PersonaCoin, Stack, Toggle, TooltipHost } from "office-ui-fabric-react";
import { UserContext } from "../../UserContext";

import giteaLogo from "../../assets/gitea_logo.png";
import sunriseLogo from "../../assets/sunrise_logo.png";
import { useHistory } from "react-router";

import styles from "./Header2.module.scss";
import { DisplayContext } from "../../DisplayContext";

export interface IHeaderMenuProps {}

export const HeaderMenu = (props: IHeaderMenuProps) => {

    const history = useHistory();

    const [showUserMenu, setShowUserMenu] = React.useState(false);
    const userMenuRef = React.useRef<HTMLDivElement>();

    const displayContext = React.useContext(DisplayContext);
    const userContext = React.useContext(UserContext);
    const { photo, roles, teams, displayName, email } = userContext?.profile;

    const isMobileDevice = /Mobi|Tablet|iPad|iPhone/i.test(navigator.userAgent);

    const userTeamIds = teams.map((t) => t.id);
    const userRoleNames = roles.map((r) => r.roleName);

    const isDeveloper = userTeamIds.indexOf("a467634f-fc30-4adb-8fbb-aa9ad93113e3") >= 0;
    const isCustomer = userRoleNames.indexOf("Customer") >= 0;
    const isColleague = userRoleNames.indexOf("Colleague") >= 0;
    const isAccountMgr = userRoleNames.indexOf("Account Manager") >= 0;
    const isPrioritiser = userRoleNames.indexOf("Prioritisation") >= 0;
    const isAdmin = userRoleNames.indexOf("Admin") >= 0;

    const commandBarItems: ICommandBarItemProps[] = [{
        key: "home",
        iconOnly: true,
        iconProps: { iconName: "HomeSolid" },
        itemProps: {
            styles: {
                root: {
                    backgroundColor: "#b1324e"
                }
            }
        },
        onClick: () => { history.push("/"); }
    }];
    if (isDeveloper) {
        const usefulLinks: IContextualMenuItem[] = [
            {
                key: "teams",
                split: true,
                iconProps: { iconName: "TeamsLogo", color: "#6264a7" },
                text: "Microsoft Teams App",
                onClick: () => {
                    window.location.href = "msteams://";
                },
                subMenuProps: {
                    items: [
                        {
                            key: "openTeamsWeb",
                            text: "Open Web App",
                            href: "https://teams.microsoft.com"
                        },
                        {
                            key: "divider",
                            itemType: ContextualMenuItemType.Divider
                        },
                        {
                            key: "genChan",
                            text: "General Channel",
                            href: "msteams:/l/channel/19:1691f526e8b446a0acc83599a4862ddf@thread.tacv2/General?groupId=a467634f-fc30-4adb-8fbb-aa9ad93113e3&tenantId=b07d40a5-b7e9-4f95-9e5e-5c1fe6e8964d&deeplinkId=74a33624-2698-4cd2-8f18-86ca59c3d25d",
                            subMenuProps: {
                                items: [{
                                    key: "genChanWeb",
                                    text: "Open in Web App",
                                    href: "https://teams.microsoft.com/_?tenantId=b07d40a5-b7e9-4f95-9e5e-5c1fe6e8964d#/l/channel/19:1691f526e8b446a0acc83599a4862ddf@thread.tacv2/General?groupId=a467634f-fc30-4adb-8fbb-aa9ad93113e3&tenantId=b07d40a5-b7e9-4f95-9e5e-5c1fe6e8964d&deeplinkId=74a33624-2698-4cd2-8f18-86ca59c3d25d"
                                }]
                            }
                        }
                    ]
                }
            },
            {
                key: "planner",
                href: "https://tasks.office.com",
                // styles: {{icon: { fontSize: 20, color: "#31752f" }}} 
                iconProps: {iconName: "PlannerLogo", color: "#31752f"},
                text: "Tasks / Planner"
            }
        ];
        if (!isMobileDevice) {
            usefulLinks.splice(0, 0, {
                key: "sostenuto",
                iconProps: {imageProps: { src: sunriseLogo, width: 16 }}, 
                href: "http://servicedesk/",
                target: "_blank",
                text: "Sostenuto / ITSM"
            },
            {
                key: "gitea",
                iconProps: {imageProps: { src: giteaLogo, width: 16, style: { position: "relative", top: -5}}},
                href: "http://im-webdev:3000", 
                target: "_blank",
                text: "Gitea"
            });
        }
        const subMenuItems: IContextualMenuItem[] = [
            {
                key: "developmentsHeader",
                itemType: ContextualMenuItemType.Header,
                text: "Developments"
            },
            {
                key: "developmentsByStatus",
                text: "By Status",
                onClick: () => { history.push('/view/team/default'); }
            },
            {
                key: "teamMtg",
                text: "Team Meeting View",
                onClick: () => { history.push('/view/team-meeting/new'); }
            },
            {
                key: "nonprioritised",
                text: "Non-Prioritised View",
                onClick: () => { history.push('/view/team/non-prioritised'); }
            },
            {
                key: "closed",
                text: "Closed",
                onClick: () => { history.push('/view/team/development-complete'); }
            },
            {
                key: "divider1",
                itemType: ContextualMenuItemType.Divider
            },
            {
                key: "activityHeader",
                text: "Activity",
                itemType: ContextualMenuItemType.Header,
            },
            {
                key: "activity",
                text: "Record Activity",
                onClick: () => { history.push('/activity'); }
            },
            {
                key: "divider2",
                itemType: ContextualMenuItemType.Divider
            },
            {
                key: "otherHeader",
                text: "Other",
                itemType: ContextualMenuItemType.Header
            },
            {
                key: "usefulLinks",
                text: "Useful Links",
                subMenuProps: {
                    items: usefulLinks
                }
            }
        ];
        if (isPrioritiser) {
            subMenuItems.splice(3, 0, {
               key: "prioritisation",
               text: "Prioritisation",
               onClick: () => history.push('/team/prioritisation') 
            });
        }
        commandBarItems.push({
            key: "developer",
            text: "Developer",
            subMenuProps: {
                items: subMenuItems
            }
        });
    }
    if (isCustomer || isColleague || isAccountMgr) {
        const subMenuItems: IContextualMenuItem[] = [
            {
                key: "developmentsHeader",
                itemType: ContextualMenuItemType.Header,
                text: "Developments"
            },
            {
                key: "developments",
                text: "By Status",
                onClick: () => { history.push('/view/customer/in-development'); }
            }
        ];
        if (isPrioritiser) {
            subMenuItems.push({
                key: "prioritisation",
                text: "Prioritisation",
                onClick: () => history.push('/customer/prioritisation')
            });
        }
        commandBarItems.push({
            key: "customerColleagues",
            text: "Customers / Colleagues",
            subMenuProps: { items: subMenuItems }
        });
    }
    
    const userMenuItems: IContextualMenuItem[] = [
        {
            key: "accountInfo",
            onRender: () => (
                <Persona styles={{root: {padding: 10, marginTop: 10, marginBottom: 10}}} data-is-focusable={true} text={displayName} secondaryText={email} imageUrl={photo} />
            ),
        },
        {
            key: "accountHeader",
            text: "Account",
            itemType: ContextualMenuItemType.Header
        },
        {
            key: "signOut",
            text: "Sign Out",
            href: "/MicrosoftIdentity/Account/SignOut"
        },
        {
            key: "display",
            text: "Theme",
            itemType: ContextualMenuItemType.Header
        },
        {
            key: "dispMode",
            text: "ThemeMode",
            onClick: (e) => {
                e.preventDefault();
            },
            onRender: () => (
                <Stack horizontal verticalAlign="center">
                    <TooltipHost content="Use your device theme settings">
                        <ActionButton text="Auto" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            document.cookie = 'theme=;expires=' + new Date().toUTCString();
                            displayContext.changeTheme();
                        }} />
                    </TooltipHost>
                    <TooltipHost styles={{
                        root: {
                            display: "flex",
                            alignItems: "center"
                        }
                    }} content={displayContext.darkMode ? "Dark Mode Enabled" : "Light Mode Enabled"}>
                        <Toggle className={styles.darkModeToggle} checked={displayContext.darkMode} onChange={(e, checked) => {
                            e.preventDefault();
                            e.stopPropagation();
                            document.cookie = `theme=${(checked ? 'dark' : 'light')};`;
                            displayContext.changeTheme(checked);
                        }} />
                        <Icon iconName={displayContext.darkMode ? "ClearNight" : "Sunny"} />
                    </TooltipHost>
                </Stack>
            )
        }
    ];
    if (isAdmin) {
        userMenuItems.push({
            key: "adminHeader",
            text: "Administration",
            itemType: ContextualMenuItemType.Header
        },
        {
            key: "userMgt",
            text: "User Management",
            onClick: () => { history.push('/admin/user-management'); }
        },
        {
            key: "supportAgreements",
            text: "Support Agreements",
            onClick: () => alert('In development') 
        });
    }
    const farItems: ICommandBarItemProps[] = [
        {
            key: "user",
            onRender: (item: ICommandBarItemProps, dismissMenu) => {
                
                return (
                    <div ref={userMenuRef} onClick={() => setShowUserMenu(!showUserMenu)} style={{height: "100%", display: "flex", alignItems: "center"}}>
                        <PersonaCoin style={{cursor: "pointer", margin: "0px 10px"}} coinSize={32} data-is-focusable={true} imageUrl={photo} />
                        <ContextualMenu {...item.subMenuProps} hidden={!showUserMenu} target={userMenuRef.current} onDismiss={() => setShowUserMenu(false)} />
                    </div>
                );
            },
            subMenuProps: { items: userMenuItems }
        }
    ];

    return (
        <CommandBar
            className={styles.headerMenu}
            items={commandBarItems}
            farItems={farItems}
        />
    );
};

export default HeaderMenu;