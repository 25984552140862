import * as React from "react";
import { debounce } from "lodash";
import { UserContext } from "../UserContext";
import { HeaderMenu } from "./components/Header2";
import { CommandButton, ContextualMenu, DirectionalHint, Dropdown, IContextualMenuItem, IContextualMenuProps, IDropdownOption, Label, MessageBar, MessageBarType, Persona, PersonaSize, PrimaryButton, Spinner, Stack, TextField } from "office-ui-fabric-react";
import { IBusinessGroup, IRoleMembership, IUserProfile } from "../types";

import logo from "../assets/dev_service_compact.png";
import darkLogo from "../assets/dev_service_compact_dark.png";
import styles from "./UserManagement.module.scss";
import { searchUserByEmail, assignRoles, assignBusinessRelationships } from "../api/userManagement";
import { getRoles } from "../api/roles";
import { getBusinessGroups } from "../api/businessGroups";
import { DisplayContext } from "../DisplayContext";

export interface IUserManagementProps {}

export const UserManagement = (props: IUserManagementProps) => {
    const displayContext = React.useContext(DisplayContext);
    const userContext = React.useContext(UserContext);
    
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);
    const [messageType, setMessageType] = React.useState<MessageBarType>();
    const [message, setMessage] = React.useState<string>();
    
    /* search state */
    const searchFieldRef = React.useRef<HTMLDivElement>();
    const [searchText, setSearchText] = React.useState<string>();
    const [searchResults, setSearchResults] = React.useState<IContextualMenuProps>();
    const [selectedUser, setSelectedUser] = React.useState<IUserProfile>();
    
    /* options state */
    const [roleOptions, setRoleOptions ] = React.useState<IRoleMembership[]>();
    const [businessOptions, setBusinessOptions] = React.useState<IBusinessGroup[]>();
    const [rolesToSave, setRolesToSave] = React.useState<number[]>();
    const [relationshipsToSave, setRelationshipsToSave] = React.useState<number[]>();
    const changesToSave = rolesToSave || relationshipsToSave;
    
    const loadRoleOptions = async () => {
        const roles = await getRoles();
        if (roles && roles.length) {
            setRoleOptions(roles);
        }
    };

    const loadBusinessOptions = async () => {
        const response = await getBusinessGroups();
        if (response && response.businessGroups && response.businessGroups.length) {
            setBusinessOptions(response.businessGroups);
        }
    };

    const loadOptions = async () => {
        await loadRoleOptions();
        await loadBusinessOptions();
        setLoading(false);
    }

    React.useEffect(() => {
        loadOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const search = debounce(async (searchStr: string) => {
        const results = await searchUserByEmail(searchStr);
        if (results && results.length) {
            setSearchResults({
                shouldFocusOnMount: false,
                items: results.map((r) => ({
                    key: r.displayName,
                    text: r.displayName,
                    secondaryText: r.email,
                    data: {
                        userProfile: r,
                        image: r.photo,
                    },
                    onRender: (item: IContextualMenuItem, dismissMenu) => {
                        return (
                            <CommandButton 
                                styles={{root: { height: 60 }}}
                                onClick={(e) => {
                                    setMessage(undefined);
                                    setRolesToSave(undefined);
                                    setRelationshipsToSave(undefined);
                                    setSearchText('');
                                    setSelectedUser(item.data.userProfile);
                                    dismissMenu(e, true);
                                }}
                            >
                                <Persona
                                    size={PersonaSize.size48} 
                                    text={item.text} 
                                    secondaryText={item.secondaryText}
                                    imageUrl={item.data.image}
                                />
                            </CommandButton>
                        )
                    }
                } as IContextualMenuItem)),
                target: searchFieldRef.current,
                directionalHint: DirectionalHint.bottomAutoEdge,
                onDismiss: () => setSearchResults(undefined)
            } as IContextualMenuProps);
        } else {
            setSearchResults(undefined);
        }
    }, 500);

    return (
        <div>
            <div className={styles.logoContainer}>
                <img className={styles.logo} src={displayContext.darkMode ? darkLogo : logo} alt="Development Service Portal Logo" />
            </div>
            <HeaderMenu />
            <div className={styles.userSearchContainer}>
                <Label>User Management</Label>
                <Stack horizontal verticalAlign="center">
                    <div style={{marginRight: 10}}>Search for a user:</div>
                    <div ref={searchFieldRef}>
                        <TextField 
                            autoFocus
                            placeholder="Enter email address"
                            value={searchText}
                            onChange={(e, newValue) => {
                                setSearchText(newValue);
                                if (newValue && newValue.trim() !== '') {
                                    search(newValue);
                                } else {
                                    setSearchResults(undefined);
                                }
                            }}
                        />
                    </div>  
                    <ContextualMenu 
                        hidden={searchResults === undefined}
                        {...searchResults}
                    />
                </Stack>
            </div>
            { loading === true && (
                <Spinner />
            )}
            { message !== undefined && (
                <MessageBar messageBarType={messageType}>{message}</MessageBar>
            )}
            { selectedUser !== undefined && (
                <Stack className={styles.selectedUserDetails} horizontal verticalAlign="stretch">
                    <div>
                        <Persona
                            text={selectedUser.displayName}
                            secondaryText={selectedUser.email}
                            size={window.outerWidth < 400 ? PersonaSize.size32 : PersonaSize.size72}
                            imageUrl={selectedUser.photo}
                        />
                    </div>
                    <div>
                        <Dropdown
                            className={styles.dropdown}
                            label="Roles"
                            multiSelect
                            selectedKeys={rolesToSave || selectedUser.roles.map((r) => r.id)}
                            options={roleOptions.map((r) => ({
                                key: r.id,
                                text: r.roleName
                            } as IDropdownOption))} 
                            
                            onChange={(e, option) => {
                                if (option) {
                                    const roleId = option.key as number;
                                    let roles = rolesToSave;
                                    if (roles === undefined) roles = selectedUser.roles.map((r) => r.id);
                                    setRolesToSave(
                                        option.selected ? [...roles, roleId] : roles.filter((rid) => rid !== roleId)
                                    );
                                }
                            }}
                        />
                        <Dropdown 
                            className={styles.dropdown}
                            label="Business Relationships"
                            multiSelect
                            selectedKeys={relationshipsToSave || selectedUser.relationships.map((r) => r.id)}
                            options={businessOptions.map((b) => ({
                                key: b.id,
                                text: b.name,
                            } as IDropdownOption))} 
                            onChange={(e, option) => {
                                if (option) {
                                    const relationshipId = option.key as number;
                                    let relationships = relationshipsToSave;
                                    if (relationships === undefined) relationships = selectedUser.relationships.map((r) => r.id);
                                    setRelationshipsToSave(
                                        option.selected ? [...relationships, relationshipId] : relationships.filter((rid) => rid !== relationshipId)
                                    );
                                }
                            }}
                        />
                        <div style={{marginTop: 10}}>
                            <MessageBar messageBarType={MessageBarType.info}>
                                <div>By default, customers and colleagues have access to 
                                any developments for which they are listed as a requestor.</div>
                                <div style={{marginTop: 10}}>Assigning the Account Manager or Prioritisation roles to this user
                                will allow them to view or prioritise developments for any business
                                areas, groupings or partners assigned to them via the Business Relationships
                                option.</div>
                            </MessageBar>
                        </div>
                        <Stack horizontal horizontalAlign="end">
                            <PrimaryButton
                                disabled={saving || !changesToSave}
                                text="Save"
                                onClick={async () => {
                                    setSaving(true);
                                    if (rolesToSave && rolesToSave.length) {
                                        const roleResult = await assignRoles(selectedUser.id, rolesToSave);
                                        if (!roleResult) {
                                            setMessageType(MessageBarType.error);
                                            setMessage('Unable to save roles.');
                                            return;
                                        }
                                    }
                                    if (relationshipsToSave && relationshipsToSave.length) {
                                        const relResult = await assignBusinessRelationships(selectedUser.id, relationshipsToSave);
                                        if (!relResult) {
                                            setMessageType(MessageBarType.error);
                                            setMessage('Unable to save business relationships.');
                                            return;
                                        }
                                    }
                                    setSaving(false);
                                    setMessageType(MessageBarType.success);
                                    let saveMessage = 'User setting updated';
                                    if (selectedUser.id === userContext.profile.id) {
                                        userContext.reloadProfile();
                                    }
                                    setMessage(saveMessage);
                                }}
                            /> 
                        </Stack>
                    </div>
                </Stack>
            )}
        </div>
    );
};

export default UserManagement;