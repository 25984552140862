import React from "react";

import { ChoiceGroup, IChoiceGroupOption } from "office-ui-fabric-react";

export interface IRAGPickerProps {
    disabled?: boolean;
    label?: string;
    value: string | undefined;
    onChange: (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => void;
}
export interface IRAGPickerState {}

export default class RAGPicker extends React.Component<IRAGPickerProps, IRAGPickerState> {
    public render() {
        return (
            <ChoiceGroup 
                disabled={this.props.disabled}
                label={this.props.label}
                selectedKey={this.props.value ? this.props.value : "Green"}
                onChange={this.props.onChange}
                options={[
                    {
                        iconProps: {
                            iconName: "CircleShapeSolid",
                            style: { color: "darkgreen" },
                        },
                        key: "Green",
                        text: "Green",
                    },
                    {
                        iconProps: {
                            iconName: "CircleShapeSolid",
                            style: { color: "darkorange" },
                        },
                        key: "Amber",
                        text: "Amber",
                    },
                    {
                        iconProps: {
                            iconName: "CircleShapeSolid",
                            style: { color: "darkred" },
                        },
                        key: "Red",
                        text: "Red",
                    },
                ]}
            />
        );
    }
}
