import * as React from "react";
import { ChoiceGroup, DefaultButton, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Stack } from "office-ui-fabric-react";
import { CalendarEventPicker } from "./CalendarEventPicker";
import { DevelopmentPicker, IDevelopmentOption } from "./DevelopmentPicker";
import { IActivityUpdate, IBusinessGroup, ICalendarEvent, ISLATransactionDTO } from "../../../types";
import BAUOrgPicker from "./BAUOrgPicker";

export interface IEventDevAssignPanelProps {
    events: ICalendarEvent[];
    activity?: ISLATransactionDTO[];
    onDismiss: () => void;
    onSave: () => void;
}

export const EventDevAssignPanel = (props: IEventDevAssignPanelProps) => {

    const [assignmentType, setAssignmentType] = React.useState("development");
    const [selectedEvents, setSelectedEvents] = React.useState<ICalendarEvent[]>([]);
    const [selectedDevelopment, setSelectedDevelopment] = React.useState<IDevelopmentOption>()
    const [selectedPartner, setSelectedPartner] = React.useState<IBusinessGroup>();
    const [updateText, setUpdateText] = React.useState<string>();
    const [updatePrivacy, setUpdatePrivacy] = React.useState(true);
    const [errorJsx, setErrorJsx] = React.useState<JSX.Element>(undefined);
    const [page, setPage] = React.useState(0);

    const _assignmentTypeOptions = [
        {
            key: "development",
            text: "Development",
            iconProps: { iconName: "GitGraph" },
            styles: { field: { width: 120 }, labelWrapper: { maxWidth: 120 }}
        },
        {
            key: "bau",
            text: "BAU",
            iconProps: { iconName: "Work" }
        }
    ];

    const _saveEventActivity = async () => {
        const isDevActivity = assignmentType === "development";
        if (isDevActivity && (selectedDevelopment === undefined || selectedEvents.length === 0)) return;
        if (!isDevActivity && (selectedPartner === undefined || selectedEvents.length === 0)) return;
        const activityToSave: ISLATransactionDTO[] = [];
        for (let i = 0; i < selectedEvents.length; i++) {
            const event = selectedEvents[i];
            const start = new Date(event.start.dateTime);
            const end = new Date(event.end.dateTime);
            const hours = Math.abs(end.getTime() - start.getTime()) / (60*60*1000);
            const activity: ISLATransactionDTO = {
                type: isDevActivity ? "Activity" : "BAU",
                credit: false,
                hours,
                periodStart: start.toISOString(),
                periodEnd: end.toISOString(),
                comment: event.subject,
                calendarItemId: event.id
            };
            if (isDevActivity) {
                activity.developmentId = selectedDevelopment.id;
                activity.developmentName = selectedDevelopment.name;
            } else {
                activity.businessGroupId = selectedPartner.id;
                activity.businessGroupName = selectedPartner.name;
            }
            activityToSave.push(activity);
        }
        const saveObj: IActivityUpdate = {
            activities: activityToSave,
        };
        if (updateText) {
            saveObj.update = updateText;
            saveObj.updatePrivacy = updatePrivacy;
        }
        const url = isDevActivity ?
            `/api/activity/development/${selectedDevelopment.id}/multiple` :
            `/api/activity/baus`;
        const result = await fetch(url, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json'
            },
            body: JSON.stringify(saveObj),
            credentials: 'include'
        });
        if (!result.ok) {
            setErrorJsx(
                <div>
                    <p>Failed to save one or more event(s)</p>
                </div>
            );
        } else {
            props.onSave();
        }
    };

    return (
        <Panel 
            isOpen={true} 
            isFooterAtBottom={true}
            type={PanelType.medium} 
            headerText={ page === 0 ? "Choose Items" : "Assign to Development" }
            onDismiss={props.onDismiss}
            onRenderFooter={(panelProps) => (
                <Stack horizontal horizontalAlign={page === 0 ? "end" : "space-between"} verticalAlign="center" styles={{root: {margin: 20}}}>
                    { page === 1 && (
                        <DefaultButton
                            onClick={() => setPage(0)}
                            text="Back"
                        />
                    )}
                    { page === 0 && (
                        <PrimaryButton 
                            disabled={selectedEvents.length === 0}
                            onClick={() => setPage(1)}
                            text="Next"
                        />
                    )}
                    { page === 1 && (
                        <PrimaryButton
                            disabled={
                                (assignmentType === "development" && selectedDevelopment === undefined) ||
                                (assignmentType === "bau" && selectedPartner === undefined)
                            } 
                            onClick={_saveEventActivity}
                            text="Save"
                        />
                    )}
                </Stack>
            )}
        >
            {errorJsx !== undefined && (
                <MessageBar messageBarType={MessageBarType.error}>
                    {errorJsx}
                </MessageBar>
            )}

            {page === 0 && (
                <CalendarEventPicker 
                    disableIds={props.activity ? props.activity.map((a) => a.calendarItemId) : undefined}
                    selectedEvents={selectedEvents}
                    onSelectEvents={(events) => {
                        setSelectedEvents(events);
                    }}
                    calendarEvents={props.events} 
                />
            )}

            {page === 1 && (
                <React.Fragment>
                    <ChoiceGroup
                        options={_assignmentTypeOptions}
                        selectedKey={assignmentType}
                        onChange={(e, option) => {
                            setAssignmentType(option.key);
                            if (option.key === "bau") {
                                setUpdatePrivacy(true);
                                setUpdateText(undefined);
                            }
                        }}
                    />
                    <p>Assign {selectedEvents.length} events to {assignmentType === "development" ? "a development" : "BAU"}:</p>
                    {assignmentType === "development" && (
                        <DevelopmentPicker 
                            selectedDevelopment={selectedDevelopment}
                            onSelect={(development, newUpdateText, newUpdatePrivacy) => {
                                setSelectedDevelopment(development);
                                setUpdateText(newUpdateText);
                                setUpdatePrivacy(newUpdatePrivacy);
                            }} 
                        />
                    )}
                    {assignmentType === "bau" && (
                        <BAUOrgPicker
                            selectedPartner={selectedPartner}
                            onSelect={(selectedPartner) => {
                                setSelectedPartner(selectedPartner)
                            }}
                        />
                    )}
                </React.Fragment>
            )}
        </Panel>
    );
}

export default EventDevAssignPanel;