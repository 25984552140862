import React, { Suspense } from "react";
import { Switch, Router, Route, Redirect, RouteComponentProps } from "react-router";
import { Fabric, loadTheme, MessageBar, MessageBarType, Spinner } from 'office-ui-fabric-react';
import { ThemeProvider, PartialTheme, createTheme } from "@fluentui/react";

import MainMenu from "./pages/MainMenu";
import TeamView from "./pages/TeamView";
import TeamMtgView from "./pages/TeamMeetingView";
import NonPrioritisedView from "./pages/NonPrioritisedView";
import ClosedView from "./pages/ClosedView";
import CustomerView from "./pages/CustomerView";
import Activity from "./pages/Activity";
import UserManagement from "./pages/UserManagement";
import PrioritisationSurvey from "./prioritisationMatrix/matrixSurvey";
import CustomerPrioritisation from "./prioritisation/CustomerPrioritisation";
import DevTeamPrioritisation from "./prioritisation/DevTeamPrioritisation";

import { createBrowserHistory } from "history";
import { saveSubscription } from './api/pushMessaging';
import { getMyProfile } from './api';
import { IUserProfile } from './types';
import { DisplayContext } from "./DisplayContext";
import { UserContext } from "./UserContext";

import "./app.module.scss";

const history = createBrowserHistory();

const DevelopmentsView = (props: RouteComponentProps) => {
    const params = props.match.params as Record<string,any>;
    if (params.view.toLowerCase() === "team") {
        if (params.filter === "non-prioritised") {
            return <NonPrioritisedView {...props} />;
        } else if (/closed-\(all\)|not-required|development-complete/i.test(params.filter)) {
            return <ClosedView {...props} />;
        } else {
            return <TeamView {...props} />;
        }
    }
    if (params.view.toLowerCase() === "customer") {
        return <CustomerView {...props} />;
    }
    if (params.view.toLowerCase() === "team-meeting") {
        return <TeamMtgView {...props} />;
    }
};

const OlitView = (props: any) => {
    return (
        <Suspense fallback={<Spinner />}>
            {/* <OlitViewPage history={history} guid={props.match.params.guid} />                 */}
        </Suspense>
    );
};

export interface IAppProps {}
export interface IAppState {
    systemDarkMode?: boolean;
    theme?: "light" | "dark";
    loadingProfile: boolean;
    profile?: IUserProfile;
    error?: string;
}
export default class App extends React.Component<IAppProps, IAppState> {
    private profileRefresh: number;
    constructor(props: IAppProps) {
        super(props);
        this._changeTheme = this._changeTheme.bind(this);
        this._loadProfile = this._loadProfile.bind(this);
        const darkModeMatch = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
        const darkMode = darkModeMatch.matches;
        darkModeMatch.addEventListener('change', (e) => {
            this.setState({
                systemDarkMode: Boolean(e.matches)
            }, this._updateBody);
        });
        this.state = {
            systemDarkMode: darkMode,
            loadingProfile: true
        };
    }
    public componentDidMount() {
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                navigator.serviceWorker.register('/serviceworker.js')
                    .then((reg) => {
                        if (Notification.permission === "granted") {
                            this._getSubscription(reg);
                        } else {
                            if (Notification.permission !== "denied") {
                                this._requestNotificationPermissions(reg);
                            }
                        }
                    });
            });
        }
        this._loadProfile();
        this.profileRefresh = window.setInterval(this._loadProfile, 1000 * 60 * 5);
        const darkMode = (!this.state.theme && this.state.systemDarkMode);
        if (darkMode) {
            document.body.classList.add("dark");
        }
        const themeOverride = /theme=(light|dark);?/.exec(document.cookie);
        if (themeOverride) {
            this.setState({
                theme: themeOverride[1] as "light" | "dark"
            });
        }
    }
    public componentWillUnmount(): void {
        window.clearInterval(this.profileRefresh);
    }
    public componentDidUpdate(prevProps: IAppProps, prevState: IAppState) {
        if (prevState.theme !== this.state.theme) {
            this._updateBody();
        }
    }
    private async _loadProfile() {
        const profile = await getMyProfile();
        if (!(profile instanceof Error)) {
            this.setState({
                ...this.state,
                loadingProfile: false,
                profile,
            });
        } else {
            this.setState({
                ...this.state,
                loadingProfile: false,
                error: profile.message
            });
        }
    }
    private _requestNotificationPermissions(reg: ServiceWorkerRegistration) {
        Notification.requestPermission((status) => {
            if (status === "granted") {
                this._getSubscription(reg);
            }
        });
    }
    private _getSubscription(reg: ServiceWorkerRegistration) {
        reg.pushManager.getSubscription().then((sub1) => {
            if (sub1 === null) {
                reg.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: "BFyTiZcif39SC1Ik0NmHX2uyWDICWpckeoAIgJOQMx4dBenqnkOxvTkvkmuzcuQv_j0ZeNKHdKZHsRarodLMEDA"
                }).then((sub2) => {
                    this._saveSubscription(sub2);
                }).catch((e) => {
                    console.error("Unable to subscribe to push messages", e);
                });
            }
        });
    }
    private async _saveSubscription(sub: PushSubscription) {
        await saveSubscription(sub);
    }

    public render() {

        const RedirectOrMenu = () => {
            let menu = <MainMenu />;
            if (document.cookie) {
                const postConsentRedirection = /dp_postconsent_redirect=([^;]+)/.exec(document.cookie);
                if (postConsentRedirection && postConsentRedirection.length >= 2) {
                    document.cookie = `dp_postconsent_redirect=; Max-Age=0; path=/;`;
                    return <Redirect to={unescape(postConsentRedirection[1])} />;
                }
            }
            return menu;
        };

        const DefaultRoute = () => {
            let route: JSX.Element = <Redirect to="/view/team/my-current-work" />;
            if (document.cookie) {
                const postConsentRedirection = /dp_postconsent_redirect=([^;]+)/.exec(document.cookie);
                if (postConsentRedirection && postConsentRedirection.length >= 2) {
                    document.cookie = `dp_postconsent_redirect=; Max-Age=0; path=/;`;
                    route = <Redirect to={unescape(postConsentRedirection[1])} />;
                }
            }
            return route;
        };

        if (this.state.loadingProfile) {
            return (
                <Spinner label="Loading" />
            );
        }

        if (this.state.error) {
            return (
                <MessageBar messageBarType={MessageBarType.error}>
                    {this.state.error}
                </MessageBar>
            );
        }

        const darkMode = this.state.theme === "dark" || (!this.state.theme && this.state.systemDarkMode);
        let theme: PartialTheme | undefined = darkMode ? this._getTheme() : {};
        loadTheme(theme);
        // if (darkMode) {
        //     theme = this._getTheme();
        // }

        const appContent = <>
            <DisplayContext.Provider value={{ darkMode, changeTheme: this._changeTheme }}>
                <UserContext.Provider value={{ profile: this.state.profile, reloadProfile: this._loadProfile }}>
                    <Router history={history}>
                        <Switch>
                            <Route exact={true} path="/">
                                <RedirectOrMenu />
                            </Route>
                            <Route exact={true} path="/view/team/default">
                                <DefaultRoute />
                            </Route>
                            <Route exact={true} path="/view/:view/:filter/development/:development/tab/:tabIdx" component={DevelopmentsView} />
                            <Route exact={true} path="/view/:view/:filter/development/:development" component={DevelopmentsView} />
                            <Route exact={true} path="/view/:view/:filter" component={DevelopmentsView} />
                            <Route exact={true} path="/admin/user-management">
                                <UserManagement />
                            </Route>
                            <Route exact={true} path="/olit/:guid" component={OlitView} />
                            <Route exact={true} path="/team/sostenuto">
                                {/* <Sostenuto /> */}
                            </Route>
                            <Route exact={true} path="/activity">
                                <Activity />
                            </Route>
                            <Route exact={true} path="/my/sostenuto">
                                {/* <Sostenuto backTo="/team" showAssignedToSelf={true} /> */}
                            </Route>
                            <Route exact={true} path="/team/non-prioritised">
                                <NonPrioritisedView />
                            </Route>
                            <Route exact={true} path="/team/meeting">
                                <Suspense fallback={<Spinner />}>
                                    {/* <TeamMeetingPage /> */}
                                </Suspense>
                            </Route>
                            <Route exact={true} path="/surveytest/:developmentId" component={PrioritisationSurvey} />
                            <Route exact={true} path="/customer/prioritisation">
                                <CustomerPrioritisation />
                            </Route>
                            <Route exact={true} path="/team/prioritisation">
                                <DevTeamPrioritisation />
                            </Route>
                        </Switch>
                    </Router>
                </UserContext.Provider>
            </DisplayContext.Provider>
        </>;

        if (theme !== undefined) {
            return (
                <ThemeProvider theme={theme} applyTo="body">
                    { appContent }
                </ThemeProvider>
                // <Fabric applyTheme applyThemeToBody theme={theme}>
                //     <Customizer settings={{ theme }}>
                //     </Customizer>
                // </Fabric>
            );
        } else {
            return (
                <Fabric>
                    { appContent }
                </Fabric>
            );
        }
    }

    private _getTheme() {
        return createTheme({
            palette: {
                neutralLighterAlt: '#282828',
                neutralLighter: '#313131',
                neutralLight: '#3f3f3f',
                neutralQuaternaryAlt: '#484848',
                neutralQuaternary: '#4f4f4f',
                neutralTertiaryAlt: '#6d6d6d',
                neutralTertiary: '#c8c8c8',
                neutralSecondary: '#d0d0d0',
                neutralPrimaryAlt: '#dadada',
                neutralPrimary: '#ffffff',
                neutralDark: '#f4f4f4',
                black: '#f8f8f8',
                white: '#1f1f1f',
                themePrimary: '#3a96dd',
                themeLighterAlt: '#020609',
                themeLighter: '#091823',
                themeLight: '#112d43',
                themeTertiary: '#235a85',
                themeSecondary: '#3385c3',
                themeDarkAlt: '#4ba0e1',
                themeDark: '#65aee6',
                themeDarker: '#8ac2ec',
                accent: '#3a96dd'
            },
            semanticColors: {
                listText: '#f8f8f8',
                bodyText: '#f8f8f8',
                errorText: '#e28388',
                errorBackground: '#e28388',
                errorIcon: '#e28388', 
                bodyBackground: '#1f1f1f'
            }
        });
    }

    private _updateBody() {
        const darkMode = this.state.theme === "dark" || (!this.state.theme && this.state.systemDarkMode);
        if (darkMode) {
            document.body.classList.add("dark");
        } else {
            document.body.classList.remove("dark");
        }
    }

    private _changeTheme(dark?: boolean) {
        let theme: "light" | "dark" | undefined;
        if (dark !== undefined) {
            theme = dark ? "dark" : "light";
        }
        this.setState({
            theme
        });
    }
}
