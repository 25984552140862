import { TextField } from "office-ui-fabric-react";
import * as React from "react";

import styles from "./SearchFilter.module.scss";

export interface ISearchFilterProps {
    filterCount: number;
    itemCount: number;
    onFilterChanged: (filterVal: string) => void;
}

const SearchFilter = (props: ISearchFilterProps) => {

    const { filterCount, itemCount, } = props;
    const [ filter, setFilter ] = React.useState<string>("");

    return (
        <div className={styles.filterContainer}>
            <div className={styles.label}>Filter:</div>
            <TextField 
            key="globalFilter" 
            className={styles.filter} 
            value={filter} 
            onChange={(e, newValue) => {
                setFilter(newValue);
                props.onFilterChanged(newValue);
            }}/>
            { filterCount < itemCount && (
                <div className={styles.resultCount}>{filterCount}/{itemCount}</div>
            )}
        </div>
    );
};

export default SearchFilter;