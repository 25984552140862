import * as React from "react";
import { DetailsList, Icon, Label, MessageBar, MessageBarType, Persona, PersonaSize, SelectionMode, TooltipHost } from "office-ui-fabric-react";
import { getPlatformIconName } from "../utils";
import { IUserLookup, ViewItem } from "../types";

import styles from "./UpcomingWork.module.scss";

export interface ICustomerWorkUpdateProps {
    developments?: ViewItem[];
}

export const CustomerWorkUpdate = (props: ICustomerWorkUpdateProps) => {

    const renderTitle = React.useCallback((item: ViewItem) => {
        return (
            <div>
                <p className={styles.upcomingDevTitle}>{item.title}</p>
                <div>
                    Requested by:<br/>
                    {item.requestors.map((requestor: IUserLookup) => {
                        const photo = requestor.photo;
                        let imageUrl: string;
                        if (photo) {
                            imageUrl = `data:${photo.type};base64,${photo.data}`;
                        }
                        return (
                            <TooltipHost 
                                key={requestor.name}
                                content={requestor.name}
                                tooltipProps={{
                                    onRenderContent: (tooltipProps) => {
                                        const name = tooltipProps?.content as string;
                                        return (
                                            <Persona key={name} text={name} secondaryText={requestor.mail} imageUrl={imageUrl} />
                                        );
                                    }
                                }}>
                                <div>
                                    <Persona showOverflowTooltip={false} size={PersonaSize.size8} text={requestor.name} />
                                </div>
                            </TooltipHost>
                        );
                    })}
                </div>
            </div>
        );
    }, []);

    const columns = React.useMemo(() => [
        {
            key: "platform",
            name: "Platform",
            isIconOnly: true,
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: ViewItem) => {
                return (
                    <TooltipHost content={item.platform}>
                        <Icon style={{fontSize: "2.2em"}} iconName={getPlatformIconName(item.platform)} />
                    </TooltipHost>
                );
            }
        },
        {
            key: "title",
            name: "Development Name",
            fieldName: "title",
            minWidth: 100,
            maxWidth: 200,
            onRender: renderTitle
        },
        {
            key: "description",
            name: "Description",
            fieldName: "description",
            minWidth: 200,
        },
        {
            key: "status",
            name: "Status",
            fieldName: "status",
            minWidth: 80,
            onRender: (item: ViewItem) => item.status.text
        }
    ], []);

    let upcomingWork: ViewItem[], workInProgress: ViewItem[];
    if (props.developments) {
        upcomingWork = props.developments.filter((d) => d.status.id < 3);
        workInProgress = props.developments.filter((d) => d.status.id > 5);
    } 

    return (
        <>
            { workInProgress.length > 0 && (
                <>
                    <Label style={{fontSize: "1.5em"}}>Work In Progress</Label>
                    <DetailsList
                        className={styles.table}
                        items={workInProgress}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                    />
                </>
            )}
            { upcomingWork.length > 0 && (
                <>
                    <Label style={{fontSize: "1.5em"}}>Upcoming Work</Label>
                    <DetailsList
                        className={styles.table}
                        items={upcomingWork}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                    />
                </>
            )}
            { upcomingWork.length === 0 && workInProgress.length === 0 && (
                <MessageBar messageBarType={MessageBarType.info}>No current or upcoming work to discuss.</MessageBar>
            )}
        </>
    );
}

export default CustomerWorkUpdate;