import React from "react";

import { 
    DefaultButton, 
    Dialog, 
    DialogContent, 
    DialogFooter, 
    DialogType, 
    MessageBar, 
    MessageBarType, 
    PrimaryButton, 
    Spinner,
    TextField, 
} from "office-ui-fabric-react";
import { IDevelopmentItem } from "../../types";

import * as API from "../../api";

export interface IDeleteDialogProps {
    className?: string;
    onDeletion: (developmentId: number) => void;
}
export interface IDeleteDialogState {
    deleting: boolean;
    development?: IDevelopmentItem;
    confirmBox: string | undefined;
    isOpen: boolean;
}

export default class DeleteDialog extends React.Component<IDeleteDialogProps, IDeleteDialogState> {
    constructor(props: IDeleteDialogProps) {
        super(props);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.state = {
            confirmBox: "",
            deleting: false,
            isOpen: false,
        };
    }
    public open(development: IDevelopmentItem) {
        this.setState({
            ...this.state,
            confirmBox: "",
            deleting: false,
            development,
            isOpen: true,
        });
    }
    
    public render() {
        if (!this.state.development) {
            return null;
        } else {
            return (
                <Dialog onDismiss={this.close} className={this.props.className} hidden={!this.state.isOpen} title="Are you sure?" type={DialogType.largeHeader}>
                    <DialogContent>
                        <MessageBar messageBarType={MessageBarType.warning}>
                            <ul>
                                <li>This operation CANNOT be undone.</li>
                                <li>This operation will permanently delete the development <strong>
                                    {this.state.development.title}</strong> and all
                                    related data (developer assignments, updates etc.)</li>
                            </ul>
                        </MessageBar>
                        <p>Please enter the development name as above to confirm:</p>
                        <TextField value={this.state.confirmBox} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
                            this.setState({
                                ...this.state,
                                confirmBox: newValue,
                            });
                        }} 
                        errorMessage={this.state.confirmBox !== this.state.development.title ? 
                            "Enter the development name above to confirm you wish to delete this development" : ""
                        }
                        />
                    </DialogContent>
                    <DialogFooter>
                        { this.state.deleting && (
                            <Spinner style={{display: "inline-block"}} />
                        )}
                        <PrimaryButton text="Confirm" 
                            disabled={this.state.deleting || this.state.confirmBox !== this.state.development.title} 
                            onClick={() => {
                                this.setState({
                                    ...this.state,
                                    deleting: true,
                                }, () => {
                                    if (this.state.development && this.state.development.id) {
                                        this._deleteItem(this.state.development.id);
                                    }
                                });
                            }}
                            />
                        <DefaultButton text="Cancel" onClick={this.close}/>
                    </DialogFooter>
                </Dialog>
            );
        }
    }

    private close() {
        this.setState({
            ...this.state,
            confirmBox: "",
            deleting: false,
            development: undefined,
            isOpen: false,
        });
    }

    private async _deleteItem(id: number) {
        const response = await API.deleteDevelopment(id);
        if (this.state.development && this.state.development.id && response && response.ok) {
            this.props.onDeletion(this.state.development.id);
            this.close();
        } else {
            alert('Unable to delete development');
        }
    }
}
