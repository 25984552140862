import * as React from "react";
import { useState } from "react";
import { 
    css,
    DefaultButton, 
    Icon, 
    PrimaryButton, 
    Stack,
    TooltipHost 
} from "office-ui-fabric-react";
import AssignedToStack from "../../../common/AssignedToStack";
import CompactDateIcon from "../../components/CompactDateIcon";
import ProgressBar from "../../../common/ProgressBar";

/* types */
import { ISostenutoItem } from "../../../api/sostenuto.types";
import { 
    IDevelopmentItem, 
    IOLITDevelopmentItem,
    IStatusOption, 
} from "../../../types";

/* utils */
import { getPlatformIconName } from "../../../utils";

import styles from "./DevListCompact.module.scss";

export interface IDevListCompactProps {
    statusFilter: boolean;
    items: (IDevelopmentItem | IOLITDevelopmentItem | ISostenutoItem)[];
    onNew?: () => void;
    onEdit?: (item: any, tabId?: string) => void;
    onStatusChange?: (item: IDevelopmentItem | IOLITDevelopmentItem, newStatus: IStatusOption) => void;
}

export const DevListCompact = (props: IDevListCompactProps) => {
    const { statusFilter } = props;

    const [selectedItem, setSelectedItem] = useState<number|undefined>();

    return (
        <div>
            {
                props.items.map((item) => {
                    const itemSelected = selectedItem === item.id;
                    
                    let itemStyle = styles.development;
                    let itemInfoStyle = styles.itemInfo;
                    if (itemSelected) {
                        itemStyle = css(itemStyle, styles.selected);
                        itemInfoStyle = css(itemInfoStyle, styles.selected);
                    }
                    
                    return (
                        <div className={styles.item} key={item.id} onClick={() => setSelectedItem(item.id)}>
                            <Stack className={itemStyle} horizontal horizontalAlign="start">
                                <div className={styles.platformIconContainer}>
                                    <Icon iconName={getPlatformIconName(item.platform)} />
                                </div>
                                <div className={styles.detail}>
                                    <Stack className={styles.titleGroup} horizontal horizontalAlign="space-between">
                                        <div>
                                            <div className={styles.title}>{item.title}</div>
                                            { statusFilter === false && (
                                                <div><em>{item.status.text}</em></div>
                                            )}
                                        </div>
                                        <AssignedToStack className={styles.assignedTo} item={item} />
                                        {/* <div className={styles.assignedTo}>
                                            { (item as IDevelopmentItem).assignedTo.map((user) => (
                                                <TooltipHost key={user.mail} content={[
                                                    <span>{user.name}</span>,<br/>,
                                                    <span>({user.mail})</span>
                                                ]}>
                                                    <PersonaCoin size={PersonaSize.size24} text={user.name} secondaryText={user.mail} />
                                                </TooltipHost>
                                            )) }
                                        </div> */}
                                    </Stack>
                                    <div className={styles.progressBarContainer}>
                                        <ProgressBar barValue={item.complete} ragClass={item.completedRagClass} />
                                    </div>
                                    <Stack className={styles.updateDetail} horizontal horizontalAlign="space-between">
                                        <Stack horizontal horizontalAlign="start">
                                            {Boolean(item.reviewDate) === true && (
                                                <CompactDateIcon iconName="EntryView" tooltip="Review Date" dateStr={item.reviewDate} redIfPast={true} />
                                            )}
                                            {Boolean(item.endDate) === false && Boolean(item.estimatedEnd) === true && (
                                                <CompactDateIcon iconName="IconSetsFlag" tooltip="Estimated End Date" dateStr={item.estimatedEnd} redIfPast={true} />
                                                )}
                                            {Boolean(item.endDate) === true && (
                                                <CompactDateIcon iconName="IconSetsFlag" tooltip="End Date" dateStr={item.endDate} />
                                            )}
                                        </Stack>
                                        <Stack horizontal horizontalAlign="end">
                                            {Boolean(item.modifiedDate) === true && (
                                                <CompactDateIcon iconName="DateTime" tooltip="Date Modified" dateStr={item.modifiedDate} />
                                            )}
                                            {Boolean(item.latestUpdate) === true && (
                                                <TooltipHost content={item.latestUpdate}>
                                                    <Icon iconName="Comment" />
                                                </TooltipHost>
                                            )}
                                        </Stack>
                                    </Stack>
                                </div>
                            </Stack>
                            <div className={itemInfoStyle}>
                                { selectedItem === item.id && (
                                    <div className={styles.spacer}>
                                        <Stack horizontal horizontalAlign="space-between">
                                            <Stack horizontal>
                                                { Boolean(item.businessGroups) === true && (
                                                    <TooltipHost content="Organisation">
                                                        <div style={{marginRight: 10}}>
                                                            <Icon iconName="Org" /> {(item as IDevelopmentItem).businessGroups.reduce((prev, cur, i) => (i > 0 ? prev + ", " : "") + cur.name, "")}
                                                        </div>
                                                    </TooltipHost>
                                                )}
                                                { Boolean(item.project) === true && (
                                                    <TooltipHost content="Associated Project">
                                                        <div style={{marginRight: 10}}>
                                                            <Icon iconName="ProjectCollection" /> {item.project}
                                                        </div>
                                                    </TooltipHost>
                                                )}
                                            </Stack>
                                            <Stack horizontal horizontalAlign="end">
                                                { Boolean(item.ragStatus) === true && (
                                                    <TooltipHost content="RAG">
                                                        <div style={{marginRight: 10}}>
                                                            RAG: <Icon style={{color: item.ragStatus.toLowerCase() }} iconName="CircleShapeSolid" /> 
                                                        </div>
                                                    </TooltipHost>
                                                )}
                                            </Stack>
                                        </Stack>
                                        <Stack horizontal>
                                            <div><strong>Developers:</strong></div>
                                            <div className={styles.developers}>{(item as IDevelopmentItem).assignedTo.reduce((prev, cur, i) => (i > 0 ? prev + ", " : "") + cur.name, "")}</div>
                                        </Stack>
                                        <Stack horizontal horizontalAlign="space-around" style={{marginTop: 10}}>
                                            <PrimaryButton text="Open" onClick={() => {
                                                props.onEdit(item);
                                            }} />
                                            <DefaultButton text="Quick Update" onClick={() => {
                                                props.onEdit(item, "3");
                                            }} />
                                        </Stack>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};