import * as React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// load theme and initialise office-ui icons
import { initializeIcons } from "@uifabric/icons";
import { loadTheme } from 'office-ui-fabric-react';
loadTheme({
  palette: {
      black: '#494847',
      neutralDark: '#605e5d',
      neutralLight: '#eaeaea',
      neutralLighter: '#f4f4f4',
      neutralLighterAlt: '#f8f8f8',
      neutralPrimary: '#323130',
      neutralPrimaryAlt: '#8d8b8a',
      neutralQuaternary: '#d0d0d0',
      neutralQuaternaryAlt: '#dadada',
      neutralSecondary: '#a3a2a0',
      neutralTertiary: '#bab8b7',
      neutralTertiaryAlt: '#c8c8c8',
      themeDark: '#86253a',
      themeDarkAlt: '#9e2c45',
      themeDarker: '#631c2b',
      themeLight: '#e7b5c0',
      themeLighter: '#f2d6dc',
      themeLighterAlt: '#fcf5f6',
      themePrimary: '#b1324e',
      themeSecondary: '#b9445d',
      themeTertiary: '#d07689',
      white: '#ffffff',
  },
});
initializeIcons();

const appContainer = document.getElementById("root");
if (appContainer) {
    const docElement = document.getElementById("root");
    const reactElement = React.createElement(App);
    ReactDOM.render(reactElement, docElement);
}

