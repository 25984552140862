import * as React from "react";
import { DefaultButton, Dialog, IPersonaProps, PrimaryButton, Stack, TextField } from "office-ui-fabric-react";

export interface IRequestorDialogProps {
    isOpen: boolean;
    requestor?: IPersonaProps;
    onClose: (value?: IPersonaProps) => void;
}

export const RequestorDialog = (props: IRequestorDialogProps) => {

    let initialName: string, initialEmail: string;
    if (props.requestor) {
        initialName = props.requestor.text;
        initialEmail = props.requestor.secondaryText;
    }
    const [name, setName] = React.useState<string>(initialName);
    const [email, setEmail] = React.useState<string>(initialEmail);

    const title = props.requestor ? "Edit requestor" : "Add a new requestor";
    const emailAccuracy = "Please ensure the email address is accurate, this will be used to provide the user with access to updates.";
    const instruction = props.requestor ? 
        `Amend the requestor's details below. ${emailAccuracy}` :
        `Add a new requestor's detials below. ${emailAccuracy}`;

    const isValidMail = (address: string) => {
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(address);
    };

    return (
        <Dialog isOpen={props.isOpen} dialogContentProps={{
            title
        }}>
            <p>{instruction}</p>
            <Stack horizontal horizontalAlign="space-between" verticalAlign="start">
                <TextField 
                    required autoFocus 
                    styles={{
                        root: {
                            marginRight: 10
                        }
                    }}
                    errorMessage={!name || name.trim() === "" ? "Please enter a name" : undefined}
                    label="Name" value={name} 
                    onChange={(e, newValue) => setName(newValue)} />
                <TextField 
                    required 
                    type="email"
                    errorMessage={!email || email.trim() === "" || !isValidMail(email) ? "Please enter a valid email" : undefined}
                    label="Email" value={email} 
                    onChange={(e, newValue) => setEmail(newValue)} />
            </Stack>
            <Stack horizontal horizontalAlign="end" style={{marginTop: 20}}>
                <PrimaryButton disabled={
                    (!name || name.trim() === "") ||
                    (!email || email.trim() === "" || !isValidMail(email))
                } style={{marginRight: 10}} text="OK" onClick={() => {
                    props.onClose({
                        text: name, secondaryText: email
                    } as IPersonaProps);
                }}></PrimaryButton>
                <DefaultButton text="Cancel" onClick={() => {
                    props.onClose();
                }}></DefaultButton>
            </Stack>
        </Dialog>
    );
};

export default RequestorDialog;