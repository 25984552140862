import { css, Label } from "office-ui-fabric-react";
import * as React from "react";

import styles from "./FormSection.module.scss";

export interface IFormSectionProps {
    label?: string;
    style?: React.CSSProperties;
    shade?: boolean;
}

export const FormSection: React.FC<IFormSectionProps> = (props) => {
    let sectionContainerStyleArr = [styles.sectionContainer];
    if (props.shade) sectionContainerStyleArr.push(styles.shade);

    return (
        <div className={styles.formSection} style={props.style}>
            { props.label && props.label.trim() !== "" && (
                <Label>{props.label}</Label>
            )}
            <div className={css(...sectionContainerStyleArr)}>
                {props.children}
            </div>
        </div>
    );
};

export default FormSection;