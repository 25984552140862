import { IPrioritisation, IPrioritisationData } from "../types/Prioritisation";
import { ISurveyResponse } from "../prioritisationMatrix/matrix.types";

export async function saveSurveyResult(developmentId: number, data: ISurveyResponse) {
    const response = await fetch(`/api/developments/surveyResult/${developmentId}`, {
        method: 'POST',
        body: JSON.stringify(data),
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            "content-type": 'application/json'
        },
    });
    if (response.ok) {
        return true;
    } else {
        return false;
    }
}

export async function loadSurveyResult(developmentId: number) {
    const response = await fetch(`/api/developments/surveyResult/${developmentId}`, {
        credentials: 'include',
        headers: {
            Accept: 'application/json'
        }
    });
    if (response.ok) {
        return await response.json() as ISurveyResponse;
    } else {
        if (response.status === 404) {
            return new Error("Not Found");
        } else {
            return new Error("Unable to load survey result");
        }
    }
}

export async function loadPrioritisedDevelopments() {
    const response = await fetch(`/api/prioritisation/devService/current`, {
        credentials: 'include',
        headers: {
            Accept: 'application/json',
        }
    });
    if (response.ok) {
        return await response.json() as IPrioritisationData;
    } else {
        return new Error("Unable to load prioritised developments");
    }
}

export async function loadDevelopmentsByBusinessArea(businessAreaId: number) {
    const response = await fetch(`/api/prioritisation/businessArea/${businessAreaId}/developments/byOrgPriority`,
    {
        credentials: 'include',
        headers: {
            Accept: 'application/json'
        }
    });
    if (response.ok) {
        return await response.json() as IPrioritisation;
    } else {
        return new Error("Unable to load developments");
    }
}

export async function saveDevServicePriorityInfo(data: IPrioritisation, isDraft: boolean, itemId?: number) {
    let requestUrl = `/api/prioritisation/devService`;
    requestUrl += isDraft ? `/draft` : `/publish`;
    if (itemId !== undefined) {
        if (isNaN(itemId)) return Error ("Invalid draft ID");
        requestUrl += `/${itemId}`;
    }
    const response = await fetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify(data),
        credentials: 'include',
        headers: {
            "Accept": 'application/json',
            "Content-Type": 'application/json',
        },
    });
    if (response.ok) {
        return await response.json() as IPrioritisation;
    } else {
        return new Error("Unable to load developments");
    }
}

export async function savePriorityInfo(businessAreaId: number, data: IPrioritisation, isDraft: boolean, itemId?: number) {
    let requestUrl = `/api/prioritisation/businessArea/${businessAreaId}`;
    requestUrl += isDraft ? `/draft` : `/publish`;
    if (itemId !== undefined) {
        if (isNaN(itemId)) return Error ("Invalid draft ID");
        requestUrl += `/${itemId}`;
    }
    const response = await fetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify(data),
        credentials: 'include',
        headers: {
            "Accept": 'application/json',
            "Content-Type": 'application/json',
        },
    });
    if (response.ok) {
        return await response.json() as IPrioritisation;
    } else {
        return new Error("Unable to load developments");
    }
}