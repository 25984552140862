import { ISLATransactionDTO } from "../types";

export async function getMyActivity(weekStart: Date, weekEnd: Date) {
    const response = await fetch(`/api/activity/my?startDate=${weekStart.toISOString()}&endDate=${weekEnd.toISOString()}`, {
        headers: {
            'accept': 'application/json',
        },
        credentials: 'include',
    });

    if (response.ok) {
        return await response.json() as ISLATransactionDTO[];
    } else {
        return new Error(`Unable to load activity: ${response.statusText}`);
    }
}

export function getCalendarTeamsItems(weekStart: Date, weekEnd: Date) {
    return fetch(`/api/activity/calendar?startDate=${weekStart.toISOString()}&endDate=${weekEnd.toISOString()}`, {
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "x-ReturnUrl": `${window.location.protocol}//${window.location.host}`
        },
        credentials: 'include',
    });
}