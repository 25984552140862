import React from "react";

/* types */
import { IDevelopmentItem, IRequestorInfo, IUserLookup } from "../types";
import { FilterMenuItem } from "./components/developmentViews/FilterMenu";

/* Fabric UI */
import {
    IColumn,
    IFacepilePersona,
    PersonaSize,
    TooltipHost,
} from "office-ui-fabric-react";

/* base view */
import BaseView, { IBaseViewState, ViewDisplayMode } from "./BaseView";
import { DevTableColumn } from "./components/developmentViews/DevTable.types";

/* utils */
import { 
    getStatusIconName, 
    getPlatformIconName,
} from "../utils";
import { RouteComponentProps, withRouter } from "react-router";

/* State and Props */
export interface ICustomerViewProps extends RouteComponentProps {
}
export interface ICustomerViewState extends IBaseViewState {}

export class CustomerView extends BaseView<ICustomerViewProps, ICustomerViewState> {

    protected viewName = "Customer / Colleague View";
    
    constructor(props: ICustomerViewProps) {
        super(props);
        this.isSostenutoView = false;
        this._isDevTeamView = false;

        // init state
        this.state = {
            activeColumns: this._tableConfig,
            editFormTabId: undefined,
            editingItem: undefined,
            items: undefined,
            itemCounts: {},
            loading: true,
            loadingMessage: "Loading",
            statusOptions: undefined,
            displayMode: ViewDisplayMode.Table,
            statusFilter: false,
            toastNotifications: []
        };
    }

    //#region query / filter menu
    protected _menuConfig: FilterMenuItem[] = [
        {
            text: "All Requests",
            itemQuery: { status: "New || More Information Required || Review || On Hold || Scoping Stage || Pipeline || In Development || Testing || Ready for Deployment || Live" },
            columnFilter: (cols) => cols.filter((c: any) => c.fieldName !== "dateSubmitted" && c.fieldName !== "Status"),
            iconName: getStatusIconName("All"),
        }, 
        { 
            text: "On Hold", 
            itemQuery: { status: "On Hold" },
            columnFilter: (cols) => cols.filter((c: any) => c.fieldName !== "complete"), 
            iconName: getStatusIconName("On Hold") 
        },
        { 
            text: "Scoping Stage",
            itemQuery: { status: "Scoping Stage" },
            iconName: getStatusIconName("Scoping Stage")
        }, 
        { 
            text: "In Development",
            itemQuery: { status: "In Development" },
            columnFilter: (cols) => cols.filter((c: any) => c.fieldName !== "complete"), 
            iconName: getStatusIconName("In Development"),
            isDefault: true
        }, 
        { 
            text: "Testing",
            itemQuery: { status: "Testing" },
            columnFilter: (cols) => cols.filter((c: any) => c.fieldName !== "complete"), 
            iconName: getStatusIconName("Testing")
        }, 
        { 
            text: "Ready for Deployment",
            itemQuery: { status: "Ready for Deployment" },
            columnFilter: (cols) => cols.filter((c: any) => c.fieldName !== "complete"), 
            iconName: getStatusIconName("Ready for Deployment")
        }, 
        { 
            text: "Live",
            itemQuery: { status: "Live" },
            iconName: getStatusIconName("Live")
        }, 
    ];
    //#endregion

    //#region table config
    protected _tableConfig: DevTableColumn[] = [
        {
            type: "Icon",
            fieldName: "platform",
            minWidth: 40,
            maxWidth: 40,
            iconConversion: (value: any) => ({
                iconName: getPlatformIconName(value),
                styles: { root: { fontSize: "2.5em", color: "#b1324e" }}
            }),
            mapToText: (item: IDevelopmentItem) => item.platform,
            filters: "UniqueText"
        },
        {
            type: "Date",
            fieldName: "dateSubmitted",
            displayName: "Submitted",
            minWidth: 80,
            maxWidth: 80,
        },
        {
            type: "Text",
            fieldName: "title",
            displayName: "Development",
            minWidth: 100,
        },
        {
            type: "Persona",
            fieldName: "combinedRequestors",
            displayName: "Requestors",
            minWidth: 100,
            maxWidth: 200,
            personaConversion: (value: IRequestorInfo) => ({
                size: PersonaSize.size32,
                name: value.contactName,
                data: {
                    email: value.contactEmail
                }
                // secondaryText: value.contactEmail,
                // imageUrl: value.contactPhoto ? `data:${value.contactPhoto.type};base64,${value.contactPhoto.data}` : undefined
            } as IFacepilePersona),
            mapToText: (item: IDevelopmentItem) => {
                return item.combinedRequestors.map((value, i, arr) =>
                    `${value.contactName} ${value.contactEmail}` + (i + 1 < arr.length ? ',' : '') 
                ).join('\n');
            }
        },
        {
            type: "Text",
            fieldName: "businessGroups",
            minWidth: 100,
            objectValueMapping: (value: any) => value.name,
            filters: "UniqueText",
            mapToText: (item: IDevelopmentItem) => {
                return item.businessGroups.map((value, i, arr) =>
                    value.name 
                );
            }
        },
        {
            type: "Date",
            fieldName: "startDate",
            displayName: "Start Date",
            minWidth: 80,
            maxWidth: 80
        },
        {
            type: "Date",
            fieldName: "endDate",
            displayName: "End Date",
            minWidth: 80,
            maxWidth: 80
        },
        {
            type: "Progress",
            fieldName: "complete",
            minWidth: 100,
            maxWidth: 100,
            progressConversion: (value, item) => {
                return {
                    barValue: value,
                    barClass: item.completedRagClass
                };
            },
            mapToText: (item) => item.complete
        },
        {
            type: "Text",
            fieldName: "latestUpdate",
            displayName: "Latest Update",
            minWidth: 200,
            onRender: (item?: any, index?: number | undefined, column?: IColumn | undefined) => {
                return (
                    <TooltipHost content="Double click to add an update">
                        <div style={{whiteSpace: "pre-wrap"}} className="latestUpdate">
                            {item.latestUpdate}
                        </div>
                    </TooltipHost>
                );
            },
        },
        {
            type: "Date",
            fieldName: "modifiedDate",
            minWidth: 80,
            maxWidth: 80,
        },
        {
            type: "Persona",
            fieldName: "assignedTo",
            minWidth: 100,
            maxWidth: 100,
            filters: "UniqueText",
            personaConversion: (value: IUserLookup) => ({
                size: PersonaSize.size32,
                name: value.name,
                data: {
                    email: value.mail
                }
                // secondaryText: value.mail,
                // imageUrl: value.photo ? `data:${value.photo.type};base64,${value.photo.data}` : undefined
            } as IFacepilePersona),
            mapToText: (item) => item.assignedTo.map((a: any) => a.mail)
        },
        {
            type: "Text",
            fieldName: "status",
            objectValueMapping: (value: any) => value.text,
            mapToText: (item) => item.status.text,
            filters: "UniqueText",
            minWidth: 100,
            maxWidth: 100
        }
    ];
    //#endregion
}

export default withRouter(CustomerView);