import * as React from "react";
import { DefaultButton, Panel, PanelType, PrimaryButton, Stack } from "office-ui-fabric-react";
import { SortComponent, SortIntent } from "./SortComponent";
import { ViewItem } from "../types";

export interface IConflictResolverProps {
    developmentGroups: ViewItem[][];
    onResolved: (orderedDevelopments: ViewItem[]) => void;
    onDoLater: () => void;
}

export const ConflictResolver = (props: IConflictResolverProps) => {
    const [orderedDevelopmentGroups, setOrderedDevelopmentGroups] = React.useState(props.developmentGroups);
    const [currentConflictGroup, setCurrentConflictGroup] = React.useState(0);

    const onButtonClick = React.useCallback(() => {
        setCurrentConflictGroup(Math.min(currentConflictGroup + 1, props.developmentGroups.length - 1));
        if (currentConflictGroup + 1 === orderedDevelopmentGroups.length) {
            // save this back
            let i = 1;
            const allDevelopments = orderedDevelopmentGroups.reduce((prev, cur, curIdx, arr) => {
                cur.forEach((development, devIdx) => {
                    development.businessPriority = i;
                    i++;
                });
                return [...prev, ...cur];
            }, []);
            // allDevelopments.sort((a,b) => {
            //     if (a.businessPriority === null) return 1;
            //     if (b.businessPriority === null) return -1;
            //     return a.businessPriority - b.businessPriority
            // });
            props.onResolved(allDevelopments);
        }
    }, [props, orderedDevelopmentGroups, currentConflictGroup]);

    if (orderedDevelopmentGroups.length === 0) return null;

    return (
        <Panel 
            isOpen={true}
            type={PanelType.medium} 
            headerText="Resolve Conflicts" 
            isFooterAtBottom={true}
            hasCloseButton={false}
            onRenderFooter={(panelProps) => (
                <Stack styles={{root: {margin: 20}}} horizontal horizontalAlign="space-between" verticalAlign="center">
                    <DefaultButton text="Do this later" onClick={props.onDoLater}/ >
                    <PrimaryButton
                        text={currentConflictGroup + 1 < orderedDevelopmentGroups.length ? "Next" : "Finish"}
                        onClick={onButtonClick}
                    />
                </Stack>
            )}
        >
            <p>Each of the following developments were ranked: #{(currentConflictGroup + 1)}</p>
            <p>If the order below is acceptable, click next - otherwise, drag the tiles to reorder:</p>
            <SortComponent 
                intent={SortIntent.ResolveConflicts}
                developments={orderedDevelopmentGroups[currentConflictGroup]}
                onUpdate={(developments) => {
                    const newOrderedGroups = [...orderedDevelopmentGroups]
                    newOrderedGroups[currentConflictGroup] = developments.sort((a,b) => {
                        if (a.businessPriority === null) return 1;
                        if (b.businessPriority === null) return -1
                        return a.businessPriority - b.businessPriority;
                    });
                    setOrderedDevelopmentGroups(newOrderedGroups);
                }}
                sortProperty="businessPriority"
            />
        </Panel>
    );
}

export default ConflictResolver;