import * as React from "react";
import { Calendar, DatePicker, DateRangeType, DefaultButton, Dialog, DialogFooter, Dropdown, Icon, IDropdownOption, PrimaryButton, Stack, TextField, Toggle } from "office-ui-fabric-react";
import { IDevelopmentUpdate } from "../../types";

export interface IEditUpdateDialogProps {
    update: IDevelopmentUpdate;
    onDismiss: () => void;
    onUpdated: (editedUpdate: IDevelopmentUpdate) => void;
    onSave: (editedUpdate: IDevelopmentUpdate) => void;
}

export const EditUpdateDialog = (props: IEditUpdateDialogProps) => {
    const { update } = props;

    return (
        <Dialog 
            dialogContentProps={{
                title: "Edit Update", onDismiss: props.onDismiss
            }} 
            hidden={false}>
            <TextField multiline label="Update" value={update.entry} onChange={(e, newValue) => {
                const editedUpdate = {...update};
                editedUpdate.entry = newValue;
                props.onUpdated(editedUpdate);
            }} styles={{root: {marginBottom: 20}}} />
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                <Stack horizontal verticalAlign="center">
                    <Icon iconName={update.private ? "Hide" : "View"} style={{marginRight:10, fontSize:24}} />
                    <Toggle styles={{root: {marginBottom: 0, marginRight: 20}}} checked={!update.private} onChange={(e, isChecked) => {
                        const editedUpdate = {...update};
                        editedUpdate.private = !isChecked;
                        props.onUpdated(editedUpdate);
                    }}/>
                </Stack>
                <DatePicker value={new Date(update.date)} onSelectDate={(date) => {
                    const editedUpdate = {...update};
                    const oldDate = new Date(editedUpdate.date);
                    const hours = oldDate.getHours();
                    const mins = oldDate.getMinutes();
                    date.setHours(hours, mins, 0, 0);
                    editedUpdate.date = date.toISOString();
                    props.onUpdated(editedUpdate);
                }} calendarAs={(calendarProps) => {
                    const date = new Date(update.date);
                    const hours = [...new Array(24)].map((v, idx) => ("0" + idx).slice(-2));
                    const mins = [...new Array(60)].map((v, idx) => ("0" + idx).slice(-2));
                    const selectedHours = ("0" + date.getHours()).slice(-2);
                    const selectedMins = ("0" + date.getMinutes()).slice(-2);
                    return <div>
                        <Calendar {...calendarProps} 
                        isMonthPickerVisible={false}
                        dateRangeType={DateRangeType.Day}
                        autoNavigateOnSelection={false}
                        showGoToToday={true}
                        value={new Date(update.date)}
                        showSixWeeksByDefault={true}
                        />
                        <Stack horizontal verticalAlign="center">
                            <Icon iconName="Clock" style={{marginRight: 20}} />
                            <Dropdown 
                                selectedKey={selectedHours}
                                options={hours.map((v) => ({
                                    key: v, text: v      
                                } as IDropdownOption))}
                                onChange={(e, option) => {
                                    if (option && option.key !== undefined) {
                                        const editedUpdate = {...update};
                                        const newDate = new Date(editedUpdate.date);
                                        newDate.setHours(parseInt(option.key as string, 10));
                                        editedUpdate.date = newDate.toISOString();
                                        props.onUpdated(editedUpdate);
                                    }
                                }}
                            />
                            <span>:</span>
                            <Dropdown
                                selectedKey={selectedMins}
                                options={mins.map((v) => ({
                                    key: v, text: v
                                } as IDropdownOption))}
                                onChange={(e, option) => {
                                    if (option && option.key !== undefined) {
                                        const editedUpdate = {...update};
                                        const newDate = new Date(editedUpdate.date);
                                        newDate.setMinutes(parseInt(option.key as string, 10));
                                        editedUpdate.date = newDate.toISOString();
                                        props.onUpdated(editedUpdate);
                                    }
                                }}
                            />
                        </Stack>
                    </div>
                }} />
            </Stack>
            <DialogFooter>
                <PrimaryButton text="Save" iconProps={{iconName: "Save"}} onClick={() => props.onSave(update) } />
                <DefaultButton text="Cancel" iconProps={{iconName: "Cancel"}} onClick={props.onDismiss} />
            </DialogFooter>
        </Dialog>
    );
};

export default EditUpdateDialog;