import { IPersonaProps } from "office-ui-fabric-react";

export async function searchUsers(searchStr: string): Promise<IPersonaProps[]> {
    const response = await fetch("/api/users/" + searchStr, {
        headers: {
            Accept: "application/json",
        },
        credentials: 'include',
    });
    const json = await response.json();
    return json.map((r: any) => {
        return {
            itemID: r.id,
            secondaryText: r.mail,
            text: r.displayName,
            imageUrl: r.photo
        } as IPersonaProps;
    });
}

export async function searchDevs(searchStr: string): Promise<IPersonaProps[]> {
    const response = await fetch("/api/devTeam/" + searchStr, {
        headers: {
            Accept: "application/json",
        },
        credentials: 'include',
    });
    const json = await response.json();
    return json.map((r: any) => {
        return {
            itemID: r.id,
            secondaryText: r.mail,
            text: r.displayName,
        } as IPersonaProps;
    });
}

export interface ISPUser {
    DisplayName: string;
    Mail: string;
}
