import * as React from "react";
import { Layer, Stack } from "office-ui-fabric-react";

import styles from "./ToastNotifications.module.scss";

export interface INotification {
    iconName?: string;
    text: string;
    time: number;
}

export interface IToastNotificationsProps {
    notifications: INotification[];
    onExpiry: (idx: number) => void;
}

export const ToastNotifications = (props: IToastNotificationsProps) => {
    if (props.notifications && props.notifications.length) {
        return (
            <Layer>
                <Stack style={{margin: 20}}>
                    { props.notifications.map((notification, idx) => (
                        <div key={`${idx}_${notification.time}`} className={styles.toastNotification} onAnimationEnd={(e) => {
                            props.onExpiry(notification.time);
                        }}>
                            {notification.text}
                        </div>
                    ))}
                </Stack>
            </Layer>
        );
    } else {
        return null;
    }
};

export default ToastNotifications;