import { IDevelopmentResponse, IDevelopmentUpdatesResponse, IServiceResponse } from "../types";

export async function getUpdatesByDevelopmentId(devId: number): Promise<IDevelopmentUpdatesResponse | undefined> {
    return fetch("/api/updates/development/" + devId + "?" + Date.now(), {
        headers: {
            Accept: "application/json",
        },
        credentials: 'include',
    }).then((response) => {
        return response.json();
    });
}

export async function addLatestUpdateToDevelopment(devId: number, entry: string, privacy: boolean): Promise<IDevelopmentResponse | undefined> {
    return fetch("/api/updates/development/" + devId, {
        method: 'POST',
        body: JSON.stringify({
            entry,
            privacy
        }),
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        credentials: 'include',
    }).then((response) => {
        return response.json();
    });
}

export async function modifyUpdate(updateId: number, entry: string, privacy: boolean, date: string): Promise<IServiceResponse | undefined> {
    return fetch(`/api/updates/${updateId}`, {
        method: 'POST',
        body: JSON.stringify({
            entry,
            privacy,
            date
        }),
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        credentials: 'include',
    }).then((response) => response.json());
}

export async function deleteUpdate(updateId: number): Promise<Response> {
    const deletionResult = await fetch(`/api/updates/${updateId}`, {
        method: 'DELETE',
        credentials: 'include'
    });
    return deletionResult;
}